// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PantallaCarga__PantallaCarga__7ht1D {\r\n  display: flex;\r\n  flex-direction: row;\r\n  flex: 1 1;\r\n  width: 100%;\r\n  height: 100%;\r\n  position: fixed;\r\n  place-content: center;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PantallaCarga": "PantallaCarga__PantallaCarga__7ht1D"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useRef } from "react";
import { connect } from "react-redux";

import classes from "./TablaDeudasDelMesPorSeleccion.css";

import * as actions from "../../../../store/actions/index";
import { formatNumber, isEven } from "../../../../assets/shared/formatNumber";
import { storageMonedaSigno } from "../../../../assets/shared/sessionData";
import FilterListIcon from "@material-ui/icons/FilterList";
import { IconButton, Tooltip } from "@material-ui/core";
import Table from "../../../UI/Table/Table";
import TablaDeudasPorSeleccionToExcel from "./TablaDeudasPorSeleccionToExcel";
import TablaDeudasPorClienteDeLaSeleccion from "../TablaDeudasPorProveedorDeLaSeleccion/TablaDeudasPorProveedorDeLaSeleccion";
import { dataOptions } from "../../../../assets/constants/dataOptions/OrdenamientoDeudaProveedor";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Proveedor from "../../../../containers/Proveedor/Proveedor";

const TablaDeudasDelMesPorSeleccion = (props) => {
  const { ordenamientoSelected, fechaDeudaDelMes, onOpenProveedorModal } = props;

  const columns = [
    {
      title: dataOptions[ordenamientoSelected].title,
      field: "name",
      align: "left",
      cellStyle: {
        fontSize: "12px",
        paddingBottom: "5px",
        paddingTop: "5px",
        height: "49px",
      },
      headerStyle: {
        fontSize: "14px",
        paddingTop: "0px",
        paddingBottom: "0px",
        backgroundColor: "#fafafa",
      },
    },
    {
      title: `Deuda`,
      field: "deuda",
      align: "right",
      type: "numeric",
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
          color: cellData < 0 && "red",
        };
      },
      headerStyle: {
        fontSize: "14px",
        paddingTop: "0px",
        paddingBottom: "0px",
        backgroundColor: "#fafafa",
      },
      render: (rowData) =>
        `${storageMonedaSigno()} ${formatNumber(rowData.deuda)}`,
    },
    {
      title: `Porc. participación`,
      field: "porcParticipacion",
      align: "right",
      type: "numeric",
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
          color: cellData < 0 && "red",
        };
      },
      headerStyle: {
        fontSize: "14px",
        paddingTop: "0px",
        paddingBottom: "0px",
        backgroundColor: "#fafafa",
      },
      render: (rowData) => `${formatNumber(rowData.porcParticipacion)}%`,
    },
    {
      title: ``,
      field: "id",
      align: "right",
      type: "numeric",
      sorting: false,
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
          color: cellData < 0 && "red",
          width: "8%",
        };
      },
      headerStyle: {
        fontSize: "14px",
        paddingTop: "0px",
        paddingBottom: "0px",
        backgroundColor: "#fafafa",
      },
      render: (rowData) =>
        ordenamientoSelected === 1 ? buildActionsButtons(rowData) : ``,
    },
  ];

  const openEstadoProveedor = (idProveedor) => {
    props.onClearChart();
    sessionStorage.setItem("idProveedor", idProveedor);

    const title= "Estado del proveedor";
    const body= estadoProveedor();
    onOpenProveedorModal(true, title, body);
  }

  const estadoProveedor = () => {
    return (
      <Proveedor />
    )
  }

  const buildActionsButtons = (rowData) => {
    return (
      <div style={{ textAlign: "right" }}>
        <Tooltip title="Ver estado del proveedor">
          <IconButton size="small" onClick={() => openEstadoProveedor(rowData.id)}>
            <VisibilityIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const data = () => {
    return props[
      dataOptions[ordenamientoSelected].stateKey
    ].deudasDataTable.map((deuda) => {
      return {
        id: deuda.id,
        mes: deuda.mes,
        anio: deuda.anio,
        name: deuda.nombre,
        porcParticipacion: deuda.porcentajeDeParticipacion,
        deuda: deuda.monto,
        creditoMaximo: deuda.creditoMaximo,
      };
    });
  };

  const tableRef = useRef();

  return (
    <div className={classes.TablaDeudasDelMesPorSeleccionContainer}>
      <div className={classes.TitleCard}>
        {!props[dataOptions[ordenamientoSelected].stateKey].loading && (
          <TablaDeudasPorSeleccionToExcel
            deudas={data()}
            fecha={`${fechaDeudaDelMes.mes}/${fechaDeudaDelMes.anio}`}
            deudasPor={dataOptions[ordenamientoSelected].title}
          />
        )}
        <Tooltip title="Ver filtros del indicador">
          <IconButton size="small" onClick={() => props.onShowFiltersModal()}>
            <FilterListIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
      <Table
        tableRef={tableRef}
        title=""
        loading={props[dataOptions[ordenamientoSelected].stateKey].loading}
        columns={columns}
        data={data()}
        noBoxShadow
        detailPanel={
          ordenamientoSelected === 0
            ? (rowData) => {
                return (
                  <TablaDeudasPorClienteDeLaSeleccion
                    ordenamiento={ordenamientoSelected}
                    itemExpanded={{
                      ...rowData,
                      mes: fechaDeudaDelMes.mes,
                      anio: fechaDeudaDelMes.anio,
                    }}
                  />
                );
              }
            : undefined
        }
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: 430,
          rowStyle: (rowData, index) => ({
            color: rowData.tableData.monto < 0 && "red",
            backgroundColor: !isEven(index) && "#f2f2f2",
          }),
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fechaDeudaDelMes: state.compras.fechaDeudasDelMesAnioProveedores,
    ordenamientoSelected: state.compras.ordenamientoDeudaDelMesPorSelectedProveedores,
    deudasPorCategoriaDeCliente: state.compras.deudaDelMesProveedoresPorCategoriaDeCliente,
    deudasPorProveedor: state.compras.deudaDelMesProveedoresPorProveedor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: (deudasPor) => dispatch(actions.showListFiltersModal(true, `Deudas del mes por ${deudasPor}`, ["deuda",])),
    onClearChart: () => dispatch(actions.clearIndicadoresProveedor()),
    onOpenProveedorModal: (show, title, body) => dispatch(actions.showEstadoModal(show, title, body))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaDeudasDelMesPorSeleccion);

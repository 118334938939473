import React from "react";
import { connect } from "react-redux";

import TextChart from "../../../Charts/TextChart";

const TextoSaldoProveedor = (props) => {
  const { proveedor, saldo, onBodyClick, loading } = props;

  return (
    <TextChart
      title={`Saldo del proveedor`}
      bigAmount={proveedor && saldo ? saldo : 0}
      noClient={!proveedor}
      bigCurrency
      loading={loading}      
      onBodyClick={() => onBodyClick()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    saldo: state.proveedores.saldo.saldo,
    loading: state.proveedores.saldo.loading,
    proveedor: state.proveedores.proveedorById.proveedor,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoSaldoProveedor);
export const montoMesAnioToDataTablePorMesWithTotal = (datas) => {
 let formatDataTable = [];
 let total = 0;

 datas?.map((data) => {
   if (!formatDataTable.hasOwnProperty(data.anio)) {
     formatDataTable[data.anio] = {
       anio: data.anio,
       meses: [],
     };
   }
   formatDataTable[data.anio].meses.push({
     mes: data.mes,
     monto: data.monto,
   });
   return (total += data.monto);
 });

 return { formatedData: formatDataTable, total: total };
}
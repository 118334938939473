import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "../../UI/Dialog/Dialog";
import Button from "../../UI/Button/Button";
import { storageFechaFija } from "../../../assets/shared/sessionData";

import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/es";
import { Fragment } from "react";
import { compareFechaActualFechaFija } from "../../../assets/shared/fechas";
import LightTooltip from "../../UI/LightTooltip/LightTooltip";
import { Typography } from "@material-ui/core";

moment.locale("es");

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto 10px", 
    display: "flex", 
    alignItems: "center",
    justifyContent: 'flex-start',
  },
  tooltip: {
    textAlign: "left",
    font: "normal normal normal 14px/19px Roboto",
    color: "8D8C8C",
  },
  icon: {
    fontSize: "1.8rem",
    cursor: "pointer",
    marginRight: "10px",
    paddingTop: 0,
    paddingBottom: 0
  },
  iconMobile: {   
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'inline',
      color: 'white',
    }
  },
}));

const FechaFijaSelector = (props) => {
  const classes = useStyles();
  const { onCambiarFechaFija, iconSize } = props;
  const [selectedDate, setSelectedDate] = useState(
    new Date(storageFechaFija())
  );
  const [showCalendar, setShowCalendar] = useState(false);

  const onChangeFechaFijaClick = () => {
    onCambiarFechaFija(new Date(selectedDate));
  };

  const handleFechaFijaChange = (date) => {
    setSelectedDate(date);
  };

  const onCloseClick = () => {
    setSelectedDate(new Date(storageFechaFija()));
    setShowCalendar(false);
  };

  const onHoyFechaFijaClick = () => {
    onCambiarFechaFija(new Date());
  };

  return (
    <Fragment>
      <div className={classes.root} onClick={() => setShowCalendar(true)}>
        <LightTooltip
          arrow
          title={
            <Typography className={classes.tooltip}>
              Fecha seleccionada: {moment(selectedDate).format("DD/MM/YYYY")}
            </Typography>
          }
        >
          <DateRangeIcon
            className={classes.icon}
            style={{
              color: props.color, 
              fontSize: props.iconSize,
            }}            
          />
        </LightTooltip>
        <Typography className={classes.iconMobile}>
          Fecha selec.: {moment(storageFechaFija()).format('DD/MM/YYYY')}
        </Typography>
      </div>
      {showCalendar && (
        <Dialog
          show={showCalendar}
          title={
            <div style={{ width: "98%", textAlign: "right" }}>
              <IconButton aria-label="close" onClick={() => onCloseClick()}>
                <CloseIcon />
              </IconButton>
            </div>
          }
          onClose={() => onCloseClick()}
          body={
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={MomentUtils}
              locale={"es"}
            >
              <DatePicker
                autoOk
                orientation="landscape"
                maxDate={new Date()}
                variant="static"
                onChange={(date) => handleFechaFijaChange(date)}
                openTo="date"
                value={moment(selectedDate.toISOString())}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  float: "right",
                }}
              >
                {!compareFechaActualFechaFija() && (
                  <Button
                    size="small"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      opacity: 0.5,
                      border: "none",
                    }}
                    onClick={() => onHoyFechaFijaClick()}
                  >
                    Hoy
                  </Button>
                )}
                <Button onClick={() => onChangeFechaFijaClick()}>
                  Aceptar
                </Button>
              </div>
            </MuiPickersUtilsProvider>
          }
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  onCambiarFechaFija: (nuevaFechaFija) =>
    dispatch(actions.cambiarFechaFija(nuevaFechaFija)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FechaFijaSelector);

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccordionObservacionesComprobante__AccordionCustomRoot__\\+v4Ks {\r\n  margin-bottom: 10px;\r\n  border: none;\r\n  box-shadow: none !important;\r\n}\r\n\r\n.AccordionObservacionesComprobante__AccordionCustomSummaryRoot__8q1Pr {  \r\n  display: inline-flex !important;\r\n}\r\n\r\n.AccordionObservacionesComprobante__AccordionTitle__3CJe- {\r\n  font-size: small;\r\n  font-weight: 500;\r\n  margin-left: -15px;\r\n}\r\n\r\n.AccordionObservacionesComprobante__AccordionCustomRounded__sp95A::before {\r\n  display: none !important;\r\n}\r\n\r\n.AccordionObservacionesComprobante__AccordionDetailCustomRoot__6Zog\\+ {\r\n  background-color: rgb(245, 245, 245);\r\n  border: 1px solid #acacac99;\r\n  border-radius: 3px;\r\n  max-height: 200px;\r\n  overflow-y: auto;\r\n  overflow-x: hidden;\r\n}\r\n\r\n.AccordionObservacionesComprobante__ObservacionDescription__UgINl {\r\n  font-weight: 500;\r\n  font-size: small;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AccordionCustomRoot": "AccordionObservacionesComprobante__AccordionCustomRoot__+v4Ks",
	"AccordionCustomSummaryRoot": "AccordionObservacionesComprobante__AccordionCustomSummaryRoot__8q1Pr",
	"AccordionTitle": "AccordionObservacionesComprobante__AccordionTitle__3CJe-",
	"AccordionCustomRounded": "AccordionObservacionesComprobante__AccordionCustomRounded__sp95A",
	"AccordionDetailCustomRoot": "AccordionObservacionesComprobante__AccordionDetailCustomRoot__6Zog+",
	"ObservacionDescription": "AccordionObservacionesComprobante__ObservacionDescription__UgINl"
};
export default ___CSS_LOADER_EXPORT___;

import React, { Suspense, useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";

import Spinner from "./components/UI/Spinner/Spinner";
import Layout from "./containers/Layout/Layout";

import * as actions from "./store/actions";

import PantallaCarga from "./containers/PantallaCarga/PantallaCarga";
import AnalisisDeudaCliente from "./containers/AnalisisDeudaCliente/AnalisisDeudaCliente";
import SimpleBackdrop from "./components/UI/Backdrop/Backdrop";
import Logout from "./containers/Auth/Logout/Logout";
import Cliente from "./containers/Cliente/Cliente";

import { storageToken } from "./assets/shared/sessionData";
import IframePortal from "./containers/Auth/IframePortal/IframePortal";
import AnalisisDeudaProveedores from "./containers/AnalisisDeudaProveedores/AnalisisDeudaProveedores";
import Login from "./containers/Auth/Login/Login";
import Proveedor from "./containers/Proveedor/Proveedor";

const App = (props) => {
  const {
    onTryAuthSignin,
    login,
    token,
    loadingAuth,
    loadingIndicadoresOcultos,
    loadingCambiarEmpresa,
    loadingCambiarMoneda,
    loadingExecuteCambiarAjustaPorInflacion,
  } = props;

  const { pathname, search } = useLocation();

  useEffect(() => {    
    if (storageToken()) {
      onTryAuthSignin();
    }
  }, [onTryAuthSignin, storageToken]);

  useEffect(() => {
    if (!storageToken()) {
      login(search.slice(1));
    }
  }, [login, search, storageToken]);

  let routes = (
    <Switch>
      <Route path="/" component={PantallaCarga} />
    </Switch>
  );

  if (token !== null && !loadingAuth){
    routes = (
      <Switch>
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/analisisDeudaProveedor" render={(props) => <AnalisisDeudaProveedores {...props} />} />
        <Route exact path="/analisisDeudaCliente" render={(props) => <AnalisisDeudaCliente {...props} />} />
        <Route exact path="/cliente/:id" render={(props) => <Cliente {...props} />} />
        <Route exact path="/proveedor/:id" render={(props) => <Proveedor {...props} />} />
        {token !== null ? <Redirect push to="/analisisDeudaCliente" /> : <Login />}
      </Switch>
    );
  }

  const loadingIndicadoresOcultosWithHome =
    (pathname === "/analisisDeudaCliente" || pathname === "") &&
    loadingIndicadoresOcultos;

  return (
    <div>
      <Suspense fallback={<Spinner />}>
        <Layout>
          <SimpleBackdrop
            open={
              loadingCambiarEmpresa ||
              loadingCambiarMoneda ||
              loadingExecuteCambiarAjustaPorInflacion ||
              loadingIndicadoresOcultosWithHome
            }
          />
          {routes}
        </Layout>
        <IframePortal />
      </Suspense>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    loadingAuth: state.auth.loading,
    loadingCambiarEmpresa: state.multiempresa.loadingCambiarEmpresa,
    loadingCambiarMoneda: state.moneda.loadingCambiarMoneda,
    loadingExecuteCambiarAjustaPorInflacion: state.configuraciones.executeCambiarAjustaPorInflacion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAuthSignin: () => dispatch(actions.authCheckState()),
    login: (token) => dispatch(actions.login(token)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  token: null,
  error: null,
  loading: false,
  authRedirectPath: '/analisisDeudaCliente',
  ajustaInflacion: false,
  userInfo: {}
};

const authStart = (state) => {
  return updateObject(state, { token: null, error: null, loading: true, authRedirectPath: '/analisisDeudaCliente' });
};

const authSuccess = (state, token) => {
  return updateObject(state, {
    error: null,
    loading: false,
    token: token
  });
};

const authFail = (state, error) => {
  return updateObject(state, { loading: false, error: error });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    token: null,
    authRedirectPath: '/'
  });
};

const setAuthRedirectPath = (state, path) => {
  return updateObject(state, {
    authRedirectPath: path
  });
};

const setUserInfo = (state, user) => {
  console.log(user);
  return updateObject(state, {
    userInfo: updateObject(state.userInfo, user),
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state);

    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action).token;

    case actionTypes.AUTH_FAIL:
      return authFail(state, action.error);

    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);

    case actionTypes.AUTH_SET_REDIRECT_PATH:
      return setAuthRedirectPath(state, action.path);

    case actionTypes.SET_USER_INFO:
      return setUserInfo(state, action.user);

    default:
      return state;
  }
};

export default reducer;

import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import Dialog from "../Dialog/Dialog";
import { Typography, IconButton, Divider } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EnviarChartEmailModal from "./EnviarChartEmailModal";

const useStyles = makeStyles({ 
  titleTexto: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
});

const EnviarChart = (props) => {
  const classes = useStyles();

  const {
    show,
    onClose,
  } = props;

  const sendChartDialog = () => {
    return (
      <EnviarChartEmailModal />
    )
  }

  return (
    <Fragment>
      <div id="enviarChart"></div>
      {show && (
        <Dialog
          fullWidth
          maxWidth={"sm"}
          title={
            <div style={{ width: "100%" }}>
              <div className={classes.titleTexto}>
                <Typography style={{ fontSize: "large" }}>Enviar gráfico</Typography>
                <IconButton aria-label="close" onClick={() => onClose()}>
                  <CloseIcon />
                </IconButton>
              </div>
              <Divider />
            </div>
          }
          show={show}
          body={sendChartDialog()}
          onClose={() => onClose()}
        />
      )}
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    show: state.modals.sendChartModal.show,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => dispatch(actions.showSendChartModal(false, null)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnviarChart);
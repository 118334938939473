import React from "react";

import Spinner from "../../components/UI/Spinner/Spinner";

import classes from "./PantallaCarga.css";

const PantallaCarga = () => {
  return (
    <div style={{ textAlign: "center" }} className={classes.PantallaCarga}>
      <Spinner />
    </div>
  );
};

export default PantallaCarga;

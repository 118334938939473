// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BackButtonModal__TitleDialogContent__hYJnx {\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin-right: 5px;\r\n}\r\n\r\n.BackButtonModal__TitleDialogTexto__3RMAF {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  padding-left: 10px;\r\n  padding-right: 5px;\r\n}\r\n\r\n.BackButtonModal__BotonCancelar__FVYcu {\r\n  width: 100% !important;\r\n  margin-inline: 10px !important;\r\n}\r\n\r\n.BackButtonModal__BotonConfirmar__S4l3H {\r\n  width: 100% !important;\r\n  margin-inline: 10px !important;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TitleDialogContent": "BackButtonModal__TitleDialogContent__hYJnx",
	"TitleDialogTexto": "BackButtonModal__TitleDialogTexto__3RMAF",
	"BotonCancelar": "BackButtonModal__BotonCancelar__FVYcu",
	"BotonConfirmar": "BackButtonModal__BotonConfirmar__S4l3H"
};
export default ___CSS_LOADER_EXPORT___;

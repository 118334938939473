import * as actionTypes from './actionTypes';

export const cambioPaginaRender = (page) => {
  return {
    type: actionTypes.CAMBIO_PAGINA_RENDER,
    page
  };
};

export const ejecutarAnalisisDeudaCliente = () => {
  return {
    type: actionTypes.EJECUTAR_ANALISIS_DEUDA_CLIENTE
  };
};

export const ejecutarAnalisisDeudaProveedor = () => {
  return {
    type: actionTypes.EJECUTAR_ANALISIS_DEUDA_PROVEEDOR,
  };
};

import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
import Spinner from "../../../UI/Spinner/Spinner";
import classes from "./CardCliente.css";

const CardCliente = (props) => {
  const { cliente, clientSelected, loading } = props;
  const [seconds, setSeconds] = useState(1);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!clientSelected) {
        setSeconds(seconds + 1);
      }
    }, 2000);

    // clearing interval
    return () => clearInterval(timer);
  });

  return loading ? (
    <div className={classes.CardClienteContainer}>
      <div style={{ textAlign: 'center' }}>
        <Spinner />
      </div>
    </div>
  ) : (
    <div className={classes.CardClienteContainer}>  
      <Grid container spacing={1} >
        <Grid item xs={12}>
          <Typography
            style={{ marginRight: "5px", fontSize: '14px' }}
            align="left"
          >
            <span style={{ fontWeight: 'bold' }}>{cliente.razonSocial} ({cliente.idCliente && cliente.idCliente.trimEnd()})</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ marginRight: "5px", fontSize: '14px' }}
            align="left"
          >
            <span style={{ fontWeight: 'bold' }}>Dirección: </span>{" "}{clientSelected && `${cliente.direccion}, ${cliente.localidad}`}
          </Typography>          
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ marginRight: "5px", fontSize: '14px' }}
            align="left"
          >
            <span style={{ fontWeight: 'bold' }}>Mail: </span>{" "}{clientSelected && cliente.mail}
          </Typography>
          <Typography>
          </Typography>        
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ marginRight: "5px", fontSize: '14px' }}
            align="left"
          >
            <span style={{ fontWeight: 'bold' }}>Tel: </span>{clientSelected && cliente.telefonos[0].numero}
          </Typography>          
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ marginRight: "5px", fontSize: '14px' }}
            align="left"
          >
            <span style={{ fontWeight: 'bold' }}>Provincia: </span>{" "}{clientSelected && cliente.provincia}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ marginRight: "5px", fontSize: '14px' }}
            align="left"
          >
            <span style={{ fontWeight: 'bold' }}>Tel 2: </span>{" "}{clientSelected && cliente.telefonos[1] && cliente.telefonos[1].numero}
          </Typography>        
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ marginRight: "5px", fontSize: '14px' }}
            align="left"
          >
            <span style={{ fontWeight: 'bold' }}>CUIT: </span> {clientSelected && cliente.cuit}
          </Typography>          
        </Grid>
      </Grid>    
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cliente: state.clientes.clienteById.cliente,
    loading: state.clientes.clienteById.loading,
    clientSelected: state.clientes.clienteById.clientSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUnmountCard: () => dispatch(actions.unmountClienteById()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardCliente);

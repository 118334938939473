// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DeleteableItem__DeleteableItemContainer__\\+Ob6- {\r\n    border: 2.5px solid #00bcd4;\r\n    font-size: smaller;\r\n    font-weight: 400;\r\n    border-radius: 17px;\r\n    padding-right: 10px;\r\n    padding-left: 10px;\r\n    margin-right: 10px;\r\n    transition: 0.3s;\r\n    cursor: pointer;\r\n}\r\n\r\n.DeleteableItem__DeleteableItemContainer__\\+Ob6-:hover{\r\n    border-color: #00bbd4a3;\r\n    transition: 0.3s;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DeleteableItemContainer": "DeleteableItem__DeleteableItemContainer__+Ob6-"
};
export default ___CSS_LOADER_EXPORT___;

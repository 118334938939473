import React from "react";
import { storageMonedaSigno } from "../../../../assets/shared/sessionData";
import { formatNumber, isEven } from "../../../../assets/shared/formatNumber";
import { IconButton, Tooltip } from "@material-ui/core";
import { PieChartOutline } from "@mui/icons-material";
import ExportToExcelIcon from "../../../UI/Icons/ExportToExcelIcon";
import Spinner from "../../../UI/Spinner/Spinner";
import Table from "../../../UI/Table/Table";
import classes from "./PieOrdenesDeCompraPendientes.css";

const TableOrdenesDeCompraPendientes = (props) => {

  const { ordenesDeCompra, loading, totalOrdenesDeCompra } = props;

  const headerStyle = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: '#c9c9c9'
  };

  const cellStyles = (value, width) => {
    return {
      fontSize: '12px',
      paddingBottom: '5px',
      paddingTop: '5px',
      width: width,
      color: value < 0 && 'red'
    }
  };

  const columns = [
    {
      title: 'Orden De Compra Pendiente',
      field: 'nombre',
      align: 'left',
      cellStyle: (cellData) => cellStyles(cellData, '35%'),
      headerStyle: { ...headerStyle, width: '35%' }
    },
    {
      title: 'Monto',
      field: 'monto',
      align: 'right',
      cellStyle: (cellData) => cellStyles(cellData, '30%'),
      headerStyle: { ...headerStyle, width: '30%' },
      render: (rowData) => `${storageMonedaSigno()}${formatNumber(rowData.monto)}`

    },
    {
      title: 'Porc. Participacion',
      field: 'porcParticipacion',
      align: 'right',
      cellStyle: (cellData) => cellStyles(cellData, '30%'),
      headerStyle: { ...headerStyle, width: '30%' },
      render: (rowData) => `${formatNumber(rowData.porcParticipacion)}%`
    }
  ];

  const data = () => {
    return ordenesDeCompra && ordenesDeCompra.map((ordenDeCompra) => {
      return {
        nombre: ordenDeCompra.name,
        porcParticipacion: ordenDeCompra.value && ordenDeCompra.value > 0 ? (ordenDeCompra.value / totalOrdenesDeCompra) * 100 : 0,
        monto: ordenDeCompra.value,
      };
    });
  }

  //#region Export to Excell
  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center' }
  };

  const cellsStyleVerifNegative = (value) => {
    return {
      font: { sz: '10', bold: false, color: { rgb: value < 0 && 'FFFF0000' } },
      alignment: { horizontal: 'right' },
    };
  };

  const columnsForExcel = () => {
    return [
      {
        title: 'Orden De Compra Pendiente',
        width: { wpx: 150 },
        align: 'left',
        style: headersStyle
      },
      {
        title: 'Monto',
        width: { wpx: 150 },
        align: 'right',
        style: headersStyle
      },
      {
        title: 'Porc. Participacion',
        width: { wpx: 150 },
        align: 'right',
        style: headersStyle
      }
    ]
  };

  const formatData = () => {
    let tableData = [];

    ordenesDeCompra && ordenesDeCompra.map((ordenDeCompra) => {
      const porcentajeDeParticipacion = ordenDeCompra.value && ordenDeCompra.value > 0 ? (ordenDeCompra.value / totalOrdenesDeCompra) * 100 : 0;
      const row = [
        {
          value: ordenDeCompra.name,
          style: { font: { sz: '10' } }
        },
        {
          value: `${storageMonedaSigno()}${formatNumber(ordenDeCompra.value)}`,
          style: cellsStyleVerifNegative(ordenDeCompra.value)
        },
        {
          value: `${formatNumber(porcentajeDeParticipacion)}%`,
          style: cellsStyleVerifNegative(porcentajeDeParticipacion)
        }
      ];

      return tableData.push(row);
    });

    return tableData;
  }

  const multiDataSet = [
    {
      columns: columnsForExcel(),
      data: formatData()
    }
  ];
  //#endregion

  return (
    <div style={{ height: '100%', width: props.width, minHeight: props.minHeight }}>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', margin: '3px 5px 0 20px' }}>
        <div className={classes.TitleCard}>
          <div style={{ fontWeight: 'bold', marginBottom: '1px', marginTop: '14px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
            {props.title}
          </div>
          {props.onChangeViewClick && !props.disabled && (
            <Tooltip title="Ver como torta">
              <IconButton size="small" onClick={() => props.onChangeViewClick()}>
                <PieChartOutline />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className={classes.TitleCard}>
          {props.subTitle && <p style={{ marginTop: '1px', marginBottom: '2px', fontSize: '12px' }}>{props.subTitle}</p>}
          {ordenesDeCompra && ordenesDeCompra.length > 0 && (<ExportToExcelIcon
            filename={props.filename}
            name={'Ordenes De Compra Pendientes'}
            dataSet={multiDataSet}
          />)}
        </div>
        {loading ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Spinner />
          </div>
        ) : (
          <div className={classes.TablaContainer}>
            <Table
              style={{ width: '100%', marginBottom: '20px' }}
              columns={columns}
              data={data()}
              loading={loading}
              customOptions={{
                draggable: false,
                search: false,
                paging: false,
                toolbar: false,
                showTitle: false,
                maxBodyHeight: props.height,
                rowStyle: (rowData, index) => ({
                  backgroundColor: isEven(index) ? "#f2f2f2" : "white",
                }),
              }}
              tableKey={"table-ordenes-de-compra-pendientes"}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default TableOrdenesDeCompraPendientes;
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formatNumber } from '../../../../assets/shared/formatNumber';
import { storageMonedaSigno } from '../../../../assets/shared/sessionData';
import LineChart from '../../../Charts/LineChart';
import Spinner from '../../../UI/Spinner/Spinner';

const LineChequesEnCartera = (props) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false), 500);
  }, []);

  return loading 
    ? (
      <div style={{ textAlign: 'center' }}>
        <Spinner />
      </div>      
    ) : (
    <LineChart
      title={`Cheques en cartera ${props.empresa ? props.empresa.nombre : ''}`}
      subTitle={`Total: ${storageMonedaSigno()} ${formatNumber(props.totalCheques)}`}
      textoTooltip={'Monto'}
      series={props.cheques.map((cheque) => cheque.valor)}
      categories={props.cheques.map((cheque, index) =>
        index === 0 ? cheque.mes : `${new Date(cheque.anio, cheque.mes - 1, 1)}`
      )}
      loading={props.loading}
      imgSinDatos={props.cheques.length === 0}
      showXLabels
      height={250}
      width="100%"
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.cheques.lineChequesEnCartera.loading,
    cheques: state.cheques.lineChequesEnCartera.cheques,
    totalCheques: state.cheques.lineChequesEnCartera.totalCheques
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LineChequesEnCartera);

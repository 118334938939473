import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { storageEmpresaId } from '../../../assets/shared/sessionData';
import * as actions from "../../../store/actions";

import Spinner from '../Spinner/Spinner';
import { Tooltip } from '@material-ui/core';
import { pathNamePages } from '../../../assets/shared/pathnames';

const EmpresaSelector = (props) => {
  const [empresaId, setEmpresaId] = useState(storageEmpresaId());
  const {
    loading,
    cargandoIndicadores,
    setEmpresasessionStorage,
    onChangeValue,
    empresas,
    indicadoresOcultos,
    onLoadEmpresas,
    onSeleccionarEmpresa,
    onActualizarIndicadoresAnalisisDeudaCliente,
    onUpdateUltimaActualizacionAnalisisDeudaCliente,
    // loadingCambiarEmpresa,
    // onLimpiarIndicadoresAnalisisDeudaCliente,
    // onLimpiarUltimaActualizacionAnalisisDeudaCliente,
    // onLoadVendedores,
  } = props;

  useEffect(() => {
    if (empresas.length === 0) {
      onLoadEmpresas();
    }
  }, []);

  const onEmpresaClick = (empresa) => {
    if (setEmpresasessionStorage) {
      onSeleccionarEmpresa(empresa.id, () => {
        props.onLoadVendedores();
        setEmpresaId(storageEmpresaId());
        actualizarIndicadoresPaginaActivaDesmontarOtras();
      });
    } else {
      onChangeValue(empresa);
    }
  };

  const urlRef = global.window.location.pathname;
  const dateActual = moment(Date.now()).format('DD/MM/YYYY HH:mm');

  const actualizarIndicadoresPaginaActivaDesmontarOtras = () => {
    switch (urlRef) {
      case pathNamePages.analisisDeudaCliente:
        return actualizarAnalisisDeudaClienteLimpiarOtros();

      case pathNamePages.home:
        return actualizarAnalisisDeudaClienteLimpiarOtros();

      default:
        break;
    }
  };

  const actualizarAnalisisDeudaClienteLimpiarOtros = () => {
    if (props.ejecucionAnalisisDeudaCliente) {
      onUpdateUltimaActualizacionAnalisisDeudaCliente(dateActual);
      onActualizarIndicadoresAnalisisDeudaCliente(indicadoresOcultos);
    }
  };

  

  return loading ? (
    <Spinner personalSize="35px" />
  ) : (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px', zIndex: 1200 }}>
      <div>
        {props.empresas.map((empresa, key) => (
          <Tooltip
            title="Cargando indicadores..."
            placement="right"
            arrow
            open={cargandoIndicadores && key === props.empresas.length - 1}
            key={key}
          >
            <button
              disabled={cargandoIndicadores}
              key={key}
              style={{
                margin: '0px',
                outline: 'none',
                color: empresa.id === empresaId ? '#FFFFFF' : '#CECECE',
                border: '0.5px solid #CECECE',
                textAlign: 'center',
                font: 'normal normal medium 12px/16px Roboto',
                backgroundColor: empresa.id === empresaId ? '#0CC5FF' : '#FFFFFF',
                borderRadius: '3px 0px 0px 3px',
                cursor: empresa.id !== empresaId && !cargandoIndicadores && 'pointer'
              }}
              onClick={() => empresa.id !== empresaId && onEmpresaClick(empresa)}
            >
              {empresa.nombre}
            </button>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.multiempresa.empresas.loading,
    empresas: state.multiempresa.empresas.empresas,
    ejecucionAnalisisDeudaCliente: state.ejecucionPaginas.ejecucionAnalisisDeudaCliente,
    loadingCambiarEmpresa: state.multiempresa.loadingCambiarEmpresa,
    indicadoresOcultos: {
      ventasDelMes: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ventasDelMes,
      ventasDelDia: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ventasDelDia,
      ivaFiscal: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ivaFiscal,
      cobranzasDelMes: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.cobranzasDelMes,
      gastosPorCategoria: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.gastosPorCategoria,
      saldoDeudores: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.saldoDeudores,
      saldoAcreedores: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.saldoAcreedores,
      plazoMedioVencACobrar: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.plazoMedioVencACobrar,
      plazoMedioVencAPagar: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.plazoMedioVencAPagar,
      promDiasDeCobranzas: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.promDiasDeCobranzas,
      promDiasDePagos: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.promDiasDePagos,
      chequesEnCartera: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesEnCartera,
      chequesEmitidos: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesEmitidos,
      gastosDelMes: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.gastosDelMes,
      pagosDelMes: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.pagosDelMes,
      deudaPorCategoriaDeCliente:
        state.configuraciones.indicadoresOcultosPorUsuario.indicadores.deudaPorCategoriaDeCliente
    },
    indicadoresOcultosMultiempresa: {
      ivaFiscalMultiempresa: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ivaFiscalMultiempresa,
      chequesEnCarteraMultiempresa:
        state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesEnCarteraMultiempresa,
      chequesEmitidosPendientesMultiempresa:
        state.configuraciones.indicadoresOcultosPorUsuario.indicadores.chequesEmitidosPendientesMultiempresa,
      cobranzasMensualesMultiempresa:
        state.configuraciones.indicadoresOcultosPorUsuario.indicadores.cobranzasMensualesMultiempresa,
      gastosDelMesMultiempresa: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.gastosDelMesMultiempresa,
      pagosDelMesMultiempresa: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.pagosDelMesMultiempresa,
      saldoAcreedoresMultiempresa:
        state.configuraciones.indicadoresOcultosPorUsuario.indicadores.saldoAcreedoresMultiempresa,
      saldoDeudoresMultiempresa:
        state.configuraciones.indicadoresOcultosPorUsuario.indicadores.saldoDeudoresMultiempresa,
      ventasDiariasMultiempresa:
        state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ventasDiariasMultiempresa,
      ventasMensualesMultiempresa:
        state.configuraciones.indicadoresOcultosPorUsuario.indicadores.ventasMensualesMultiempresa,
      rentabilidadMultiempresa: state.configuraciones.indicadoresOcultosPorUsuario.indicadores.rentabilidadMultiempresa
    },
    cargandoIndicadores: false
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadEmpresas: () => dispatch(actions.loadEmpresas()),

    onLoadVendedores: () => dispatch(actions.loadVendedores()),

    onSeleccionarEmpresa: (empresaId, success, error) =>
      dispatch(actions.seleccionarEmpresa(empresaId, success, error)),

    //#region Foto del dia
    onActualizarIndicadoresAnalisisDeudaCliente: (indicadoresOcultos) =>
      dispatch(actions.actualizarIndicadoresAnalisisDeudaCliente(indicadoresOcultos)),
    onUpdateUltimaActualizacionAnalisisDeudaCliente: (fechaHora) =>
      dispatch(actions.updateUltimaActualizacionAnalisisDeudaCliente(fechaHora)),
    onLimpiarIndicadoresAnalisisDeudaCliente: () => dispatch(actions.clearIndicadoresAnalisisDeudaCliente()),
    onLimpiarUltimaActualizacionAnalisisDeudaCliente: () => dispatch(actions.clearUltimaActualizacionAnalisisDeudaCliente()),
    //#endregion

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmpresaSelector);

import React, { useEffect, useState } from 'react';

import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { IconButton, Typography } from '@material-ui/core';
import SelectAllIcon from '@material-ui/icons/SelectAll';

import Spinner from '../../UI/Spinner/Spinner';

const ComprobantesConfigBody = (props) => {
  const {
    comprobantes,
    loading,
    onChangeValue,
    onLoad,
    empresaId,
    configInicial,
    seleccionarTodos,
    deseleccionarTodos
  } = props;

  const [seleccionarTodoCheck, setSeleccionarTodoCheck] = useState(null);
  const [comprobantesLocal, setComprobantesLocal] = useState([]);
  const [loadingLocal, setLoadingLocal] = useState(false);

  useEffect(() => {
    setComprobantesLocal(comprobantes);
    return () => setComprobantesLocal([]);
  }, [comprobantes, loading]);

  useEffect(() => {
    onLoad(empresaId);
  }, [empresaId]);

  useEffect(() => {
    enviarValoresTrue(comprobantes);
  }, [loading]);

  const enviarValoresTrue = (comprobantes) => {
    var arr = [];
    comprobantes.map((comprobante) => {
      if (comprobante.estaSeleccionado) {
        return arr.push(comprobante);
      }
      return undefined;
    });

    return configInicial(arr);
  };

  const onSeleccionarTodosClick = () => {
    setLoadingLocal(true);

    var formatComprobantesFalse = [];
    seleccionarTodos();
    setSeleccionarTodoCheck(false);
    comprobantes.map((comprobante) => {
      return formatComprobantesFalse.push({ ...comprobante, estaSeleccionado: false });
    });
    setComprobantesLocal(formatComprobantesFalse);
    setTimeout(() => setLoadingLocal(false), 500);
  };

  const onDeseleccionarTodosClick = () => {
    setLoadingLocal(true);
    var arr = [];
    var formatComprobantesTrue = [];
    comprobantes.map((comprobante) => {
      arr.push(comprobante);
      return formatComprobantesTrue.push({ ...comprobante, estaSeleccionado: true });
    });
    setSeleccionarTodoCheck(true);
    deseleccionarTodos(arr);
    setComprobantesLocal(formatComprobantesTrue);
    setTimeout(() => setLoadingLocal(false), 500);
  };

  const buildSeleccionarTodoButton = () => {
    const verifyTodoSeleccionado = comprobantesLocal.filter((comp) => comp.estaSeleccionado === false);
    const verifyTodoDeseleccionado = comprobantesLocal.filter((comp) => comp.estaSeleccionado === true);
    return (
      <div>
        <IconButton
          onClick={
            seleccionarTodoCheck === null
              ? verifyTodoSeleccionado.length === comprobantesLocal.length
                ? () => onDeseleccionarTodosClick()
                : verifyTodoDeseleccionado.length === comprobantesLocal.length
                ? () => onSeleccionarTodosClick()
                : () => onDeseleccionarTodosClick()
              : seleccionarTodoCheck
              ? () => onSeleccionarTodosClick()
              : () => onDeseleccionarTodosClick()
          }
        >
          <SelectAllIcon style={{ color: seleccionarTodoCheck && 'rgb(9, 212, 255)' }} />
        </IconButton>
        <Typography variant="button">
          {seleccionarTodoCheck === null
            ? verifyTodoSeleccionado.length === comprobantesLocal.length
              ? 'Deseleccionar todos'
              : verifyTodoDeseleccionado.length === comprobantesLocal.length
              ? 'Seleccionar todos'
              : 'Deseleccionar todos'
            : seleccionarTodoCheck
            ? 'Seleccionar todos'
            : 'Deseleccionar todos'}
        </Typography>
      </div>
    );
  };

  const formatComprobantes = () => {
    const formateoComprobantes = [];
    for (var i = 0; i <= comprobantesLocal.length / 12; i++) {
      formateoComprobantes.push(comprobantesLocal.slice(i * 12, i * 12 + 12));
    }

    return (
      <div style={{ display: 'flex' }}>
        {formateoComprobantes.map((comprobantes, key) => (
          <div key={key} style={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
            {comprobantes.map((comprobante, key) => (
              <FormControlLabel
                key={key}
                control={
                  <Switch
                    key={key}
                    defaultChecked={!comprobante.estaSeleccionado}
                    onChange={() => onChangeValue(comprobante)}
                  />
                }
                label={comprobante.nombre.trim()}
              />
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={{ minHeight: loading || loadingLocal ? '205px' : '0px' }}>
      {!loading && !loadingLocal && buildSeleccionarTodoButton()}
      {loading || loadingLocal ? (
        <Spinner />
      ) : (
        <FormControl component="fieldset">
          <FormGroup>{formatComprobantes()}</FormGroup>
        </FormControl>
      )}
    </div>
  );
};

export default ComprobantesConfigBody;

import React from 'react';
import classes from './Input.css';

import TextField from '@material-ui/core/TextField';

const Input = (props) => {
  let validationError = null;
  if (props.invalid && props.touched) {
    validationError = props.errorMessage;
  }

  let elementId = '';
  let elementType = '';

  switch (props.elementConfig.type) {
    case 'password': {
      elementId = 'standard-password-input';
      elementType = 'password';
      break;
    }

    default: {
      elementId = 'standard-basic';
      elementType = null;
    }
  }

  return (
    <div className={classes.Input}>
      <TextField
        className={classes.InputElement}
        id={elementId}
        type={elementType}
        disabled={props.disabled}
        value={props.value}
        label={props.elementConfig.placeholder}
        error={validationError ? true : false}
        helperText={validationError}
        onChange={props.valueChanged}
      />
    </div>
  );
};

export default Input;

import React from "react";
import { connect } from "react-redux";

import * as actions from "../../../../store/actions";
import TextChart from "../../../Charts/TextChart";

import {
  fechaFinPeriodoMesActualFunc,
  fechaFinPeriodoMesAnteriorFunc,
  fechaInicioPeriodoMesActualFunc,
  fechaInicioPeriodoMesAnteriorFunc,
} from "../../../../assets/shared/fechas";

const TextoOrdenesDeCompraMensuales = (props) => {
  const { loading, mesActual, mesAnterior, error } = props;
  const chartSinDatos = error;
  const isDisabledChart = props.estaOculto;

  return (
    <TextChart
      title={`Órdenes de compras del mes`}
      bigAmount={mesActual}
      bigAmountTooltip={`Período ${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()} `}
      smallAmount={mesAnterior}
      smallAmountTooltip={`Período ${fechaInicioPeriodoMesAnteriorFunc()} al ${fechaFinPeriodoMesAnteriorFunc()}`}
      loading={loading}
      porcentaje
      bigCurrency
      smallCurrency
      disabled={isDisabledChart}
      border={props.border && !props.error}
      imgSinDatos={chartSinDatos}
      onFiltersClick={() => props.onShowFiltersModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.compras.ordenesDeComprasMesComparativo.loading,
    error: state.compras.ordenesDeComprasMesComparativo.error,
    estaCargado: state.compras.ordenesDeComprasMesComparativo.estaCargado,
    mesActual: state.compras.ordenesDeComprasMesComparativo.totalMesAct,
    mesAnterior: state.compras.ordenesDeComprasMesComparativo.totalMesAnt,
    porcTotal: state.compras.ordenesDeComprasMesComparativo.porcTotal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'OrÓrdenesdenes de compras del mes', ['compra']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoOrdenesDeCompraMensuales);
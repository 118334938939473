import React, { createRef } from 'react';
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";
import Table from '../../../UI/Table/Table';
import { MTableHeader } from 'material-table';
import { storageMonedaSigno } from '../../../../assets/shared/sessionData';
import { formatNumber, isEven } from '../../../../assets/shared/formatNumber';
import FilterIcon from '../../../UI/Icons/FilterIcon';
import TableToExcel from '../../../UI/Table/TableToExcel';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',    
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
  },
  iconsHeader: {
    display: 'flex',    
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    maxHeight: '30px',
  },
  table:{
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  }
}));

const TablaCheques = (props) => {
  const {
    loading,
    data,   
    maxHeight,
    minHeight,
    onFiltersClick,
    tituloTabla,
  } = props;

  const classes = useStyles();

  const cellStyles = {
    fontSize: 'smaller',
    paddingBottom: '5px',
    paddingTop: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    borderBottom: '0px'
  };

  const headerStyle = {
    fontSize: 'small',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: "rgb(220 220 220)",
    paddingLeft: '5px',
    paddingRight: '5px',
    borderBottom: '0px'
  };

  const columns = [
    {
      title: 'Valor',
      field: 'valor',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: 'Nro. Cheque',
      field: 'nroCheque',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: 'Orden',
      field: 'orden',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: 'Importe',
      field: 'importe',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.importe)}`,
    },
    {
      title: 'Vencimiento',
      field: 'vencimiento',
      align: 'left',
      width: 10,
      cellStyle: cellStyles,
      headerStyle: headerStyle,
      render: (rowData) => `${moment(rowData.vencimiento).format('DD/MM/YY')}`,
    },
    {
      title: 'Estado',
      field: 'estado',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: 'Banco',
      field: 'banco',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: 'Cliente',
      field: 'cliente',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: 'Razón Social',
      field: 'razonSocial',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: 'Tenedor',
      field: 'tenedor',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
  ];  

  const name = `${tituloTabla}`
  const filename = name;
  const tableRef = createRef();

  return (
    <div className={classes.root}>
      <h4 style={{ margin: '0'}}>{name}</h4>
      <div className={classes.table}>
        <Table
          tableRef={tableRef}
          columns={columns}
          style={{ width: props.customWidth ? props.customWidth : '100%', borderRadius: '0px', boxShadow: 'none' }}
          data={data}
          loading={loading}
          noBoxShadow
          components={{
            Header: (props) => (
              <MTableHeader {...props}>
                {(props.columns[0].tableData.width = '18%')}
                {(props.columns[1].tableData.width = '15%')}
                {(props.columns[2].tableData.width = '5%')}
                {(props.columns[3].tableData.width = '10%')}
                {(props.columns[4].tableData.width = '10%')}
                {(props.columns[5].tableData.width = '11%')}
                {(props.columns[6].tableData.width = '20%')}
                {(props.columns[7].tableData.width = '10%')}
                {(props.columns[8].tableData.width = '35%')}
                {(props.columns[9].tableData.width = '5%')}
              </MTableHeader>
            ),
            Toolbar: props => (
              <div className={classes.iconsHeader}>
                <TableToExcel
                  columns={columns}
                  data={data}
                  name={name}
                  filename={filename}
                />
                <FilterIcon onClick={onFiltersClick} />
              </div>
            ),
          }}
          customOptions={{
            actionsColumnIndex: -1,
            grouping: false,
            draggable: false,
            showTitle: false,
            detailPanelType: 'single',
            detailPanelColumnAlignment: 'right',
            paging: false,
            overflowY: "auto",
            rowStyle: (rowData, index) => ({
              backgroundColor: isEven(index) ? "#f2f2f2" : "white",
            }),
            maxBodyHeight: maxHeight,
            minBodyHeight: minHeight,
          }}
        />
      </div>
    </div>
  );
};

export default TablaCheques;
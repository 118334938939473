// import * as actions from "./index";

import {
  clearDeudaDelMesPorOrdenamiento,
  clearDeudaDelMesPorOrdenamientoProveedores,
  clearDeudaPorMesProveedores,
  loadDeudaDelMesPorOrdenamiento,
  loadDeudaDelMesPorOrdenamientoProveedores,
  loadDeudaPorMes,
  loadDeudaPorMesProveedores,
} from ".";
import { dataOptions as deudasClienteDataOptions } from "../../assets/constants/dataOptions/OrdenamientoDeudasCliente";
import { dataOptions as deudasProveedorDataOptions } from "../../assets/constants/dataOptions/OrdenamientoDeudaProveedor";
import { clearDeudaPorMes } from "./ventas";
import * as actions from "./index";

export const actualizarIndicadoresAnalisisDeudaCliente = () => {
  return (dispatch, getState) => {
    const {
      ventas: {
        ordenamientoDeudaDelMesPorSelected,
        fechaDeudasDelMesAnio: { mes, anio },
      },
    } = getState();
    dispatch(loadDeudaPorMes());
    deudasClienteDataOptions.map((value, index) => {
      return index !== ordenamientoDeudaDelMesPorSelected
        ? dispatch(clearDeudaDelMesPorOrdenamiento(index))
        : dispatch(loadDeudaDelMesPorOrdenamiento(index, mes, anio));
    });
  };
};

export const clearIndicadoresAnalisisDeudaCliente = () => {
  return (dispatch, getState) => {

    dispatch(clearDeudaPorMes());
    deudasClienteDataOptions.map((value, index) => {
      return dispatch(clearDeudaDelMesPorOrdenamiento(index));
    });
  };
};

export const actualizarIndicadoresAnalisisDeudaProveedor = () => {
  return (dispatch, getState) => {
    const {
      ventas: {
        ordenamientoDeudaDelMesPorSelected,
        fechaDeudasDelMesAnio: { mes, anio },
      },
    } = getState();
    dispatch(loadDeudaPorMesProveedores());
    deudasProveedorDataOptions.map((value, index) => {
      return index !== ordenamientoDeudaDelMesPorSelected
        ? dispatch(clearDeudaDelMesPorOrdenamientoProveedores(index))
        : dispatch(loadDeudaDelMesPorOrdenamientoProveedores(index, mes, anio));
    });
  };
};

export const clearIndicadoresAnalisisDeudaProveedor = () => {
  return (dispatch, getState) => {
    dispatch(clearDeudaPorMesProveedores());
    deudasProveedorDataOptions.map((value, index) => {
      return dispatch(clearDeudaDelMesPorOrdenamientoProveedores(index));
    });
  };
};

export const actualizarIndicadoresCliente = (idCliente) => {
  return (dispatch) => {
    dispatch(actions.loadClienteById(idCliente, () => {}));
    dispatch(actions.loadVentasMesComparativo(idCliente));
    dispatch(actions.loadPedidosPendientes(idCliente));
    dispatch(actions.loadEvolucionDeGastosMensual(idCliente));
    dispatch(actions.loadPedidosMesComparativo(idCliente));
    dispatch(actions.loadEstadosPedidosPendientes(idCliente));
    dispatch(actions.loadUltimaCompraByCliente(idCliente));      
    dispatch(actions.loadVentasPorVendedor(idCliente));
    dispatch(actions.loadComprobantesPedidosPendientes(idCliente));
    dispatch(actions.loadChequesRechazados(idCliente));
    dispatch(actions.loadChequesEnCartera(idCliente));
    dispatch(actions.loadListadoChequesEnCartera(idCliente));
    dispatch(actions.loadListadoChequesRechazados(idCliente));
    dispatch(actions.loadFacturasPendientes(idCliente));
    dispatch(actions.loadComprobantesFacturasPendientes(idCliente));
    dispatch(actions.loadCuentaCorrienteCliente(idCliente));
    dispatch(actions.loadLineChequesEnCartera(idCliente));
  };
};

export const clearIndicadoresCliente = () => {
  return (dispatch) => {
    dispatch(actions.unmountClienteById());
    dispatch(actions.clearVentasMesComparativo());
    dispatch(actions.clearEstadosPedidosPendientes());
    dispatch(actions.clearPedidosMesComparativo());
    dispatch(actions.clearEstadosPedidosPendientes());
    dispatch(actions.clearChequesRechazados());
    dispatch(actions.clearFacturasPendientes());
    dispatch(actions.clearComprobantesPedidosPendientes());
    dispatch(actions.clearComprobantesFacturasPendientes());
    dispatch(actions.clearEvolucionDeGastosMensual());
    dispatch(actions.clearVentasPorVendedor());
    dispatch(actions.clearListadoChequesEnCartera());
    dispatch(actions.clearListadoChequesRechazados());
    dispatch(actions.clearCuentaCorrienteCliente());
    dispatch(actions.clearListadoChequesEnCartera());
    dispatch(actions.clearListadoChequesRechazados());
  };
};

export const actualizarIndicadoresProveedor = (idProveedor) => {
  return (dispatch) => {
    dispatch(actions.loadProveedorById(idProveedor));
    dispatch(actions.loadCompraDelMesComparativo(idProveedor));
    dispatch(actions.loadEvolucionDeComprasMensual(idProveedor));
    dispatch(actions.loadUltimaCompraByProveedor(idProveedor));
    dispatch(actions.loadSaldoProveedor(idProveedor));
    dispatch(actions.loadOrdenesDeComprasMesComparativo(idProveedor));
    dispatch(actions.loadCantidadOrdenesDeCompraDelMes(idProveedor));
    dispatch(actions.loadComprobantesOrdenesDeCompraPendientes(idProveedor));
    dispatch(actions.loadFacturasDeCompraPendientes(idProveedor));
    dispatch(actions.loadComprobantesCompraFacturasPendientes(idProveedor));
    dispatch(actions.loadChequesPropios(idProveedor));
    dispatch(actions.loadOrdenesDeCompraPendientes(idProveedor));
    dispatch(actions.loadCuentaCorrienteProveedor(idProveedor));
  }
}

export const clearIndicadoresProveedor = () => {
  return (dispatch) => {
    dispatch(actions.unmountProveedorById());
    dispatch(actions.clearComprasMesComparativo());
    dispatch(actions.clearEvolucionDeComprasMensual());
    dispatch(actions.clearUltimaCompraByProveedor());
    dispatch(actions.clearSaldoProveedor());
    dispatch(actions.clearOrdenesDeComprasMesComparativo());
    dispatch(actions.clearCantidadOrdenesDeCompraDelMes());
    dispatch(actions.clearComprobantesOrdenesDeCompraPendientes());
    dispatch(actions.clearFacturasDeCompraPendientes());
    dispatch(actions.clearComprobantesCompraFacturasPendientes());
    dispatch(actions.clearChequesPropios());
    dispatch(actions.clearOrdenesDeCompraPendientes());
    dispatch(actions.clearCuentaCorrienteProveedor());
  }
}

export const colorsChart = [
  '#4abdac',  
  '#eeaa7b',
  '#7c4da9',
  '#9bbb59',
  '#c0504d',
  '#f9b200',
  '#5fabd7',
  '#021e73',
  '#f04646',
  '#1f497d', 
  '#f97c44',  
  '#078c03',
  '#a60311',
  '#049dbf',
  '#126666',
  '#0049ff'
];

import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import ComprobantesConfigBody from './ComprobantesConfigBody';

const ComprobantesVentas = (props) => {
  const {
    comprobantes,
    empresaId,
    loading,
    onChangeValue,
    onLoad,
    configInicial,
    seleccionarTodos,
    deseleccionarTodos
  } = props;

  return (
    <ComprobantesConfigBody
      comprobantes={comprobantes}
      empresaId={empresaId}
      loading={loading}
      onChangeValue={onChangeValue}
      onLoad={onLoad}
      configInicial={configInicial}
      seleccionarTodos={seleccionarTodos}
      deseleccionarTodos={deseleccionarTodos}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    comprobantes: state.ventas.comprobantesVentas.comprobantes,
    loading: state.ventas.comprobantesVentas.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (empresaId) => dispatch(actions.loadComprobantesVentas(empresaId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ComprobantesVentas);

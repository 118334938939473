import * as actionTypes from "./actionTypes";
import axiosDashboard from "../../axios-dashboard";
import idIndicadores from "../../assets/shared/idIndicadores";
import { storageEmpresaId, storageMonedaId } from "../../assets/shared/sessionData";

//#region Load Proveedor
export const loadProveedorById = (idProveedor, success) => {
  return (dispatch) => {
    dispatch(loadProveedorByIdStart());

    axiosDashboard
      .get(
        `/proveedores/InformacionProveedor?idProveedor=${idProveedor}&idEmpresa=${
          storageEmpresaId()}&indicadorId=${idIndicadores.informacionProveedor}`
      )
      .then((response) => {
        sessionStorage.setItem('idProveedor', idProveedor);
        dispatch(loadProveedorByIdSucces(response.data));
        return success(Promise.resolve);
      })
      .catch((error) => {
        if (error.response && (error.response.status === 500 || error.response.status === 404)) {
          dispatch(loadProveedorByIdFail(error));
        } else {
          dispatch(loadProveedorById(idProveedor, success));
        }
      });
  };
};

const loadProveedorByIdStart = () => {
  return {
    type: actionTypes.LOAD_PROVEEDOR_BY_ID_START,
  };
};

const loadProveedorByIdSucces = (proveedor) => {
  return {
    type: actionTypes.LOAD_PROVEEDOR_BY_ID_SUCCESS,
    proveedor,
  };
};

const loadProveedorByIdFail = (error) => {
  return {
    type: actionTypes.LOAD_PROVEEDOR_BY_ID_FAIL,
    error,
  };
};

export const unmountProveedorById = () => {
  return {
    type: actionTypes.UNMOUNT_PROVEEDOR_BY_ID,
  };
};

//#endregion

//#region Load Evolucion de Compras del Mes
export const loadEvolucionDeComprasMensual = (idProveedor) => {
  return (dispatch) => {
    dispatch(loadEvolucionDeComprasMensualStart());

    axiosDashboard
      .get(
        `/compras/ComprasPorMes?idProveedor=${idProveedor}&idEmpresa=${
          storageEmpresaId()}&indicadorId=${idIndicadores.evolucionComprasMensual}`,
      )
      .then((response) => {
        let formatVentasParaTabla = [];
        let totalVentas = 0;
        response.data.map((venta) => {
          if (!formatVentasParaTabla.hasOwnProperty(venta.anio)) {
            formatVentasParaTabla[venta.anio] = { anio: venta.anio, meses: [] };
          }
          formatVentasParaTabla[venta.anio].meses.push({ mes: venta.mes, monto: venta.monto });
          return (totalVentas += venta.monto);
        });

        dispatch(loadEvolucionDeComprasMensualSuccess(response.data, formatVentasParaTabla, totalVentas));               
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500)
            dispatch(loadEvolucionDeComprasMensualFail());              
          else
            dispatch(loadEvolucionDeComprasMensual(idProveedor));
        } else {
          dispatch(loadEvolucionDeComprasMensualFail());
        }
      });
  };
};

export const clearEvolucionDeComprasMensual = () => {
  return {
    type: actionTypes.CLEAR_COMPRAS_POR_MES
  };
};

const loadEvolucionDeComprasMensualStart = () => {
  return {
    type: actionTypes.LOAD_COMPRAS_POR_MES_START
  };
};

const loadEvolucionDeComprasMensualSuccess = (compras, comprasDataTable, totalCompras) => {
  return {
    type: actionTypes.LOAD_COMPRAS_POR_MES_SUCCESS,
    compras,
    comprasDataTable,
    totalCompras
  };
};

const loadEvolucionDeComprasMensualFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPRAS_POR_MES_FAIL,
    error
  };
};

//#endregion

//#region Ultima Compra
export const loadUltimaCompraByProveedor = (idProveedor) => {
  return (dispatch) => {
    dispatch(loadUltimaCompraByProveedorStart());

    axiosDashboard
      .get(
        `/compras/ultimaCompra?idProveedor=${idProveedor}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.ultimaCompra}`,
      )
      .then((response) => {
        if (response.status === 204) 
          dispatch(loadUltimaCompraByProveedorFail(response));

        const { fecha, idComproba, comprobante, referencia, monto } = response.data;
        dispatch(loadUltimaCompraByProveedorSuccess(fecha, idComproba, comprobante, referencia, monto));
      })
      .catch((error) => {
        if (error.response && (error.response.status === 500 || error.response.status === 404)) {
          dispatch(loadUltimaCompraByProveedorFail(error));
        } else {
          dispatch(loadUltimaCompraByProveedor(idProveedor));
        }
      });
  };
};

export const clearUltimaCompraByProveedor = () => {
  return {
    type: actionTypes.CLEAR_ULTIMA_COMPRA_BY_PROVEEDOR,
  };
};

const loadUltimaCompraByProveedorStart = () => {
  return {
    type: actionTypes.LOAD_ULTIMA_COMPRA_BY_PROVEEDOR_START,
  };
};

const loadUltimaCompraByProveedorSuccess = (fecha, idComproba, nombre, referencia, total) => {
  return {
    type: actionTypes.LOAD_ULTIMA_COMPRA_BY_PROVEEDOR_SUCCESS,
    fecha,
    idComproba,
    nombre,
    referencia,
    total,
  };
};

const loadUltimaCompraByProveedorFail = (error) => {
  return {
    type: actionTypes.LOAD_ULTIMA_COMPRA_BY_PROVEEDOR_FAIL,
    error,
  };
};

//#endregion

//#region Cuenta Corriente
export const loadCuentaCorrienteProveedor = (idProveedor) => {
  return (dispatch) => {
    dispatch(loadCuentaCorrienteProveedorStart());

    axiosDashboard
      .get(
        `/compras/CuentaCorriente?idMoneda=${storageMonedaId()}&idProveedor=${
          idProveedor}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.cuentaCorrienteProveedor}`,
      )
      .then((response) => {
        dispatch(loadCuentaCorrienteProveedorSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadCuentaCorrienteProveedorFail(error));
          } else {
            dispatch(loadCuentaCorrienteProveedor(idProveedor));
          }
        } else {
          dispatch(loadCuentaCorrienteProveedorFail(error));
        }
      });
  };
};

export const clearCuentaCorrienteProveedor = () => {
  return {
    type: actionTypes.CLEAR_CUENTA_CORRIENTE_PROVEEDOR,
  };
};

const loadCuentaCorrienteProveedorStart = () => {
  return {
    type: actionTypes.LOAD_CUENTA_CORRIENTE_PROVEEDOR_START,
  };
};

const loadCuentaCorrienteProveedorSuccess = (comprobantes) => {
  return {
    type: actionTypes.LOAD_CUENTA_CORRIENTE_PROVEEDOR_SUCCESS,
    comprobantes,
  };
};

const loadCuentaCorrienteProveedorFail = (error) => {
  return {
    type: actionTypes.LOAD_CUENTA_CORRIENTE_PROVEEDOR_FAIL,
    error,
  };
};

//#endregion

//#region Saldo del Proveedor
export const loadSaldoProveedor = (idProveedor) => {
  return (dispatch) => {
    dispatch(loadSaldoProveedorStart());

    axiosDashboard
      .get(
        `/compras/SaldoDelProveedor?idProveedor=${idProveedor}&idEmpresa=${
          storageEmpresaId()}&indicadorId=${idIndicadores.saldoProveedor}`,
      )
      .then((response) => {
        const { monto } = response.data;
        dispatch(loadSaldoProveedorSuccess(monto));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadSaldoProveedorFail(error));
          } else {
            dispatch(loadSaldoProveedor(idProveedor));
          }
        } else {
          dispatch(loadSaldoProveedorFail(error));
        }
      });
  };
};

export const clearSaldoProveedor = () => {
  return {
    type: actionTypes.CLEAR_SALDO_PROVEEDOR,
  };
};

const loadSaldoProveedorStart = () => {
  return {
    type: actionTypes.LOAD_SALDO_PROVEEDOR_START,
  };
};

const loadSaldoProveedorSuccess = (saldo) => {
  return {
    type: actionTypes.LOAD_SALDO_PROVEEDOR_SUCCESS,
    saldo,
  };
};

const loadSaldoProveedorFail = (error) => {
  return {
    type: actionTypes.LOAD_SALDO_PROVEEDOR_FAIL,
    error,
  };
};

//#endregion
import React from 'react';
import ExportToExcelIcon from '../Icons/ExportToExcelIcon';

const TableToExcel = (props) => {
  const {
    data,
    columns,
    name,
    filename,
  } = props;

  const headersStyle = (align) => {
    return {
      font: { sz: '12', bold: true },
      fill: { fgColor: { rgb: 'FFD3D3D3' } },
      alignment: { horizontal: align ? align : 'center' }
    }
  };

  const cellsStyle = (align) => {
    return {
      font: { sz: '10', bold: false },
      alignment: { horizontal: align },
    }
  }

  const filterColumns = columns.filter((column) => column.title !== 'Ver' && column.title !== '');

  const columnData = () => {
    var headerData = [];
    filterColumns.map((column) => {
      return headerData.push({
        title: column.title, 
        width: { wpx: 150 }, 
        style: headersStyle(column.align)
      });
    }); 
    return headerData;
  }

  const formatData = () => {
    let tableData = [];
    data.map((item) => {
      const row = [];
      filterColumns.map((column) => {
        return row.push({
          value: item[column.field],
          style: cellsStyle(column.align)
        })
      });
      return tableData.push(row);
    });

    return tableData;
  }

  const multiDataSet = [
    {
      columns: columnData(),
      data: formatData()
    }
  ];

  return (
    <ExportToExcelIcon 
      filename={filename}
      name={name}
      dataSet={multiDataSet}
    />
  )
}

export default TableToExcel;
import { MTableHeader } from 'material-table';
import React from 'react';
import { formatNumber, isEven, formatNumberWithMonedaSigno } from '../../../../assets/shared/formatNumber';
import Table from '../../../UI/Table/Table';

const TableDetallesComprobante = (props) => {
  const { articulos, simbolo, loading } = props;

  const headerStyles = {
    fontSize: '12px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'rgb(200, 200, 200)',
    border: 'none'
  };

  const cellStyles = {
    fontSize: '11px',
    paddingBottom: '5px',
    paddingTop: '5px',
    border: 'none'
  };

  const columns = [
    {
      title: 'Cód. Art.',
      field: 'codArt',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyles
    },
    {
      title: 'Artículo',
      field: 'nombre',
      align: 'left',
      cellStyle: cellStyles,
      headerStyle: headerStyles
    },
    {
      title: 'Cantidad',
      field: 'cantidad',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyles,
      render: (rowData) => formatNumber(rowData.cantidad)
    },
    {
      title: 'Precio',
      field: 'precio',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyles,
      render: (rowData) => formatNumberWithMonedaSigno(rowData.precio, simbolo)
    },
    {
      title: '% Desc.',
      field: 'descuento',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyles,
      render: (rowData) => formatNumber(rowData.descuento)
    },
    {
      title: 'Precio',
      field: 'totalDesc',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyles,
      render: (rowData) => formatNumberWithMonedaSigno(rowData.totalDesc, simbolo)
    },
    {
      title: '% IVA',
      field: 'iva',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyles,
      render: (rowData) => formatNumber(rowData.iva)
    },
    {
      title: 'Total',
      field: 'total',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyles,
      render: (rowData) => formatNumberWithMonedaSigno(rowData.total, simbolo)
    }
  ];

  const data = () => {
    return articulos.map((articulo) => ({
      codArt: articulo.id_Articulo,
      nombre: articulo.articulo,
      cantidad: articulo.cantidad,
      precio: articulo.precio,
      descuento: articulo.descuento,
      totalDesc: articulo.totalConDescuento,
      iva: articulo.porc_IVA,
      total: articulo.total
    }));
  };

  return (
    <Table
      title=""
      loading={loading}
      noBoxShadow
      style={{ backgroundColor: 'rgb(245, 245, 245)', border: '1px solid #acacac99', marginBottom: '10px' }}
      columns={columns}
      data={data()}
      components={{
        Header: (props) => (
          <MTableHeader {...props}>
            {(props.columns[0].tableData.width = '70px')}
            {(props.columns[1].tableData.width = '200px')}
            {(props.columns[2].tableData.width = '60px')}
            {(props.columns[3].tableData.width = '60px')}
            {(props.columns[4].tableData.width = '60px')}
            {(props.columns[5].tableData.width = '60px')}
            {(props.columns[6].tableData.width = '60px')}
            {(props.columns[7].tableData.width = '60px')}
          </MTableHeader>
        )
      }}
      customOptions={{
        grouping: false,
        draggable: false,
        search: false,
        paging: false,
        showTitle: false,
        toolbar: false,
        layout: 'fixed',
        maxBodyHeight: window.screen.width < 1367 ? 430 : 600,
        rowStyle: (rowData, index) => ({
          backgroundColor: isEven(index) ? 'rgb(240, 240, 240)' : 'rgba(255, 255, 255)'
        })
      }}
    />
  );
};

export default TableDetallesComprobante;

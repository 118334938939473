import React, { Fragment } from "react";
import { connect } from 'react-redux';
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Hidden from "@material-ui/core/Hidden";

import NavigationItems from "../NavigationItems/NavigationItems";
import MenuUsuarioMobile from '../../MenuUsuario/MenuUsuarioMobile';
import { storageEmpresaNombre, storageSeccionNombre } from "../../../assets/shared/sessionData";
import LogoNav from "../../../assets/images/SoloLogoSup.png";
import ToolbarComponents from "../../ToolbarComponents/ToolbarComponents";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: 'RGB(81,81,81)',
    color: 'white'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 10,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline'
    }
  },
  mobileMenuButton: {
    marginRight: 0,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    backgroundColor: 'RGB(81,81,81)'
  },
  drawerClose: {
    '@media (min-width: 600px)': {
      width: '60px !important'
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(9) + 1
    },
    backgroundColor: 'RGB(81,81,81)'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    '@media (min-width: 600px)': {
      minHeight: '55px !important'
    },
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: 'RGB(229,229,229)'
  },
  navItemIcon: {
    color: 'white'
  },
  toolbarUsuario: {
    display: 'none',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  mobileToolbarUsuario: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  NavContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxHeight: '55px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  UsuarioLog: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end'
  },
  Selector: {
    color: 'white',
    marginLeft: '10px',
    marginRight: '10px'
  },
  Logo: {
    fontSize: 'xxx-large',
    cursor: 'context-menu',
    display: 'flex',
    fontFamily: 'Righteous',
    fontStyle: 'normal'
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '3px',
  },
  appTitles: {
    marginRight: '15px', 
    display: 'flex', 
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  appTitle: {
    fontSize: 'x-large', 
    fontFamily: 'Righteous',
  },
  appSubTitle: {
    fontSize: 'x-small', 
    fontFamily: 'Righteous',
  },
  title: {
    display: 'none', 
    marginRight: '15px', 
    fontSize: 'x-large', 
    fontFamily: 'Righteous',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    padding: '5px'
  }
}));

const MiniDrawer = (props) => {
  const { window, isAuth, children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar
          style={{ minHeight: '55px', paddingLeft: '10px', paddingRight: '5px' }}
        >
          <Fragment>
            <IconButton // hamburguesa desktop
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <IconButton //hamburguesa mobile
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.mobileMenuButton}
            >
              <MenuIcon />
            </IconButton>               
          </Fragment>
          <div className={classes.NavContainer}>
            <div className={classes.Logo}>
              <img alt="" src={LogoNav} />
              <div className={classes.appTitles} style={{ alignSelf: 'center' }}>
                <div className={classes.appTitle} >
                  Analytics
                </div>
                <div className={classes.appSubTitle}>
                  {props.seccionNombre}
                </div>
              </div>
            </div>
            {props.isAuth && (
              <div className={classes.title}>
                {storageEmpresaNombre()}
              </div>
            )}
            {isAuth && (
              <ToolbarComponents />
            )}
          </div>
        </Toolbar>
      </AppBar>
      {/* mobile */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {isAuth && (
            <div className={classes.appTitle} style={{ margin: '10px', justifyContent: 'center' }}>
              {storageEmpresaNombre()}
            </div>
          )}
          <Divider />   
          {props.isAuth && (
            <MenuUsuarioMobile />
          )}
          <NavigationItems isAuth={isAuth} onClickItem={handleDrawerToggle} />
        </Drawer>
      </Hidden>
      {/* mobile */}
      {/* desktop */}
      <Hidden xsDown implementation="css">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton
              onClick={handleDrawerClose}
              className={classes.navItemIcon}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <NavigationItems isAuth={isAuth} onClickItem={() =>{}} />
        </Drawer>
      </Hidden>
      {/* desktop */}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  seccionNombre: storageSeccionNombre(),
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MiniDrawer);
// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigationItem__NavigationItem__wugYl {\r\n  margin: 10px 0;\r\n  box-sizing: border-box;\r\n  display: flex;\r\n  width: 100%;\r\n}\r\n\r\n.NavigationItem__NavigationItem__wugYl a {\r\n  color: white !important;\r\n  text-decoration: none;\r\n  width: 100%;\r\n  box-sizing: border-box;\r\n  display: block;\r\n  cursor: pointer;\r\n}\r\n\r\n.NavigationItem__NavigationItem__wugYl a:hover,\r\n.NavigationItem__NavigationItem__wugYl a:active,\r\n.NavigationItem__NavigationItem__wugYl a.NavigationItem__active__qKf-x {\r\n  color: lightgray;\r\n}\r\n\r\n.NavigationItem__NavigationItemIcon__fjzXc {\r\n  visibility: visible;  \r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NavigationItem": "NavigationItem__NavigationItem__wugYl",
	"active": "NavigationItem__active__qKf-x",
	"NavigationItemIcon": "NavigationItem__NavigationItemIcon__fjzXc"
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import { connect } from "react-redux";
import { dataOptions } from "../../../../assets/constants/dataOptions/OrdenamientoDeudaProveedor";
import BarChart from "../../../Charts/BarChart";

const BarUtilidadPorSeleccion = (props) => {
  const { ordenamientoSelected } = props;
  
  return (
    <BarChart
      title={""}
      titleJustifyContent={"flex-end"}
      series={[
        {
          name: "monto",
          data: props[dataOptions[ordenamientoSelected].stateKey].deudas.map(
            (deuda) => deuda.monto
          ),
        },
      ]}
      categories={props[dataOptions[ordenamientoSelected].stateKey].deudas.map(
            (deuda) => deuda.nombre
          )}
      loading={props[dataOptions[ordenamientoSelected].stateKey].loading}
      chartTextAlign={
        props[dataOptions[ordenamientoSelected].stateKey].loading
          ? "center"
          : "left"
      }
      chartStacked
      height={400}
      noBoxShadow
      gridShow
      xaxisTickPlacement
      tooltipEnabled
      // tooltipCustom={(series, seriesIndex, dataPointIndex, w) =>
      //   buildCustomTooltip(series, seriesIndex, dataPointIndex, w)
      // }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    fechaDeudaDelMes: state.compras.fechaDeudasDelMesAnioProveedores,
    ordenamientoSelected: state.compras.ordenamientoDeudaDelMesPorSelectedProveedores,
    deudasPorCategoriaDeCliente:
      state.compras.deudaDelMesProveedoresPorCategoriaDeCliente,
    deudasPorProveedor: state.compras.deudaDelMesProveedoresPorProveedor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BarUtilidadPorSeleccion);

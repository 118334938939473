const locales = [
  {
    name: 'en',
    options: {
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      toolbar: {
        exportToSVG: 'Download SVG',
        exportToPNG: 'Download PNG',
        menu: 'Menu',
        selection: 'Selection',
        selectionZoom: 'Selection Zoom',
        zoomIn: 'Zoom In',
        zoomOut: 'Zoom Out',
        pan: 'Panning',
        reset: 'Reset Zoom'
      }
    }
  },
  {
    name: 'es',
    options: {
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ],
      shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'],
      days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      shortDays: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
      toolbar: {
        exportToSVG: 'Descargar SVG',
        exportToPNG: 'Descargar PNG',
        menu: 'Menu',
        selection: 'Selección',
        selectionZoom: 'Selección Zoom',
        zoomIn: 'Zoom In',
        zoomOut: 'Zoom Out',
        pan: 'Panning',
        reset: 'Resetear Zoom'
      }
    }
  }
];

export default locales;

import React, { Fragment, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import * as actions from "../../store/actions";
import Dialog from '../../components/UI/Dialog/Dialog';
import { storageMonedaId, storageMonedaSigno, storageProveedorId } from "../../assets/shared/sessionData";
import CardProveedor from "../../components/Indicadores/Proveedores/CardProveedor/CardProveedor";
import TextoUltimaCompra from "../../components/Indicadores/Proveedores/TextoUltimaCompra/TextoUltimaCompra";
import PieFacturasPendientes from "../../components/Indicadores/Compras/PieFacturasPendientes/PieFacturasPendientes";
import TablaFacturasPendientes from "../../components/Indicadores/Compras/TablaFacturasPendientes/TablaFacturasPendientes";
import LineEvolucionCompras from "../../components/Indicadores/Proveedores/LineEvolucionCompras/LineEvolucionCompras";
import TableComprobantesCtaCte from '../../components/Indicadores/Proveedores/TableComprobantesCtaCte/TableComprobantesCtaCte';
import LineChequesEnCartera from "../../components/Indicadores/Cheques/LineCheques/LineChequesEnCartera";
import TablaChequesEnCartera from "../../components/Indicadores/Cheques/TablaCheques/TablaChequesEnCartera";
import TablaChequesRechazados from "../../components/Indicadores/Cheques/TablaCheques/TablaChequesRechazados";
import TextoComprasMensuales from "../../components/Indicadores/Compras/TextoComprasMensuales/TextoComprasMensuales";
import TextoSaldoProveedor from "../../components/Indicadores/Proveedores/TextoSaldoProveedor/TextoSaldoProveedor";
import TextoOrdenesDeCompraMensuales from "../../components/Indicadores/Compras/TextoOrdenesDeCompraMensuales/TextoOrdenesDeCompraMensuales";
import TextoChequesEmitidos from "../../components/Indicadores/Cheques/TextoChequesEmitidos/TextoChequesEmitidos";
import TextoOrdenesDeComprasPendientes from "../../components/Indicadores/Compras/TextoOrdenesDeComprasPendientes/TextoOrdenesDeComprasPendientes";
import PieOrdenesDeCompraPendientes from "../../components/Indicadores/Compras/PieOrdenesDeCompraPendientes/PieOrdenesDeCompraPendientes";
import TablaOrdenesDeCompraPendientes from "../../components/Indicadores/Compras/TablaOrdenesDeCompraPendientes/TablaOrdenesDeCompraPendientes";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px', 
    }
  },
  containerSaldoCheques: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px',
      paddingLeft: '9px',
      marginTop: '10px', 
    }
  },
  combinedChartsCard: {
    display: 'inline',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      margin: '5px 0px 0px 0px',
      border: '1px solid #d9d9d9',
      borderRadius: '3px',
      backgroundColor: 'white',
      boxShadow: '0px 3px 6px #00000029',
      paddingRight: '0px'
    }
  },
  gridItem: {   
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    }
  },
  gridItemSaldoCheques: {   
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    },
    [theme.breakpoints.up('sm')]: {
      paddingInline: '5px'
    }
  },
  combinedChartsCardPie: {  
    [theme.breakpoints.down('md')]: {
      marginBottom: '10px',
    },
  },
  combinedChartsCardTabla: {  
    [theme.breakpoints.down('md')]: {
      marginTop: '10px',
    },
  },
  tablaDeuda: {  
    margin: '0px',
    [theme.breakpoints.up('sm')]: {
      paddingRight: '5px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '5px 0px 5px 0px',
    }
  },
  pieDeuda: {
    margin: '0px',
    [theme.breakpoints.down('md')]: {
      marginTop: '5px',
      marginBottom: '10px',
      backgroundColor: 'white',
      border: '1px solid #d9d9d9',
      borderRadius: '3px',
      boxShadow: '0px 3px 6px #00000029',
    }
  },
  tablaSeleccion: {  
    marginTop: '5px',
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    }
  },
  lineEvolucion: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px', 
      paddingLeft: '9px',
      minHeight: '345px',
    },
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    }    
  },
  ordenesDeCompra: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '10px', 
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '5px',
    } 
  },
  cardProveedor: { 
    [theme.breakpoints.up('sm')]: {
      marginBottom: '10px', 
    },
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    }
  },
}));

const Proveedor = (props) => { 
  const classes2 = useStyles();
  const { onLoadChart, onLoadProveedor } = props;

  const [idProveedor] = useState(storageProveedorId());  
  const [monedaPresentacion] = useState({ id: storageMonedaId(), signo: storageMonedaSigno() });
  const [dialogState, setDialogState] = useState({ show: false, content: '' });
  const inPhone = window.screen.width <= 750;

  const showContent = () => {
    switch (dialogState.content) {
      case 'EnCartera':
        return <Fragment>
          <LineChequesEnCartera />
          <br/>
          <TablaChequesEnCartera />
        </Fragment>;
      case 'Rechazados':
        return <Fragment>
          <TablaChequesRechazados />
        </Fragment>;
      default:
        break;
    }
  };

  const handleCloseDialog = () => {
    setDialogState({ show: false, content: '' });
  };

  const onSuccess = useCallback(() => {
    onLoadChart(idProveedor)
  }, [idProveedor, onLoadChart]);

  useEffect(() => {
    onLoadProveedor(idProveedor, onSuccess);  
  }, [idProveedor, onLoadProveedor]);

  const renderFila1 = () => {
    return (
      <Grid item xs={12} container className={classes2.container}>
        <Grid item xs={12} md={3} lg={3} container>
          <Grid item xs={12} className={classes2.cardProveedor}>
            <CardProveedor />
          </Grid>
          <Grid item xs={12} className={classes2.gridItem}>
            <TextoComprasMensuales border />
          </Grid>
          <Grid item xs={12} className={classes2.ordenesDeCompra} >
            <TextoOrdenesDeCompraMensuales border />
          </Grid>
        </Grid>
        <Grid item xs={12} md={9} lg={9} container>
          <Grid item xs={12} className={classes2.lineEvolucion}>
            <LineEvolucionCompras height={295} minHeight={295} />
          </Grid>
          <Grid item xs={12} container className={classes2.containerSaldoCheques}>
            <Grid item xs={12} lg={3} className={classes2.gridItem}>
              <TextoSaldoProveedor />
            </Grid>
            <Grid item xs={12} lg={3} className={classes2.gridItemSaldoCheques}>
              <TextoUltimaCompra />
            </Grid>
            <Grid item xs={12} lg={3} className={classes2.gridItemSaldoCheques}>
              <TextoChequesEmitidos />
            </Grid>
            <Grid item xs={12} lg={3} className={classes2.gridItem}>
              <TextoOrdenesDeComprasPendientes />
            </Grid>
          </Grid>
        </Grid>
      </Grid>   
    );
  }

  const renderFila2 = () => {
    return (
      <Grid item xs={12} container className={classes2.combinedChartsCard} >
        <Grid item xs={12} md={3} lg={3} className={classes2.combinedChartsCardPie}>
          <PieOrdenesDeCompraPendientes noBorder minHeight={'280px'}/>
        </Grid>
        <Grid item xs={12} md={9} lg={9} className={classes2.combinedChartsCardTabla}>
          <TablaOrdenesDeCompraPendientes maxHeight={280} />
        </Grid>
      </Grid>
    )
  }

  const renderFila3 = () => {
    return (
      <Grid item xs={12} container className={classes2.combinedChartsCard} >
        <Grid item xs={12} md={3} lg={3} className={classes2.combinedChartsCardPie}>
          <PieFacturasPendientes noBorder minHeight={'280px'}/>
        </Grid>
        <Grid item xs={12} md={9} lg={9} className={classes2.combinedChartsCardTabla}>
          <TablaFacturasPendientes maxHeight={280} />
        </Grid>
      </Grid>
    )
  }

  const renderFila4 = () => {
    return (
      <Grid item xs={12}>
        <TableComprobantesCtaCte 
          monedaPresentacion={monedaPresentacion}
          maxHeight={450}
        />
      </Grid>
    )
  }
  return (
    <Fragment>
      <Grid container spacing={1}>
        {renderFila1()}
        {renderFila2()}
        {renderFila3()}
        {renderFila4()}
      </Grid>
      <Dialog
        show={dialogState.show}
        onClose={handleCloseDialog}
        body={<div style={{ minWidth: !inPhone && '500px' }}>{showContent()}</div>}
      />
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    cliente: state.clientes.clienteById.cliente,
    ejecucionClientes: state.ejecucionPaginas.ejecucionClientes,
    estaCargadoPedidos: state.ventas.comprobantesPedidosPendientes.estaCargado,
    estaCargadoFacturas: state.ventas.comprobantesFacturasPendientes.estaCargado,
    loadingPedidos: state.ventas.comprobantesPedidosPendientes.loading,
    loadingFacturas: state.ventas.comprobantesFacturasPendientes.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadProveedor: (idProveedor, success) => dispatch(actions.loadProveedorById(idProveedor, success)),     
    onLoadChart: (idProveedor) => {
      dispatch(actions.loadCompraDelMesComparativo(idProveedor));
      dispatch(actions.loadEvolucionDeComprasMensual(idProveedor));
      dispatch(actions.loadUltimaCompraByProveedor(idProveedor));
      dispatch(actions.loadSaldoProveedor(idProveedor));
      dispatch(actions.loadOrdenesDeComprasMesComparativo(idProveedor));
      dispatch(actions.loadCantidadOrdenesDeCompraDelMes(idProveedor));
      dispatch(actions.loadComprobantesOrdenesDeCompraPendientes(idProveedor));
      dispatch(actions.loadFacturasDeCompraPendientes(idProveedor));
      dispatch(actions.loadComprobantesCompraFacturasPendientes(idProveedor));
      dispatch(actions.loadChequesPropios(idProveedor));
      dispatch(actions.loadOrdenesDeCompraPendientes(idProveedor));
      dispatch(actions.loadCuentaCorrienteProveedor(idProveedor));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Proveedor);
import React from 'react';
import { Accordion as MaterialAccordion } from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = (props) => {
  const { title, body, accordionClasses, accordionSummaryClasses, accordionDetailsClasses } = props;

  return (
    <MaterialAccordion classes={accordionClasses}>
      <AccordionSummary classes={accordionSummaryClasses} expandIcon={<ExpandMoreIcon />}>
        {title}
      </AccordionSummary>
      <AccordionDetails classes={accordionDetailsClasses}>{body}</AccordionDetails>
    </MaterialAccordion>
  );
};

export default Accordion;

import React from 'react';
import { connect } from 'react-redux';
import TablaComprobantes from '../TablaComprobantes/TablaComprobantes';
import * as actions from "../../../../store/actions";

const TablaPedidosPendientes = (props) => {
  const {
    comprobantes,
    loading,
    estaCargado,
  } = props;

  const data = () => {
    let listaComprobantes = estaCargado ? comprobantes : [];

    return listaComprobantes && listaComprobantes.map((comprobante) => {
      return {
        fecha: comprobante.fecha,
        comprobante: comprobante.comprobante,
        referencia: comprobante.referencia,
        numeroExterno: comprobante.numeroExterno,
        monto: comprobante.monto && comprobante.monto.toFixed(2),
        idComproba: comprobante.idComproba,
        idSistema: comprobante.idSistema,
        idSucursal: comprobante.idSucursal,
        clienteNombre: comprobante.clienteNombre,
        clienteCodigo: comprobante.clienteCodigo,
        montoPendiente: comprobante.montoPendiente && comprobante.montoPendiente.toFixed(2),
      };
    });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }} >
      <TablaComprobantes 
        data={data()} 
        loading={loading} 
        minHeight='300px'
        maxHeight='380px'
        onFiltersClick={() => props.onShowFiltersModal()}
        tituloTabla={"Pedidos pendientes" }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    comprobantes: state.ventas.comprobantesPedidosPendientes.comprobantes,
    loading: state.ventas.comprobantesPedidosPendientes.loading,
    estaCargado: state.ventas.comprobantesPedidosPendientes.estaCargado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Pedidos pendientes', ['pedido']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaPedidosPendientes);
import moment from "moment";
import { storageFechaFija } from "./sessionData";

export const fechaActualFunc = () => {
  const fechaFija = storageFechaFija()
    ?.split("-")
    .map((value) => parseInt(value, 10));
  const createFechaFija = fechaFija && new Date(fechaFija[2], fechaFija[0], fechaFija[1]);
  let fechaActual = new Date( new Date().setMonth(new Date().getMonth() + 1));

  return createFechaFija || fechaActual;
};

export const fechaActualComparativoFunc = () => {
  const fechaFija = moment(storageFechaFija(), 'MM-DD-YYYY hh:mm:ss');
  const fechaActual = !fechaFija._isValid ? new Date() : fechaFija;
  return new Date(fechaActual);
};

export const fechaInicioPeriodoMesActualFunc = () => {
  return `01/${moment(fechaActualComparativoFunc()).format("MM/YY")}`;
};

export const fechaFinPeriodoMesActualFunc = () => {
  return moment(fechaActualComparativoFunc()).format("DD/MM/YY");
};

export const fechaInicioPeriodoMesAnteriorFunc = () => {
  return `01/${moment(fechaActualComparativoFunc()).subtract(1, "month").format("MM/YY")}`;
};

export const fechaFinPeriodoMesAnteriorFunc = () => {
  return `${moment(fechaActualComparativoFunc()).subtract(1, "month").format("DD/MM/YY")}`;
};

export const fechaFinPeriodoUltimoDiaMesAnteriorFunc = () => {
  return `${moment(fechaActualComparativoFunc())
    .subtract(1, "month")
    .endOf("month")
    .format("DD/MM/YY")}`;
};

export const compareFechaActualFechaFija = () => {
  const actualDate = moment(new Date()).format("DD/MM/yyyy");
  const fechaFijaDate = moment(fechaActualFunc()).format("DD/MM/yyyy");

  return actualDate === fechaFijaDate;
};

export const formatFechaDD_MM_YY = (date) => {
  const formatDate = moment(date).format("DD/MM/YY");
  return formatDate._isValid ? formatDate : ``;
};

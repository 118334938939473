import axios from "axios";
import { buildBearerToken } from "./assets/shared/sessionData";
import { baseUrlDashboard } from "./assets/shared/urlApps";


const instance = axios.create({
  //baseURL: 'http://gausssistemas.serveftp.com:8030/api'
  baseURL: baseUrlDashboard(),
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  //baseURL: 'https://dashboard-gauss-api.azurewebsites.net/api'
});

instance.interceptors.request.use((config) => {
  config.headers.Authorization = buildBearerToken();

  return config;
});

export default instance;

import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import * as actions from "../../../store/actions";
import classes from "./NavigationItem.css";
import { makeStyles } from "@material-ui/core/styles";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LightTooltip from "../../UI/LightTooltip/LightTooltip";
import { connect } from "react-redux";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  listItem: {
    "&.Mui-selected": {
      background: "linear-gradient(90deg, #fbb040, #f15a29)",
    },
    "&:focus": {
      background: "linear-gradient(90deg, #fbb040, #f15a29)",
    },
  },
}));

const NavigationItem = (props) => {
  const {
    roles,
    currentUserRoles,
    title,
    style,
    text,
    link,
    exact,
    cambiarPage,
    children,
    pageRender
  } = props;

  const classes2 = useStyles();
  const checkRoles = roles && roles.indexOf(currentUserRoles) === -1;

  const onItemClick = () => {
    sessionStorage.setItem('seccionNombre', props.title);
    props.onClickItem();
    cambiarPage(link);
  }

  return !checkRoles ? (
    <Fragment>
      <LightTooltip arrow title={title} placement="right">
        <ListItem
          className={classes2.listItem}
          style={style}
          button
          key={text}
          component={NavLink}
          to={link}
          exact={exact}
          activeClassName="Mui-selected"
          onClick={() => onItemClick()}
          selected={pageRender === link}
        >
          <ListItemIcon className={classes.NavigationItemIcon}>
            {children}
          </ListItemIcon>
          <ListItemText primary={title} />
          {/* style={{ color: 'white' }} */}
        </ListItem>
      </LightTooltip>
    </Fragment>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    pageRender: state.ejecucionPaginas.paginaRender,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cambiarPage: (page) => dispatch(actions.cambioPaginaRender(page)),
  };
};

export default connect(null, mapDispatchToProps)(NavigationItem);

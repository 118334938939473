import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { storageEmpresaId } from '../../../assets/shared/sessionData';
import Spinner from '../Spinner/Spinner';

const EmpresaSelector = (props) => {
  const [empresaId, setEmpresaId] = useState(storageEmpresaId());

  useEffect(() => {
    if (props.empresas.length === 0) {
      props.onLoadEmpresas();
    }
  }, []);

  return props.loading ? (
    <Spinner size="small" />
  ) : ( 
    <FormControl style={{ margin: '0px 10px 0px 10px' }}>
      <InputLabel id="empresaInputLabel">Empresas</InputLabel>
      <Select
        labelId="empresaLabel"
        id="empresaSelect"
        value={props.empId ? props.empId : empresaId}
        onChange={(event) => {
          setEmpresaId(event.target.value);
          if (props.setEmpresasessionStorage) {
            props.onSeleccionarEmpresa(event.target.value);
          } else {
            props.onChangeValue(event);
          }
        }}
      >
        {props.empresas.map((empresa) => (
          <MenuItem key={empresa.id} value={empresa.id}>
            {empresa.nombre}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.multiempresa.empresas.loading,
    empresas: state.multiempresa.empresas.empresas
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadEmpresas: () => dispatch(actions.loadEmpresas()),
    onSeleccionarEmpresa: (empresaId) => dispatch(actions.seleccionarEmpresa(empresaId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmpresaSelector);

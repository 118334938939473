import React, { useCallback, useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { formatNumber } from "../../../../assets/shared/formatNumber";
import { storageMonedaSigno } from "../../../../assets/shared/sessionData";
import PieChart from "../../../Charts/PieChart";
import Spinner from "../../../UI/Spinner/Spinner";
import { Grid, IconButton, TextField, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { compareByDesc } from "../../../../assets/shared/sorting";
import TableOrdenesDeCompraPendientes from "./TableOrdenesDeCompraPendientes";

const PieOrdenesDeCompraPendientes = (props) => {
  const { loading, ordenes, total, noBorder, minHeight, estaOculto } = props;
  const isDisabledChart = estaOculto;
  const [items, setItems] = useState([]);
  const [limit, setLimit] = useState(20);
  const chartSinDatos = ordenes.length === 0 || (ordenes.length === 1 && ordenes[0].value === 0);

  const ordenesPositivas = useCallback(() => {
    let ordenesPos = ordenes ? ordenes.filter((vnt) => vnt['value'] >= 0) : [];
    return ordenesPos;
  }, [ordenes]);

  const getSeries = useCallback(() => {
    let tempItems = limit && limit > 0 ? items : ordenesPositivas();
    let series = tempItems ? tempItems.map((pedido) => Math.abs(pedido.value)) : [];

    return series;
  }, [limit, items, ordenesPositivas]);

  const getLabels = useCallback(() => {
    let tempItems = limit && limit > 0 ? items : ordenesPositivas();
    let labels = tempItems ? tempItems.map((pedido) => pedido.name) : [];

    return labels;
  }, [limit, items, ordenesPositivas]);

  useEffect(() => {
    if (!loading && limit && limit > 0) {
      let ordenes = ordenesPositivas();
      let ordered = ordenes.sort((a, b) => compareByDesc(a, b, 'monto'));
      let filtered = ordered.slice(0, limit);
      let others = ordered.slice(limit);

      if (others && others.length > 0) {
        let otherItem = {
          mes: others[0].mes,
          anio: others[0].anio,
          monto: 0,
        };
        otherItem['nombre'] = "OTROS";
        otherItem['id'] = "0000";
        others.forEach(element => {
          otherItem.monto += element.monto;
        });
        filtered.splice(0, 0, otherItem);
      }
      setItems(filtered);
    }

  }, [loading, limit, ordenesPositivas]);

  //#region Limit

  const onLimitChange = (value) => {
    let numValue = Number(value);
    setLimit(numValue);
  }

  const getLimit = () => {
    return (
      <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Grid item xs={6}>
          <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
            Límite
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={limit}
            onChange={(event) => onLimitChange(event.target.value)}
            type="number"
            size='small'
            InputProps={{
              inputProps: {
                max: ordenes && ordenes.length,
                min: 0,
                style: { padding: '2px', textAlign: 'center' }
              }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          {limit && limit > 0 ? (
            <IconButton size='small' onClick={() => setLimit(0)}>
              <Close fontSize='small' />
            </IconButton>
          ) : <Fragment></Fragment>}
        </Grid>
      </Grid>
    )
  }

  //#endregion

  //#region Change View
  const [viewAsPie, setViewAsPie] = useState(true);

  const handleChangeView = () => {
    setViewAsPie(!viewAsPie);
  }

  const dataLabels = {
    enabled: true,
    formatter: function (val, opts) {
      let item = opts.w.config.labels[opts.seriesIndex];

      if (item && item.toUpperCase() === 'OTROS')
        return `OTROS ${formatNumber(val)}%`;
      else
        return `${formatNumber(val)}%`;
    },
  }

  const subTitle = () => {
    return `Total: ${storageMonedaSigno()} ${formatNumber(total)}`;
  }

  const renderPie = () => {
    return (
      <PieChart
        title={`Ordenes Pendientes`}
        endItems={getLimit()}
        subTitle={subTitle()}
        labels={getLabels()}
        series={getSeries()}
        imgSinDatos={chartSinDatos}
        loading={loading}
        legendPosition={'bottom'}
        chartType="donut"
        legendHorizontalAlign={"left"}
        height={'90%'}
        width={'100%'}
        maxWidthImgSinResult={330}
        noBorder={noBorder}
        minHeight={minHeight}
        onChangeViewClick={handleChangeView}
        dataLabels={dataLabels}
      />
    );
  }

  const renderTable = () => {
    return (
      <TableOrdenesDeCompraPendientes
        title={`Ordenes Pendientes`}
        subTitle={subTitle()}
        ordenesDeCompra={ordenes}
        totalOrdenesDeCompra={total}
        loading={loading}
        onChangeViewClick={handleChangeView}
        height={436}
        disabled={isDisabledChart}
        filename={`Ordenes Pendientes`}
      />
    )
  }

  //#endregion

  return loading ? (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Spinner />
    </div>
  ) : viewAsPie ? (
    <Fragment>
      {renderPie()}
    </Fragment>
  ) : (
    <Fragment>
      {renderTable()}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ordenes: state.compras.ordenesDeCompraConDiasPendientes.ordenes,
    loading: state.compras.ordenesDeCompraConDiasPendientes.loading,
    total: state.compras.ordenesDeCompraConDiasPendientes.total,
    estaCargado: state.compras.ordenesDeCompraConDiasPendientes.estaCargado,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PieOrdenesDeCompraPendientes);
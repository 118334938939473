import React from 'react';
import { Grid } from '@material-ui/core';

import classes from './LabelGridTitleDescription.css';

const LabelGridTitleDescription = (props) => {
  const { xs, title, description, descriptionSmall, style } = props;

  return (
    <Grid style={style} item xs={xs} className={classes.titleDescriptionContainer}>
      <Grid item className={classes.title}>
        {title}
      </Grid>
      <Grid item className={classes.description}>
        {description}
      </Grid>
      <Grid item className={classes.descriptionSmall}>
        {descriptionSmall}
      </Grid>
    </Grid>
  );
};

export default LabelGridTitleDescription;

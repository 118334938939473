import { dataOptions } from "../../assets/constants/dataOptions/OrdenamientoDeudaProveedor";
import { fechaActualFunc } from "../../assets/shared/fechas";
import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  fechaDeudasDelMesAnioProveedores: {
    mes: fechaActualFunc().getMonth(),
    anio: fechaActualFunc().getFullYear(),
  },
  ordenamientoDeudaDelMesPorSelectedProveedores: 0,
  comprobantesPagos: {
    loading: false,
    comprobantes: [],
    error: null,
    estaCargado: false,
  },
  comprobantesCompras: {
    loading: false,
    comprobantes: [],
    error: null,
    estaCargado: false,
  },
  deudasPorMesProveedores: {
    deudas: [],
    deudasDataTable: [],
    totalDeudas: 0,
    loading: false,
    estaCargado: false,
    error: null,
  },
  deudaDelMesProveedoresPorCategoriaDeCliente: {
    deudas: [],
    deudasNegativo: [],
    deudasDataTable: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: "",
  },
  deudaDelMesProveedoresPorProveedor: {
    deudas: [],
    deudasNegativo: [],
    deudasDataTable: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null,
    fecha: "",
  },
  comprasMesComparativo: {
    montoMesActual: 0,
    montoMesAnterior: 0,
    loading: false,
    error: null,
    estaCargado: false,
  },
  ordenesDeComprasPendientes: {
    cantidad: 0,
    total: 0,
    prom30Dias: 0,
    loading: false,
    error: null,
    estaCargado: false,
  },  
  ordenesDeCompraConDiasPendientes: {
    ordenes: [],
    loading: false,
    error: null,
    estaCargado: false,
    total: 0,
  },
  ordenesDeComprasMesComparativo: {
    totalMesAnt: 0,
    totalMesAct: 0,
    porcTotal: 0,
    loading: false,
    estaCargado: false,
    error: null,
  }, 
  comprobantesOrdenesDeComprasPendientes: {
    comprobantes: [],
    loading: false,
    estaCargado: false,
    error: null,
  },
  facturasPendientes: {
    facturas: [],
    totalFacturas: 0,
    loading: false,
    estaCargado: false,
    error: null,
  }, 
  comprobantesCompraFacturasPendientes: {
    comprobantes: [],
    loading: false,
    estaCargado: false,
    error: null,
  },
};

const keyOrdenamiento = (ordenamiento) => {
  const { storeStateKey } = dataOptions[ordenamiento];

  return storeStateKey;
};

//#region seleccion fechaDeudasDelMesAnioProveedores
const seleccionFechaDeudasDelMesAnioProveedores = (state, mes, anio) => {
  return updateObject(state, {
    fechaDeudasDelMesAnioProveedores: updateObject(
      state.fechaDeudasDelMesAnioProveedores,
      {
        mes,
        anio,
      }
    ),
  });
};
//#endregion

//#region load comprobantes de Pagos
const loadComprobantesPagosStart = (state) => {
  return updateObject(state, {
    comprobantesPagos: updateObject(state.comprobantesPagos, {
      loading: true,
      comprobantes: [],
      error: null,
    }),
  });
};

const loadComprobantesPagosSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesPagos: updateObject(state.comprobantesPagos, {
      loading: false,
      comprobantes,
      estaCargado: true,
    }),
  });
};

const loadComprobantesPagosFail = (state, error) => {
  return updateObject(state, {
    comprobantesPagos: updateObject(state.comprobantesPagos, {
      loading: false,
      error: error,
      estaCargado: true,
    }),
  });
};
//#endregion

//#region load comprobantes de Compras
const loadComprobantesComprasStart = (state) => {
  return updateObject(state, {
    comprobantesCompras: updateObject(state.comprobantesCompras, {
      loading: true,
      comprobantes: [],
      error: null,
    }),
  });
};

const loadComprobantesComprasSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesCompras: updateObject(state.comprobantesCompras, {
      loading: false,
      comprobantes,
      estaCargado: true,
    }),
  });
};

const loadComprobantesComprasFail = (state, error) => {
  return updateObject(state, {
    comprobantesCompras: updateObject(state.comprobantesCompras, {
      loading: false,
      error: error,
      estaCargado: true,
    }),
  });
};
//#endregion

//#region load evolucion de deudas
const loadDeudaPorMesProveedoresStart = (state) => {
  return updateObject(state, {
    deudasPorMesProveedores: updateObject(state.deudasPorMesProveedores, {
      deudas: [],
      deudasDataTable: [],
      totalDeudas: 0,
      loading: true,
      estaCargado: false,
      error: null,
    }),
  });
};

const loadDeudaPorMesProveedoresSuccess = (
  state,
  deudas,
  deudasDataTable,
  totalDeudas
) => {
  return updateObject(state, {
    deudasPorMesProveedores: updateObject(state.deudasPorMesProveedores, {
      deudas,
      deudasDataTable,
      totalDeudas,
      loading: false,
      estaCargado: true,
    }),
  });
};

const loadDeudaPorMesProveedoresFail = (state, error) => {
  return updateObject(state, {
    deudasPorMesProveedores: updateObject(state.deudasPorMesProveedores, {
      loading: false,
      error: error,
      estaCargado: true,
    }),
  });
};

const clearDeudaPorMesProveedores = (state) => {
  return updateObject(state, {
    deudasPorMesProveedores: updateObject(state.deudasPorMesProveedores, {
      deudas: [],
      deudasDataTable: [],
      totalDeudas: 0,
      loading: false,
      estaCargado: false,
      error: null,
    }),
  });
};
//#endregion

//#region load deudas del mes por ordenamiento
const loadDeudaDelMesPorOrdenamientoProveedoresStart = (state, ordenamiento) => {
  const keyOrd = keyOrdenamiento(ordenamiento);

  return updateObject(state, {
    [keyOrd]: updateObject(state[keyOrd], {
      deudas: [],
      deudasNegativo: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null,
      fecha: "",
    }),
  });
};

const loadDeudaDelMesPorOrdenamientoProveedoresSuccess = (
  state,
  deudas,
  deudasNegativo,
  deudasDataTable,
  total,
  fecha,
  ordenamiento
) => {
  const keyOrd = keyOrdenamiento(ordenamiento);

  return updateObject(state, {
    [keyOrd]: updateObject(state[keyOrd], {
      deudas,
      deudasNegativo,
      deudasDataTable,
      total,
      loading: false,
      estaCargado: true,
      fecha,
    }),
  });
};

const loadDeudaDelMesPorOrdenamientoProveedoresFail = (
  state,
  error,
  ordenamiento
) => {
  const keyOrd = keyOrdenamiento(ordenamiento);

  return updateObject(state, {
    [keyOrd]: updateObject(state[keyOrd], {
      error,
      loading: false,
      estaCargado: true,
    }),
  });
};

const clearDeudaDelMesPorOrdenamiento = (state, ordenamiento) => {
  const keyOrd = keyOrdenamiento(ordenamiento);

  return updateObject(state, {
    [keyOrd]: updateObject(state[keyOrd], initialState[keyOrd]),
  });
};
//#endregion

//#region loadComprasMesComparativo
const loadComprasMesComparativoStart = (state) => {
  return updateObject(state, {
    comprasMesComparativo: updateObject(state.comprasMesComparativo, {
      loading: true,
    }),
  });
};

const loadComprasMesComparativoSuccess = (state, montoMesActual, montoMesAnterior) => {
  return updateObject(state, {
    comprasMesComparativo: updateObject(state.comprasMesComparativo, {
      montoMesActual,
      montoMesAnterior,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadComprasMesComparativoFail = (state, error) => {
  return updateObject(state, {
    comprasMesComparativo: updateObject(state.comprasMesComparativo, {
      loading: false,
      loadingPorCliente: false,
      error,
    }),
  });
};

const clearComprasMesComparativo = (state) => {
  return updateObject(state, {
    comprasMesComparativo: updateObject(state.comprasMesComparativo, {
      montoMesActual: 0,
      montoMesAnterior: 0,
      loading: false,
      estaCargado: false,
      error: null,
    }),
  });
};
//#endregion

//#region loadOrdenesDeComprasMesComparativo
const loadOrdenesDeComprasMesComparativoStart = (state) => {
  return updateObject(state, {
    ordenesDeComprasMesComparativo: updateObject(state.ordenesDeComprasMesComparativo, {
      loading: true,
    }),
  });
};

const loadOrdenesDeComprasMesComparativoSuccess = (state, totalMesAnt, totalMesAct, porcTotal) => {
  return updateObject(state, {
    ordenesDeComprasMesComparativo: updateObject(state.ordenesDeComprasMesComparativo, {
      totalMesAnt,
      totalMesAct,
      porcTotal,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadOrdenesDeComprasMesComparativoFail = (state, error) => {
  return updateObject(state, {
    ordenesDeComprasMesComparativo: updateObject(state.ordenesDeComprasMesComparativo, {
      error,
      loading: false,
      loadingPorCliente: false,
    }),
  });
};

const clearOrdenesDeComprasMesComparativo = (state) => {
  return updateObject(state, {
    ordenesDeComprasMesComparativo: updateObject(state.ordenesDeComprasMesComparativo, {
      totalMesAnt: 0,
      totalMesAct: 0,
      porcTotal: 0,
      loading: false,
      error: null,
      estaCargado: false,
    }),
  });
};
//#endregion

//#region loadOrdenesDeComprasPendientes
const loadCantidadOrdenesDeComprasPendientesStart = (state) => {
  return updateObject(state, {
    ordenesDeComprasPendientes: updateObject(state.ordenesDeComprasPendientes, {
      loading: true,
    }),
  });
};

const loadCantidadOrdenesDeComprasPendientesSuccess = (state, cantidad, total, prom30Dias) => {
  return updateObject(state, {
    ordenesDeComprasPendientes: updateObject(state.ordenesDeComprasPendientes, {
      cantidad,
      total,
      prom30Dias,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadCantidadOrdenesDeComprasPendientesFail = (state, error) => {
  return updateObject(state, {
    ordenesDeComprasPendientes: updateObject(state.ordenesDeComprasPendientes, {
      error,
      loading: false,
    }),
  });
};

const clearCantidadOrdenesDeComprasPendientes = (state) => {
  return updateObject(state, {
    ordenesDeComprasPendientes: updateObject(state.ordenesDeComprasPendientes, {
      cantidad: 0,
      total: 0,
      prom30Dias: 0,
      loading: false,
      error: null,
      estaCargado: false,
    }),
  });
};
//#endregion

//#region Load Ordenes De Compra Con Dias Pendientes
const loadOrdenesDeCompraConDiasPendientesStart = (state) => {
  return updateObject(state, {
    ordenesDeCompraConDiasPendientes: updateObject(state.ordenesDeCompraConDiasPendientes, {
      loading: true,
    }),
  });
};

const loadOrdenesDeCompraConDiasPendientesSuccess = (state, ordenes, total) => {
  return updateObject(state, {
    ordenesDeCompraConDiasPendientes: updateObject(state.ordenesDeCompraConDiasPendientes, {
      ordenes,
      loading: false,
      estaCargado: true,
      total,
      error: null,
    }),
  });
};

const loadOrdenesDeCompraConDiasPendientesFail = (state, error) => {
  return updateObject(state, {
    ordenesDeCompraConDiasPendientes: updateObject(state.ordenesDeCompraConDiasPendientes, {
      error,
      loading: false,
    }),
  });
};

const clearOrdenesDeCompraConDiasPendientes = (state) => {
  return updateObject(state, {
    ordenesDeCompraConDiasPendientes: updateObject(state.ordenesDeCompraConDiasPendientes, {
      ordenes: [],
      loading: false,
      error: null,
      estaCargado: false,
      total: 0,
    }),
  });
};
//#endregion

//#region Comprobantes Ordenes De Compras Pendientes
const loadComprobantesOrdenesDeComprasPendientesStart = (state) => {
  return updateObject(state, {
    comprobantesOrdenesDeComprasPendientes: updateObject(state.comprobantesOrdenesDeComprasPendientes, {
      loading: true,
    }),
  });
};

const loadComprobantesOrdenesDeComprasPendientesSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesOrdenesDeComprasPendientes: updateObject(state.comprobantesOrdenesDeComprasPendientes, {
      comprobantes,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadComprobantesOrdenesDeComprasPendientesFail = (state, error) => {
  return updateObject(state, {
    comprobantesOrdenesDeComprasPendientes: updateObject(state.comprobantesOrdenesDeComprasPendientes, {
      loading: false,
      error,
    }),
  });
};

const clearComprobantesOrdenesDeComprasPendientes = (state) => {
  return updateObject(state, {
    comprobantesOrdenesDeComprasPendientes: updateObject(state.comprobantesOrdenesDeComprasPendientes, {
      comprobantes: [],
      loading: false,
      estaCargado: false,
      error: null,
    }),
  });
};

//#endregion

//#region Load Facturas Pendientes
const loadFacturasPendientesStart = (state) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      loading: true,
    }),
  });
};

const loadFacturasPendientesSuccess = (state, facturas, totalFacturas) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      facturas,
      totalFacturas,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadFacturasPendientesFail = (state, error) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      loading: false,
      error,
    }),
  });
};

const clearFacturasPendientes = (state) => {
  return updateObject(state, {
    facturasPendientes: updateObject(state.facturasPendientes, {
      facturas: [],
      totalFacturas: 0,
      loading: false,
      estaCargado: false,
      error: null,
    }),
  });
};
//#endregion

//#region Comprobantes Facturas Pendientes
const loadComprobantesCompraFacturasPendientesStart = (state) => {
  return updateObject(state, {
    comprobantesCompraFacturasPendientes: updateObject(state.comprobantesCompraFacturasPendientes, {
      loading: true,
    }),
  });
};

const loadComprobantesCompraFacturasPendientesSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesCompraFacturasPendientes: updateObject(state.comprobantesCompraFacturasPendientes, {
      comprobantes,
      loading: false,
      estaCargado: true,
      error: null,
    }),
  });
};

const loadComprobantesCompraFacturasPendientesFail = (state, error) => {
  return updateObject(state, {
    comprobantesCompraFacturasPendientes: updateObject(state.comprobantesCompraFacturasPendientes, {
      loading: false,
      error,
    }),
  });
};

const clearComprobantesCompraFacturasPendientes = (state) => {
  return updateObject(state, {
    comprobantesCompraFacturasPendientes: updateObject(state.comprobantesCompraFacturasPendientes, {
      comprobantes: [],
      loading: false,
      estaCargado: false,
      error: null,
    }),
  });
};

//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECCION_FECHA_DEUDAS_DEL_MES_ANIO_PROVEEDORES:
      return seleccionFechaDeudasDelMesAnioProveedores(
        state,
        action.mes,
        action.anio
      );

    case actionTypes.SELECCION_DATA_DEUDA_DEL_MES_PROVEEDORES_POR:
      return {
        ...state,
        ordenamientoDeudaDelMesPorSelectedProveedores:
          action.ordenamientoSelected,
      };

    case actionTypes.LOAD_COMPROBANTES_PAGOS_START:
      return loadComprobantesPagosStart(state);
    case actionTypes.LOAD_COMPROBANTES_PAGOS_SUCCESS:
      return loadComprobantesPagosSuccess(state, action.comprobantesPagos);
    case actionTypes.LOAD_COMPROBANTES_PAGOS_FAIL:
      return loadComprobantesPagosFail(state, action.error);

    case actionTypes.LOAD_COMPROBANTES_COMPRAS_START:
      return loadComprobantesComprasStart(state);
    case actionTypes.LOAD_COMPROBANTES_COMPRAS_SUCCESS:
      return loadComprobantesComprasSuccess(state, action.comprobantesCompras);
    case actionTypes.LOAD_COMPROBANTES_COMPRAS_FAIL:
      return loadComprobantesComprasFail(state, action.error);

    case actionTypes.LOAD_DEUDA_POR_MES_PROVEEDORES_START:
      return loadDeudaPorMesProveedoresStart(state);
    case actionTypes.LOAD_DEUDA_POR_MES_PROVEEDORES_SUCCESS:
      return loadDeudaPorMesProveedoresSuccess(state, action.deudas, action.deudasDataTable, action.totalDeudas);
    case actionTypes.LOAD_DEUDA_POR_MES_PROVEEDORES_FAIL:
      return loadDeudaPorMesProveedoresFail(state, action.error);
    case actionTypes.CLEAR_DEUDA_POR_MES_PROVEEDORES:
      return clearDeudaPorMesProveedores(state);

    case actionTypes.LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_START_PROVEEDORES:
      return loadDeudaDelMesPorOrdenamientoProveedoresStart(
        state,
        action.ordenamiento
      );
    case actionTypes.LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_SUCCESS_PROVEEDORES:
      return loadDeudaDelMesPorOrdenamientoProveedoresSuccess(
        state,
        action.deudas,
        action.deudasNegativo,
        action.deudasDataTable,
        action.totalDeudas,
        action.fecha,
        action.ordenamiento
      );
    case actionTypes.LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_FAIL_PROVEEDORES:
      return loadDeudaDelMesPorOrdenamientoProveedoresFail(
        state,
        action.error,
        action.ordenamiento
      );
    case actionTypes.CLEAR_DEUDA_DEL_MES_POR_ORDENAMIENTO_PROVEEDORES:
      return clearDeudaDelMesPorOrdenamiento(state, action.ordenamiento);

    case actionTypes.LOAD_COMPRAS_DEL_MES_START:
      return loadComprasMesComparativoStart(state);
    case actionTypes.LOAD_COMPRAS_DEL_MES_SUCCESS:
      return loadComprasMesComparativoSuccess(state, action.montoMesActual, action.montoMesAnterior);
    case actionTypes.LOAD_COMPRAS_DEL_MES_FAIL:
      return loadComprasMesComparativoFail(state, action.error);
    case actionTypes.CLEAR_COMPRAS_DEL_MES:
      return clearComprasMesComparativo(state);

    case actionTypes.LOAD_CANTIDAD_ORDENES_PENDIENTES_START:
      return loadCantidadOrdenesDeComprasPendientesStart(state);
    case actionTypes.LOAD_CANTIDAD_ORDENES_PENDIENTES_SUCCESS:
      return loadCantidadOrdenesDeComprasPendientesSuccess(state, action.cantidad, action.total, action.prom30Dias);
    case actionTypes.LOAD_CANTIDAD_ORDENES_PENDIENTES_FAIL:
      return loadCantidadOrdenesDeComprasPendientesFail(state, action.error);
    case actionTypes.CLEAR_CANTIDAD_ORDENES_PENDIENTES:
      return clearCantidadOrdenesDeComprasPendientes(state);

    case actionTypes.LOAD_ORDENES_DE_COMPRAS_DEL_MES_START:
      return loadOrdenesDeComprasMesComparativoStart(state);
    case actionTypes.LOAD_ORDENES_DE_COMPRAS_DEL_MES_SUCCESS:
      return loadOrdenesDeComprasMesComparativoSuccess(state, action.totalMesAnt, action.totalMesAct, action.porcTotal);
    case actionTypes.LOAD_ORDENES_DE_COMPRAS_DEL_MES_FAIL:
      return loadOrdenesDeComprasMesComparativoFail(state, action.error);
    case actionTypes.CLEAR_ORDENES_DE_COMPRAS_DEL_MES:
      return clearOrdenesDeComprasMesComparativo(state);

    case actionTypes.LOAD_ORDENES_COMPRA_PENDIENTES_START:
      return loadComprobantesOrdenesDeComprasPendientesStart(state);
    case actionTypes.LOAD_ORDENES_COMPRA_PENDIENTES_SUCCESS:
      return loadComprobantesOrdenesDeComprasPendientesSuccess(state, action.comprobantes);
    case actionTypes.LOAD_ORDENES_COMPRA_PENDIENTES_FAIL:
      return loadComprobantesOrdenesDeComprasPendientesFail(state, action.error);
    case actionTypes.CLEAR_LOAD_ORDENES_COMPRA_PENDIENTES:
      return clearComprobantesOrdenesDeComprasPendientes(state);

    case actionTypes.LOAD_FACTURAS_COMPRA_PENDIENTES_START:
      return loadFacturasPendientesStart(state);
    case actionTypes.LOAD_FACTURAS_COMPRA_PENDIENTES_SUCCESS:
      return loadFacturasPendientesSuccess(state, action.facturas, action.totalFacturas);
    case actionTypes.LOAD_FACTURAS_COMPRA_PENDIENTES_FAIL:
      return loadFacturasPendientesFail(state, action.error);
    case actionTypes.CLEAR_FACTURAS_COMPRA_PENDIENTES:
      return clearFacturasPendientes(state);

    case actionTypes.LOAD_COMPROBANTES_FACTURAS_COMPRA_PENDIENTES_START:
      return loadComprobantesCompraFacturasPendientesStart(state);
    case actionTypes.LOAD_COMPROBANTES_FACTURAS_COMPRA_PENDIENTES_SUCCESS:
      return loadComprobantesCompraFacturasPendientesSuccess(state, action.comprobantes);
    case actionTypes.LOAD_COMPROBANTES_FACTURAS_COMPRA_PENDIENTES_FAIL:
      return loadComprobantesCompraFacturasPendientesFail(state, action.error);
    case actionTypes.CLEAR_COMPROBANTES_FACTURAS_COMPRA_PENDIENTES:
      return clearComprobantesCompraFacturasPendientes(state);

    case actionTypes.LOAD_ORDENES_DE_COMPRA_PENDIENTES_START:
      return loadOrdenesDeCompraConDiasPendientesStart(state);
    case actionTypes.LOAD_ORDENES_DE_COMPRA_PENDIENTES_SUCCESS:
      return loadOrdenesDeCompraConDiasPendientesSuccess(state, action.ordenesDeCompraPendientes, action.total);
    case actionTypes.LOAD_ORDENES_DE_COMPRA_PENDIENTES_FAIL:
      return loadOrdenesDeCompraConDiasPendientesFail(state, action.error);
    case actionTypes.CLEAR_ORDENES_DE_COMPRA_PENDIENTES:
      return clearOrdenesDeCompraConDiasPendientes(state);

    default:
      return state;
  }
};

export default reducer;

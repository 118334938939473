import React, { useRef } from 'react';
import moment from 'moment';

import { formatNumber } from '../../../assets/shared/formatNumber';
import Table from '../../UI/Table/Table';
import classes from './TablaInflacionMesAMes.css';

const TablaInflacionMesAMes = (props) => {

    const columns = [
      {
        title: 'Mes',
        field: 'mes',
        align: 'right',
        cellStyle: {
          fontSize: '12px',
          paddingBottom: '4px',
          paddingTop: '4px',
        },
        headerStyle: {
          fontSize: '14px',
          paddingTop: '0px',
          paddingBottom: '0px',
          backgroundColor: 'rgb(220 220 220)'
        },
        render: (rowData) => `${moment(rowData.mes).format('MM/YYYY').substr(0,2)}`
      },
      {
        title: `Porcentaje`,
        field: 'porcentaje',
        align: 'right',
        type: 'numeric',
        cellStyle: (cellData) => {
          return {
            fontSize: '12px',
            paddingBottom: '4px',
            paddingTop: '4px',
          };
        },
        headerStyle: {
          fontSize: '14px',
          paddingTop: '0px',
          paddingBottom: '0px',
          backgroundColor: 'rgb(220 220 220)'
        },
        render: (rowData) => `${formatNumber(rowData.porcentaje)}%`
      }
    ];
  
    const data = () => {
      return props.inflacion.map((inflacion) => {
        return {
          mes: inflacion.fecha,
          porcentaje: inflacion.porcentaje
        };
      });
    };
  
    const tableRef = useRef();
    return (
      <div className={classes.tablaInflacionMesAMes}>
        <Table
          tableRef={tableRef}
          style={{ width: '100%', marginTop: 0, marginBottom: '15px', paddingLeft: '85px' }}
          title=""
          noBoxShadow
          columns={columns}
          data={data()}
          customOptions={{
            thirdSortClick: false,
            sorting: true,
            grouping: false,
            draggable: false,
            search: false,
            paging: false,
            toolbar: false,
            showTitle: false,
          }}
        />
      </div>
    );
  };
  
  export default TablaInflacionMesAMes;
  
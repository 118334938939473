import React, { useState } from 'react';
import { IconButton, Typography, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '../../UI/Dialog/Dialog';
import Buton from "../Button/Button";
import { urlPortalLogout } from '../../../assets/shared/urlApps';
import classes from './BackButtonModal.css';

export const BackButtonModal = (props) => {
  const [title] = useState("");
  const [message] = useState("¿Desea cerrar sesión en la aplicación?");

  const onLogoutClick = () => {
    sessionStorage.clear();
    return setTimeout(() => urlPortalLogout(), 100);
  };

  return (
    <Dialog
      title={
        <div classes={classes.TitleDialogContent} style={{ width: '100%' }}>
          <div className={classes.TitleDialogTexto}>
            <Typography variant="h6">{title}</Typography>
            <div>
              <IconButton aria-label="close" onClick={() => props.onHiddenModal()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </div>
      }
      show={props.show}
      body={
        <Grid container spacing={1}>
          <Grid item
            container 
            spacing={1}
            direction='row'
            justify='center'
            alignItems='center'>
            <Grid item>
              <Typography variant="h5">{message}</Typography>
            </Grid>
          </Grid>
          <Grid item 
            container 
            spacing={1}
            direction='row'
            justify='center'
            alignItems='center'
            style={{ maxWidth: 'calc(100% - 8px)' }}
          >
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Buton onClick={() => onLogoutClick()} style={{ width:'100%', marginInline:'10px' }}>
                Confirmar
              </Buton>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Buton onClick={() => props.onHiddenModal()} style={{ width:'100%', marginInline:'10px', backgroundColor: "red", opacity: 0.5 }}>
                Cancelar
              </Buton>
            </Grid>
          </Grid>
        </Grid>
      }
      onClose={() => {}}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    show: state.modals.backButtonModal.show,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onHiddenModal: () => dispatch(actions.showBackButtonModal(false))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BackButtonModal);

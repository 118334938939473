import React from "react";
import { connect } from "react-redux";

import TextChart from "../../../Charts/TextChart";

const TextoChequesEmitidos = (props) => {
  const {
    monto,
    cantidadCheques,
    loading,
    error,
  } = props;

  return (
    <TextChart
      title={`Cheques emitidos`}
      bigAmount={monto}
      smallAmount={cantidadCheques}
      smallAmountTooltip={`Cant. cheques`}
      bigCurrency
      loading={loading}
      imgSinDatos={error}
      onBodyClick={() => props.onBodyClick()}
      cursorPointer
    />
  );
};

const mapStateToProps = (state) => {
  return {
    monto: state.cheques.chequesEmitidos.monto,
    cantidadCheques: state.cheques.chequesEmitidos.cantidadCheques,
    loading: state.cheques.chequesEmitidos.loading,
    error: state.cheques.chequesEmitidos.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoChequesEmitidos);
// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DetallesComprobanteGasto__infoComprobanteContainer__IUCNF {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  flex-direction: column;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.DetallesComprobanteGasto__infoComprobanteRow__76SQB {\r\n  display: flex;\r\n}\r\n\r\n.DetallesComprobanteGasto__titleTotales__VRUei {\r\n  font-size: small;\r\n  font-weight: 500;\r\n  margin-bottom: 10px !important;\r\n  text-align: right;\r\n}\r\n\r\n.DetallesComprobanteGasto__Spinner__py9Oo {\r\n  text-align: center;\r\n  overflow-y: hidden;\r\n}\r\n\r\n.DetallesComprobanteGasto__footer__DVlFd {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.DetallesComprobanteGasto__TitleCard__oNq\\+K {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-content: center;\r\n  background: rgb(251, 175, 64);\r\n  background: linear-gradient(90deg, rgba(251, 175, 64, 1) 0%, rgba(241, 91, 41, 1) 100%);\r\n  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=\"#fbaf40\",endColorstr=\"#f15b29\",GradientType=1);\r\n  margin: -15px -15px 15px -15px;\r\n  padding-bottom: 10px;\r\n  padding-left: 15px;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoComprobanteContainer": "DetallesComprobanteGasto__infoComprobanteContainer__IUCNF",
	"infoComprobanteRow": "DetallesComprobanteGasto__infoComprobanteRow__76SQB",
	"titleTotales": "DetallesComprobanteGasto__titleTotales__VRUei",
	"Spinner": "DetallesComprobanteGasto__Spinner__py9Oo",
	"footer": "DetallesComprobanteGasto__footer__DVlFd",
	"TitleCard": "DetallesComprobanteGasto__TitleCard__oNq+K"
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import { connect } from "react-redux";

import TextChart from "../../../../Charts/TextChart";

const TextoSaldoCliente = (props) => {
  const { clientSelected, cliente, onBodyClick, loading } = props;

  return (
    <TextChart
      title={`Saldo del cliente`}
      bigAmount={cliente && cliente.deuda ? cliente.deuda.importe : 0}
      noClient={!cliente}
      bigCurrency
      currencySimbol={cliente && cliente.cuentaCorriente ? cliente.cuentaCorriente.moneda : null}
      loading={loading}      
      onBodyClick={() => onBodyClick()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    cliente: state.clientes.clienteById.cliente,
    loading: state.clientes.clienteById.loading,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoSaldoCliente);
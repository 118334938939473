import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  proveedorById: {
    proveedor: {},
    loading: false,
    error: null,
    proveedorSelected: false,
  },
  ultimaCompra: {
    fecha: "",
    idComproba: "",
    nombre: "",
    referencia: 0,
    total: 0,
    loading: false,
    error: null,
  },
  cuentaCorriente: {
    comprobantes: [],
    loading: false,
    error: null,
    estaCargado: false,
  },  
  comprasPorMes: {
    compras: [],
    comprasDataTable: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  saldo: {
    saldo: 0,
    loading: false,
    estaCargado: false,
    error: null
  }
};

//#region loadProveedorById
const loadProveedorByIdStart = (state) => {
  return updateObject(state, {
    proveedorById: updateObject(state.proveedorById, {
      loading: true,
    }),
  });
};

const loadProveedorByIdSuccess = (state, proveedor) => {
  return updateObject(state, {
    proveedorById: updateObject(state.proveedorById, {
      loading: false,
      proveedor,
      proveedorSelected: true,
      error: null,
    }),
  });
};

const loadProveedorByIdFail = (state, error) => {
  return updateObject(state, {
    proveedorById: updateObject(state.proveedorById, {
      loading: false,
      error,
      proveedorSelected: false,
    }),
  });
};

const unmountProveedorById = (state) => {
  return updateObject(state, {
    proveedorById: updateObject(state.proveedorById, {
      loading: false,
      proveedor: {},
      error: null,
      proveedorSelected: false,
    }),
  });
};
//#endregion

//#region loadUltimaCompra
const loadUltimaCompraStart = (state) => {
  return updateObject(state, {
    ultimaCompra: updateObject(state.ultimaCompra, {
      loading: true,
    }),
  });
};

const loadUltimaCompraSuccess = (
  state,
  fecha,
  idComproba,
  nombre,
  referencia,
  total
) => {
  return updateObject(state, {
    ultimaCompra: updateObject(state.ultimaCompra, {
      fecha,
      idComproba,
      nombre,
      referencia,
      total,
      loading: false,
      error: null,
    }),
  });
};

const loadUltimaCompraFail = (state, error) => {
  return updateObject(state, {
    ultimaCompra: updateObject(state.ultimaCompra, {
      loading: false,
      fecha: "",
      idComproba: "",
      nombre: "",
      referencia: 0,
      total: 0,
      error,
    }),
  });
};

const clearUltimaCompra = (state) => {
  return updateObject(state, {
    ultimaCompra: updateObject(state.ultimaCompra, {
      fecha: "",
      idComproba: "",
      nombre: "",
      referencia: 0,
      total: 0,
      loading: false,
      error: null,
    }),
  });
};
//#endregion

//#region Cuenta Corriente
const loadCuentaCorrienteProveedorStart = (state) => {
  return updateObject(state, {
    cuentaCorriente: updateObject(state.cuentaCorriente, {
      loading: true,
      estaCargado: false,
    }),
  });
};

const loadCuentaCorrienteProveedorSuccess = (state, comprobantes) => {
  return updateObject(state, {
    cuentaCorriente: updateObject(state.cuentaCorriente, {
      loading: false,
      comprobantes,
      error: null,
      estaCargado: true,
    }),
  });
};

const loadCuentaCorrienteProveedorFail = (state, error) => {
  return updateObject(state, {
    cuentaCorriente: updateObject(state.cuentaCorriente, {
      comprobantes: [],
      loading: false,
      error,
      estaCargado: true,
    }),
  });
};

const clearCuentaCorrienteProveedor = (state) => {
  return updateObject(state, {
    cuentaCorriente: updateObject(state.cuentaCorriente, {
      comprobantes: [],
      loading: false,
      error: null,
      estaCargado: false,
    }),
  });
};
//#endregion

//#region load evolucion de compras
const loadComprasPorMesStart = (state) => {
  return updateObject(state, {
    comprasPorMes: updateObject(state.comprasPorMes, {
      compras: [],
      comprasDataTable: [],
      total: 0,
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const loadComprasPorMesSuccess = (state, compras, comprasDataTable, total) => {
  return updateObject(state, {
    comprasPorMes: updateObject(state.comprasPorMes, {
      compras,
      comprasDataTable,
      total,
      loading: false,
      estaCargado: true
    })
  });
};

const loadComprasPorMesFail = (state, error) => {
  return updateObject(state, {
    comprasPorMes: updateObject(state.comprasPorMes, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearComprasPorMes = (state) => {
  return updateObject(state, {
    comprasPorMes: updateObject(state.comprasPorMes, {
      compras: [],
      comprasDataTable: [],
      total: 0,
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#region Saldo Proveedor
const loadSaldoProveedorStart = (state) => {
  return updateObject(state, {
    saldo: updateObject(state.saldo, {
      loading: true,
      estaCargado: false,
    }),
  });
};

const loadSaldoProveedorSuccess = (state, saldo) => {
  return updateObject(state, {
    saldo: updateObject(state.saldo, {
      loading: false,
      error: null,
      estaCargado: true,
      saldo
    }),
  });
};

const loadSaldoProveedorFail = (state, error) => {
  return updateObject(state, {
    saldo: updateObject(state.saldo, {
      loading: false,
      error,
      estaCargado: true,
    }),
  });
};

const clearSaldoProveedor = (state) => {
  return updateObject(state, {
    saldo: updateObject(state.saldo, {
      loading: false,
      error: null,
      estaCargado: false,
    }),
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PROVEEDOR_BY_ID_START:
      return loadProveedorByIdStart(state);
    case actionTypes.LOAD_PROVEEDOR_BY_ID_SUCCESS:
      return loadProveedorByIdSuccess(state, action.proveedor);
    case actionTypes.LOAD_PROVEEDOR_BY_ID_FAIL:
      return loadProveedorByIdFail(state, action.error);
    case actionTypes.UNMOUNT_PROVEEDOR_BY_ID:
      return unmountProveedorById(state);
    
    case actionTypes.LOAD_COMPRAS_POR_MES_START:
      return loadComprasPorMesStart(state);
    case actionTypes.LOAD_COMPRAS_POR_MES_SUCCESS:
      return loadComprasPorMesSuccess(state, action.compras, action.comprasDataTable, action.totalcompras);
    case actionTypes.LOAD_COMPRAS_POR_MES_FAIL:
      return loadComprasPorMesFail(state, action.error);
    case actionTypes.CLEAR_EVOLUCION_DE_GASTOS:
      return clearComprasPorMes(state);    

    case actionTypes.LOAD_ULTIMA_COMPRA_BY_PROVEEDOR_START:
      return loadUltimaCompraStart(state);
    case actionTypes.LOAD_ULTIMA_COMPRA_BY_PROVEEDOR_SUCCESS:
      return loadUltimaCompraSuccess(state, action.fecha, action.idComproba, action.nombre, action.referencia, action.total);
    case actionTypes.LOAD_ULTIMA_COMPRA_BY_PROVEEDOR_FAIL:
      return loadUltimaCompraFail(state, action.error);
    case actionTypes.CLEAR_ULTIMA_COMPRA_BY_PROVEEDOR:
      return clearUltimaCompra(state);

    case actionTypes.LOAD_CUENTA_CORRIENTE_PROVEEDOR_START:
      return loadCuentaCorrienteProveedorStart(state);
    case actionTypes.LOAD_CUENTA_CORRIENTE_PROVEEDOR_SUCCESS:
      return loadCuentaCorrienteProveedorSuccess(state, action.comprobantes);
    case actionTypes.LOAD_CUENTA_CORRIENTE_PROVEEDOR_FAIL:
      return loadCuentaCorrienteProveedorFail(state, action.error);
    case actionTypes.CLEAR_CUENTA_CORRIENTE_PROVEEDOR:
      return clearCuentaCorrienteProveedor(state);

    case actionTypes.LOAD_SALDO_PROVEEDOR_START:
      return loadSaldoProveedorStart(state);
    case actionTypes.LOAD_SALDO_PROVEEDOR_SUCCESS:
      return loadSaldoProveedorSuccess(state, action.saldo);
    case actionTypes.LOAD_SALDO_PROVEEDOR_FAIL:
      return loadSaldoProveedorFail(state, action.error);
    case actionTypes.CLEAR_SALDO_PROVEEDOR:
      return clearSaldoProveedor(state);

    default:
      return state;
  }
};

export default reducer;
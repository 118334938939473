import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions/index';
import LineChart from '../../../Charts/LineChart';

const LineEvolucionCompras = (props) => { 
  return (
    <LineChart
      title={'Evolución de ventas por mes'}
      categories={props.ventas && props.ventas.map((venta) => {
          return new Date(venta.anio, venta.mes - 1, 1);
        })
      }
      textoTooltip='Monto'
      series={props.ventas && props.ventas.map((venta) => venta.monto)}
      width={props.width}
      loading={props.loading}
      imgSinDatos={props.ventas.length === 0}
      showXLabels
      labelsRotateAlways
      onFiltersClick={() => props.onShowFiltersModal()}
      height={props.height}
      minHeight={props.minHeight}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    ventas: state.clientes.evolucionGastosMensual.ventas,
    loading: state.clientes.evolucionGastosMensual.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Evolución de ventas por mes', ['venta']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LineEvolucionCompras);

import React, { Fragment, useEffect, useState } from 'react';
import TableDetallesComprobante from  '../TablaDetallesComprobante/TablaDetallesComprobante';
import AccordionObservacionesComprobante from '../AccordionObservacionesComprobante/AccordionObservacionesComprobante';
import moment from 'moment';
import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import * as actions from '../../../../store/actions';

import classes from './DetallesComprobanteGasto.css';
import { connect } from 'react-redux';
import Spinner from '../../../UI/Spinner/Spinner';
import LabelGridTitleDescription from '../../../UI/LabelGridTitleDescription/LabelGridTitleDescription';

import { formatNumber, formatNumberWithMonedaSigno } from '../../../../assets/shared/formatNumber';
import { storageMonedaSigno } from '../../../../assets/shared/sessionData';
import TableDetallesComprobantesCompras from '../TableComprobantesGastos/TableDetallesComprobantesCompras';

const DetallesComprobanteGasto = (props) => {
  const { onLoadDetallesComprobante, comprobante } = props;

  const { fecha, referencia, numeroExterno, idSucursal, idSistema, idComproba } = comprobante;

  const [loading, setLoading] = useState(false);
  const [comprobanteLocal, setComprobanteLocal] = useState();

  useEffect(() => {
    setLoading(true);
    onLoadDetallesComprobante(
      idSucursal,
      idSistema,
      idComproba,
      referencia,
      (detallesComprobante) => {
        setComprobanteLocal(detallesComprobante);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  const InfoComprobanteContainer = (props) => {
    return (
      <Grid className={classes.infoComprobanteContainer} container spacing={1}>
        {props.children}
      </Grid>
    );
  };

  const InfoComprobanteRow = (props) => {
    return (
      <Grid className={classes.infoComprobanteRow} container item xs={12} spacing={5}>
        {props.children}
      </Grid>
    );
  };

  const buildInfoComprobante = () => {
    return (
      <InfoComprobanteContainer>
        <InfoComprobanteRow>
          <LabelGridTitleDescription title="Proveedor" description={comprobanteLocal.proveedor.trim()} xs={6} />
          <LabelGridTitleDescription title="Comprobante" description={comprobanteLocal.comprobante} xs={6} />
        </InfoComprobanteRow>
        <InfoComprobanteRow>
          <LabelGridTitleDescription title="Cond. de pago" description={comprobanteLocal.condicionPago} xs={6} />
          <LabelGridTitleDescription title="Número" description={numeroExterno} xs={3} />
          <LabelGridTitleDescription title="Fecha" description={moment(fecha).format('DD/MM/YY')} xs={3} />
        </InfoComprobanteRow>
        <InfoComprobanteRow>
          <LabelGridTitleDescription title="Estado comprobante" descriptionSmall={comprobanteLocal.estado} xs={6} />
          <LabelGridTitleDescription title="Referencia" descriptionSmall={referencia} xs={3} />
          <LabelGridTitleDescription
            title="Fecha Contable"
            descriptionSmall={
              comprobanteLocal.fecha_Contable ? moment(comprobanteLocal.fecha_Contable).format('DD/MM/YY') : ''
            }
            xs={3}
          />
        </InfoComprobanteRow>
        <InfoComprobanteRow>
          <LabelGridTitleDescription title="Concepto" descriptionSmall={comprobanteLocal.concepto} xs={6} />
          <LabelGridTitleDescription title="Operación" descriptionSmall={comprobanteLocal.operacion} xs={3} />
          <LabelGridTitleDescription
            title="Nro. Operación"
            descriptionSmall={comprobanteLocal.numeroOperacion}
            xs={3}
          />
        </InfoComprobanteRow>
      </InfoComprobanteContainer>
    );
  };

  const buildModalHeader = () => {
    return (
      <div className={classes.TitleCard}>
        <p style={{ fontWeight: 'bold', marginBottom: '1px' }}>DETALLE DE COMPROBANTE</p>
        <div>
          <IconButton aria-label="close" onClick={() => props.onCloseShowModal()}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    );
  };

  const buildModalFooter = (cotizacion) => {
    return (
      <InfoComprobanteContainer>
        <InfoComprobanteRow>
          <LabelGridTitleDescription
            title="Confección"
            descriptionSmall={
              comprobanteLocal.fechaConfeccion ? moment(comprobanteLocal.fechaConfeccion).format('DD/MM/YY') : ''
            }
            xs={1}
          />
          <LabelGridTitleDescription title="Cotización" descriptionSmall={formatNumberWithMonedaSigno(cotizacion)} xs={1} /> :

          {/* <LabelGridTitleDescription title="Usuario" descriptionSmall={'USUARIO TEST'} xs={3} /> */}
        </InfoComprobanteRow>
      </InfoComprobanteContainer>
    );
  };

  const bodyComprobante = (data) => {
    return (
      <div style={{ opacity: loading && 0.5 }}>
      {buildModalHeader()}
      <Grid container>
        <Grid item xs={6}>
          {buildInfoComprobante()}
        </Grid>
        <Grid item xs={6}>
          <TableDetallesComprobantesCompras detalleItemsCompras={data.detalleItemsCompras} simbolo={data.simboloMoneda} />
        </Grid>
      </Grid>
      <div style={{ marginBottom: '10px' }} />
      {data.observaciones && data.observaciones.length !== 0 && (
        <AccordionObservacionesComprobante observaciones={data.observaciones} />
      )}

      <TableDetallesComprobante articulos={data.detalleComprobante} />        

      <div style={{ marginBottom: '10px' }} />

      <LabelGridTitleDescription
        style={{ alignSelf: 'flex-end', textAlign: 'right' }}
        title="Total"
        description={`${data.simboloMoneda} ${formatNumber(data.importe)}`}
        xs={12}
      />
      <hr />
      {buildModalFooter(data.cotizacion)}
    </div>
    )
  }

  const ModalBody = (data) => {
    return data 
    ? bodyComprobante(data)
    : (
      <div style={{ textAlign: 'center' }}>
        No se pudo cargar el comprobante solicitado. Por favor, intente más tarde.
      </div>
    );
  };

  return loading ? (
    <div className={classes.Spinner}>
      <Spinner />
    </div>
  ) : (
    ModalBody(comprobanteLocal)
  );
};

const mapStateToProps = (state) => ({
  showModal: state.modals.globalModal.show
});

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseShowModal: () => dispatch(actions.showGlobalModal(false, '', undefined, undefined, true, true , 'lg')),
    onChangeModalBody: (newBody) => dispatch(actions.changeGlobalModalBody(newBody)),
    onLoadDetallesComprobante: (idSucursal, idSistema, idComproba, nroReferencia, success, errorFail) =>
      dispatch(actions.loadDetalleComprobanteGasto(idSucursal, idSistema, idComproba, nroReferencia, success, errorFail),)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetallesComprobanteGasto);

import React from "react";
import { connect } from "react-redux";

import * as actions from "../../../../store/actions";
import TextChart from "../../../Charts/TextChart";

import {
  fechaFinPeriodoMesActualFunc,
  fechaFinPeriodoMesAnteriorFunc,
  fechaInicioPeriodoMesActualFunc,
  fechaInicioPeriodoMesAnteriorFunc,
} from "../../../../assets/shared/fechas";

const TextoVentasMensuales = (props) => {
  const {
    loading,
    mesActual,
    mesAnterior,
    error,
  } = props;

  const chartSinDatos = error;
  const fechaTitulo = `${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()}`;
  const isDisabledChart = props.estaOculto;

  return (
    <TextChart
      title={`Ventas del mes ${fechaTitulo}`}
      bigAmount={mesActual}
      bigAmountTooltip={`Período ${fechaInicioPeriodoMesActualFunc()} al ${fechaFinPeriodoMesActualFunc()} `}
      smallAmount={mesAnterior}
      smallAmountTooltip={`Período ${fechaInicioPeriodoMesAnteriorFunc()} al ${fechaFinPeriodoMesAnteriorFunc()}`}
      loading={loading}
      porcentaje
      bigCurrency
      smallCurrency
      disabled={isDisabledChart}
      border={props.border && !props.error}
      imgSinDatos={chartSinDatos}
      onFiltersClick={() => props.onShowFiltersModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ventas.ventasMesComparativo.loading,
    error: state.ventas.ventasMesComparativo.error,
    estaCargado: state.ventas.ventasMesComparativo.estaCargado,
    mesActual: state.ventas.ventasMesComparativo.montoMesActual,
    mesAnterior: state.ventas.ventasMesComparativo.montoMesAnterior,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Ventas del mes', ['venta']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoVentasMensuales);

import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";

import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Spinner from "../../UI/Spinner/Spinner";

const ComprobantesPedidos = (props) => {
  const {
    comprobantesPedidos,
    loading,
    onLoad,
    onChangeValue,
    empresaId,
  } = props;

  useEffect(() => {
    onLoad(empresaId);
  }, [empresaId, onLoad]);

  useEffect(() => {
    enviarValoresTrue(comprobantesPedidos);
  }, [loading]);

  const enviarValoresTrue = (comprobantes) => {
    return comprobantes.map((comprobante) => {
      if (comprobante.estaSeleccionado) 
        return onChangeValue(comprobante);
      return undefined;
    });
  };

  const formatComprobantes = () => {
    const formateoComprobantes = [];
    for (var i = 0; i <= comprobantesPedidos.length / 12; i++) {
      formateoComprobantes.push(comprobantesPedidos.slice(i * 12, i * 12 + 12));
    }

    return (
      <div style={{ display: "flex" }}>
        {formateoComprobantes.map((comprobantes, key) => (
          <div
            key={key}
            style={{ display: "flex", flexDirection: "column", width: "300px" }}
          >
            {comprobantes.map((comprobante, key) => (
              <FormControlLabel
                key={key}
                control={
                  <Switch
                    key={key}
                    defaultChecked={!comprobante.estaSeleccionado}
                    onChange={() => onChangeValue(comprobante)}
                  />
                }
                label={comprobante.nombre.trim()}
              />
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={{ minHeight: props.loading ? "205px" : "0px" }}>
      {props.loading ? (
        <Spinner />
      ) : (
        <FormControl component="fieldset">
          <FormGroup>{formatComprobantes()}</FormGroup>
        </FormControl>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    comprobantesPedidos: state.configuraciones.comprobantesPedidos.comprobantes,
    loading: state.configuraciones.comprobantesPedidos.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (empresaId) => dispatch(actions.loadComprobantesPedidos(empresaId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComprobantesPedidos);

import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

const FilterIcon = (props) => {
  const {
    title,
    iconSize,
    filterSize,
    onClick,
    rootClass,
    iconClass,
  } = props;

  return (
    <Tooltip className={rootClass} title={ title ? title : "Ver filtros del indicador" }>
      <IconButton className={iconClass} size={iconSize ? iconSize : "small"} onClick={onClick}>
        <FilterListIcon fontSize={filterSize ? filterSize : "small"} />
      </IconButton>
    </Tooltip>
  );
}

export default FilterIcon;
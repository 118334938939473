/*eslint-disable */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import Spinner from '../Spinner/Spinner';
import {
  storageMonedaId,
  storageMonedaSigno,
} from "../../../assets/shared/sessionData";

const MonedaSelector = (props) => {
  const [monedaId, setMonedaId] = useState(storageMonedaId() !== null ? storageMonedaId() : '');

  useEffect(() => {
    if (props.monedas.length === 0 && props.token !== null) props.onLoadMonedas();
  }, []);

  return props.loading ? (
    <Spinner personalSize="35px" />
  ) : (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ fontSize: '20px' }}>{storageMonedaSigno()}</div>
      <FormControl style={{ margin: '0px 10px 0px 10px' }}>
        <Select
          style={{ minWidth: 120, color: props.letraColor }}
          labelId="monedaLabel"
          id="monedaSelect"
          value={props.monedas.length !== 0 ? monedaId : ''}
          onChange={(event) => {
            setMonedaId(event.target.value);
            props.onSeleccionarMoneda(event.target.value);
          }}
        >
          {props.monedas.map((moneda) => (
            <MenuItem key={moneda.idMoneda} value={moneda.idMoneda}>
              {moneda.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.moneda.loading,
    monedas: state.moneda.monedas,
    token: state.auth.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadMonedas: () => dispatch(actions.loadMonedas()),
    onSeleccionarMoneda: (monedaId) => dispatch(actions.seleccionarMoneda(monedaId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MonedaSelector);
/*eslint-enable */

import axios from '../../axios-dashboard';
import { storageEmpresaId, storageToken } from '../../assets/shared/sessionData';
import * as actionTypes from './actionTypes';
import { enqueueSnackbar } from './snackbar';

const executeCreateSucursal = (loading) => {
  return {
    type: actionTypes.EXECUTE_CREATE_SUCURSAL,
    loading
  };
};

export const createSucursal = (nuevaSucursal, success) => {
  return (dispatch) => {
    dispatch(executeCreateSucursal(true));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .post(`/sucursales/sucursal`, nuevaSucursal, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(loadSucursales(undefined, true));
        dispatch(executeCreateSucursal(false));
        dispatch(
          enqueueSnackbar({
            message: `Sucursal creada con éxito!`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        return success();
      })
      .catch((error) => {
        dispatch(executeCreateSucursal(false));
      });
  };
};

const executeUpdateSucursal = (loading) => {
  return {
    type: actionTypes.EXECUTE_UPDATE_SUCURSAL,
    loading
  };
};

export const updateSucursal = (sucursalId, updatedSucursal) => {
  return (dispatch) => {
    dispatch(executeUpdateSucursal(true));
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .put(`/sucursales/sucursal?sucursalId=${sucursalId}`, updatedSucursal, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(
          enqueueSnackbar({
            message: `Sucursal modificada con éxito!`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        dispatch(executeUpdateSucursal(false));
      })
      .catch((error) => {
        dispatch(executeUpdateSucursal(false));
      });
  };
};

export const loadSucursales = (empresaId = storageEmpresaId(), incluirInactivas, success) => {
  return (dispatch) => {
    dispatch(loadSucursalesStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(`/sucursales/sucursales?empresaId=${empresaId}&incluirInactivas=${incluirInactivas}`, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(loadSucursalesSuccess(response.data));

        return success && success(response.data);
      })
      .catch((error) => {
        dispatch(loadSucursalesFail(error));
      });
  };
};

const loadSucursalesStart = () => {
  return {
    type: actionTypes.LOAD_SUCURSALES_START
  };
};

const loadSucursalesSuccess = (sucursales) => {
  return {
    type: actionTypes.LOAD_SUCURSALES_SUCCESS,
    sucursales
  };
};

const loadSucursalesFail = (error) => {
  return {
    type: actionTypes.LOAD_SUCURSALES_FAIL,
    error
  };
};

export const loadSucursalById = (sucursalId, success, errorFail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(`/sucursales/sucursal?sucursalId=${sucursalId}`, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        success(response.data);
      })
      .catch((error) => {
        errorFail(error);
      });
  };
};



import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import TextChart from "../../../Charts/TextChart";
import { formatNumber } from "../../../../assets/shared/formatNumber";
import { storageMonedaSigno } from "../../../../assets/shared/sessionData";

const TextoUltimaCompra = (props) => {
  const { proveedor, fecha, idComproba, nombre, referencia, total, loading, } = props;
  const indSinInfo = idComproba === undefined || idComproba === null;
  const validateDate = moment(fecha).format("DD/MM/YYYY");

  return (
    <TextChart
      title={`Última compra`}
      bigAmount={
        indSinInfo || validateDate._isValid
          ? "Sin info"
          : moment(fecha).format("DD/MM/YYYY")
      }
      smallTextTooltip={
        !indSinInfo && (
          <div>
            Nombre: {nombre}
            <br />
            Numero: {referencia}
            <br />
            Importe:
            {` ${storageMonedaSigno()} ${formatNumber(total)}`}
          </div>
        )
      }
      noClient={!proveedor}
      smallText={
        !indSinInfo
          ? `Comprobante: ${idComproba} ${referencia}`
          : "Comprobante: -"
      }
      loading={loading}
      imgSinDatos={indSinInfo}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    proveedor: state.proveedores.proveedorById.proveedor,
    fecha: state.proveedores.ultimaCompra.fecha,
    idComproba: state.proveedores.ultimaCompra.idComproba,
    nombre: state.proveedores.ultimaCompra.nombre,
    referencia: state.proveedores.ultimaCompra.referencia,
    total: state.proveedores.ultimaCompra.total,
    loading: state.proveedores.ultimaCompra.loading,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoUltimaCompra);

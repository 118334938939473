import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as actions from "../../../../store/actions";

import { formatNumber, isEven } from "../../../../assets/shared/formatNumber";
import Table from "../../../UI/Table/Table";
import { storageMonedaSigno } from "../../../../assets/shared/sessionData";
import { IconButton, Tooltip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import Proveedor from "../../../../containers/Proveedor/Proveedor";

const TablaDeudasPorProveedorDeLaSeleccion = (props) => {
  let history = useHistory();
  const { ordenamiento, clientesPrimero, onLoadAperturaDeDeuda, itemExpanded, onOpenProveedorModal } = props;
  const { id, mes, anio } = itemExpanded;
  const [ventasLocal, setVentasLocal] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    onLoadAperturaDeDeuda({ id, ordenamiento, mes, anio }, (response) => {
      response.success && setVentasLocal(response.data);
      setLoading(false);
    });
  }, []);

  const columns = [
    {
      title: "Proveedor",
      field: "razonSocial",
      render: (rowData) => rowData.razonSocial?.trim(),
      align: "left",
      cellStyle: {
        fontSize: "12px",
        paddingBottom: "5px",
        paddingTop: "5px",
      },
      headerStyle: {
        fontSize: "14px",
        paddingTop: "0px",
        paddingBottom: "0px",
        backgroundColor: clientesPrimero
          ? "rgb(220 220 220)"
          : "rgb(235 235 235)",
      },
    },
    {
      title: ``,
      field: "deuda",
      align: "right",
      type: "numeric",
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
          color: cellData < 0 && "red",
        };
      },
      headerStyle: {
        fontSize: "14px",
        paddingTop: "0px",
        paddingBottom: "0px",
        backgroundColor: clientesPrimero
          ? "rgb(220 220 220)"
          : "rgb(235 235 235)",
      },
      render: (rowData) =>
        `${storageMonedaSigno()} ${formatNumber(rowData.deuda)}`,
    },
    {
      title: ``,
      field: "porcentajeDeParticipacion",
      align: "right",
      type: "numeric",
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
          color: cellData < 0 && "red",
        };
      },
      headerStyle: {
        fontSize: "14px",
        paddingTop: "0px",
        paddingBottom: "0px",
        backgroundColor: clientesPrimero
          ? "rgb(220 220 220)"
          : "rgb(235 235 235)",
      },
      render: (rowData) =>
        `${formatNumber(rowData.porcentajeDeParticipacion)}%`,
    },   
    {
      title: ``,
      field: "id",
      align: "right",
      type: "numeric",
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
          color: cellData < 0 && "red",
          width: "8%",
        };
      },
      headerStyle: {
        fontSize: "14px",
        paddingTop: "0px",
        paddingBottom: "0px",
        backgroundColor: clientesPrimero
          ? "rgb(220 220 220)"
          : "rgb(235 235 235)",
      },
      render: (rowData) => buildActionsButtons(rowData),
    },
  ];

  const openEstadoProveedor = (idProveedor) => {
    props.onClearChart();
    sessionStorage.setItem("idProveedor", idProveedor);

    const title= "Estado del proveedor";
    const body= estadoProveedor();
    onOpenProveedorModal(true, title, body);
  }

  const estadoProveedor = () => {
    return (
      <Proveedor />
    )
  }

  const buildActionsButtons = (rowData) => {
    return (
      <div style={{ textAlign: "right" }}>
        <Tooltip title="Ver estado del proveedor">
          <IconButton size="small" onClick={() => openEstadoProveedor(rowData.id)}>
            <VisibilityIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const data = () => {
    return ventasLocal.map((venta) => {
      return {
        id: venta.id,
        razonSocial: venta.razonSocial,
        deuda: venta.deuda,
        porcentajeDeParticipacion: venta.porcentajeDeParticipacion,
        creaditoMaximo: venta.creditoMaximo,
      };
    });
  };

  const tableRef = useRef();

  return (
    <div >
      <Table
        tableRef={tableRef}
        title=""
        style={{ width: "95%", marginTop: 0, marginBottom: "15px", float: "right", }}
        columns={columns}
        loading={loading}
        data={data()}
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: 275,
          rowStyle: (rowData, index) => ({
            color: rowData.tableData.monto < 0 && "red",
            backgroundColor: !isEven(index) && "#f2f2f2",
          }),
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadAperturaDeDeuda: ({ id, ordenamiento, mes, anio }, final) =>
      dispatch(actions.loadAperturaDeDeudaProveedores({ id, ordenamiento, mes, anio }, final)),
    onClearChart: () => dispatch(actions.clearIndicadoresProveedor()),
    onOpenProveedorModal: (show, title, body) => dispatch(actions.showEstadoModal(show, title, body))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaDeudasPorProveedorDeLaSeleccion);

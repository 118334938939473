import * as actionTypes from "./actionTypes";

export const updateUltimaActualizacionAnalisisDeudaCliente = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_DEUDA_CLIENTE,
    fechaHora,
  };
};

export const clearUltimaActualizacionAnalisisDeudaCliente = () => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_DEUDA_CLIENTE,
  };
};

export const updateUltimaActualizacionAnalisisDeudaProveedor = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_DEUDA_PROVEEDOR,
    fechaHora,
  };
};

export const clearUltimaActualizacionAnalisisDeudaProveedor = () => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_DEUDA_PROVEEDOR,
  };
};

export const updateUltimaActualizacionCliente = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_CLIENTE,
    fechaHora,
  };
};

export const clearUltimaActualizacionCliente = () => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_CLIENTE,
  };
};

export const updateUltimaActualizacionProveedor = (fechaHora) => {
  return {
    type: actionTypes.UPDATE_ULTIMA_ACTUALIZACION_PROVEEDOR,
    fechaHora,
  };
};

export const clearUltimaActualizacionProveedor = () => {
  return {
    type: actionTypes.CLEAR_ULTIMA_ACTUALIZACION_PROVEEDOR,
  };
};
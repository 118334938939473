import React from "react";
import { connect } from "react-redux";

import TextChart from "../../../Charts/TextChart";

const TextoCreditoMaximo = (props) => {
  const { clientSelected, cliente, loading } = props;

  return (
    <TextChart
      title={`Crédito máximo`}
      bigAmount={cliente && cliente.cuentaCorriente ? cliente.cuentaCorriente.creditoMaximo : 0}
      currencySimbol={cliente && cliente.cuentaCorriente ? cliente.cuentaCorriente.moneda : null}
      noClient={!clientSelected}
      bigCurrency
      loading={loading}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    clientSelected: state.clientes.clienteById.clientSelected,
    cliente: state.clientes.clienteById.cliente,
    loading: state.clientes.clienteById.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoCreditoMaximo);

import React from "react";
import { connect } from "react-redux";

import * as actions from "../../../../store/actions/index";
import LineChart from "../../../Charts/LineChart";

const LineEvolucionDeudasPorMes = (props) => {
    const { deudas, width, loading, onShowFiltersModal} = props;
    
  return (
    <LineChart
      title={"Evolución de deudas por mes"}
      textoTooltip={"Monto"}
      categories={
        deudas &&
        deudas.map((venta) => {
          return new Date(venta.anio, venta.mes - 1, 1);
        })
      }
      series={deudas && deudas.map((venta) => venta.monto)}
      width={width}
      loading={loading}
      imgSinDatos={deudas.length === 0}
      showXLabels
      labelsRotateAlways
      onFiltersClick={() => onShowFiltersModal()}
      height={370}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    deudas: state.compras.deudasPorMesProveedores.deudas,
    loading: state.compras.deudasPorMesProveedores.loading,
    totalDeudas: state.compras.deudasPorMesProveedores.totalDeudas,
    estaCargado: state.compras.deudasPorMesProveedores.estaCargado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () =>
      dispatch(
        actions.showListFiltersModal(true, "Evolución de deudas por mes", [
          "venta",
        ])
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LineEvolucionDeudasPorMes);

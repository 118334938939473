import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  indicadoresPorUsuario: {
    indicadores: [],
    loading: false,
    error: null
  },
  indicadoresOcultosPorUsuario: {
    indicadores: {
      ventasDelMes: false,
      ventasDelDia: false,
      ivaFiscal: false,
      cobranzasDelMes: false,
      gastosPorCategoria: false,
      saldoDeudores: false,
      saldoAcreedores: false,
      plazoMedioVencACobrar: false,
      plazoMedioVencAPagar: false,
      promDiasDeCobranzas: false,
      promDiasDePagos: false,
      chequesEnCartera: false,
      chequesEmitidos: false,
      gastosDelMes: false,
      pagosDelMes: false,
      ivaFiscalMultiempresa: false,
      chequesEnCarteraMultiempresa: false,
      chequesEmitidosPendientesMultiempresa: false,
      cobranzasMensualesMultiempresa: false,
      gastosDelMesMultiempresa: false,
      pagosDelMesMultiempresa: false,
      saldoAcreedoresMultiempresa: false,
      saldoDeudoresMultiempresa: false,
      ventasDiariasMultiempresa: false,
      ventasMensualesMultiempresa: false,
      rentabilidadMultiempresa: false,
      deudaPorCategoriaDeCliente: false,
      deudaPorCategoriaDeProveedor: false
    },
    loading: true,
    error: null
  },
  postIndicadoresOcultos: {
    loading: false
  },
  postComprobantes: {
    loading: false
  },
  postCategoriasItemsDeCompras: {
    loading: false
  },
  loadCategoriasItemsDeCompras: {
    categoriasItemsDeCompras: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  loadTiemposRequest: {
    indicadores: [],
    tiempos: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  actualizacionIndicadores: {
    analisisDeudaCliente: false,
    gastos: false,
    multiempresa: false
  },
  categoriasDeProveedor: {
    categorias: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  executeCambiarAjustaPorInflacion: false,
  loadInflacion: {
    inflaciones: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  loadInflacionPorAnio: {
    inflaciones: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  loadNovedades: {
    novedades: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  loadEstadosCheques: {
    estados: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  loadChequesValorEstado: {
    chequesValorEstado: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  comprobantesPedidos: {
    loading: false,
    comprobantes: [],
    error: null,
  },
};

//#region load Indicadores
const loadIndicadoresStart = (state) => {
  return updateObject(state, {
    loadInflacion: updateObject(state.loadInflacion, {
      loading: true,
      indicadores: [],
      error: null
    })
  });
};

const loadIndicadoresSuccess = (state, indicadores) => {
  return updateObject(state, {
    indicadoresPorUsuario: updateObject(state.indicadoresPorUsuario, {
      loading: false,
      indicadores
    })
  });
};

const loadIndicadoresFail = (state, error) => {
  return updateObject(state, {
    indicadoresPorUsuario: updateObject(state.indicadoresPorUsuario, {
      loading: false,
      error
    })
  });
};
//#endregion

//#region load Indicadores Ocultos
const loadIndicadoresOcultosStart = (state) => {
  return updateObject(state, {
    indicadoresOcultosPorUsuario: updateObject(state.indicadoresOcultosPorUsuario, {
      loading: true,
      indicadores: [],
      error: null
    })
  });
};

const loadIndicadoresOcultosSuccess = (state, indicadores) => {
  return updateObject(state, {
    indicadoresOcultosPorUsuario: updateObject(state.indicadoresOcultosPorUsuario, {
      loading: false,
      indicadores
    })
  });
};

const loadIndicadoresOcultosFail = (state, error) => {
  return updateObject(state, {
    indicadoresOcultosPorUsuario: updateObject(state.indicadoresOcultosPorUsuario, {
      loading: false,
      error
    })
  });
};
//#endregion

//#region post Indicadores Ocultos
const executePostIndicadoresOcultos = (state, loading) => {
  return updateObject(state, {
    postIndicadoresOcultos: updateObject(state.postIndicadoresOcultos, {
      loading
    })
  });
};
//#endregion

const executePostComprobantes = (state, loading) => {
  return updateObject(state, {
    postComprobantes: updateObject(state.postComprobantes, {
      loading
    })
  });
};

const executePostCategoriasItemsDeCompras = (state, loading) => {
  return updateObject(state, {
    postCategoriasItemsDeCompras: updateObject(state.postCategoriasItemsDeCompras, {
      loading
    })
  });
};
//#endregion

//#region load Categorias Items de compras
const loadCategoriasItemsDeComprasStart = (state) => {
  return updateObject(state, {
    loadCategoriasItemsDeCompras: updateObject(state.loadCategoriasItemsDeCompras, {
      loading: true,
      categoriasItemsDeCompras: [],
      error: null
    })
  });
};

const loadCategoriasItemsDeComprasSuccess = (state, categoriasItemsDeCompras) => {
  return updateObject(state, {
    loadCategoriasItemsDeCompras: updateObject(state.loadCategoriasItemsDeCompras, {
      loading: false,
      categoriasItemsDeCompras
    })
  });
};

const loadCategoriasItemsDeComprasFail = (state, error) => {
  return updateObject(state, {
    loadCategoriasItemsDeCompras: updateObject(state.loadCategoriasItemsDeCompras, {
      loading: false,
      error
    })
  });
};
//#endregion

//#region load Tiempos request
const loadTiemposRequestStart = (state) => {
  return updateObject(state, {
    loadTiemposRequest: updateObject(state.loadTiemposRequest, {
      loading: true
    })
  });
};

const loadTiemposRequestSuccess = (state, indicadores, tiempos) => {
  return updateObject(state, {
    loadTiemposRequest: updateObject(state.loadTiemposRequest, {
      loading: false,
      indicadores,
      tiempos,
      estaCargado: true
    })
  });
};

const loadTiemposRequestFail = (state, error) => {
  return updateObject(state, {
    loadTiemposRequest: updateObject(state.loadTiemposRequest, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};

const clearTiemposRequest = (state) => {
  return updateObject(state, {
    loadTiemposRequest: updateObject(state.loadTiemposRequest, {
      indicadores: [],
      tiempos: [],
      loading: false,
      error: null,
      estaCargado: false
    })
  });
};
//#endregion

//#region actualizar indicadores
const actualizarIndicadores = (state, page) => {
  switch (page) {
    case '/analisisDeudaCliente' || '':
      return updateObject(state, {
        actualizacionIndicadores: updateObject(state.actualizacionIndicadores, {
          analisisDeudaCliente: !state.actualizacionIndicadores.analisisDeudaCliente
        })
      });

    case '/gastos':
      return updateObject(state, {
        actualizacionIndicadores: updateObject(state.actualizacionIndicadores, {
          gastos: !state.actualizacionIndicadores.analisisDeudaCliente
        })
      });

    case '/multiempresa':
      return updateObject(state, {
        actualizacionIndicadores: updateObject(state.actualizacionIndicadores, {
          multiempresa: !state.actualizacionIndicadores.analisisDeudaCliente
        })
      });

    default:
      return state;
  }
};
//#endregion

//#region load Categorias de proveedor
const loadCategoriasDeProveedorStart = (state) => {
  return updateObject(state, {
    categoriasDeProveedor: updateObject(state.categoriasDeProveedor, {
      loading: true
    })
  });
};

const loadCategoriasDeProveedorSuccess = (state, categorias) => {
  return updateObject(state, {
    categoriasDeProveedor: updateObject(state.categoriasDeProveedor, {
      loading: false,
      categorias,
      estaCargado: true
    })
  });
};

const loadCategoriasDeProveedorFail = (state, error) => {
  return updateObject(state, {
    categoriasDeProveedor: updateObject(state.categoriasDeProveedor, {
      loading: false,
      error,
      estaCargado: true
    })
  });
};
//#endregion

//#region load Inflacion
const loadInflacionesStart = (state) => {
  return updateObject(state, {
    loadInflacion: updateObject(state.loadInflacion, {
      loading: true,
      inflaciones: [],
      error: null
    })
  });
};

const loadInflacionesSuccess = (state, inflaciones) => {
  return updateObject(state, {
    loadInflacion: updateObject(state.loadInflacion, {
      loading: false,
      inflaciones
    })
  });
};

const loadInflacionesFail = (state, error) => {
  return updateObject(state, {
    loadInflacion: updateObject(state.loadInflacion, {
      loading: false,
      error
    })
  });
};

const loadInflacionPorAnioStart = (state) => {
  return updateObject(state, {
    loadInflacionPorAnio: updateObject(state.loadInflacionPorAnio, {
      loading: true,
      inflaciones: [],
      error: null
    })
  });
};

const loadInflacionPorAnioSuccess = (state, inflaciones) => {
  return updateObject(state, {
    loadInflacionPorAnio: updateObject(state.loadInflacionPorAnio, {
      loading: false,
      inflaciones,
      estaCargado: true
    })
  });
};

const loadInflacionPorAnioFail = (state, error) => {
  return updateObject(state, {
    loadInflacion: updateObject(state.loadInflacion, {
      loading: false,
      error
    })
  });
};
//#endregion

//#region load Novedades
const loadNovedadesStart = (state) => {
  return updateObject(state, {
    loadNovedades: updateObject(state.loadNovedades, {
      loading: true,
      novedades: [],
      error: null
    })
  });
};

const loadNovedadesSuccess = (state, novedades) => {
  return updateObject(state, {
    loadNovedades: updateObject(state.loadNovedades, {
      loading: false,
      novedades
    })
  });
};

const loadNovedadesFail = (state, error) => {
  return updateObject(state, {
    loadNovedades: updateObject(state.loadNovedades, {
      loading: false,
      error
    })
  });
};
//#endregion

//#region load EstadosCheques
const loadEstadosChequesStart = (state) => {
  return updateObject(state, {
    loadEstadosCheques: updateObject(state.loadEstadosCheques, {
      loading: true,
      estados: [],
      error: null
    })
  });
};

const loadEstadosChequesSuccess = (state, estados) => {
  return updateObject(state, {
    loadEstadosCheques: updateObject(state.loadEstadosCheques, {
      loading: false,
      estados
    })
  });
};

const loadEstadosChequesFail = (state, error) => {
  return updateObject(state, {
    loadEstadosCheques: updateObject(state.loadEstadosCheques, {
      loading: false,
      error
    })
  });
};
//#endregion

//#region load ChequesValorEstado
const loadChequesValorEstadoStart = (state) => {
  return updateObject(state, {
    loadChequesValorEstado: updateObject(state.loadChequesValorEstado, {
      loading: true,
      chequesValorEstado: [],
      error: null
    })
  });
};

const loadChequesValorEstadoSuccess = (state, chequesValorEstado) => {
  return updateObject(state, {
    loadChequesValorEstado: updateObject(state.loadChequesValorEstado, {
      loading: false,
      chequesValorEstado
    })
  });
};

const loadChequesValorEstadoFail = (state, error) => {
  return updateObject(state, {
    loadChequesValorEstado: updateObject(state.loadChequesValorEstado, {
      loading: false,
      error
    })
  });
};
//#endregion

//#region load comprobantes de Pedidos
const loadComprobantesPedidosStart = (state) => {
  return updateObject(state, {
    comprobantesPedidos: updateObject(state.comprobantesPedidos, {
      loading: true,
      comprobantes: [],
      error: null,
    }),
  });
};

const loadComprobantesPedidosSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesPedidos: updateObject(state.comprobantesPedidos, {
      loading: false,
      comprobantes,
    }),
  });
};

const loadComprobantesPedidosFail = (state, error) => {
  return updateObject(state, {
    comprobantesPedidos: updateObject(state.comprobantesPedidos, {
      loading: false,
      error: error,
    }),
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXECUTE_CAMBIAR_AJUSTA_POR_INFLACION:
      return { ...state, executeCambiarAjustaPorInflacion: action.loading };

    case actionTypes.LOAD_CONFIGURACION_INDICADORES_START:
      return loadIndicadoresStart(state);
    case actionTypes.LOAD_CONFIGURACION_INDICADORES_SUCCESS:
      return loadIndicadoresSuccess(state, action.indicadores);
    case actionTypes.LOAD_CONFIGURACION_INDICADORES_FAIL:
      return loadIndicadoresFail(state, action.error);

    case actionTypes.LOAD_INDICADORES_OCULTOS_START:
      return loadIndicadoresOcultosStart(state);
    case actionTypes.LOAD_INDICADORES_OCULTOS_SUCCESS:
      return loadIndicadoresOcultosSuccess(state, action.indicadores);
    case actionTypes.LOAD_INDICADORES_OCULTOS_FAIL:
      return loadIndicadoresOcultosFail(state, action.error);

    case actionTypes.EXECUTE_POST_INDICADORES_OCULTOS:
      return executePostIndicadoresOcultos(state, action.loading);
    case actionTypes.EXECUTE_POST_COMPROBANTES:
      return executePostComprobantes(state, action.loading);
    case actionTypes.EXECUTE_POST_CATEGORIAS_ITEMS_COMPRA:
      return executePostCategoriasItemsDeCompras(state, action.loading);

    case actionTypes.LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_START:
      return loadCategoriasItemsDeComprasStart(state);
    case actionTypes.LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_SUCCESS:
      return loadCategoriasItemsDeComprasSuccess(state, action.categoriasItemsDeCompras);
    case actionTypes.LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_FAIL:
      return loadCategoriasItemsDeComprasFail(state, action.error);

    case actionTypes.LOAD_TIEMPOS_REQUEST_START:
      return loadTiemposRequestStart(state);
    case actionTypes.LOAD_TIEMPOS_REQUEST_SUCCESS:
      return loadTiemposRequestSuccess(state, action.indicadores, action.tiempos);
    case actionTypes.LOAD_TIEMPOS_REQUEST_FAIL:
      return loadTiemposRequestFail(state, action.error);
    case actionTypes.CLEAR_TIEMPOS_REQUEST:
      return clearTiemposRequest(state);

    case actionTypes.ACTUALIZAR_INDICADORES:
      return actualizarIndicadores(state, action.page);

    case actionTypes.LOAD_CATEGORIAS_DE_PROVEEDOR_START:
      return loadCategoriasDeProveedorStart(state);
    case actionTypes.LOAD_CATEGORIAS_DE_PROVEEDOR_SUCCESS:
      return loadCategoriasDeProveedorSuccess(state, action.categorias);
    case actionTypes.LOAD_CATEGORIAS_DE_PROVEEDOR_FAIL:
      return loadCategoriasDeProveedorFail(state, action.error);

    case actionTypes.LOAD_INFLACION_START:
      return loadInflacionesStart(state);
    case actionTypes.LOAD_INFLACION_SUCCESS:
      return loadInflacionesSuccess(state, action.inflaciones);
    case actionTypes.LOAD_INFLACION_FAIL:
      return loadInflacionesFail(state, action.error);

    case actionTypes.LOAD_INFLACION_POR_ANIO_START:
      return loadInflacionPorAnioStart(state);
    case actionTypes.LOAD_INFLACION_POR_ANIO_SUCCESS:
      return loadInflacionPorAnioSuccess(state, action.inflaciones);
    case actionTypes.LOAD_INFLACION_POR_ANIO_FAIL:
      return loadInflacionPorAnioFail(state, action.error);

    case actionTypes.LOAD_NOVEDADES_START:
      return loadNovedadesStart(state);
    case actionTypes.LOAD_NOVEDADES_SUCCESS:
      return loadNovedadesSuccess(state, action.novedades);
    case actionTypes.LOAD_NOVEDADES_FAIL:
      return loadNovedadesFail(state, action.error);

    case actionTypes.LOAD_ESTADOS_CHEQUES_START:
      return loadEstadosChequesStart(state);
    case actionTypes.LOAD_ESTADOS_CHEQUES_SUCCESS:
      return loadEstadosChequesSuccess(state, action.estados);
    case actionTypes.LOAD_ESTADOS_CHEQUES_FAIL:
      return loadEstadosChequesFail(state, action.error);

    case actionTypes.LOAD_CHEQUES_VALOR_ESTADO_START:
      return loadChequesValorEstadoStart(state);
    case actionTypes.LOAD_CHEQUES_VALOR_ESTADO_SUCCESS:
      return loadChequesValorEstadoSuccess(state, action.chequesValorEstado);
    case actionTypes.LOAD_CHEQUES_VALOR_ESTADO_FAIL:
      return loadChequesValorEstadoFail(state, action.error);

    case actionTypes.LOAD_COMPROBANTES_PEDIDOS_START:
      return loadComprobantesPedidosStart(state);
    case actionTypes.LOAD_COMPROBANTES_PEDIDOS_SUCCESS:
      return loadComprobantesPedidosSuccess(state, action.comprobantesPedidos);
    case actionTypes.LOAD_COMPROBANTES_PEDIDOS_FAIL:
      return loadComprobantesPedidosFail(state, action.error);
      
    default:
      return state;
  }
};

export default reducer;

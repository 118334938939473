import React from "react";
import { connect } from "react-redux";
import TextChart from "../../../Charts/TextChart";

const TextoPedidosPendientes = (props) => {
  const {
    cantidad,
    loading,
    error,
    total,
  } = props;

  const chartSinDatos = error;

  return (
    <TextChart
      title={`Cantidad de órdenes de compra pendientes`}
      bigAmount={`${cantidad}`}
      loading={loading}
      imgSinDatos={error || chartSinDatos}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.compras.ordenesDeComprasPendientes.loading,
    error: state.compras.ordenesDeComprasPendientes.error,
    cantidad: state.compras.ordenesDeComprasPendientes.cantidad,
    total: state.compras.ordenesDeComprasPendientes.total,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoPedidosPendientes);

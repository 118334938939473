import React from "react";
import { connect } from "react-redux";
import ListaFiltrosPorIndicadorModal from "../../components/Configuraciones/ListaFiltrosPorIndicadorModal/ListaFiltrosPorIndicadorModal";

import Toolbar from "../../components/Navigation/Drawer/Drawer";
import ModalGlobal from "../../components/UI/ModalGlobal/ModalGlobal";
import Notifier from "../../components/UI/Notifier/Notifier";
import EnviarChart from "../../components/UI/EnviarChart/EnviarChart";
import ModalEstado from "../../components/UI/ModalEstado/ModalEstado";
import BackButton from '../../components/UI/BackButton/BackButton';
import BackButtonModal from '../../components/UI/BackButton/BackButtonModal';

const layout = (props) => {
  return (
    <React.Fragment>
      <BackButton />
      <Notifier />
      <Toolbar
        isAuth={props.isAuth}
        nombreEmpresa={sessionStorage.getItem("empresaNombre")}
      >
        {props.children}
      </Toolbar>
      <ModalGlobal />
      <ListaFiltrosPorIndicadorModal />
      <EnviarChart />
      <ModalEstado />
      <EnviarChart />
      <BackButtonModal/>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.token !== null,
  };
};

export default connect(mapStateToProps)(layout);

import React, { useRef } from 'react';

import Chart from 'react-apexcharts';
import Spinner from '../UI/Spinner/Spinner';
import { withRouter } from 'react-router-dom';
import SinResultados from '../../assets/images/SinResultados.png';
import IndicadorDeshabilitado from '../../assets/images/IndicadorDeshabilitado.png';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Divider, IconButton, Tooltip } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { withStyles } from '@material-ui/core/styles';

import classes from './Styles/SmallChartCard.css';
import { formatNumber, formatNumberWithoutDecimals } from '../../assets/shared/formatNumber';
import { storageMonedaSigno } from '../../assets/shared/sessionData';
import { Fragment } from 'react';
import { colorsChart } from '../../assets/shared/colorsCharts';
import { enviarPorMailOption } from '../UI/EnviarChart/EnviarChartOptions';
import ViewInTable from '../../assets/images/ViewInTable.png';

const PieChart = (props) => {
  let chartRef = useRef(null);

  const tooltip = {
    y: {
      formatter: function (val) {
        return props.showUnidades ? `${formatNumberWithoutDecimals(val)}` : `${storageMonedaSigno()} ${formatNumber(val)}`;
      }
    }
  };

  const dataLabels = {
    enabled: true,
    formatter: function (val) {
      return `${formatNumber(val)}%`;
    },
  }

  const options = {
    chart: {
      type: 'pie',
      toolbar: {
        show: true,
        tools: {
          download: true,
          customIcons: [
            enviarPorMailOption(chartRef, props.title),
          ]
        }
      },
      events: {
        dataPointSelection: function (event, chartContext, config) {
          if (props.redirect) {
            const queryParams = props.queryParamsGetter ? config.w.config.labels[config.dataPointIndex] : '';
            props.history.push(props.redirect, queryParams);
          }
          if (props.onPorcionClick) {
            props.onPorcionClick(config);
          }
        }
      },
      zoom: {
        enabled: false
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 5,
        left: 3,
        blur: 7,
        opacity: 0.2
      }
    },
    colors: props.colors ? props.colors : colorsChart,
    tooltip: props.tooltip ? props.tooltip : tooltip,
    legend: {
      position: props.legendPosition ? props.legendPosition : 'right',
      height: props.legendHeight
    },
    labels: props.labels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%'
          }
        }
      }
    ],
    states: {
      active: {
        filter: {
          type: 'darken',
          value: 1
        }
      }
    },
    xaxis: {
      type: 'numeric',
      categories: props.ids ? props.ids : []
    },
    dataLabels: props.dataLabels ? props.dataLabels : dataLabels,
  };

  const NegativeTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#ff7879',
      color: 'white',
      boxShadow: theme.shadows[1],
      fontSize: 11
    }
  }))(Tooltip);

  const listNegativeTooltip = () => {
    let totalNegativos = 0;
    props.valoresNegativos.map((valorNegativo) => (totalNegativos += valorNegativo.monto));
    let rowTotal = props.valoresNegativos.find((vn) => vn.nombre === 'Total');

    if (!rowTotal)
      props.valoresNegativos.push({ nombre: 'Total', monto: totalNegativos });

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginBottom: '3px' }}>Valores negativos:</div>
        {props.valoresNegativos.map((valorNegativo, key) => {
          return (
            <Fragment>
              {valorNegativo.nombre === 'Total' && <Divider light />}
              <div
                key={key}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: valorNegativo.nombre === 'Total' && '5px'
                }}
              >
                <div style={{ marginRight: '5px' }}>{`${valorNegativo.nombre}:`}</div>
                <div>{props.showUnidades ? `${formatNumberWithoutDecimals(valorNegativo.monto)}` : `${storageMonedaSigno()} ${formatNumber(valorNegativo.monto)}`}</div>
              </div>
            </Fragment>
          );
        })}
      </div>
    );
  };

  return (
    <div
      ref={chartRef}
      disabled={props.disabled}
      className={!props.disabledCss ? props.noBorder ? classes.SmallChartCardNoBorder : classes.SmallChartCard : undefined}
      style={{ height: '100%', width: props.width, minHeight: props.minHeight }}
    >
      <div style={{ display: 'flex', margin: 'auto', flexDirection: 'column', textAlign: 'left', marginLeft: '20px' }}>
        <div className={classes.TitleCard}>
          <div style={{ marginBottom: '1px', marginTop: '14px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
            {props.title && <div style={{ fontWeight: 'bold' }}>{props.title}</div>}
            {props.middleItems && <div style={{ alignSelf: 'center' }}>{props.middleItems}</div>}
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              {props.endItems && <div style={{ marginRight: '10px' }}>{props.endItems}</div>}
              {props.valoresNegativos && props.valoresNegativos.length > 0 && (
                <NegativeTooltip arrow title={listNegativeTooltip()}>
                  <WarningRoundedIcon style={{ color: 'red', opacity: 0.6 }} fontSize="small" />
                </NegativeTooltip>
              )}
            </div>
          </div>
          {props.onFiltersClick && !props.disabled && (
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => props.onFiltersClick()}>
                <FilterListIcon style={{ color: props.border && 'white' }} fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {props.onChangeViewClick && !props.disabled && (
            <Tooltip title="Ver como tabla">
              <IconButton size="small" onClick={() => props.onChangeViewClick()}>
                <img style={{ opacity: 0.5 }} alt="" src={ViewInTable} />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {props.subTitle && <p style={{ marginTop: '1px', marginBottom: '2px', fontSize: '12px' }}>{props.subTitle}</p>}
        {props.children}
      </div>
      {props.spaceBetweenChartTitle && <div style={{ marginBottom: props.spaceBetweenChartTitle }}></div>}
      {props.disabled ? (
        <img alt="Indicador inhablitado" src={IndicadorDeshabilitado} />
      ) : props.loading ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Spinner />
        </div>
      ) : props.imgSinDatos ? (
        <img alt="Sin resultados" src={SinResultados} />
      ) : (
        <Chart
          options={options}
          series={props.series}
          type={props.chartType}
          style={{ textAlign: 'left' }}
          height={props.height}
        />
      )}
    </div>
  );
};

export default withRouter(PieChart);
import React, { useEffect, useState } from 'react';

import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Spinner from '../../UI/Spinner/Spinner';
import { IconButton, Typography } from '@material-ui/core';
import SelectAllIcon from '@material-ui/icons/SelectAll';

const CategoriasConfigBody = (props) => {
  const {
    categorias,
    loading,
    onChangeValue,
    onLoad,
    empresaId,
    configInicial,
    seleccionarTodos,
    deseleccionarTodos
  } = props;

  const [seleccionarTodoCheck, setSeleccionarTodoCheck] = useState(null);
  const [categoriasLocal, setCategoriasLocal] = useState([]);
  const [loadingLocal, setLoadingLocal] = useState(false);

  useEffect(() => {
    setCategoriasLocal(categorias);
    return () => setCategoriasLocal([]);
  }, [categorias, loading]);

  useEffect(() => {
    onLoad(empresaId);
  }, [empresaId]);

  useEffect(() => {
    enviarValoresTrue(categorias);
  }, [loading]);

  const enviarValoresTrue = (comprobantes) => {
    var arr = [];
    comprobantes.map((comprobante) => {
      if (comprobante.estaSeleccionado) {
        return arr.push(comprobante);
      }
      return undefined;
    });

    return configInicial(arr);
  };

  const onSeleccionarTodosClick = () => {
    setLoadingLocal(true);

    var formatCategoriasFalse = [];
    seleccionarTodos();
    setSeleccionarTodoCheck(false);
    categorias.map((categoria) => {
      return formatCategoriasFalse.push({ ...categoria, estaSeleccionado: false });
    });
    setCategoriasLocal(formatCategoriasFalse);
    setTimeout(() => setLoadingLocal(false), 500);
  };

  const onDeseleccionarTodosClick = () => {
    setLoadingLocal(true);
    var arr = [];
    var formatCategoriasTrue = [];
    categorias.map((categoria) => {
      arr.push(categoria);
      return formatCategoriasTrue.push({ ...categoria, estaSeleccionado: true });
    });
    setSeleccionarTodoCheck(true);
    deseleccionarTodos(arr);
    setCategoriasLocal(formatCategoriasTrue);
    setTimeout(() => setLoadingLocal(false), 500);
  };

  const buildSeleccionarTodoButton = () => {
    const verifyTodoSeleccionado = categoriasLocal.filter((categ) => categ.estaSeleccionado === false);
    const verifyTodoDeseleccionado = categoriasLocal.filter((categ) => categ.estaSeleccionado === true);
    const verifySeleccionadoMayor = verifyTodoSeleccionado.length > verifyTodoDeseleccionado.length;
    return (
      <div>
        <IconButton
          onClick={
            seleccionarTodoCheck === null
              ? verifyTodoSeleccionado.length === categoriasLocal.length
                ? () => onDeseleccionarTodosClick()
                : verifyTodoDeseleccionado.length === categoriasLocal.length
                ? () => onSeleccionarTodosClick()
                : verifySeleccionadoMayor
                ? () => onDeseleccionarTodosClick()
                : () => onSeleccionarTodosClick()
              : seleccionarTodoCheck
              ? () => onSeleccionarTodosClick()
              : () => onDeseleccionarTodosClick()
          }
        >
          <SelectAllIcon style={{ color: seleccionarTodoCheck && 'rgb(9, 212, 255)' }} />
        </IconButton>
        <Typography variant="button">
          {seleccionarTodoCheck === null
            ? verifyTodoSeleccionado.length === categoriasLocal.length
              ? 'Deseleccionar todos'
              : verifyTodoDeseleccionado.length === categoriasLocal.length
              ? 'Seleccionar todos'
              : verifySeleccionadoMayor
              ? 'Deseleccionar todos'
              : 'Seleccionar todos'
            : seleccionarTodoCheck
            ? 'Seleccionar todos'
            : 'Deseleccionar todos'}
        </Typography>
      </div>
    );
  };

  const formatCategorias = () => {
    const formateoComprobantes = [];
    for (var i = 0; i <= categoriasLocal.length / 12; i++) {
      formateoComprobantes.push(categoriasLocal.slice(i * 12, i * 12 + 12));
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {formateoComprobantes.map((categorias, key) => (
          <div key={key} style={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
            {categorias.map((categoria, key) => (
              <FormControlLabel
                key={key}
                control={
                  <Switch
                    key={key}
                    defaultChecked={!categoria.estaSeleccionado}
                    onChange={() => onChangeValue(categoria)}
                  />
                }
                label={categoria.nombre.trim()}
              />
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={{ minHeight: props.loading || loadingLocal ? '205px' : '0px' }}>
      {!props.loading && !loadingLocal && buildSeleccionarTodoButton()}
      {props.loading || loadingLocal ? (
        <Spinner />
      ) : (
        <FormControl component="fieldset">
          <FormGroup>{formatCategorias()}</FormGroup>
        </FormControl>
      )}
    </div>
  );
};

export default CategoriasConfigBody;

import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
import Spinner from "../../../UI/Spinner/Spinner";
import classes from "./CardProveedor.css";

const CardProveedor = (props) => {
  const { proveedor, proveedorSelected, loading } = props;
  const [seconds, setSeconds] = useState(1);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!proveedorSelected) {
        setSeconds(seconds + 1);
      }
    }, 2000);

    // clearing interval
    return () => clearInterval(timer);
  });

  return loading ? (
    <div className={classes.CardProveedorContainer}>
      <div style={{ textAlign: 'center' }}>
        <Spinner />
      </div>
    </div>
  ) : (
    <div className={classes.CardProveedorContainer}>  
      <Grid container spacing={1} >
        <Grid item xs={12}>
          <Typography
            style={{ marginRight: "5px", fontSize: '14px' }}
            align="left"
          >
            <span style={{ fontWeight: 'bold' }}>{proveedor.razonSocial} ({proveedor.idProveedor && proveedor.idProveedor.trimEnd()})</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ marginRight: "5px", fontSize: '14px' }}
            align="left"
          >
            <span style={{ fontWeight: 'bold' }}>Dirección: </span>{" "}{proveedorSelected && `${proveedor.direccion}, ${proveedor.localidad}`}
          </Typography>          
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ marginRight: "5px", fontSize: '14px' }}
            align="left"
          >
            <span style={{ fontWeight: 'bold' }}>Mail: </span>{" "}{proveedorSelected && proveedor.mail}
          </Typography>
          <Typography>
          </Typography>        
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ marginRight: "5px", fontSize: '14px' }}
            align="left"
          >
            <span style={{ fontWeight: 'bold' }}>Tel: </span>{proveedorSelected && proveedor.telefonos[0].numero}
          </Typography>          
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ marginRight: "5px", fontSize: '14px' }}
            align="left"
          >
            <span style={{ fontWeight: 'bold' }}>Provincia: </span>{" "}{proveedorSelected && proveedor.provincia}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ marginRight: "5px", fontSize: '14px' }}
            align="left"
          >
            <span style={{ fontWeight: 'bold' }}>Tel 2: </span>{" "}{proveedorSelected && proveedor.telefonos[1] && proveedor.telefonos[1].numero}
          </Typography>        
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ marginRight: "5px", fontSize: '14px' }}
            align="left"
          >
            <span style={{ fontWeight: 'bold' }}>CUIT: </span> {proveedorSelected && proveedor.cuit}
          </Typography>          
        </Grid>
      </Grid>    
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    proveedor: state.proveedores.proveedorById.proveedor,
    loading: state.proveedores.proveedorById.loading,
    proveedorSelected: state.proveedores.proveedorById.proveedorSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUnmountCard: () => dispatch(actions.unmountProveedorById()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardProveedor);
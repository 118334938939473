import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import Dialog from "../../UI/Dialog/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import classes from "./ListaFiltrosPorIndicadorModal.css";
import Spinner from "../../UI/Spinner/Spinner";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { storageEmpresaId } from "../../../assets/shared/sessionData";

const ListaFiltrosPorIndicadorModal = (props) => {
  const {
    filterTypes,
    cobranzasEstaCargado,
    onLoadFiltrosCobranzas,
    comprasEstaCargado,
    onLoadFiltrosCompras,
    gastosEstaCargado,
    onLoadFiltrosGastos,
    pagosEstaCargado,
    onLoadFiltrosPagos,
    ventasEstaCargado,
    onLoadFiltrosVentas,
    categoriasDeClienteEstaCargado,
    onLoadFiltrosCategDelCliente,
    categoriasDeProveedorEstaCargado,
    onLoadFiltrosCategDeProveedor,
    categoriasItemsDeComprasEstaCargado,
    onLoadFiltrosCategItemsDeCompras,
    cobranzasLoading,
    cobranzasFiltros,
    comprasLoading,
    comprasFiltros,
    gastosLoading,
    gastosFiltros,
    pagosLoading,
    pagosFiltros,
    ventasLoading,
    ventasFiltros,
    categoriasItemsDeComprasLoading,
    categoriasItemsDeComprasFiltros,
    categoriasDeClienteLoading,
    categoriasDeClienteFiltros,
    categoriasDeProveedorLoading,
    categoriasDeProveedorFiltros,
    title,
    disableCloseButton,
    onCloseShowModal,
    fullWidth,
    maxWidth,
    show,
  } = props;
  
  const cobranza = props.filterTypes.includes("cobranza");
  const compra = props.filterTypes.includes("compra");
  const gasto = props.filterTypes.includes("gasto");
  const pago = props.filterTypes.includes("pago");
  const venta = props.filterTypes.includes("venta");
  const categoriaDeCliente = props.filterTypes.includes("categoriaDeCliente");
  const categoriaDeProveedor = props.filterTypes.includes(
    "categoriaDeProveedor"
  );
  const categoriaItemsDeCompra = filterTypes.includes("categoriaItemsDeCompra");

  useEffect(() => {
    if (cobranza && !cobranzasEstaCargado) onLoadFiltrosCobranzas();
    if (compra && !comprasEstaCargado) onLoadFiltrosCompras();
    if (gasto && !gastosEstaCargado) onLoadFiltrosGastos();
    if (pago && !pagosEstaCargado) onLoadFiltrosPagos();
    if (venta && !ventasEstaCargado) onLoadFiltrosVentas();
    if (categoriaDeCliente && !categoriasDeClienteEstaCargado)
      onLoadFiltrosCategDelCliente();
    if (categoriaDeProveedor && !categoriasDeProveedorEstaCargado)
      onLoadFiltrosCategDeProveedor();
    if (categoriaItemsDeCompra && !categoriasItemsDeComprasEstaCargado)
      onLoadFiltrosCategItemsDeCompras();
  }, [
    cobranza,
    compra,
    gasto,
    pago,
    venta,
    categoriaDeCliente,
    categoriaDeProveedor,
    categoriaItemsDeCompra,
  ]);

  const filtrarSeleccionados = (loading, datas, columnTitle) => {
    const filtrosSeleccionados = datas.filter(
      (data) => data.estaSeleccionado === false
    );

    const listarFiltros = [];
    for (var i = 0; i <= filtrosSeleccionados.length / 12; i++) {
      listarFiltros.push(filtrosSeleccionados.slice(i * 12, i * 12 + 12));
    }

    return (
      <Accordion classes={{ root: classes.AccordionRoot }} square>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id={columnTitle}>
          <Typography variant="button">{columnTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loading ? (
            <Spinner personalSize="20px" />
          ) : filtrosSeleccionados.length === 0 ? (
            "No hay filtros activos"
          ) : (
            listarFiltros.map((filtros, key) => {
              return (
                <div key={key} style={{ marginLeft: key !== 0 && "15px" }}>
                  {filtros.map((filtro, index) => {
                    return <div key={index}>{filtro.nombre}</div>;
                  })}
                </div>
              );
            })
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  const body = () => {
    return (
      <div>
        {cobranza &&
          filtrarSeleccionados(
            cobranzasLoading,
            cobranzasFiltros,
            "Filtros comprobantes de cobranza"
          )}

        {compra &&
          filtrarSeleccionados(
            comprasLoading,
            comprasFiltros,
            "Filtros comprobantes de compra"
          )}

        {gasto &&
          filtrarSeleccionados(
            gastosLoading,
            gastosFiltros,
            "Filtros comprobantes de gasto"
          )}

        {pago &&
          filtrarSeleccionados(
            pagosLoading,
            pagosFiltros,
            "Filtros comprobantes de pago"
          )}

        {venta &&
          filtrarSeleccionados(
            ventasLoading,
            ventasFiltros,
            "Filtros comprobantes de venta"
          )}
        {categoriaItemsDeCompra &&
          filtrarSeleccionados(
            categoriasItemsDeComprasLoading,
            categoriasItemsDeComprasFiltros,
            "Filtros categoría de items de compras"
          )}

        {categoriaDeCliente &&
          filtrarSeleccionados(
            categoriasDeClienteLoading,
            categoriasDeClienteFiltros,
            "Filtros Saldos por categoría del cliente"
          )}

        {categoriaDeProveedor &&
          filtrarSeleccionados(
            categoriasDeProveedorLoading,
            categoriasDeProveedorFiltros,
            "Filtros categoría de proveedor"
          )}
      </div>
    );
  };

  return (
    <Dialog
      title={
        <div classes={classes.TitleDialogContent} style={{ width: "100%" }}>
          <div className={classes.TitleDialogTexto}>
            <Typography variant="h5" style={{ paddingRight: "10px" }}>
              {title}
            </Typography>
            {!disableCloseButton && (
              <div>
                <IconButton
                  aria-label="close"
                  onClick={() => onCloseShowModal()}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}
          </div>
          <div className={classes.subtitleTexto}>
            <Typography variant="caption">
              Filtros aplicados sobre el indicador
            </Typography>
          </div>
        </div>
      }
      fullWidth={fullWidth}
      maxWidth={maxWidth ? maxWidth : false}
      show={show}
      body={body()}
      onClose={() => onCloseShowModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    show: state.modals.listFiltersModal.show,
    title: state.modals.listFiltersModal.title,
    filterTypes: state.modals.listFiltersModal.filterTypes,
    cobranzasFiltros: state.ventas.comprobantesCobranzas.comprobantes,
    cobranzasLoading: state.ventas.comprobantesCobranzas.loading,
    cobranzasEstaCargado: state.ventas.comprobantesCobranzas.estaCargado,

    comprasFiltros: state.compras.comprobantesCompras.comprobantes,
    comprasLoading: state.compras.comprobantesCompras.loading,
    comprasEstaCargado: state.compras.comprobantesCompras.estaCargado,

    gastosFiltros: state.gastos.comprobantesGastos.comprobantes,
    gastosLoading: state.gastos.comprobantesGastos.loading,
    gastosEstaCargado: state.gastos.comprobantesGastos.estaCargado,

    pagosFiltros: state.compras.comprobantesPagos.comprobantes,
    pagosLoading: state.compras.comprobantesPagos.loading,
    pagosEstaCargado: state.compras.comprobantesPagos.estaCargado,

    ventasFiltros: state.ventas.comprobantesVentas.comprobantes,
    ventasLoading: state.ventas.comprobantesVentas.loading,
    ventasEstaCargado: state.ventas.comprobantesVentas.estaCargado,

    categoriasDeClienteFiltros: state.ventas.categoriasDelCliente.categorias,
    categoriasDeClienteLoading: state.ventas.categoriasDelCliente.loading,
    categoriasDeClienteEstaCargado:
      state.ventas.categoriasDelCliente.estaCargado,

    categoriasDeProveedorFiltros:
      state.configuraciones.categoriasDeProveedor.categorias,
    categoriasDeProveedorLoading:
      state.configuraciones.categoriasDeProveedor.loading,
    categoriasDeProveedorEstaCargado:
      state.configuraciones.categoriasDeProveedor.estaCargado,

    categoriasItemsDeComprasFiltros:
      state.configuraciones.loadCategoriasItemsDeCompras
        .categoriasItemsDeCompras,
    categoriasItemsDeComprasLoading:
      state.configuraciones.loadCategoriasItemsDeCompras.loading,
    categoriasItemsDeComprasEstaCargado:
      state.configuraciones.loadCategoriasItemsDeCompras.estaCargado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseShowModal: () =>
      dispatch(actions.showListFiltersModal(false, "", [])),
    onLoadFiltrosCobranzas: () =>
      dispatch(actions.loadComprobantesCobranzas(storageEmpresaId())),
    onLoadFiltrosCompras: () =>
      dispatch(actions.loadComprobantesCompras(storageEmpresaId())),
    onLoadFiltrosGastos: () =>
      dispatch(actions.loadComprobantesGastos(storageEmpresaId())),
    onLoadFiltrosPagos: () =>
      dispatch(actions.loadComprobantesPagos(storageEmpresaId())),
    onLoadFiltrosVentas: () =>
      dispatch(actions.loadComprobantesVentas(storageEmpresaId())),
    onLoadFiltrosCategDelCliente: () =>
      dispatch(actions.loadCategoriasDelCliente(storageEmpresaId())),
    onLoadFiltrosCategDeProveedor: () =>
      dispatch(actions.loadCategoriasDeProveedor(storageEmpresaId())),
    onLoadFiltrosCategItemsDeCompras: () =>
      dispatch(actions.loadCategoriasItemsDeCompras(storageEmpresaId())),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListaFiltrosPorIndicadorModal);

import IconAnalisisDeudaCliente from "../images/IconAnalisisDeudaCliente.png";
import IconAnalisisDeudaProveedores from "../images/IconAnalisisDeudaProveedores.png";

export const pagesInfo = [
  {
    name: "Análisis deuda de cliente",
    uri: "/analisisDeudaCliente",
    imgIcon: IconAnalisisDeudaCliente,
  },
  {
    name: "Análisis deuda de proveedor",
    uri: "/analisisDeudaProveedor",
    imgIcon: IconAnalisisDeudaProveedores,
  },
];
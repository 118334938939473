import * as actionTypes from "./actionTypes";
import axiosDashboard from "../../axios-dashboard";
import idIndicadores from "../../assets/shared/idIndicadores";
import { storageMonedaId } from "../../assets/shared/sessionData";
import { storageEmpresaId, buildBearerToken } from "../../assets/shared/sessionData";
import axiosConfig from "../../axios-config";
import { enqueueSnackbar } from ".";
import { fechaActualFunc } from "../../assets/shared/fechas";
import { montoMesAnioToDataTablePorMesWithTotal } from "../../assets/constants/functions/formatMontoMesAnio";

//#region CONFIGURACIONES
export const loadComprobantesVentas = (idEmpresa) => {
  return (dispatch) => {
    dispatch(loadComprobantesVentasStart());
    axiosConfig
      .get(
        `/Ventas/ComprobantesVentas?idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.comprobantesVentas}`
      )
      .then((response) => {
        dispatch(loadComprobantesVentasSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadComprobantesVentasFail(error));
          } else {
            dispatch(loadComprobantesVentas(idEmpresa));
          }
        } else {
          dispatch(loadComprobantesVentasFail(error));
        }
      });
  };
};

const loadComprobantesVentasStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_VENTAS_START,
  };
};

const loadComprobantesVentasSuccess = (comprobantesVentas) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_VENTAS_SUCCESS,
    comprobantesVentas,
  };
};

const loadComprobantesVentasFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_VENTAS_FAIL,
    error,
  };
};

export const loadComprobantesCobranzas = (idEmpresa) => {
  return (dispatch) => {
    dispatch(loadComprobantesCobranzasStart());

    axiosConfig
      .get(
        `/ventas/ComprobantesCobranzas?empresaId=${idEmpresa}&indicadorId=${idIndicadores.comprobantesCobranzas}`
      )
      .then((response) => {
        dispatch(loadComprobantesCobranzasSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadComprobantesCobranzasFail(error));
          } else {
            dispatch(loadComprobantesCobranzas(idEmpresa));
          }
        } else {
          dispatch(loadComprobantesCobranzasFail(error));
        }
      });
  };
};

const loadComprobantesCobranzasStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_COBRANZAS_START,
  };
};

const loadComprobantesCobranzasSuccess = (comprobantesCobranzas) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_COBRANZAS_SUCCESS,
    comprobantesCobranzas,
  };
};

const loadComprobantesCobranzasFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_COBRANZAS_FAIL,
    error,
  };
};

export const postComprobantesVentas = (empresaId, comprobantes) => {
  return (dispatch) => {
    dispatch(executePostComprobantes(true));

    axiosConfig
      .post(`/ventas/ComprobantesVentas?idEmpresa=${empresaId}`, comprobantes)
      .then((response) => {
        dispatch(executePostComprobantes(false));

        window.location.reload();
      })
      .catch((error) => {
        dispatch(executePostComprobantes(false));

        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "warning",
            },
          })
        );
      });
  };
};

export const postComprobantesCobranzas = (empresaId, comprobantes) => {
  return (dispatch) => {
    dispatch(executePostComprobantes(true));

    axiosConfig
      .post(
        `/ventas/ComprobantesCobranzas?idEmpresa=${empresaId}`,
        comprobantes
      )
      .then((response) => {
        dispatch(executePostComprobantes(false));
        window.location.reload();
      })
      .catch((error) => {
        dispatch(executePostComprobantes(false));
        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "warning",
            },
          })
        );
      });
  };
};

const executePostComprobantes = (loading) => {
  return {
    type: actionTypes.EXECUTE_POST_COMPROBANTES,
    loading,
  };
};

export const loadCategoriasDelCliente = (idEmpresa) => {
  return (dispatch) => {
    dispatch(loadCategoriasDelClienteStart());

    axiosConfig
      .get(
        `/ventas/CategoriasDeCliente?empresaId=${idEmpresa}&indicadorId=${idIndicadores.categoriasDelCliente}`
      )
      .then((response) => {
        dispatch(loadCategoriasDelClienteSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadCategoriasDelClienteFail(error));
          } else {
            dispatch(loadCategoriasDelCliente(idEmpresa));
          }
        } else {
          dispatch(loadCategoriasDelClienteFail(error));
        }
      });
  };
};

const loadCategoriasDelClienteStart = () => {
  return {
    type: actionTypes.LOAD_CATEGORIAS_DEL_CLIENTE_START,
  };
};

const loadCategoriasDelClienteSuccess = (categorias) => {
  return {
    type: actionTypes.LOAD_CATEGORIAS_DEL_CLIENTE_SUCCESS,
    categorias,
  };
};

const loadCategoriasDelClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_CATEGORIAS_DEL_CLIENTE_FAIL,
    error,
  };
};

export const postCategoriasDelCliente = (empresaId, categorias) => {
  return (dispatch) => {
    dispatch(executePostCategoriasDelCliente(true));

    axiosConfig
      .post(`/ventas/CategoriasDeCliente?idEmpresa=${empresaId}`, categorias)
      .then((response) => {
        dispatch(executePostCategoriasDelCliente(false));

        window.location.reload();
      })
      .catch((error) => {
        dispatch(executePostCategoriasDelCliente(false));

        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "warning",
            },
          })
        );
      });
  };
};

const executePostCategoriasDelCliente = (loading) => {
  return {
    type: actionTypes.EXECUTE_POST_CATEGORIAS_DEL_CLIENTE,
    loading,
  };
};
//#endregion

export const seleccionFechaDeudaDelMesPor = (mes, anio) => {
  return {
    type: actionTypes.SELECCION_FECHA_DEUDAS_DEL_MES_ANIO,
    mes,
    anio,
  };
};

export const loadDeudaPorMes = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadDeudaPorMesStart());

    axiosDashboard
      .get(
        `/ventas/DeudaPorMes?idMoneda=${storageMonedaId()}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.evolucionVentasMensual
        }`
      )
      .then((response) => {
        const { formatedData: deudasDataTable, total: totalDeudas } =
          montoMesAnioToDataTablePorMesWithTotal(response.data);

        dispatch(
          loadDeudaPorMesSuccess(response.data, deudasDataTable, totalDeudas)
        );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadDeudaPorMesFail(error));
          } else {
            dispatch(loadDeudaPorMes(idEmpresa));
          }
        } else {
          dispatch(loadDeudaPorMesFail(error));
        }
      });
  };
};

export const clearDeudaPorMes = () => {
  return {
    type: actionTypes.CLEAR_DEUDA_POR_MES,
  };
};

const loadDeudaPorMesStart = () => {
  return {
    type: actionTypes.LOAD_DEUDA_POR_MES_START,
  };
};

const loadDeudaPorMesSuccess = (deudas, deudasDataTable, totalDeudas) => {
  return {
    type: actionTypes.LOAD_DEUDA_POR_MES_SUCCESS,
    deudas,
    deudasDataTable,
    totalDeudas,
  };
};

const loadDeudaPorMesFail = (error) => {
  return {
    type: actionTypes.LOAD_DEUDA_POR_MES_FAIL,
    error,
  };
};

export const seleccionOrdenamientoDeudaDelMesPor = (ordenamientoSelected) => {
  return {
    type: actionTypes.SELECCION_DATA_DEUDA_DEL_MES_POR,
    ordenamientoSelected,
  };
};

export const loadDeudaDelMesPorOrdenamiento = (
  ordenamiento,
  mes = 0,
  anio = 0,
  idEmpresa = storageEmpresaId()
) => {
  return (dispatch) => {
    dispatch(loadDeudaDelMesPorOrdenamientoStart(ordenamiento));
    axiosDashboard
      .get(
        `/ventas/DeudaDelMes?idMoneda=${storageMonedaId()}&ordenamiento=${ordenamiento}&mes=${mes}&anio=${anio}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasPorCategoriaDeCliente}&tomarEncuentaInflacion=false`
      )
      .then((response) => {
        const deudasNegativo = response.data.deudasReducidas.filter(
          (rd) => rd.monto < 0
        );
        const formatDeudasDataTable = response.data?.deudasCompletas || [];

        let totalDeudas = 0;

        response.data.deudasReducidas.map(
          (venta) => (totalDeudas += venta.monto)
        );
        let fecha = fechaActualFunc();

        fecha =
          response.data.deudasReducidas.length > 0
            ? new Date(
                response.data.deudasReducidas[0].anio,
                response.data.deudasReducidas[0].mes - 1
              )
            : new Date();

        dispatch(
          loadDeudaDelMesPorOrdenamientoSuccess(
            response.data.deudasReducidas,
            deudasNegativo,
            formatDeudasDataTable,
            fecha,
            totalDeudas,
            ordenamiento
          )
        );
      })
      .catch((error) => {
        // if (error.response) {
        //   if (error.response.statusCode === 500) {
        //     dispatch(loadDeudaDelMesPorOrdenamientoFail(error));
        //   } else {
        //     dispatch(loadDeudaDelMesPorOrdenamiento(mes, anio, idEmpresa));
        //   }
        // } else {
        dispatch(loadDeudaDelMesPorOrdenamientoFail(error, ordenamiento));
        // }
      });
  };
};

export const clearDeudaDelMesPorOrdenamiento = (ordenamiento) => {
  return {
    type: actionTypes.CLEAR_DEUDA_DEL_MES_POR_ORDENAMIENTO,
    ordenamiento,
  };
};

const loadDeudaDelMesPorOrdenamientoStart = (ordenamiento) => {
  return {
    type: actionTypes.LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_START,
    ordenamiento,
  };
};

const loadDeudaDelMesPorOrdenamientoSuccess = (
  deudas,
  deudasNegativo,
  deudasDataTable,
  fecha,
  totalDeudas,
  ordenamiento
) => {
  return {
    type: actionTypes.LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_SUCCESS,
    deudas,
    deudasNegativo,
    deudasDataTable,
    fecha,
    totalDeudas,
    ordenamiento,
  };
};

const loadDeudaDelMesPorOrdenamientoFail = (error, ordenamiento) => {
  return {
    type: actionTypes.LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_FAIL,
    error,
    ordenamiento,
  };
};

export const loadAperturaDeDeuda = ({ id, ordenamiento, mes, anio, idEmpresa = storageEmpresaId() }) => {
  return (dispatch) => {
    dispatch(loadAperturaDeDeudaStart(id, ordenamiento));

    axiosDashboard
      .get(
        `/ventas/AperturaDeDeuda?idMoneda=${storageMonedaId()}&id=${id}&ordenamiento=${ordenamiento}&mes=${mes}&anio=${anio}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasDelMesPorArticuloDelCliente}&tomarEncuentaInflacion=false`
      )
      .then((response) => {
        dispatch(loadAperturaDeDeudaSuccess(id, response.data, ordenamiento));
      })
      .catch((error) => {
        dispatch(loadAperturaDeDeudaFail(id, error, ordenamiento));
      });
  };
};

export const clearAperturaDeDeuda = (id, ordenamiento) => {
  return {
    type: actionTypes.CLEAR_APERTURA_DE_DEUDA_POR_ORDENAMIENTO,
    id,
    ordenamiento,
  };
}

const loadAperturaDeDeudaStart = (id, ordenamiento) => {
  return {
    type: actionTypes.LOAD_APERTURA_DE_DEUDA_POR_ORDENAMIENTO_START,
    id,
    ordenamiento,
  };
}

const loadAperturaDeDeudaSuccess = (id, ventasLocal, ordenamiento) => {
  return {
    type: actionTypes.LOAD_APERTURA_DE_DEUDA_POR_ORDENAMIENTO_SUCCESS,
    id,
    ventasLocal,
    ordenamiento,
  };
};

const loadAperturaDeDeudaFail = (id, error, ordenamiento) => {
  return {
    type: actionTypes.LOAD_APERTURA_DE_DEUDA_POR_ORDENAMIENTO_FAIL,
    id,
    error,
    ordenamiento,
  };
};

//#region Ventas Mes Comparativo

export const loadVentasMesComparativo = (idCliente) => {
  return (dispatch) => {
    dispatch(loadVentasMesComparativoStart());

    const authStr = 'Bearer '.concat(sessionStorage.getItem("token"));

    axiosDashboard
      .get(
        `/ventas/VentasDelMesEnteroComparativo?idMoneda=${storageMonedaId()}&idCliente=${idCliente}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.ventasDelMes}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        const { montoMesActual, montoMesAnterior } = response.data;
        dispatch(loadVentasMesComparativoSuccess(montoMesActual, montoMesAnterior));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadVentasMesComparativoFail(error));
          } else {
            dispatch(loadVentasMesComparativo(idCliente));
          }
        } else {
          dispatch(loadVentasMesComparativoFail(error));
        }
      });
  };
};

export const clearVentasMesComparativo = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_MES_COMPARATIVO,
  };
};

const loadVentasMesComparativoStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_MES_COMPARATIVO_START,
  };
};

const loadVentasMesComparativoSuccess = (montoMesActual, montoMesAnterior) => {
  return {
    type: actionTypes.LOAD_VENTAS_MES_COMPARATIVO_SUCCESS,
    montoMesActual,
    montoMesAnterior,
  };
};

const loadVentasMesComparativoFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_MES_COMPARATIVO_FAIL,
    error,
  };
};

//#endregion

//#region Load Comprobantes
export const loadDetalleComprobante = (idSucursal, idSistema, idComproba, nroReferencia, success, errorFail, cobranza = false) => {
  return (dispatch) => {
    const idMoneda = storageMonedaId();
    const idEmpresa = storageEmpresaId();
    const authStr = buildBearerToken();

    axiosDashboard
      .get(
        `/ventas/DetalleComprobante?idSucursal=${idSucursal}&idSistema=${idSistema}&idComproba=${idComproba}&nroReferencia=${nroReferencia}&idMoneda=${
          idMoneda}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasAlCliente}&cobranza=${cobranza}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

//#endregion

//#region Pedidos Pendientes

export const loadPedidosPendientes = (idCliente) => {
  return (dispatch) => {
    dispatch(loadPedidosPendientesStart());

    axiosDashboard
      .get(
        `/ventas/PedidosPendientes?idCliente=${idCliente}&idMoneda=${storageMonedaId()}&idEmpresa=${
          storageEmpresaId()}&indicadorId=${idIndicadores.cantidadPedidosPendientes}`,
      )
      .then((response) => {
        const { cantidad, total, promedio30Dias } = response.data;
        dispatch(loadPedidosPendientesSuccess(cantidad, total, promedio30Dias));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadPedidosPendientesFail(error));
          } else {
            dispatch(loadPedidosPendientes(idCliente));
          }
        } else {
          dispatch(loadPedidosPendientesFail(error));
        }
      });
  };
};

export const clearPedidosPendientes = () => {
  return {
    type: actionTypes.CLEAR_PEDIDOS_PENDIENTES,
  };
};

const loadPedidosPendientesStart = () => {
  return {
    type: actionTypes.LOAD_PEDIDOS_PENDIENTES_START,
  };
};

const loadPedidosPendientesSuccess = (cantidad, total, prom30Dias) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_PENDIENTES_SUCCESS,
    cantidad,
    total,
    prom30Dias,
  };
};

const loadPedidosPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_PENDIENTES_FAIL,
    error,
  };
};

export const loadPedidosMesComparativo = (idCliente) => {
  return (dispatch) => {
    dispatch(loadPedidosMesComparativoStart());

    axiosDashboard
      .get(
        `/ventas/PedidosDelMesComparativo?idCliente=${idCliente}&idMoneda=${
          storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
            idIndicadores.pedidosDelMesComparativo}`,
      )
      .then((response) => {
        const { transaccionesMesAnterior, totalMesAnterior, transaccionesMesActual,
          totalMesActual, porcentajeMesActual, porcentajeTotal } = response.data;
        dispatch(
          loadPedidosMesComparativoSuccess(transaccionesMesAnterior, totalMesAnterior, transaccionesMesActual,
            totalMesActual, porcentajeMesActual, porcentajeTotal)
        );
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadPedidosMesComparativoFail(error));
          } else {
            dispatch(loadPedidosMesComparativo(idCliente));
          }
        } else {
          dispatch(loadPedidosMesComparativoFail(error));
        }
      });
  };
};

export const clearPedidosMesComparativo = () => {
  return {
    type: actionTypes.CLEAR_PEDIDOS_MES_COMPARATIVO,
  };
};

const loadPedidosMesComparativoStart = () => {
  return {
    type: actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_START,
  };
};

const loadPedidosMesComparativoSuccess = (transMesAnt, totalMesAnt, transMesAct, totalMesAct, porcTrans, porcTotal) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_SUCCESS,
    transMesAnt,
    totalMesAnt,
    transMesAct,
    totalMesAct,
    porcTrans,
    porcTotal,
  };
};

const loadPedidosMesComparativoFail = (error) => {
  return {
    type: actionTypes.LOAD_PEDIDOS_MES_COMPARATIVO_FAIL,
    error,
  };
};

export const loadEstadosPedidosPendientes = (idCliente) => {
  return (dispatch) => {
    dispatch(loadEstadosPedidosPendientesStart());
    let total = 0;

    axiosDashboard
      .get(
        `/ventas/EstadosPedidosPendientes?&idCliente=${idCliente}&idMoneda=${
          storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.estadosPedidosPendientes}`,
      )
      .then((response) => {
        response.data.map((pedido) => {
          return (total += pedido.monto);
        });
        dispatch(loadEstadosPedidosPendientesSuccess(response.data, total));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadEstadosPedidosPendientesFail(error));
          } else {
            dispatch(loadEstadosPedidosPendientes(idCliente));
          }
        } else {
          dispatch(loadEstadosPedidosPendientesFail(error));
        }
      });
  };
};

export const clearEstadosPedidosPendientes = () => {
  return {
    type: actionTypes.CLEAR_ESTADOS_PEDIDOS_PENDIENTES,
  };
};

const loadEstadosPedidosPendientesStart = () => {
  return {
    type: actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_START,
  };
};

const loadEstadosPedidosPendientesSuccess = (estadosPedidosPendientes, total) => {
  return {
    type: actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_SUCCESS,
    estadosPedidosPendientes,
    total,
  };
};

const loadEstadosPedidosPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_ESTADOS_PEDIDOS_PENDIENTES_FAIL,
    error,
  };
};

export const loadComprobantesPedidosPendientes = (idCliente) => {
  return (dispatch) => {
    dispatch(loadComprobantesPedidosPendientesStart());

    axiosDashboard
      .get(
        `/ventas/comprobantesPedidosPendientes?idCliente=${idCliente}&idMoneda=${
          storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${
          idIndicadores.comprobantesPedidosPendientes}`,
      )
      .then((response) => {
        dispatch(loadComprobantesPedidosPendientesSuccess(response.data));          
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadComprobantesPedidosPendientesFail(error));              
          } else {
            dispatch(loadComprobantesPedidosPendientes(idCliente));
          }
        } else {
          dispatch(loadComprobantesPedidosPendientesFail(error));
        }
      });
  };
};

export const clearComprobantesPedidosPendientes = () => {
  return {
    type: actionTypes.CLEAR_COMPROBANTES_PEDIDOS_PENDIENTES,
  };
};

const loadComprobantesPedidosPendientesStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_START,
  };
};

const loadComprobantesPedidosPendientesSuccess = (comprobantes) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_SUCCESS,
    comprobantes,
  };
};

const loadComprobantesPedidosPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_FAIL,
    error,
  };
};

//#endregion

//#region Ventas
export const loadVentasPorVendedor = (idCliente) => {
  return (dispatch) => {
    dispatch(loadVentasPorVendedorStart());

    axiosDashboard
      .get(
        `/ventas/ventasPorVendedor?idCliente=${idCliente}&idMoneda=${storageMonedaId()}&idEmpresa=${storageEmpresaId()}&indicadorId=${idIndicadores.cantidadPedidosPendientes}`,
      )
      .then((response) => {
        const { unidadesVendidas, ventas, cantidad } = response.data;

        dispatch(loadVentasPorVendedorSuccess(unidadesVendidas, ventas, cantidad));          
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadVentasPorVendedorFail(error));              
          } else {
            dispatch(loadVentasPorVendedor(idCliente));
          }
        } else {
          dispatch(loadVentasPorVendedorFail(error));              
        }
      });
  };
};

export const clearVentasPorVendedor = () => {
  return {
    type: actionTypes.CLEAR_VENTAS_POR_VENDEDOR,
  };
};

const loadVentasPorVendedorStart = () => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_START,
  };
};

const loadVentasPorVendedorSuccess = (unidadesVendidas, ventas, cantidad) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_SUCCESS,
    unidadesVendidas,
    ventas,
    cantidad,
  };
};

const loadVentasPorVendedorFail = (error) => {
  return {
    type: actionTypes.LOAD_VENTAS_POR_VENDEDOR_FAIL,
    error,
  };
};

//#endregion

//#region Facturas Pendientes

export const loadFacturasPendientes = (idCliente) => {
  return (dispatch) => {
    dispatch(loadFacturasPendientesStart());

    axiosDashboard
      .get(
        `/ventas/DeudoresPorVentas?idMoneda=${storageMonedaId()}&idEmpresa=${
          storageEmpresaId()}&indicadorId=${idIndicadores.facturasPendientes}&idCliente=${idCliente}`
      )
      .then((response) => {
        const deudas = response.data;
        let totalDeuda = 0;
        response.data.map((deuda) => (totalDeuda += deuda.value));
        dispatch(loadFacturasPendientesSuccess(deudas, totalDeuda));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadFacturasPendientesFail(error));
          } else {
            dispatch(loadFacturasPendientes(idCliente));
          }
        } else {
          dispatch(loadFacturasPendientesFail(error));
        }
      });
  };
};

export const clearFacturasPendientes = () => {
  return {
    type: actionTypes.CLEAR_FACTURAS_PENDIENTES,
  };
};

const loadFacturasPendientesStart = () => {
  return {
    type: actionTypes.LOAD_FACTURAS_PENDIENTES_START,
  };
};

const loadFacturasPendientesSuccess = (facturas, totalFacturas) => {
  return {
    type: actionTypes.LOAD_FACTURAS_PENDIENTES_SUCCESS,
    facturas,
    totalFacturas,
  };
};

const loadFacturasPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_FACTURAS_PENDIENTES_FAIL,
    error,
  };
};

export const loadComprobantesFacturasPendientes = (idCliente) => {
  return (dispatch) => {
    dispatch(loadComprobantesFacturasPendientesStart());

    axiosDashboard
      .get(
        `/ventas/comprobantesFacturasPendientes?idMoneda=${storageMonedaId()}&idEmpresa=${
          storageEmpresaId()}&indicadorId=${idIndicadores.comprobantesFacturasPendientes}&idCliente=${idCliente}`,
      )
      .then((response) => {
        dispatch(loadComprobantesFacturasPendientesSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadComprobantesFacturasPendientesFail(error));
          } else {
            dispatch(loadComprobantesFacturasPendientes(idCliente));
          }
        } else {
          dispatch(loadComprobantesFacturasPendientesFail(error));
        }
      });
  };
};

export const clearComprobantesFacturasPendientes = () => {
  return {
    type: actionTypes.CLEAR_COMPROBANTES_FACTURAS_PENDIENTES,
  };
};

const loadComprobantesFacturasPendientesStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_START,
  };
};

const loadComprobantesFacturasPendientesSuccess = (comprobantes) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_SUCCESS,
    comprobantes,
  };
};

const loadComprobantesFacturasPendientesFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_FACTURAS_PENDIENTES_FAIL,
    error,
  };
};

//#endregion
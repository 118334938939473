import * as actionTypes from './actionTypes';
import axios from '../../axios-dashboard';
import axiosConfig from '../../axios-config';
import { enqueueSnackbar } from './snackbar';
import { storageToken } from '../../assets/shared/sessionData';
import idIndicadores from '../../assets/shared/idIndicadores';

// const ejecucionRequest = (url, success, fail) => {
//   const authStr = 'Bearer '.concat(storageToken());
//   axios
//     .get(url, {
//       headers: { Authorization: authStr }
//     })
//     .then((response) => {
//       let total = 0;
//       let formatResponse = [];
//       response.data.map((data) => {
//         return (total += Math.abs(data.total));
//       });
//       response.data.map((data) => {
//         return formatResponse.push({ nombreEmpresa: data.nombreEmpresa, total: Math.abs(data.total), id: data.id });
//       });

//       const armadoResponse = {
//         dataPorEmpresas: formatResponse,
//         total
//       };
//       return success(armadoResponse);
//     })
//     .catch((error) => {
//       return fail(error);
//     });
// };

export const loadEmpresas = () => {
  return (dispatch) => {
    dispatch(loadEmpresasStart());

    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(`/empresas/empresas`, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(loadEmpresasSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadEmpresasFail(error));
          } else {
            dispatch(loadEmpresas());
          }
        } else {
          dispatch(loadEmpresasFail(error));
        }
      });
  };
};

const loadEmpresasStart = () => {
  return {
    type: actionTypes.LOAD_EMPRESAS_START
  };
};

const loadEmpresasSuccess = (empresas) => {
  return {
    type: actionTypes.LOAD_EMPRESAS_SUCCESS,
    empresas
  };
};

const loadEmpresasFail = (error) => {
  return {
    type: actionTypes.LOAD_EMPRESAS_FAIL,
    error
  };
};

export const seleccionarEmpresa = (empresaId, success, errorFail) => {
  return (dispatch) => {
    dispatch(executeCambiarEmpresa(true));
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .put(
        `/empresas/CambiarEmpresaPorDefecto?empresaId=${empresaId}&indicadorId=${
          idIndicadores.cambiarEmpresa
        }`,
        null,
        {
          headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        if (response) {
          sessionStorage.setItem('empresaId', response.data.empresaId);
          sessionStorage.setItem('monedaId', response.data.monedaId);
          sessionStorage.setItem('monedaSigno', response.data.monedaSigno);
          sessionStorage.setItem('empresaNombre', response.data.empresa);
        }
        dispatch(executeCambiarEmpresa(false));
        return success();
      })
      .catch((error) => {
        dispatch(executeCambiarEmpresa(false));
        dispatch(
          enqueueSnackbar({
            message: `No se pudo cambiar de empresa, intente nuevamente.`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'warning'
            }
          })
        );
        return errorFail && errorFail(error);
      });
  };
};

const executeCambiarEmpresa = (loading) => {
  return {
    type: actionTypes.EXECUTE_CAMBIAR_EMPRESA,
    loading
  };
};

import { Grid } from '@material-ui/core';
import React from 'react';
import Accordion from '../../../UI/Accordion/Accordion';

import classes from './AccordionObservacionesComprobante.css';

const AccordionObservacionesComprobante = (props) => {
  const { observaciones } = props;

  const buildObservaciones = () => {
    return (
      <Grid container spacing={2}>
        {observaciones.map((observacion, index) => {
          return (
            <Grid container key={index} direction="column" item xs={6}>
              <div className={classes.ObservacionTitle}>{`OBS ${index + 1}`}</div>
              <div className={classes.ObservacionDescription}>{observacion}</div>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <Accordion
      title={<div className={classes.AccordionTitle}>Observaciones</div>}
      body={buildObservaciones()}
      accordionClasses={{ root: classes.AccordionCustomRoot, rounded: classes.AccordionCustomRounded }}
      accordionDetailsClasses={{ root: classes.AccordionDetailCustomRoot }}
      accordionSummaryClasses={{ root: classes.AccordionCustomSummaryRoot }}
    />
  );
};

export default AccordionObservacionesComprobante;

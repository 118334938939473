export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_SET_REDIRECT_PATH = "AUTH_SET_REDIRECT_PATH";

export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const LOAD_VENDEDORES_START = "LOAD_VENDEDORES_START";
export const LOAD_VENDEDORES_SUCCESS = "LOAD_VENDEDORES_SUCCESS";
export const LOAD_VENDEDORES_FAIL = "LOAD_VENDEDORES_FAIL";

export const EXECUTE_POST_COMPROBANTES = "EXECUTE_POST_COMPROBANTES";

export const LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_START = 'LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_START';
export const LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_SUCCESS = 'LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_SUCCESS';
export const LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_FAIL = 'LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_FAIL';
export const CLEAR_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA = 'CLEAR_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA';

export const LOAD_COMPROBANTES_VENTAS_START = "LOAD_COMPROBANTES_VENTAS_START";
export const LOAD_COMPROBANTES_VENTAS_SUCCESS = "LOAD_COMPROBANTES_VENTAS_SUCCESS";
export const LOAD_COMPROBANTES_VENTAS_FAIL = "LOAD_COMPROBANTES_VENTAS_FAIL";

export const LOAD_COMPROBANTES_PEDIDOS_START = "LOAD_COMPROBANTES_PEDIDOS_START";
export const LOAD_COMPROBANTES_PEDIDOS_SUCCESS = "LOAD_COMPROBANTES_PEDIDOS_SUCCESS";
export const LOAD_COMPROBANTES_PEDIDOS_FAIL = "LOAD_COMPROBANTES_PEDIDOS_FAIL";

export const LOAD_EMPRESAS_START = "LOAD_EMPRESAS_START";
export const LOAD_EMPRESAS_SUCCESS = "LOAD_EMPRESAS_SUCCESS";
export const LOAD_EMPRESAS_FAIL = "LOAD_EMPRESAS_FAIL";

export const EXECUTE_CAMBIAR_EMPRESA = "EXECUTE_CAMBIAR_EMPRESA";

export const LOAD_TIEMPOS_REQUEST_START = "LOAD_TIEMPOS_REQUEST_START";
export const LOAD_TIEMPOS_REQUEST_SUCCESS = "LOAD_TIEMPOS_REQUEST_SUCCESS";
export const LOAD_TIEMPOS_REQUEST_FAIL = "LOAD_TIEMPOS_REQUEST_FAIL";
export const CLEAR_TIEMPOS_REQUEST = "CLEAR_TIEMPOS_REQUEST";

export const LOAD_CHANGE_LOGS_START = "LOAD_CHANGE_LOGS_START";
export const LOAD_CHANGE_LOGS_SUCCESS = "LOAD_CHANGE_LOGS_SUCCESS";
export const LOAD_CHANGE_LOGS_FAIL = "LOAD_CHANGE_LOGS_FAIL";
export const CLEAR_CHANGE_LOGS = "CLEAR_CHANGE_LOGS";

export const EXECUTE_CREATE_CHANGE_LOG = "EXECUTE_CREATE_CHANGE_LOG";
export const EXECUTE_UPDATE_CHANGE_LOG = "EXECUTE_UPDATE_CHANGE_LOG";
export const EXECUTE_DELETE_CHANGE_LOG = "EXECUTE_DELETE_CHANGE_LOG";

export const UPDATE_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA = "UPDATE_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA";
export const CLEAR_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA = "CLEAR_ULTIMA_ACTUALIZACION_FOTO_DEL_DIA";

export const UPDATE_ULTIMA_ACTUALIZACION_DEUDA_CLIENTE = "UPDATE_ULTIMA_ACTUALIZACION_DEUDA_CLIENTE";
export const CLEAR_ULTIMA_ACTUALIZACION_DEUDA_CLIENTE = "CLEAR_ULTIMA_ACTUALIZACION_DEUDA_CLIENTE";

export const UPDATE_ULTIMA_ACTUALIZACION_CLIENTE =  "UPDATE_ULTIMA_ACTUALIZACION_CLIENTE";
export const CLEAR_ULTIMA_ACTUALIZACION_CLIENTE = "CLEAR_ULTIMA_ACTUALIZACION_CLIENTE";

export const UPDATE_ULTIMA_ACTUALIZACION_DEUDA_PROVEEDOR = "UPDATE_ULTIMA_ACTUALIZACION_DEUDA_PROVEEDOR";
export const CLEAR_ULTIMA_ACTUALIZACION_DEUDA_PROVEEDOR = "CLEAR_ULTIMA_ACTUALIZACION_DEUDA_PROVEEDOR";

export const CLEAR_ULTIMA_ACTUALIZACION_TIEMPOS_REQUEST = "CLEAR_ULTIMA_ACTUALIZACION_TIEMPOS_REQUEST";
export const CLEAR_ULTIMA_ACTUALIZACION_CHANGE_LOGS = "CLEAR_ULTIMA_ACTUALIZACION_CHANGE_LOGS";

export const UPDATE_ULTIMA_ACTUALIZACION_PROVEEDOR = "UPDATE_ULTIMA_ACTUALIZACION_PROVEEDOR";
export const CLEAR_ULTIMA_ACTUALIZACION_PROVEEDOR = "CLEAR_ULTIMA_ACTUALIZACION_PROVEEDOR";

export const LOAD_MONEDAS_START = "LOAD_MONEDAS_START";
export const LOAD_MONEDAS_SUCCESS = "LOAD_MONEDAS_SUCCESS";
export const LOAD_MONEDAS_FAIL = "LOAD_MONEDAS_FAIL";

export const SET_USER_INFO = "SET_USER_INFO";

export const EJECUTAR_ANALISIS_DEUDA_CLIENTE = "EJECUTAR_ANALISIS_DEUDA_CLIENTE";
export const EJECUTAR_ANALISIS_DEUDA_PROVEEDOR = "EJECUTAR_ANALISIS_DEUDA_PROVEEDOR";

export const CAMBIO_PAGINA_RENDER = "CAMBIO_PAGINA_RENDER";
export const ACTUALIZAR_INDICADORES = "ACTUALIZAR_INDICADORES";
export const EXECUTE_CAMBIAR_AJUSTA_POR_INFLACION =
  "EXECUTE_CAMBIAR_AJUSTA_POR_INFLACION";
export const EXECUTE_POST_CATEGORIAS_ITEMS_COMPRA =
  "EXECUTE_POST_CATEGORIAS_ITEMS_COMPRA";
export const EXECUTE_POST_INDICADORES_OCULTOS =
  "EXECUTE_POST_INDICADORES_OCULTOS";

export const SHOW_GLOBAL_MODAL = "SHOW_GLOBAL_MODAL";
export const CHANGE_GLOBAL_MODAL_BODY = "CHANGE_GLOBAL_MODAL_BODY";
export const SHOW_LIST_FILTERS_MODAL = "SHOW_LIST_FILTERS_MODAL";
export const HIDDEN_GLOBAL_MODAL = "HIDDEN_GLOBAL_MODAL";
export const SHOW_SEND_CHART_MODAL = "SHOW_SEND_CHART_MODAL";
export const SHOW_BACK_BUTTON_MODAL = 'SHOW_BACK_BUTTON_MODAL';
export const SHOW_ESTADO_MODAL = "SHOW_ESTADO_MODAL";
export const HIDDEN_ESTADO_MODAL = "HIDDEN_ESTADO_MODAL";

export const LOAD_CONFIGURACION_INDICADORES_START =
  "LOAD_CONFIGURACION_INDICADORES_START";
export const LOAD_CONFIGURACION_INDICADORES_SUCCESS =
  "LOAD_CONFIGURACION_INDICADORES_SUCCESS";
export const LOAD_CONFIGURACION_INDICADORES_FAIL =
  "LOAD_CONFIGURACION_INDICADORES_FAIL";

export const LOAD_INDICADORES_OCULTOS_START = "LOAD_INDICADORES_OCULTOS_START";
export const LOAD_INDICADORES_OCULTOS_SUCCESS =
  "LOAD_INDICADORES_OCULTOS_SUCCESS";
export const LOAD_INDICADORES_OCULTOS_FAIL = "LOAD_INDICADORES_OCULTOS_FAIL";

export const LOAD_COMPROBANTES_GASTOS_START = "LOAD_COMPROBANTES_GASTOS_START";
export const LOAD_COMPROBANTES_GASTOS_SUCCESS =
  "LOAD_COMPROBANTES_GASTOS_SUCCESS";
export const LOAD_COMPROBANTES_GASTOS_FAIL = "LOAD_COMPROBANTES_GASTOS_FAIL";

export const LOAD_COMPROBANTES_PAGOS_START = "LOAD_COMPROBANTES_PAGOS_START";
export const LOAD_COMPROBANTES_PAGOS_SUCCESS =
  "LOAD_COMPROBANTES_PAGOS_SUCCESS";
export const LOAD_COMPROBANTES_PAGOS_FAIL = "LOAD_COMPROBANTES_PAGOS_FAIL";

export const LOAD_COMPROBANTES_COMPRAS_START =
  "LOAD_COMPROBANTES_COMPRAS_START";
export const LOAD_COMPROBANTES_COMPRAS_SUCCESS =
  "LOAD_COMPROBANTES_COMPRAS_SUCCESS";
export const LOAD_COMPROBANTES_COMPRAS_FAIL = "LOAD_COMPROBANTES_COMPRAS_FAIL";

export const LOAD_COMPROBANTES_COBRANZAS_START =
  "LOAD_COMPROBANTES_COBRANZAS_START";
export const LOAD_COMPROBANTES_COBRANZAS_SUCCESS =
  "LOAD_COMPROBANTES_COBRANZAS_SUCCESS";
export const LOAD_COMPROBANTES_COBRANZAS_FAIL =
  "LOAD_COMPROBANTES_COBRANZAS_FAIL";

export const LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_START =
  "LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_START";
export const LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_SUCCESS =
  "LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_SUCCESS";
export const LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_FAIL =
  "LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_FAIL";

export const LOAD_CATEGORIAS_DE_PROVEEDOR_START = "LOAD_CATEGORIAS_DE_PROVEEDOR_START";
export const LOAD_CATEGORIAS_DE_PROVEEDOR_SUCCESS = "LOAD_CATEGORIAS_DE_PROVEEDOR_SUCCESS";
export const LOAD_CATEGORIAS_DE_PROVEEDOR_FAIL = "LOAD_CATEGORIAS_DE_PROVEEDOR_FAIL";

export const LOAD_INFLACION_POR_ANIO_START = "LOAD_INFLACION_POR_ANIO_START";
export const LOAD_INFLACION_POR_ANIO_SUCCESS = "LOAD_INFLACION_POR_ANIO_SUCCESS";
export const LOAD_INFLACION_POR_ANIO_FAIL = "LOAD_INFLACION_POR_ANIO_FAIL";

export const LOAD_NOVEDADES_START = "LOAD_NOVEDADES_START";
export const LOAD_NOVEDADES_SUCCESS = "LOAD_NOVEDADES_SUCCESS";
export const LOAD_NOVEDADES_FAIL = "LOAD_NOVEDADES_FAIL";

export const LOAD_ESTADOS_CHEQUES_START = "LOAD_ESTADOS_CHEQUES_START";
export const LOAD_ESTADOS_CHEQUES_SUCCESS = "LOAD_ESTADOS_CHEQUES_SUCCESS";
export const LOAD_ESTADOS_CHEQUES_FAIL = "LOAD_ESTADOS_CHEQUES_FAIL";

export const LOAD_CHEQUES_VALOR_ESTADO_START = "LOAD_CHEQUES_VALOR_ESTADO_START";
export const LOAD_CHEQUES_VALOR_ESTADO_SUCCESS = "LOAD_CHEQUES_VALOR_ESTADO_SUCCESS";
export const LOAD_CHEQUES_VALOR_ESTADO_FAIL = "LOAD_CHEQUES_VALOR_ESTADO_FAIL";

export const LOAD_INFLACION_START = "LOAD_INFLACION_START";
export const LOAD_INFLACION_SUCCESS = "LOAD_INFLACION_SUCCESS";
export const LOAD_INFLACION_FAIL = "LOAD_INFLACION_FAIL";

export const SELECCION_FECHA_DEUDAS_DEL_MES_ANIO = "SELECCION_FECHA_DEUDAS_DEL_MES_ANIO";
export const CLEAR_DEUDA_POR_MES = "CLEAR_DEUDA_POR_MES";
export const LOAD_DEUDA_POR_MES_START = "LOAD_DEUDA_POR_MES_START";
export const LOAD_DEUDA_POR_MES_SUCCESS = "LOAD_DEUDA_POR_MES_SUCCESS";
export const LOAD_DEUDA_POR_MES_FAIL = "LOAD_DEUDA_POR_MES_FAIL";

export const LOAD_CATEGORIAS_DEL_CLIENTE_START = "LOAD_CATEGORIAS_DEL_CLIENTE_START";
export const LOAD_CATEGORIAS_DEL_CLIENTE_SUCCESS = "LOAD_CATEGORIAS_DEL_CLIENTE_SUCCESS";
export const LOAD_CATEGORIAS_DEL_CLIENTE_FAIL = "LOAD_CATEGORIAS_DEL_CLIENTE_FAIL";
export const EXECUTE_POST_CATEGORIAS_DEL_CLIENTE = "EXECUTE_POST_CATEGORIAS_DEL_CLIENTE";

export const EXECUTE_CREATE_SUCURSAL = "EXECUTE_CREATE_SUCURSAL";
export const EXECUTE_UPDATE_SUCURSAL = "EXECUTE_UPDATE_SUCURSAL";

export const LOAD_SUCURSALES_START = "LOAD_SUCURSALES_START";
export const LOAD_SUCURSALES_SUCCESS = "LOAD_SUCURSALES_SUCCESS";
export const LOAD_SUCURSALES_FAIL = "LOAD_SUCURSALES_FAIL";

export const EXECUTE_CAMBIAR_MONEDA = "EXECUTE_CAMBIAR_MONEDA";

export const SELECCION_FECHA_DEUDAS_DEL_MES_ANIO_PROVEEDORES = "SELECCION_FECHA_DEUDAS_DEL_MES_ANIO_PROVEEDORES";
export const SELECCION_DATA_DEUDA_DEL_MES_PROVEEDORES_POR = "SELECCION_DATA_DEUDA_DEL_MES_PROVEEDORES_POR";

export const LOAD_DEUDA_POR_MES_PROVEEDORES_START = "LOAD_DEUDA_POR_MES_PROVEEDORES_START";
export const LOAD_DEUDA_POR_MES_PROVEEDORES_SUCCESS = "LOAD_DEUDA_POR_MES_PROVEEDORES_SUCCESS";
export const LOAD_DEUDA_POR_MES_PROVEEDORES_FAIL = "LOAD_DEUDA_POR_MES_PROVEEDORES_FAIL";
export const CLEAR_DEUDA_POR_MES_PROVEEDORES = "CLEAR_DEUDA_POR_MES_PROVEEDORES";

export const LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_START_PROVEEDORES = "LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_START_PROVEEDORES";
export const LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_SUCCESS_PROVEEDORES = "LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_SUCCESS_PROVEEDORES";
export const LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_FAIL_PROVEEDORES = "LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_FAIL_PROVEEDORES";
export const CLEAR_DEUDA_DEL_MES_POR_ORDENAMIENTO_PROVEEDORES = "CLEAR_DEUDA_DEL_MES_POR_ORDENAMIENTO_PROVEEDORES";

export const SELECCION_DATA_DEUDA_DEL_MES_POR = "SELECCION_DATA_DEUDA_DEL_MES_POR";

export const CLEAR_DEUDA_DEL_MES_POR_ORDENAMIENTO = "CLEAR_DEUDA_DEL_MES_POR_ORDENAMIENTO";
export const LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_START = "LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_START";
export const LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_SUCCESS = "LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_SUCCESS";
export const LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_FAIL = "LOAD_DEUDA_DEL_MES_POR_ORDENAMIENTO_FAIL";

export const LOAD_APERTURA_DE_DEUDA_POR_ORDENAMIENTO_START = "LOAD_APERTURA_DE_DEUDA_POR_ORDENAMIENTO_START";
export const LOAD_APERTURA_DE_DEUDA_POR_ORDENAMIENTO_SUCCESS = "LOAD_APERTURA_DE_DEUDA_POR_ORDENAMIENTO_SUCCESS";
export const LOAD_APERTURA_DE_DEUDA_POR_ORDENAMIENTO_FAIL = "LOAD_APERTURA_DE_DEUDA_POR_ORDENAMIENTO_FAIL";
export const CLEAR_APERTURA_DE_DEUDA_POR_ORDENAMIENTO = "CLEAR_APERTURA_DE_DEUDA_POR_ORDENAMIENTO";

export const LOAD_CLIENTE_BY_ID_START = "LOAD_CLIENTE_BY_ID_START";
export const LOAD_CLIENTE_BY_ID_SUCCESS = "LOAD_CLIENTE_BY_ID_SUCCESS";
export const LOAD_CLIENTE_BY_ID_FAIL = "LOAD_CLIENTE_BY_ID_FAIL";
export const UNMOUNT_CLIENTE_BY_ID = "UNMOUNT_CLIENTE_BY_ID";

export const LOAD_VENTAS_MES_COMPARATIVO_START = "LOAD_VENTAS_MES_COMPARATIVO_START";
export const LOAD_VENTAS_MES_COMPARATIVO_SUCCESS = "LOAD_VENTAS_MES_COMPARATIVO_SUCCESS";
export const LOAD_VENTAS_MES_COMPARATIVO_FAIL = "LOAD_VENTAS_MES_COMPARATIVO_FAIL";
export const CLEAR_VENTAS_MES_COMPARATIVO = "CLEAR_VENTAS_MES_COMPARATIVO";

export const LOAD_PEDIDOS_PENDIENTES_START = "LOAD_PEDIDOS_PENDIENTES_START";
export const LOAD_PEDIDOS_PENDIENTES_SUCCESS = "LOAD_PEDIDOS_PENDIENTES_SUCCESS";
export const LOAD_PEDIDOS_PENDIENTES_FAIL = "LOAD_PEDIDOS_PENDIENTES_FAIL";
export const CLEAR_PEDIDOS_PENDIENTES = "CLEAR_PEDIDOS_PENDIENTES";

export const CLEAR_EVOLUCION_DE_GASTOS = 'CLEAR_EVOLUCION_DE_GASTOS';
export const LOAD_EVOLUCION_DE_GASTOS_START = 'LOAD_EVOLUCION_DE_GASTOS_START';
export const LOAD_EVOLUCION_DE_GASTOS_SUCCESS = 'LOAD_EVOLUCION_DE_GASTOS_SUCCESS';
export const LOAD_EVOLUCION_DE_GASTOS_FAIL = 'LOAD_EVOLUCION_DE_GASTOS_FAIL';

export const LOAD_PEDIDOS_MES_COMPARATIVO_START = "LOAD_PEDIDOS_MES_COMPARATIVO_START";
export const LOAD_PEDIDOS_MES_COMPARATIVO_SUCCESS = "LOAD_PEDIDOS_MES_COMPARATIVO_SUCCESS";
export const LOAD_PEDIDOS_MES_COMPARATIVO_FAIL = "LOAD_PEDIDOS_MES_COMPARATIVO_FAIL";
export const CLEAR_PEDIDOS_MES_COMPARATIVO = "CLEAR_PEDIDOS_MES_COMPARATIVO";

export const LOAD_ESTADOS_PEDIDOS_PENDIENTES_START = "LOAD_ESTADOS_PEDIDOS_PENDIENTES_START";
export const LOAD_ESTADOS_PEDIDOS_PENDIENTES_SUCCESS = "LOAD_ESTADOS_PEDIDOS_PENDIENTES_SUCCESS";
export const LOAD_ESTADOS_PEDIDOS_PENDIENTES_FAIL = "LOAD_ESTADOS_PEDIDOS_PENDIENTES_FAIL";
export const CLEAR_ESTADOS_PEDIDOS_PENDIENTES = "CLEAR_ESTADOS_PEDIDOS_PENDIENTES";

export const LOAD_ULTIMA_COMPRA_BY_CLIENTE_START = "LOAD_ULTIMA_COMPRA_BY_CLIENTE_START";
export const LOAD_ULTIMA_COMPRA_BY_CLIENTE_SUCCESS = "LOAD_ULTIMA_COMPRA_BY_CLIENTE_SUCCESS";
export const LOAD_ULTIMA_COMPRA_BY_CLIENTE_FAIL = "LOAD_ULTIMA_COMPRA_BY_CLIENTE_FAIL";
export const CLEAR_ULTIMA_COMPRA_BY_CLIENTE = "CLEAR_ULTIMA_COMPRA_BY_CLIENTE";

export const LOAD_VENTAS_POR_VENDEDOR_START = "LOAD_VENTAS_POR_VENDEDOR_START";
export const LOAD_VENTAS_POR_VENDEDOR_SUCCESS = "LOAD_VENTAS_POR_VENDEDOR_SUCCESS";
export const LOAD_VENTAS_POR_VENDEDOR_FAIL = "LOAD_VENTAS_POR_VENDEDOR_FAIL";
export const CLEAR_VENTAS_POR_VENDEDOR = "CLEAR_VENTAS_POR_VENDEDOR";

export const LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_START = "LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_START";
export const LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_SUCCESS = "LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_SUCCESS";
export const LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_FAIL = "LOAD_COMPROBANTES_PEDIDOS_PENDIENTES_FAIL";
export const CLEAR_COMPROBANTES_PEDIDOS_PENDIENTES = "CLEAR_COMPROBANTES_PEDIDOS_PENDIENTES";

export const LOAD_CHEQUES_PROPIOS_START = "LOAD_CHEQUES_PROPIOS_START";
export const LOAD_CHEQUES_PROPIOS_SUCCESS = "LOAD_CHEQUES_PROPIOS_SUCCESS";
export const LOAD_CHEQUES_PROPIOS_FAIL = "LOAD_CHEQUES_PROPIOS_FAIL";
export const CLEAR_CHEQUES_PROPIOS = "CLEAR_CHEQUES_PROPIOS";

export const LOAD_CHEQUES_RECHAZADOS_START = "LOAD_CHEQUES_RECHAZADOS_START";
export const LOAD_CHEQUES_RECHAZADOS_SUCCESS = "LOAD_CHEQUES_RECHAZADOS_SUCCESS";
export const LOAD_CHEQUES_RECHAZADOS_FAIL = "LOAD_CHEQUES_RECHAZADOS_FAIL";
export const CLEAR_CHEQUES_RECHAZADOS = "CLEAR_CHEQUES_RECHAZADOS";

export const LOAD_LISTADO_CHEQUES_RECHAZADOS_START = "LOAD_LISTADO_CHEQUES_RECHAZADOS_START";
export const LOAD_LISTADO_CHEQUES_RECHAZADOS_SUCCESS = "LOAD_LISTADO_CHEQUES_RECHAZADOS_SUCCESS";
export const LOAD_LISTADO_CHEQUES_RECHAZADOS_FAIL = "LOAD_LISTADO_CHEQUES_RECHAZADOS_FAIL";
export const CLEAR_LISTADO_CHEQUES_RECHAZADOS = "CLEAR_LISTADO_CHEQUES_RECHAZADOS";

export const LOAD_CHEQUES_EN_CARTERA_START = "LOAD_CHEQUES_EN_CARTERA_START";
export const LOAD_CHEQUES_EN_CARTERA_SUCCESS = "LOAD_CHEQUES_EN_CARTERA_SUCCESS";
export const LOAD_CHEQUES_EN_CARTERA_FAIL = "LOAD_CHEQUES_EN_CARTERA_FAIL";
export const CLEAR_CHEQUES_EN_CARTERA = "CLEAR_CHEQUES_EN_CARTERA";

export const LOAD_LISTADO_CHEQUES_EN_CARTERA_START = "LOAD_LISTADO_CHEQUES_EN_CARTERA_START";
export const LOAD_LISTADO_CHEQUES_EN_CARTERA_SUCCESS = "LOAD_LISTADO_CHEQUES_EN_CARTERA_SUCCESS";
export const LOAD_LISTADO_CHEQUES_EN_CARTERA_FAIL = "LOAD_LISTADO_CHEQUES_EN_CARTERA_FAIL";
export const CLEAR_LISTADO_CHEQUES_EN_CARTERA = "CLEAR_LISTADO_CHEQUES_EN_CARTERA";

export const LOAD_CUENTA_CORRIENTE_CLIENTE_START = "LOAD_CUENTA_CORRIENTE_CLIENTE_START";
export const LOAD_CUENTA_CORRIENTE_CLIENTE_SUCCESS = "LOAD_CUENTA_CORRIENTE_CLIENTE_SUCCESS";
export const LOAD_CUENTA_CORRIENTE_CLIENTE_FAIL = "LOAD_CUENTA_CORRIENTE_CLIENTE_FAIL";
export const CLEAR_CUENTA_CORRIENTE_CLIENTE = "CLEAR_CUENTA_CORRIENTE_CLIENTE";

export const LOAD_COMPROBANTES_CHEQUES_START = "LOAD_COMPROBANTES_CHEQUES_START";
export const LOAD_COMPROBANTES_CHEQUES_SUCCESS = "LOAD_COMPROBANTES_CHEQUES_SUCCESS";
export const LOAD_COMPROBANTES_CHEQUES_FAIL = "LOAD_COMPROBANTES_CHEQUES_FAIL";
export const CLEAR_COMPROBANTES_CHEQUES = "CLEAR_COMPROBANTES_CHEQUES";

export const LOAD_FACTURAS_PENDIENTES_START = "LOAD_FACTURAS_PENDIENTES_START";
export const LOAD_FACTURAS_PENDIENTES_SUCCESS = "LOAD_FACTURAS_PENDIENTES_SUCCESS";
export const LOAD_FACTURAS_PENDIENTES_FAIL = "LOAD_FACTURAS_PENDIENTES_FAIL";
export const CLEAR_FACTURAS_PENDIENTES = "CLEAR_FACTURAS_PENDIENTES";

export const LOAD_COMPROBANTES_FACTURAS_PENDIENTES_START = "LOAD_COMPROBANTES_FACTURAS_PENDIENTES_START";
export const LOAD_COMPROBANTES_FACTURAS_PENDIENTES_SUCCESS = "LOAD_COMPROBANTES_FACTURAS_PENDIENTES_SUCCESS";
export const LOAD_COMPROBANTES_FACTURAS_PENDIENTES_FAIL = "LOAD_COMPROBANTES_FACTURAS_PENDIENTES_FAIL";
export const CLEAR_COMPROBANTES_FACTURAS_PENDIENTES = "CLEAR_COMPROBANTES_FACTURAS_PENDIENTES";

export const LOAD_FACTURAS_DEL_MES_INTERANUAL_START = "LOAD_FACTURAS_DEL_MES_INTERANUAL_START";
export const LOAD_FACTURAS_DEL_MES_INTERANUAL_SUCCESS = "LOAD_FACTURAS_DEL_MES_INTERANUAL_SUCCESS";
export const LOAD_FACTURAS_DEL_MES_INTERANUAL_FAIL = "LOAD_FACTURAS_DEL_MES_INTERANUAL_FAIL";
export const CLEAR_FACTURAS_DEL_MES_INTERANUAL = "CLEAR_FACTURAS_DEL_MES_INTERANUAL";

export const LINE_CHEQUES_EN_CARTERA_START = 'LINE_CHEQUES_EN_CARTERA_START';
export const LINE_CHEQUES_EN_CARTERA_SUCCESS = 'LINE_CHEQUES_EN_CARTERA_SUCCESS';
export const LINE_CHEQUES_EN_CARTERA_FAIL = 'LINE_CHEQUES_EN_CARTERA_FAIL';
export const CLEAR_LINE_CHEQUES_EN_CARTERA = 'CLEAR_LINE_CHEQUES_EN_CARTERA';

export const LINE_CHEQUES_RECHAZADOS_START = 'LINE_CHEQUES_RECHAZADOS_START';
export const LINE_CHEQUES_RECHAZADOS_SUCCESS = 'LINE_CHEQUES_RECHAZADOS_SUCCESS';
export const LINE_CHEQUES_RECHAZADOS_FAIL = 'LINE_CHEQUES_RECHAZADOS_FAIL';
export const CLEAR_LINE_CHEQUES_RECHAZADOS = 'CLEAR_LINE_CHEQUES_RECHAZADOS';

//#region Proveedores

export const LOAD_COMPRAS_DEL_MES_START = 'LOAD_COMPRAS_DEL_MES_START';
export const LOAD_COMPRAS_DEL_MES_SUCCESS = 'LOAD_COMPRAS_DEL_MES_SUCCESS';
export const LOAD_COMPRAS_DEL_MES_FAIL = 'LOAD_COMPRAS_DEL_MES_FAIL';
export const CLEAR_COMPRAS_DEL_MES = 'CLEAR_COMPRAS_DEL_MES';

export const LOAD_COMPRAS_POR_MES_START = 'LOAD_COMPRAS_POR_MES_START';
export const LOAD_COMPRAS_POR_MES_SUCCESS = 'LOAD_COMPRAS_POR_MES_SUCCESS';
export const LOAD_COMPRAS_POR_MES_FAIL = 'LOAD_COMPRAS_POR_MES_FAIL';
export const CLEAR_COMPRAS_POR_MES = 'CLEAR_COMPRAS_POR_MES';

export const LOAD_ULTIMA_COMPRA_BY_PROVEEDOR_START = 'LOAD_ULTIMA_COMPRA_BY_PROVEEDOR_START';
export const LOAD_ULTIMA_COMPRA_BY_PROVEEDOR_SUCCESS = 'LOAD_ULTIMA_COMPRA_BY_PROVEEDOR_SUCCESS';
export const LOAD_ULTIMA_COMPRA_BY_PROVEEDOR_FAIL = 'LOAD_ULTIMA_COMPRA_BY_PROVEEDOR_FAIL';
export const CLEAR_ULTIMA_COMPRA_BY_PROVEEDOR = 'CLEAR_ULTIMA_COMPRA_BY_PROVEEDOR';

export const LOAD_ORDENES_DE_COMPRAS_DEL_MES_START = 'LOAD_ORDENES_DE_COMPRAS_DEL_MES_START';
export const LOAD_ORDENES_DE_COMPRAS_DEL_MES_SUCCESS = 'LOAD_ORDENES_DE_COMPRAS_DEL_MES_SUCCESS';
export const LOAD_ORDENES_DE_COMPRAS_DEL_MES_FAIL = 'LOAD_ORDENES_DE_COMPRAS_DEL_MES_FAIL';
export const CLEAR_ORDENES_DE_COMPRAS_DEL_MES = 'CLEAR_ORDENES_DE_COMPRAS_DEL_MES';

export const LOAD_ORDENES_DE_COMPRA_PENDIENTES_START = 'LOAD_ORDENES_DE_COMPRA_PENDIENTES_START';
export const LOAD_ORDENES_DE_COMPRA_PENDIENTES_SUCCESS = 'LOAD_ORDENES_DE_COMPRA_PENDIENTES_SUCCESS';
export const LOAD_ORDENES_DE_COMPRA_PENDIENTES_FAIL = 'LOAD_ORDENES_DE_COMPRA_PENDIENTES_FAIL';
export const CLEAR_ORDENES_DE_COMPRA_PENDIENTES = 'CLEAR_ORDENES_DE_COMPRA_PENDIENTES';

export const LOAD_CANTIDAD_ORDENES_PENDIENTES_START = 'LOAD_CANTIDAD_ORDENES_PENDIENTES_START';
export const LOAD_CANTIDAD_ORDENES_PENDIENTES_SUCCESS = 'LOAD_CANTIDAD_ORDENES_PENDIENTES_SUCCESS';
export const LOAD_CANTIDAD_ORDENES_PENDIENTES_FAIL = 'LOAD_CANTIDAD_ORDENES_PENDIENTES_FAIL';
export const CLEAR_CANTIDAD_ORDENES_PENDIENTES = 'CLEARCLEAR_CANTIDAD_ORDENES_PENDIENTES_LOAD_ULTIMA_COMPRA';

export const LOAD_ORDENES_COMPRA_PENDIENTES_START = 'LOAD_ORDENES_COMPRA_PENDIENTES_START';
export const LOAD_ORDENES_COMPRA_PENDIENTES_SUCCESS = 'LOAD_ORDENES_COMPRA_PENDIENTES_SUCCESS';
export const LOAD_ORDENES_COMPRA_PENDIENTES_FAIL = 'LOAD_ORDENES_COMPRA_PENDIENTES_FAIL';
export const CLEAR_LOAD_ORDENES_COMPRA_PENDIENTES = 'CLEAR_LOAD_ORDENES_COMPRA_PENDIENTES';

export const LOAD_FACTURAS_COMPRA_PENDIENTES_START = 'LOAD_FACTURAS_COMPRA_PENDIENTES_START';
export const LOAD_FACTURAS_COMPRA_PENDIENTES_SUCCESS = 'LOAD_FACTURAS_COMPRA_PENDIENTES_SUCCESS';
export const LOAD_FACTURAS_COMPRA_PENDIENTES_FAIL = 'LOAD_FACTURAS_COMPRA_PENDIENTES_FAIL';
export const CLEAR_FACTURAS_COMPRA_PENDIENTES = 'CLEAR_FACTURAS_COMPRA_PENDIENTES';

export const LOAD_COMPROBANTES_FACTURAS_COMPRA_PENDIENTES_START = 'LOAD_COMPROBANTES_FACTURAS_COMPRA_PENDIENTES_START';
export const LOAD_COMPROBANTES_FACTURAS_COMPRA_PENDIENTES_SUCCESS = 'LOAD_COMPROBANTES_FACTURAS_COMPRA_PENDIENTES_SUCCESS';
export const LOAD_COMPROBANTES_FACTURAS_COMPRA_PENDIENTES_FAIL = 'LOAD_COMPROBANTES_FACTURAS_COMPRA_PENDIENTES_FAIL';
export const CLEAR_COMPROBANTES_FACTURAS_COMPRA_PENDIENTES = 'CLEAR_COMPROBANTES_FACTURAS_COMPRA_PENDIENTES';

export const LOAD_PROVEEDOR_BY_ID_START = 'LOAD_PROVEEDOR_BY_ID_START';
export const LOAD_PROVEEDOR_BY_ID_SUCCESS = 'LOAD_PROVEEDOR_BY_ID_SUCCESS';
export const LOAD_PROVEEDOR_BY_ID_FAIL = 'LOAD_PROVEEDOR_BY_ID_FAIL';
export const UNMOUNT_PROVEEDOR_BY_ID = 'UNMOUNT_PROVEEDOR_BY_ID';

export const LOAD_CUENTA_CORRIENTE_PROVEEDOR_START = 'LOAD_CUENTA_CORRIENTE_PROVEEDOR_START';
export const LOAD_CUENTA_CORRIENTE_PROVEEDOR_SUCCESS = 'LOAD_CUENTA_CORRIENTE_PROVEEDOR_SUCCESS';
export const LOAD_CUENTA_CORRIENTE_PROVEEDOR_FAIL = 'LOAD_CUENTA_CORRIENTE_PROVEEDOR_FAIL';
export const CLEAR_CUENTA_CORRIENTE_PROVEEDOR = 'CLEAR_CUENTA_CORRIENTE_PROVEEDOR';

export const LOAD_SALDO_PROVEEDOR_START = 'LOAD_SALDO_PROVEEDOR_START';
export const LOAD_SALDO_PROVEEDOR_SUCCESS = 'LOAD_SALDO_PROVEEDOR_SUCCESS';
export const LOAD_SALDO_PROVEEDOR_FAIL = 'LOAD_SALDO_PROVEEDOR_FAIL';
export const CLEAR_SALDO_PROVEEDOR = 'CLEAR_SALDO_PROVEEDOR';

//#endregion
import React from 'react';
import classes from './DeleteableItem.css';

const DeleteableItem = (props) => {
    const { text, onClick } = props;
  return (
    <div className={classes.DeleteableItemContainer} onClick={onClick}>
      {`X  ${text}`}
    </div>
  );
};

export default DeleteableItem;

import React, { useRef } from 'react';
import moment from "moment";

import Chart from "react-apexcharts";
import Spinner from "../UI/Spinner/Spinner";

import classes from "./Styles/BigChartCard.css";
import { formatNumber } from "../../assets/shared/formatNumber";
import SinResultados from "../../assets/images/SinResultados.png";
import IndicadorDeshabilitado from "../../assets/images/IndicadorDeshabilitado.png";
import { storageMonedaSigno } from "../../assets/shared/sessionData";
import FilterListIcon from "@material-ui/icons/FilterList";
import { IconButton, Tooltip } from "@material-ui/core";
import { colorsChart } from "../../assets/shared/colorsCharts";
import { enviarPorMailOption } from '../UI/EnviarChart/EnviarChartOptions';

const LineChart = (props) => {
  let chartRef = useRef(null);

  const {
    textoTooltip,
    series,
    disableZoom,
    zoomType,
    colors,
    disableGrid,
    gridBorderColor,
    gridRow,
    categories,
    titleTooltip,
    showXLabels,
    labelsRotateAlways,
    width,
    loading,
    title,
    onFiltersClick,
    disabled,
    border,
    subTitle,
    children,
    imgSinDatos,
    height,
  } = props;

  const options = {
    series: [
      {
        name: textoTooltip,
        data: series,
      },
    ],
    chart: {
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 10,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },     
      toolbar: {
        show: true,
        tools: {
          customIcons: [
            enviarPorMailOption(chartRef, props.title),
          ]
        },
      },
      zoom: disableZoom
        ? undefined
        : {
            enabled: true,
            type: zoomType ? zoomType : "xy",
            autoScaleYaxis: false,
            zoomedArea: {
              fill: {
                color: "#90CAF9",
                opacity: 0.4,
              },
              stroke: {
                color: "#0D47A1",
                opacity: 0.4,
                width: 1,
              },
            },
          },
    },
    colors: colors ? colors : colorsChart,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      show: !disableGrid,
      borderColor: gridBorderColor ? gridBorderColor : "#e7e7e7",
      row: gridRow
        ? gridRow
        : {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
    },
    tooltip: {
      x: {
        show: true,
        formatter: function (index, config) {
          const formatDate = moment(categories[index - 1]).format("MM/YYYY");
          return `${titleTooltip ? titleTooltip : ""} ${
            formatDate === "Invalid date" || formatDate === "Fecha inválida"
              ? `${categories[index - 1]}`
              : formatDate
          }`;
        },
      },
      y: {
        formatter: function (val) {
          return `${storageMonedaSigno()} ${formatNumber(val)}`;
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0,
        inverseColors: false,
        opacityFrom: 5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      labels: {
        show: true,
        formatter: function (val) {
          return `${storageMonedaSigno()}${formatNumber(val)}`;
        },
      },
    },
    xaxis: {
      labels: {
        show: showXLabels,
        rotate: -45,
        rotateAlways: labelsRotateAlways,
        formatter: function (value, opts) {
          const formatDate = moment(new Date(value)).format("MM/YY");
          return formatDate === "Invalid date" ||
            formatDate === "Fecha inválida"
            ? value
            : formatDate;
        },
      },
      categories: categories,
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div
      ref={chartRef}
      className={classes.BigChartCard}
      style={{ width: width, minHeight: loading && "240px" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          marginLeft: "20px",
        }}
      >
        <div className={classes.TitleCard}>
          <p style={{ fontWeight: "bold", marginBottom: "1px" }}>{title}</p>
          {onFiltersClick && !disabled && (
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => onFiltersClick()}>
                <FilterListIcon
                  style={{ color: border && "white" }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {subTitle && (
          <p style={{ marginTop: "1px", fontSize: "12px" }}>{subTitle}</p>
        )}
        {children}
      </div>
      {disabled ? (
        <img alt="Indicador Inhabilitado" src={IndicadorDeshabilitado} />
      ) : loading ? (
        <div style={{ textAlign: "center" }}>
          <Spinner />
        </div>
      ) : imgSinDatos ? (
        <div style={{ textAlign: "center" }}>
          <img
            alt="Sin resultados"
            src={SinResultados}
            width="auto" style={{ maxWidth: '100vw', alignSelf: "center"}}
          />
        </div>
      ) : (
        <Chart
          options={options}
          series={options.series}
          type="area"
          height={height}
        />
      )}
    </div>
  );
};

export default LineChart;

import React from "react";
import { connect } from "react-redux";

import TextChart from "../../../Charts/TextChart";

const TextoCreditoDisponible = (props) => {
  const { clientSelected, cliente, loading } = props;

  const credDisponible =
    clientSelected &&
    cliente.cuentaCorriente.creditoMaximo - cliente.deuda.importe;

  return (
    <TextChart
      title={`Crédito disponible`}
      bigAmount={credDisponible}
      noClient={!clientSelected}
      bigCurrency
      currencySimbol={cliente && cliente.cuentaCorriente ? cliente.cuentaCorriente.moneda : null}
      border={props.border}
      loading={loading}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    clientSelected: state.clientes.clienteById.clientSelected,
    cliente: state.clientes.clienteById.cliente,
    loading: state.clientes.clienteById.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoCreditoDisponible);

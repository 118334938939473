import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";

import { formatNumber, isEven } from "../../../../assets/shared/formatNumber";
import Table from "../../../UI/Table/Table";
import { storageMonedaSigno } from "../../../../assets/shared/sessionData";
import { IconButton, Tooltip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

import classes from "./TablaDeudasPorClienteDeLaSeleccion.css";
import TableToExcel from "../../../UI/Table/TableToExcel";
import FilterListIcon from "@material-ui/icons/FilterList";
import { dataOptions } from "../../../../assets/constants/dataOptions/OrdenamientoDeudasCliente";
import Cliente from "../../../../containers/Cliente/Cliente";

const TablaDeudasPorClienteDeLaSeleccion = (props) => {
  const { ordenamiento, clientesPrimero, onLoadAperturaDeDeuda, itemExpanded, isItemExpanded, onOpenClienteModal } = props;
  const { id, mes, anio } = itemExpanded;

  useEffect(() => {
    const item = props[dataOptions[ordenamiento].stateKey].items.find((i) => i.id === id);

    if(item === undefined)
      onLoadAperturaDeDeuda({ id, ordenamiento, mes, anio });
  }, [props.deudasPorCategoriaDeCliente.items, props.deudasPorVendedor.items]);

  const headerStyle1 = {
    fontSize: "14px",
    paddingTop: "0px",
    paddingBottom: "0px",
    backgroundColor: clientesPrimero
      ? "rgb(220 220 220)"
      : "rgb(235 235 235)",
  }

  const headerStyle2 = {
    fontSize: "14px",
    paddingTop: "0px",
    paddingBottom: "0px",
    backgroundColor: "#FFFFFF",
  }

  const cellStyle1  = (cellData) => {
    return {
      fontSize: "12px",
      paddingBottom: "5px",
      paddingTop: "5px",
      color: cellData < 0 && "red"
    }
  }

  const cellStyle2 = (cellData) => {
    return {
      fontSize: "12px",
      paddingBottom: "5px",
      paddingTop: "5px",
      height: "49px",
      color: cellData < 0 && "red"
    }
  }

  const columns = [
    {
      title: "Cliente",
      field: "razonSocial",
      render: (rowData) => rowData.razonSocial?.trim(),
      align: "left",
      cellStyle: isItemExpanded ? cellStyle1() : cellStyle2(),
      headerStyle: isItemExpanded ? headerStyle1 : headerStyle2,
    },
    {
      title: isItemExpanded ? '' : 'Deuda',
      field: "deuda",
      align: "right",
      type: "numeric",
      cellStyle: (cellData) => isItemExpanded ? cellStyle1(cellData) : cellStyle2(cellData),
      headerStyle: isItemExpanded ? headerStyle1 : headerStyle2,
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.deuda)}`,
    },
    {
      title:  isItemExpanded ? '' : 'Porc. Participación',
      field: "porcentajeDeParticipacion",
      align: "right",
      type: "numeric",
      cellStyle: (cellData) => isItemExpanded ? cellStyle1(cellData) : cellStyle2(cellData),
      headerStyle: isItemExpanded ? headerStyle1 : headerStyle2,
      render: (rowData) => `${formatNumber(rowData.porcentajeDeParticipacion)}%`,
    },
    {
      title:  isItemExpanded ? '' : 'Crédito Máximo',
      field: "creditoMaximo",
      align: "right",
      type: "numeric",
      cellStyle: (cellData) => isItemExpanded ? cellStyle1(cellData) : cellStyle2(cellData),
      headerStyle: isItemExpanded ? headerStyle1 : headerStyle2,
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.creditoMaximo)}`,
    },
    {
      title: ``,
      field: "id",
      align: "right",
      type: "numeric",
      cellStyle: (cellData) => {
        return {
          ...cellStyle1(isItemExpanded ? cellStyle1(cellData) : cellStyle2(cellData)),
          width: "8%"
        }
      },
      headerStyle: isItemExpanded ? headerStyle1 : headerStyle2,
      render: (rowData) => buildActionsButtons(rowData),
    },
  ];

  const openEstadoCliente = (idCliente) => {
    props.onClearChart();
    sessionStorage.setItem("idCliente", idCliente);

    const title= "Estado del cliente";
    const body= estadoCliente();
    onOpenClienteModal(true, title, body);
  }

  const estadoCliente = () => {
    return (
      <Cliente />
    )
  }

  const buildActionsButtons = (rowData) => {
    return (
      <div style={{ textAlign: "right" }}>
        <Tooltip title="Ver estado del cliente">
          <IconButton size="small" onClick={() => openEstadoCliente(rowData.id)}>
            <VisibilityIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const data = () => {
    const item = props[dataOptions[ordenamiento].stateKey].items.find((i) => i.id === id);
    const ventasLocal = item ? item.ventasLocal : [];

    return ventasLocal.map((venta) => {
      return {
        id: venta.id,
        razonSocial: venta.razonSocial,
        deuda: venta.deuda,
        porcentajeDeParticipacion: venta.porcentajeDeParticipacion,
        creditoMaximo: venta.creditoMaximo,
      };
    });
  };

  const dataToExcel = () => {
    const item = props[dataOptions[ordenamiento].stateKey].items.find((i) => i.id === id);
    const ventasLocal = item ? item.ventasLocal : [];

    return ventasLocal.map((venta) => {
      return {
        id: venta.id,
        razonSocial: venta.razonSocial,
        deuda: `${storageMonedaSigno()} ${formatNumber(venta.deuda)}`,
        porcentajeDeParticipacion: `${formatNumber(venta.porcentajeDeParticipacion)}%`,
        creditoMaximo: `${storageMonedaSigno()} ${formatNumber(venta.creditoMaximo)}`,
      };
    });    
  }

  const getLoading = () => {    
    const item = props[dataOptions[ordenamiento].stateKey].items.find((i) => i.id === id);
    return item ? item.loading : false;
  }

  const tableRef = useRef();

  return (
    <div className={classes.tablaVentasPorArticuloDeLaSeleccion} style={{ marginTop: isItemExpanded ? '0px' : '10px'}}>
      {!isItemExpanded && (
        <div className={classes.TitleCard}>
          <TableToExcel
            columns={columns}
            data={dataToExcel()}
            name={`Deudas del Cliente del ${mes}_${anio}`}
            filename={`Deudas del Cliente del ${mes}_${anio}`}
          />
          <Tooltip title="Ver filtros del indicador">
            <IconButton size="small" onClick={() => props.onShowFiltersModal()}>
              <FilterListIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      )}
      <Table
        tableRef={tableRef}
        title=""
        style={{
          width: isItemExpanded ? "95%" : "100%",
          marginTop: 0,
          marginBottom: "15px",
          float: "right",
        }}
        columns={columns}
        loading={getLoading()}
        data={data()}
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: 275,
          rowStyle: (rowData, index) => ({
            color: rowData.tableData.monto < 0 && "red",
            backgroundColor: !isEven(index) && "#f2f2f2",
          }),
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    deudasPorCategoriaDeCliente: state.ventas.aperturaDeudaPorCategoriaDeCliente,
    deudasPorVendedor: state.ventas.aperturaDeudaPorVendedor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadAperturaDeDeuda: ({ id, ordenamiento, mes, anio }) => dispatch(actions.loadAperturaDeDeuda({ id, ordenamiento, mes, anio })),
    onClearChart: () => dispatch(actions.clearIndicadoresCliente()),
    onOpenClienteModal: (show, title, body) => dispatch(actions.showEstadoModal(show, title, body))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaDeudasPorClienteDeLaSeleccion);

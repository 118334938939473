import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  monedas: [],
  error: null,
  loading: false,
  estaCargado: false,
  loadingCambiarMoneda: false,
};

const executeCambiarMoneda = (state, loadingCambiarMoneda) => {
  return updateObject(state, {
    loadingCambiarMoneda,
  });
};

//#region load Monedas
const loadMonedasStart = (state) => {
  return updateObject(state, {
    monedas: [],
    error: null,
    loading: true,
  });
};

const loadMonedasSuccess = (state, monedas) => {
  return updateObject(state, {
    monedas,
    loading: false,
    estaCargado: true,
  });
};

const loadMonedasFail = (state, error) => {
  return updateObject(state, {
    error,
    loading: false,
    estaCargado: true,
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXECUTE_CAMBIAR_MONEDA:
      return executeCambiarMoneda(state, action.loadingCambiarMoneda);

    case actionTypes.LOAD_MONEDAS_START:
      return loadMonedasStart(state);

    case actionTypes.LOAD_MONEDAS_SUCCESS:
      return loadMonedasSuccess(state, action.monedas);

    case actionTypes.LOAD_MONEDAS_FAIL:
      return loadMonedasFail(state, action.error);

    default:
      return state;
  }
};

export default reducer;

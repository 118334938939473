// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BigChartCard__BigChartCard__Llblv {\r\n  box-sizing: border-box;\r\n  text-align: center;\r\n  border: 0px solid #ccc;\r\n  box-shadow: 0px 3px 6px #00000029;\r\n  background-color: white;\r\n  border-radius: 3px;\r\n  height: 100%;\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n}\r\n\r\n.BigChartCard__TitleCard__mAufB {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-content: center;\r\n}\r\n\r\n@media (max-width: 750px) {\r\n  .BigChartCard__BigChartCard__Llblv {\r\n    width: 100%;\r\n  }\r\n}\r\n\r\n@media (max-width: 1367px) {\r\n  .BigChartCard__BigChartCard__Llblv {\r\n    width: 100%;\r\n  }\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BigChartCard": "BigChartCard__BigChartCard__Llblv",
	"TitleCard": "BigChartCard__TitleCard__mAufB"
};
export default ___CSS_LOADER_EXPORT___;

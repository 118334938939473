// Los dataOptions se estan usando para realizar las consultas segun su ordenamiento, se utiliza su index para determinar su ordenamiento, y sus variables sirven para titulos y key de estados referidos a su key creada en su reducer.

export const dataOptions = [
  {
    title: "Categoría de cliente",
    stateKey: "deudasPorCategoriaDeCliente",
    storeStateKey: "deudaDelMesPorCategoriaDeCliente",
  },
  {
    title: "Vendedor",
    stateKey: "deudasPorVendedor",
    storeStateKey: "deudaDelMesPorVendedor",
  },
  {
    title: "Cliente",
    stateKey: "deudasPorCliente",
    storeStateKey: "deudaDelMesPorCliente",
  },
];
import React from 'react';
import { connect } from 'react-redux';
import TablaComprobantes from '../TablaComprobantes/TablaComprobantes';
import * as actions from "../../../../store/actions";

const TablaFacturasPendientes = (props) => {
  const {
    comprobantes,
    loading,
    estaCargado,
  } = props;  

  const data = () => {
    let listaComprobantes = estaCargado ? comprobantes : [];

    return listaComprobantes && listaComprobantes.map((comprobante) => {
      return {
        fecha: comprobante.fecha,
        comprobante: comprobante.comprobante,
        referencia: comprobante.referencia,
        numeroExterno: comprobante.numeroExterno,
        monto: comprobante.monto && comprobante.monto.toFixed(2),
        idComproba: comprobante.idComproba,
        idSistema: comprobante.idSistema,
        idSucursal: comprobante.idSucursal,
        proveedorNombre: comprobante.proveedorNombre,
        proveedorCodigo: comprobante.proveedorCodigo,
        montoPendiente: comprobante.montoPendiente && comprobante.montoPendiente.toFixed(2),
        cobranza: comprobante.cobranza,
      };
    });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }} >
      <TablaComprobantes 
        data={data()} 
        loading={loading}
        minHeight='300px'
        maxHeight='380px'
        onFiltersClick={() => props.onShowFiltersModal()}
        tituloTabla={ "Facturas pendientes" }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    comprobantes: state.compras.comprobantesCompraFacturasPendientes.comprobantes,
    loading: state.compras.comprobantesCompraFacturasPendientes.loading,
    estaCargado: state.compras.comprobantesCompraFacturasPendientes.estaCargado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Facturas pendientes', ['venta']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaFacturasPendientes);
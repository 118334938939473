import * as actionTypes from './actionTypes';
import { enqueueSnackbar } from './snackbar';
import axiosConfig from '../../axios-config';
import axios from '../../axios-dashboard';
import { storageToken, storageMonedaId, storageEmpresaId } from '../../assets/shared/sessionData';
import idIndicadores from '../../assets/shared/idIndicadores';


export const loadComprobantesGastos = (idEmpresa) => {
  return (dispatch) => {
    dispatch(loadComprobantesGastosStart());
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .get(
        `/gastos/ComprobantesGastos?idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.comprobantesGastos
        }`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        dispatch(loadComprobantesGastosSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadComprobantesGastosFail(error));
          } else {
            dispatch(loadComprobantesGastos(idEmpresa));
          }
        } else {
          dispatch(loadComprobantesGastosFail(error));
        }
      });
  };
};

const loadComprobantesGastosStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_GASTOS_START
  };
};

const loadComprobantesGastosSuccess = (comprobantesGastos) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_GASTOS_SUCCESS,
    comprobantesGastos
  };
};

const loadComprobantesGastosFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_GASTOS_FAIL,
    error
  };
};

export const postComprobantesGastos = (empresaId, comprobantes) => {
  return (dispatch) => {
    dispatch(executePostComprobantesGastos(true));
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .post(`/gastos/ComprobantesGastos?idEmpresa=${empresaId}`, comprobantes, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(executePostComprobantesGastos(false));
        window.location.reload();
      })
      .catch((error) => {
        dispatch(executePostComprobantesGastos(false));
        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      });
  };
};

const executePostComprobantesGastos = (loading) => {
  return {
    type: actionTypes.EXECUTE_POST_COMPROBANTES,
    loading
  };
};

export const loadDetalleGastosDelItemDeCompra = (
  idItem,
  mes,
  anio,
  idEmpresa = sessionStorage.getItem('empresaId'),
  returnData,
  success
) => {
  return (dispatch) => {
    !returnData && dispatch(loadDetalleGastosDelItemDeCompraStart());
    const authStr = 'Bearer '.concat(storageToken());
    axios
      .get(
        `/gastos/DetalleGastosDelItemDeCompra?idItem=${idItem}&mes=${mes}&anio=${anio}&idMoneda=${sessionStorage.getItem(
          'monedaId'
        )}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.detalleGastosDelItemDeCompra}`,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        if (returnData) {
          return success(response.data);
        } else {
          dispatch(loadDetallesGastosDelItemDeCompraSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.statusCode === 500) {
            dispatch(loadDetallesGastosDelItemDeCompraFail(error));
          } else {
            dispatch(loadDetalleGastosDelItemDeCompra());
          }
        } else {
          dispatch(loadDetallesGastosDelItemDeCompraFail(error));
        }
      });
  };
};

export const clearDetalleGastosDelItemDeCompra = () => {
  return {
    type: actionTypes.CLEAR_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA
  };
};

const loadDetalleGastosDelItemDeCompraStart = () => {
  return {
    type: actionTypes.LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_START
  };
};

const loadDetallesGastosDelItemDeCompraSuccess = (comprobantes) => {
  return {
    type: actionTypes.LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_SUCCESS,
    comprobantes
  };
};

const loadDetallesGastosDelItemDeCompraFail = (error) => {
  return {
    type: actionTypes.LOAD_DETALLES_GASTOS_DEL_ITEM_DE_COMPRA_FAIL,
    error
  };
};

export const loadDetalleComprobanteGasto = (idSucursal, idSistema, idComproba, nroReferencia, success, errorFail) => {
  return (dispatch) => {
    const idMoneda = storageMonedaId();
    const idEmpresa = storageEmpresaId();
    
    axios
      .get(
        `/gastos/DetalleComprobanteGastos?idSucursal=${idSucursal}&idSistema=${idSistema}&idComproba=${
          idComproba}&nroReferencia=${nroReferencia}&idMoneda=${idMoneda}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.ventasAlCliente}&pago=true`,
      )
      .then((response) => {
        return success(response.data);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};
// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaCubo__tablaCuboContainer__ISNJj {\r\n  border: 0px solid #ccc;\r\n  box-shadow: 0px 3px 6px #00000029;\r\n  box-sizing: border-box;\r\n  background-color: white;\r\n  border: 1px solid #d9d9d9;\r\n  border-radius: 3px;\r\n  width: 100%;\r\n}\r\n\r\n.TablaCubo__TitleCard__42T5Z {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-content: center;\r\n}\r\n\r\n.TablaCubo__iconSeleccionarMes__e9BfX:hover {\r\n  cursor: pointer;\r\n  background-color: #e4e4e4;\r\n}\r\n\r\n.TablaCubo__listaItems__1A-Zq {\r\n  padding: 0 !important;\r\n}\r\n\r\n.TablaCubo__listaItemsText__cHs5G {\r\n  margin: 0 !important;\r\n}\r\n\r\n.TablaCubo__listaItemsTypography__9hGNz {\r\n  font-size: 15px !important;\r\n  text-align: right;\r\n}\r\n\r\n.TablaCubo__listaItemsCell1__CftxW {\r\n  width: 20% !important;\r\n  padding: 6px 10px 6px 10px !important;\r\n}\r\n\r\n.TablaCubo__listaItemsCell2__WCAj0 {\r\n  width: 20% !important;\r\n  padding: 6px 10px 6px 10px !important;\r\n}\r\n\r\n.TablaCubo__listaItemsCell3__FHsNb {\r\n  width: 40% !important;\r\n  padding: 2px 5px 2px 5px !important;\r\n}\r\n\r\n.TablaCubo__listaItemsCell4__UiUkh {\r\n  width: 20% !important;\r\n  padding: 6px 10px 6px 10px !important;\r\n}\r\n\r\n.TablaCubo__tablaCuboGastos__rLor1::-webkit-scrollbar {\r\n  width: 8px;\r\n}\r\n\r\n/* Track */\r\n.TablaCubo__tablaCuboGastos__rLor1::-webkit-scrollbar-track {\r\n  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);\r\n  border-radius: 10px;\r\n}\r\n\r\n/* Handle */\r\n.TablaCubo__tablaCuboGastos__rLor1::-webkit-scrollbar-thumb {\r\n  border-radius: 10px;\r\n  background: rgba(78, 78, 78, 0.8);\r\n  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);\r\n}\r\n\r\n.TablaCubo__tablaCuboGastos__rLor1::-webkit-scrollbar-thumb:window-inactive {\r\n  background: rgba(63, 63, 63, 0.4);\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablaCuboContainer": "TablaCubo__tablaCuboContainer__ISNJj",
	"TitleCard": "TablaCubo__TitleCard__42T5Z",
	"iconSeleccionarMes": "TablaCubo__iconSeleccionarMes__e9BfX",
	"listaItems": "TablaCubo__listaItems__1A-Zq",
	"listaItemsText": "TablaCubo__listaItemsText__cHs5G",
	"listaItemsTypography": "TablaCubo__listaItemsTypography__9hGNz",
	"listaItemsCell1": "TablaCubo__listaItemsCell1__CftxW",
	"listaItemsCell2": "TablaCubo__listaItemsCell2__WCAj0",
	"listaItemsCell3": "TablaCubo__listaItemsCell3__FHsNb",
	"listaItemsCell4": "TablaCubo__listaItemsCell4__UiUkh",
	"tablaCuboGastos": "TablaCubo__tablaCuboGastos__rLor1"
};
export default ___CSS_LOADER_EXPORT___;

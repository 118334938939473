import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Table from '../../../UI/Table/Table';
import { MTableHeader, MTableToolbar } from "material-table";
import { formatNumber, isEven } from '../../../../assets/shared/formatNumber';
import TableToExcel from "../../../UI/Table/TableToExcel";
import ModalDetallesComprobante from '../../Comprobantes/ModalDetallesComprobante/ModalDetallesComprobante';
import { Grid, TablePagination, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',    
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
  },
  iconsHeader: {
    display: 'flex',    
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    maxHeight: '30px',
  },
  table:{
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  }
}));

export const TableComprobantesCtaCte = (props) => {
  const { monedaPresentacion, loading, comprobantes, } = props;

  const classes = useStyles();

  const cellStyles = {
    fontSize: 'small',
    paddingTop: "5px",
    paddingBottom: "5px",
    borderBottom: '0px',
  };

  const headerStyle = {
    fontSize: "small",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderBottom: '0px',
    backgroundColor: "rgb(220 220 220)",
  }

  const columns = [
    {
      title: "Comprobante",
      field: "nombreComprobante",
      align: "left",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: "Referencia",
      field: "referencia",
      align: "right",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: "Núm. Externo",
      field: "numero",
      align: "left",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: "Fecha",
      field: "fecha",
      align: "right",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: "Debe",
      field: "debe",
      align: "right",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: "Haber",
      field: "haber",
      align: "right",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: "Saldo",
      field: "saldo",
      align: "right",
      cellStyle: {
        fontSize: "smaller",
        paddingTop: "0px",
        paddingBottom: "0px",
        borderBottom: '0px',
      },
      headerStyle: headerStyle,
    },
    {
      title: "Pendiente",
      field: "pendiente",
      align: "right",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
    },
    {
      title: "Prox. Venc.",
      field: "fechaVencimiento",
      align: "right",
      cellStyle: cellStyles,
      headerStyle: headerStyle,
      render: (rowData) => `${rowData.fechaVencimiento === '01/01/0001' || rowData.fechaVencimiento === '30/12/1899' ? '' : rowData.fechaVencimiento}`,
    },
    {
      title: 'Ver',
      field: 'id',
      align: 'right',
      cellStyle: cellStyles,
      headerStyle: headerStyle,
      render: (rowData) => {
        return <ModalDetallesComprobante gasto comprobante={rowData} />;
      },
    }
  ];

  const data = () => {
    return comprobantes.map((comprobante) => ({
      nombreComprobante: comprobante.nombreComprobante,
      referencia: formatValue(comprobante.referencia),
      numero: comprobante.numero,
      fecha: `${moment(comprobante.fecha).format("DD/MM/yyyy")}`,
      fechaVencimiento: `${moment(comprobante.fechaVencimiento).format("DD/MM/yyyy")}`,
      debe: formatSignoNumero(comprobante.debe),
      haber: formatSignoNumero(comprobante.haber),
      saldo: formatSignoNumero(comprobante.saldo),
      pendiente: formatSignoNumero(comprobante.pendiente),
      detallesComprobante: comprobante.articulos,
      idComproba: comprobante.idComproba,
      idSistema: comprobante.idSistema,
      idSucursal: comprobante.idSucursal,
      cobranza: comprobante.cobranza,
    }));
  };

  const formatSignoNumero = (value) => {
    return `${monedaPresentacion.signo} ${formatNumber(value)}`;
  };

  const formatValue = (value) => {
    return value !== null && value !== undefined ? Number(value).toFixed(0) : '';
  }

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRows, setTotalRows] = useState();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [lastPageIndex, setLastPageIndex] = useState(0);

  const rowsPerPageChange = (event) => {
    const value = event.target.value;
    const currentItems = currentPage * rowsPerPage;    
    const lastPage = parseInt(totalRows / value);
    setLastPageIndex(lastPage);
    let newCurrentPage = parseInt(currentItems / value);
    newCurrentPage = newCurrentPage >= lastPage ? lastPage : newCurrentPage;
    setCurrentPage(newCurrentPage);
    setRowsPerPage(value);

    if(newCurrentPage >= lastPage)
      autoScroll();
  }

  const autoScroll = () => {
    if (tableRef.current) {
      const currentItems = currentPage * rowsPerPage;
      const index = totalRows - currentItems - 1;
      const indexRow = tableRef.current.state.data.indexOf(tableRef.current.state.originalData[index]);

      tableRef.current.tableContainerDiv.current.scrollTop = 49 * indexRow;
    }
  };

  useEffect(() => {
    if(!totalRows && comprobantes){
      setTotalRows(comprobantes.length);
    }
  }, [totalRows, comprobantes]);

  useEffect(() => {
    if(isFirstLoad && totalRows){
      const lastPage = parseInt(totalRows / rowsPerPage);
      setCurrentPage(0);
      setIsFirstLoad(false);
      setLastPageIndex(lastPage);      
      setTimeout(() => setCurrentPage(lastPage), 500);
      autoScroll();
    }
  }, [isFirstLoad, totalRows, rowsPerPage]);

  const tableRef = useRef();
  const name = "Detalle de cuenta corriente";
  const filename = props.fecha ? `${name} al ${props.fecha}` : name;

  return (
    <Table
      title={"Cuenta Corriente"}
      loading={loading}
      columns={columns}
      data={data()}
      components={{
        Header: (props) => (
          <MTableHeader {...props}>
            {(props.columns[0].tableData.width = window.screen.width < 1600 ? "22%" : "17%")}
            {(props.columns[1].tableData.width = "9%")}
            {(props.columns[2].tableData.width = "20%")}
            {(props.columns[3].tableData.width = "10%")}
            {(props.columns[4].tableData.width = "12%")}
            {(props.columns[5].tableData.width = "12%")}
            {(props.columns[6].tableData.width = "12%")}
            {(props.columns[7].tableData.width = "12%")}
            {(props.columns[8].tableData.width = "16%")}
            {(props.columns[9].tableData.width = '2%')}
          </MTableHeader>
        ),
        Toolbar: props => (
          <Grid container style={{ paddingTop: '5px', paddingLeft: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Grid item xs={12} md={6} style={{ alignSelf: 'flex-start' }}>
              <Typography variant="h6">{name}</Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.iconsHeader} style={{ alignSelf: 'flex-end' }}>
              <div style={{ marginRight: '30px' }}>
                <MTableToolbar {...props} />
              </div>                
              <TableToExcel
                columns={columns}
                data={data()}
                name={name}
                filename={filename}
              />
            </Grid>
          </Grid>
        ),
        Pagination: props => (
          <TablePagination
            {...props}
            rowsPerPageOptions={[20, 50, 100]}
            rowsPerPage={rowsPerPage}
            count={totalRows}
            page={currentPage}
            onPageChange={(event, page) => {
              setCurrentPage(page)
              props.onPageChange(event, page);
            }}
            onRowsPerPageChange={(event) => {
              props.onChangeRowsPerPage(event);
              rowsPerPageChange(event);
            }}
            onChangePage={(event, page) => {
              props.onChangePage(event, page);
              setCurrentPage(page)
            }}
            onChangeRowsPerPage={(event) => {
              props.onChangeRowsPerPage(event);
              rowsPerPageChange(event);
            }}
          />
        ),
      }}
      localization={{
        header: {
          actions: "Seleccionar",
        },
        body: {
          emptyDataSourceMessage: "No hay datos para mostrar",
        },
        pagination: {
          labelDisplayedRows: "{from}-{to} de {count}",
          labelRowsSelect: "Filas",
          previousTooltip: "Página anterior",
          nextTooltip: "Página siguiente",
          firstTooltip: "Primer página",
          lastTooltip: "Última página",
        },
        toolbar: {
          searchTooltip: "Buscar",
          searchPlaceholder: "Buscar",
        },
      }}
      customOptions={{
        showTitle: true,
        actionsColumnIndex: -1,
        grouping: false,
        draggable: false,
        rowStyle: (rowData, index) => ({
          backgroundColor: !isEven(index) && '#f2f2f2',
        }),
        maxBodyHeight: props.maxHeight,
        minBodyHeight: props.minHeight,
        paging: true,
        initialPage: lastPageIndex,
        pageSize: 20,
        pageSizeOptions: [20, 50, 100]
      }}
    />
  );
};

const mapStateToProps = (state) => ({  
  loading: state.proveedores.cuentaCorriente.loading,
  comprobantes: state.proveedores.cuentaCorriente.comprobantes,
  error: state.proveedores.cuentaCorriente.error,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TableComprobantesCtaCte);

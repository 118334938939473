import React from "react";
import { storageMultiempresa } from "../../../assets/shared/sessionData";
import EmpresaSelector from "../SelectorEmpresa/EmpresaSelector";
import BoxEjecutarPaginaInfo from "../BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo";
import classes from "./ConteinerWithEjecutarPagina.css";

const ConteinerWithEjecutarPagina = (props) => {
  const { isExecuted, showMultiempresa, children } = props;
  const isMultiemp = storageMultiempresa() === "true";

  return (
    <>
      {showMultiempresa && isMultiemp && <EmpresaSelector setEmpresasessionStorage />}
      {isExecuted ? <BoxEjecutarPaginaInfo /> : children}
    </>
  );
};
export default ConteinerWithEjecutarPagina;

import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import CategoriasConfigBody from './CategoriasConfigBody';

const CategoriasDeCliente = (props) => {
  const {
    categorias,
    empresaId,
    loading,
    onChangeValue,
    onLoad,
    configInicial,
    seleccionarTodos,
    deseleccionarTodos
  } = props;

  return (
    <CategoriasConfigBody
      categorias={categorias}
      empresaId={empresaId}
      loading={loading}
      onChangeValue={onChangeValue}
      onLoad={onLoad}
      configInicial={configInicial}
      seleccionarTodos={seleccionarTodos}
      deseleccionarTodos={deseleccionarTodos}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    categorias: state.configuraciones.categoriasDeProveedor.categorias,
    loading: state.configuraciones.categoriasDeProveedor.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (empresaId) => dispatch(actions.loadCategoriasDeProveedor(empresaId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriasDeCliente);

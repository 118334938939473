import React from "react";
import { withRouter } from "react-router-dom";

import classes from "./Styles/TextChartCard.css";
import Spinner from "../UI/Spinner/Spinner";

import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import Tooltip from "@material-ui/core/Tooltip";
import { formatNumber } from "../../assets/shared/formatNumber";
import IndDeshabilitado from "../../assets/images/IndicadorDeshabilitado-Texto.png";
import SinResultado from "../../assets/images/SinResultados-Texto.png";
import { storageMonedaSigno } from "../../assets/shared/sessionData";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import FilterListIcon from "@material-ui/icons/FilterList";
import { IconButton } from "@material-ui/core";

const TextChart = (props) => {
  const {
    bigAmount,
    smallAmount,
    border,
    disabled,
    comparacionInvertida,
    bigAmountTooltip,
    bigAmoutClickRedirect,
    cursorPointer,
    bigCurrency,
    smallAmountTooltip,
    smallAmoutClickRedirect,
    smallCurrency,
    porcentaje,
    diferencia,
    title,
    onFiltersClick,
    customDisabledImage,
    loading,
    colorSpinner,
    imgSinDatos,
    onBodyClick,
  } = props;

  let percIncrease =
    Math.round(((bigAmount / smallAmount - 1) * 100 + Number.EPSILON) * 100) /
    100;
    
  const diferenciaLocal = (bigAmount - smallAmount).toLocaleString("es-AR", {
    style: "currency",
    currency: "ARS",
  });

  if (isNaN(percIncrease)) {
    percIncrease = 0;
  }

  const cssClasses = [classes.TextChartCard];
  if (border && !disabled && (bigAmount >= smallAmount || (bigAmount >= 0 && smallAmount === undefined))) {
    comparacionInvertida
      ? cssClasses.push(classes.BorderRed)
      : cssClasses.push(classes.BorderGreen);
  }
  if (border && !disabled && (bigAmount < smallAmount || bigAmount < 0 && smallAmount === undefined)) {
    comparacionInvertida
      ? cssClasses.push(classes.BorderGreen)
      : cssClasses.push(classes.BorderRed);
  }

  const labelOnClickHandler = (redirectPath) => {
    // if (redirectPath) history.push(redirectPath);
    if (redirectPath) window.open(redirectPath);
  };

  const textoGrande = bigAmount !== undefined && (
    <Tooltip
      style={{ textAlign: "left", marginTop: "5px" }}
      title={bigAmountTooltip ? bigAmountTooltip : ""}
    >
      <div
        style={{
          fontWeight: "bold",
          marginBottom: "5px",
          marginTop: "0px",
          cursor:
            bigAmoutClickRedirect || cursorPointer ? "pointer" : "default",
        }}
        className={classes.TextoGrande}
        onClick={() => labelOnClickHandler(bigAmoutClickRedirect)}
      >
        {`${
          props.bigCurrency
            ? (props.currencySimbol !== null && props.currencySimbol !== undefined)
              ? `${props.currencySimbol} ${formatNumber(props.disabled ? 0 : props.bigAmount)}` 
              : `${storageMonedaSigno()} ${formatNumber(props.disabled ? 0 : props.bigAmount)}`
            : `${props.bigAmount}`
        }`}
      </div>
    </Tooltip>
  );

  const textoChico = smallAmount !== undefined && (
    <Tooltip title={smallAmountTooltip ? smallAmountTooltip : ""}>
      <div
        style={{
          marginTop: "0px",
          cursor:
            smallAmoutClickRedirect || cursorPointer ? "pointer" : "default",
          paddingRight: "10px",
        }}
        onClick={() => labelOnClickHandler(smallAmoutClickRedirect)}
        className={classes.TextoChico}
      >
        {`${smallAmountTooltip}: ${
          smallCurrency
            ? `${storageMonedaSigno()} ${formatNumber(
                disabled ? 0 : smallAmount
              )}`
            : `${smallAmount}`
        } `}
      </div>
    </Tooltip>
  );

  const flechaUp = (
    <TrendingUpIcon
      fontSize="large"
      style={
        border
          ? { color: "white" }
          : { color: comparacionInvertida ? "red" : "green" }
      }
    />
  );
  const flechaDown = (
    <TrendingDownIcon
      fontSize="large"
      style={
        border
          ? { color: "white" }
          : { color: comparacionInvertida ? "green" : "red" }
      }
    />
  );

  const flechaFlat = (
    <TrendingFlatIcon fontSize="large" style={border && { color: "white" }} />
  );

  const mostrarTextoComparacion =
    (porcentaje || diferencia) &&
    smallAmount !== undefined &&
    bigAmount !== undefined &&
    smallAmount !== 0;
  let iconoSubidaBajada = null;
  let diferenciaOPorcentaje = null;
  if (mostrarTextoComparacion) {
    iconoSubidaBajada =
      percIncrease === 0
        ? flechaFlat
        : percIncrease > 0
        ? flechaUp
        : flechaDown;

    diferenciaOPorcentaje = diferencia
      ? diferenciaLocal
      : `${Math.abs(percIncrease)}%`;
  }

  return (
    // <div className={classes.TextChartCard}>
    <div className={cssClasses.join(" ")}>
      <div className={classes.CardTitle}>
        <p
          style={{ fontWeight: "bold", marginTop: "5px", marginBottom: "5px" }}
        >
          {title}
        </p>
        {onFiltersClick && !disabled && (
          <Tooltip title="Ver filtros del indicador">
            <IconButton size="small" onClick={() => onFiltersClick()}>
              <FilterListIcon
                style={{ color: border && "white" }}
                fontSize="small"
              />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {disabled ? (
        <img
          alt="Indicador Inhabilitado"
          className={classes.IndDeshabilitado}
          src={customDisabledImage ? customDisabledImage : IndDeshabilitado}
        />
      ) : loading ? (
        <Spinner size="small" color={colorSpinner} />
      ) : imgSinDatos ? (
        <img src={SinResultado} width='auto' alt="Indicador sin resutado" className={classes.IndDeshabilitado} />
      ) : (
        <div
          style={{ display: "flex", flexFlow: "row", cursor: onBodyClick && "pointer" }}
          onClick={() => onBodyClick && onBodyClick()}
        >
          <div className={classes.textos} style={{ width: "100%" }}>
            {textoGrande}
            {textoChico}
          </div>
          {mostrarTextoComparacion && (
            <div className={classes.IconoDiferenciaOPorcentaje}>
              {iconoSubidaBajada}
              {diferenciaOPorcentaje}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(TextChart);
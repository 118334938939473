import { storageMonedaSigno } from './sessionData';

export const formatNumber = (value) => {
  return Intl.NumberFormat('de-DE', {
    minimumFractionDigits: '2',
    maximumFractionDigits: '2'
  }).format(value);
};

export const formatNumberWithoutDecimals = (value) => {
  return Intl.NumberFormat('de-DE', {
    minimumFractionDigits: '0',
    maximumFractionDigits: '0'
  }).format(value);
};

export const formatTwoIntegerDigits = (value) => {
  return Intl.NumberFormat('de-DE', {
    minimumIntegerDigits: 2
  }).format(value);
};

export const isEven = (value) => {
  if (value%2 === 0)
    return true;
  else
    return false;
}

export const formatNumberWithMonedaSigno = (value, simbolo) => {
  var formatedNumber = 0;
  if(simbolo !== undefined)
    formatedNumber = `${simbolo} ${formatNumber(value)}`
  else
    formatedNumber = `${storageMonedaSigno()} ${formatNumber(value)}`

  return formatedNumber
};

export const formatNumberWithoutDecimalsWithMonedaSigno = (value) => {
  var simbolo = storageMonedaSigno();
  var formatedNumber = 0;
  if(simbolo !== undefined)
    formatedNumber = `${simbolo}${formatNumberWithoutDecimals(value)}`
  else
    formatedNumber = `${storageMonedaSigno()}${formatNumberWithoutDecimals(value)}`

  return formatedNumber
};

export const range = (start, end) => {
  return Array(end - start + 1).fill().map((_, idx) => start + idx)
}
import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  button: {
    display: 'flex',
    marginLeft: '10px',
    marginRight: '10px',
    cursor: 'pointer'
  }
}));

const Menu = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const onItemClick = (event, selectedItem) => {
    handleClose(event);
    props.onItemClick(selectedItem);
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.button}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {props.title}
      </div>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              outline: 'none',
              marginTop: '10px'
            }}
          >
            <Paper className={classes.paper} style={{ outline: 'none' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  style={{ outline: 'none' }}
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {props.email && (
                    <div style={{ outline: 'none' }}>
                      <Typography style={{ marginLeft: '17px', marginBottom: '5px', marginRight: '17px' }}>
                        {props.email}
                      </Typography>
                      <Divider />
                    </div>
                  )}
                  {props.listItems.map((item, key) => (
                    <MenuItem style={{ outline: 'none' }} key={key} onClick={(event) => onItemClick(event, item)}>
                      {item.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default Menu;

import React, { useEffect } from "react";
import { connect } from "react-redux";

import * as actions from "../../../../store/actions";
import TextChart from "../../../Charts/TextChart";

const TextoPedidosPendientes = (props) => {
  const {
    cantidad,
    loading,
    error,
    total,
  } = props;

  const chartSinDatos = error;

  return (
    <TextChart
      title={`Cantidad de pedidos pendientes`}
      bigAmount={`${cantidad}`}
      loading={loading}
      imgSinDatos={error || chartSinDatos}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ventas.pedidosPendientes.loading,
    error: state.ventas.pedidosPendientes.error,
    cantidad: state.ventas.pedidosPendientes.cantidad,
    total: state.ventas.pedidosPendientes.total,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TextoPedidosPendientes);

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  analisisDeudaCliente: "",
  analisisDeudaProveedor: "",
  cliente: "",
  proveedor: "",
};

//#region Deuda Cliente
const updateUltimaActualizacionAnalisisDeudaCliente = (state, fechaHora) => {
  return updateObject(state, {
    analisisDeudaCliente: `Analisis deuda cliente: ${fechaHora}`,
  });
};

const clearUltimaActualizacionAnalisisDeudaCliente = (state) => {
  return updateObject(state, {
    analisisDeudaCliente: ``,
  });
};
//#endregion

//#region Deuda Proveedor
const updateUltimaActualizacionAnalisisDeudaProveedor = (state, fechaHora) => {
  return updateObject(state, {
    analisisDeudaProveedor: `Analisis deuda proveedor: ${fechaHora}`,
  });
};

const clearUltimaActualizacionAnalisisDeudaProveedor = (state) => {
  return updateObject(state, {
    analisisDeudaProveedor: ``,
  });
};

//#region Cliente
const updateUltimaActualizacionCliente = (state, fechaHora) => {
  return updateObject(state, {
    cliente: `Cliente: ${fechaHora}`,
  });
};

const clearUltimaActualizacionCliente = (state) => {
  return updateObject(state, {
    cliente: ``,
  });
};
//#endregion

//#region Proveedor
const updateUltimaActualizacionProveedor = (state, fechaHora) => {
  return updateObject(state, {
    proveedor: `Proveedor: ${fechaHora}`,
  });
};

const clearUltimaActualizacionProveedor = (state) => {
  return updateObject(state, {
    proveedor: ``,
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_DEUDA_CLIENTE:
      return updateUltimaActualizacionAnalisisDeudaCliente(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_DEUDA_CLIENTE:
      return clearUltimaActualizacionAnalisisDeudaCliente(state);

      case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_DEUDA_PROVEEDOR:
      return updateUltimaActualizacionAnalisisDeudaProveedor(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_DEUDA_PROVEEDOR:
      return clearUltimaActualizacionAnalisisDeudaProveedor(state);

    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_CLIENTE:
      return updateUltimaActualizacionCliente(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_CLIENTE:
      return clearUltimaActualizacionCliente(state);

    case actionTypes.UPDATE_ULTIMA_ACTUALIZACION_PROVEEDOR:
      return updateUltimaActualizacionProveedor(state, action.fechaHora);
    case actionTypes.CLEAR_ULTIMA_ACTUALIZACION_PROVEEDOR:
      return clearUltimaActualizacionProveedor(state);  

    default:
      return state;
  }
};

export default reducer;

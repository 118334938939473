import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';
import PropTypes from 'prop-types';

import classes from './SucursalesConfig.css';
import SucursalSelectorButtons from '../../UI/SucursalSelectorButtons/SucursalSelectorButtons';
import { Checkbox, FormControlLabel, IconButton, Switch, Typography, Tab, Tabs, Grid } from '@material-ui/core';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import Spinner from '../../UI/Spinner/Spinner';
import { Fragment } from 'react';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Fragment>
          {children}
        </Fragment>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SucursalesConfig = (props) => {
  const [showListaComprobantes, setShowListaComprobantes] = useState(false);
  const [typeComprobantes, setTypeComprobantes] = useState(0);
  const [sucursalSelectedId, setSucursalSelectedId] = useState('');
  const [comprobantesSeleccionados, setComprobantesSeleccionados] = useState({
    comprobantesVenta: [],
    comprobantesGasto: []
  });
  const [seleccionarTodoCheck, setSeleccionarTodoCheck] = useState(true);
  const [seleccionarTodoGastosCheck, setSeleccionarTodoGastosCheck] = useState(true);
  const [searchComprobanteVentas, setSearchComprobanteVentas] = useState('');
  const [searchComprobanteGastos, setSearchComprobanteGastos] = useState('');
  const [nombreSucursal, setNombreSucursal] = useState('');
  const [inModificarSucursal, setInModificarSucursal] = useState(false);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [inactivaSucursal, setInactivaSucursal] = useState(false);

  const getComprobantes = (optionSelected = 0) => {
    if (!props[tabsOptionsData[optionSelected].stateKey].estaCargado) {
      props[tabsOptionsData[optionSelected].loadKey](props.empresaId);
    }
  };

  const onShowListaComprobantes = (inModificar) => {
    setComprobantesSeleccionados({
      comprobantesVenta: [],
      comprobantesGasto: []
    });
    setInModificarSucursal(inModificar);
    setSucursalSelectedId('');
    getComprobantes();
    setShowListaComprobantes(false);
    clearStates();
    setTimeout(() => {
      setShowListaComprobantes(true);
    }, 100);
  };

  const onChangeTabsView = (value) => {
    getComprobantes(value);
    setTypeComprobantes(value);
  };

  const tabsOptionsData = [
    {
      stateKey: 'comprobantesVenta',
      loadKey: 'onLoadComprobantesVentas'
    },
    {
      stateKey: 'comprobantesGasto',
      loadKey: 'onLoadComprobantesGastos'
    }
  ];

  const onSeleccionarComprobante = (checked, comprobante) => {
    comprobante.estaSeleccionado = checked;

    const comprobantePorId = comprobantesSeleccionados[tabsOptionsData[typeComprobantes].stateKey].filter(
      (comp) => comp.eiffelId === comprobante.eiffelId
    );

    var i = comprobantesSeleccionados[tabsOptionsData[typeComprobantes].stateKey].indexOf(comprobantePorId[0]);

    if (checked) {
      if(i === -1){
        comprobantesSeleccionados[tabsOptionsData[typeComprobantes].stateKey].push(comprobante);
      }      
    } else {  
      if (i !== -1) {
        comprobantesSeleccionados[tabsOptionsData[typeComprobantes].stateKey].splice(i, 1);
      }
    }
  };

  const onDeseleccionarTodosClick = (setSeleccionarTodo, searchComprobante) => {
    setSeleccionarTodo(true);

    const stateKey = tabsOptionsData[typeComprobantes].stateKey;
    const comprobantes = props[stateKey].comprobantes;

    if(searchComprobante && searchComprobante !== ""){
      comprobantes
        .filter((comp) => comp.nombre.toLowerCase().includes(searchComprobante.toLowerCase()))
        .forEach(element => onSeleccionarComprobante(false, element));
    }else{
      comprobantes
        .forEach(element => {
          console.log(element);
          onSeleccionarComprobante(false, element);
        });
    }
  };

  const onSeleccionarTodosClick = (setSeleccionarTodo, searchComprobante) => {
    setSeleccionarTodo(false);

    const stateKey = tabsOptionsData[typeComprobantes].stateKey;
    const comprobantes = props[stateKey].comprobantes;

    if(searchComprobante && searchComprobante !== ""){

      comprobantes
        .filter((comp) => comp.nombre.toLowerCase().includes(searchComprobante.toLowerCase()))
        .forEach(element => onSeleccionarComprobante(true, element));
    }else{
      comprobantes
        .forEach(element => onSeleccionarComprobante(true, element));
    }
  };

  const buildSeleccionarTodoButton = (seleccionarTodoCheck, setSeleccionarTodo, searchComprobante) => {
    return (
      <Fragment>
        <Grid item xs={2}>
          <IconButton
            onClick={() => {
              seleccionarTodoCheck 
                ? onSeleccionarTodosClick(setSeleccionarTodo, searchComprobante) 
                : onDeseleccionarTodosClick(setSeleccionarTodo, searchComprobante);
            }}
          >
            <SelectAllIcon style={{ color: seleccionarTodoCheck && 'rgb(9, 212, 255)' }} />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="button">{seleccionarTodoCheck ? 'Seleccionar todos' : 'Deseleccionar todos'}</Typography>
        </Grid> 
      </Fragment>
    );
  };

  const clearStates = () => {
    setShowListaComprobantes(false);
    setSeleccionarTodoCheck(true);
    setTypeComprobantes(0);
    setComprobantesSeleccionados({
      comprobantesVenta: [],
      comprobantesGasto: []
    });
    setSearchComprobanteVentas('');
    setSearchComprobanteGastos('');
    setNombreSucursal('');
  };

  const onCrearSucursalClick = () => {
    props.onCreateSucursal(
      {
        empresaId: props.empresaId,
        nombre: nombreSucursal,
        ComprobantesDeVenta: comprobantesSeleccionados.comprobantesVenta,
        comprobantesDeGasto: comprobantesSeleccionados.comprobantesGasto
      },
      () => {
        clearStates();
      }
    );
  };

  const onModificarSucursalClick = () => {
    props.onModificarSucural(sucursalSelectedId, {
      empresaId: props.empresaId,
      nombre: nombreSucursal,
      inactiva: inactivaSucursal,
      comprobantesDeVenta: comprobantesSeleccionados.comprobantesVenta,
      comprobantesDeGasto: comprobantesSeleccionados.comprobantesGasto
    });
  };

  const onSeleccionarSucursalClick = (sucursalSelected) => {
    const localCompVentas = [];
    const localCompGastos = [];
    clearStates();
    setInModificarSucursal(true);
    getComprobantes();
    setLoadingLocal(true);
    setShowListaComprobantes(true);
    setSucursalSelectedId(sucursalSelected.id);
    props.onLoadSucursalById(
      sucursalSelected.id,
      (response) => {
        setInactivaSucursal(response.inactiva);
        setNombreSucursal(response.nombre);
        response.comprobantesDeGasto.map((comprobante) => localCompGastos.push(comprobante));
        response.comprobantesDeVenta.map((comprobante) => localCompVentas.push(comprobante));

        setComprobantesSeleccionados({
          comprobantesGasto: localCompGastos,
          comprobantesVenta: localCompVentas
        });

        setLoadingLocal(false);
      },
      (error) => {
        setLoadingLocal(false);
      }
    );
  };

  const isChecked = (stateKey, comprobante) => {
    console.log(comprobante);
    const verificarExistence = comprobantesSeleccionados[stateKey]
      .filter((comp) => comp.eiffelId === comprobante.eiffelId);
    const checked = verificarExistence !== null && verificarExistence !== undefined && verificarExistence.length !== 0;

    return checked;
  }

  const buildTabComprobante = (stateKey, seleccionarTodoCheck, setSeleccionarTodo, searchComprobante, setSearchComprobante) => {
    
    return (
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} xl={12} container spacing={2}>
            <Grid 
              item xs={12} md={4} xl={2} 
              container spacing={2} 
              direction="row"
              justify="space-between"
              alignItems="center">
              {buildSeleccionarTodoButton(seleccionarTodoCheck, setSeleccionarTodo, searchComprobante)}
            </Grid>
            <Grid item xs={12} md={5} xl={4}>
              <Input
                elementConfig={{ placeholder: 'Filtro de comprobantes' }}
                value={searchComprobante}
                valueChanged={(e) => setSearchComprobante(e.target.value)}
              />
            </Grid>
            <Grid item xs={false} md={3} xl={6}></Grid>
          </Grid>
          <Grid item xs={12} md={12} xl={12}>
            <div className={classes.ListaComprobantesContainer} style={{ alignItems: props[stateKey].loading || (loadingLocal && 'center') }} >
              {props[stateKey].loading || loadingLocal ? (
                <Spinner />
              ) : (
                props[stateKey].comprobantes
                  .filter((comp) => comp.nombre.toLowerCase().includes(searchComprobante.toLowerCase()))
                  .map((comprobante, index) => {
                    return (
                      <ComprobanteCheckbox
                        key={index + comprobante.eiffelId} 
                        index={index + comprobante.eiffelId}
                        comprobante={comprobante}
                        stateKey={stateKey}
                        isChecked={isChecked}
                        onSeleccionarComprobante={onSeleccionarComprobante}
                      />
                    )
                  })
              )}
            </div>
          </Grid>
        </Grid>  
      </Fragment>
    );
  }

  return (
    <div className={classes.sucursalesContainer}>
      <SucursalSelectorButtons
        empresaSelectedId={props.empresaId}
        onChangeSucursalSelected={(sucursal) => onSeleccionarSucursalClick(sucursal)}
      />
      <Button onClick={() => onShowListaComprobantes(false)}>+ NUEVA SUCURSAL</Button>
      {showListaComprobantes && (
        <div className={classes.boxAgragarEditarSucursal}>
          <div className={classes.InputAndControl}>
            <Input
              elementConfig={{ placeholder: 'Nombre de la sucursal' }}
              valueChanged={(e) => setNombreSucursal(e.target.value)}
              value={nombreSucursal}
            />
            {inModificarSucursal && (
              <FormControlLabel
                style={{ marginLeft: '10px' }}
                control={
                  <Switch onChange={(e) => setInactivaSucursal(!inactivaSucursal)} checked={!inactivaSucursal} />
                }
                label="Activa"
              />
            )}
          </div>
          <Tabs
            variant={'standard'}
            classes={{
              scroller: classes.TabsScroller,
              flexContainer: classes.TabsContainer,
              indicator: classes.TabsIndicator
            }}
            value={typeComprobantes}
            onChange={(e, value) => onChangeTabsView(value)}
          >
            <Tab label={'Comprobantes de ventas'} {...a11yProps(0)} />
            <Tab label={'Comprobantes de gastos'} {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={typeComprobantes} index={0}>
            {buildTabComprobante(tabsOptionsData[typeComprobantes].stateKey, seleccionarTodoCheck, 
              setSeleccionarTodoCheck, searchComprobanteVentas, setSearchComprobanteVentas)}            
          </TabPanel>
          <TabPanel value={typeComprobantes} index={1}>
            {buildTabComprobante(tabsOptionsData[typeComprobantes].stateKey, seleccionarTodoGastosCheck, 
              setSeleccionarTodoGastosCheck, searchComprobanteGastos, setSearchComprobanteGastos)}
          </TabPanel>
          <div style={{ textAlign: 'right' }}>
            {inModificarSucursal ? (
              <Button disabled={nombreSucursal.trim().length === 0} onClick={() => onModificarSucursalClick()}>
                Modificar Sucursal
              </Button>
            ) : (
              <Button disabled={nombreSucursal.trim().length === 0} onClick={() => onCrearSucursalClick()}>
                Crear Sucursal
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const ComprobanteCheckbox = (props) => {
  const {
    index,
    comprobante,
    stateKey,
    isChecked,
    onSeleccionarComprobante,
  } = props;

  const [checked, setChecked] = useState(false);

  const onCheckboxChange = (value) => {
    setChecked(value);
    onSeleccionarComprobante(value, comprobante);
  }

  useEffect(() => {
    const isCheckboxChecked = isChecked(stateKey, comprobante);

    if(checked !== isCheckboxChecked){
      setChecked(isCheckboxChecked);
    }
  }, [stateKey, comprobante, isChecked, setChecked]);

  return (
    <FormControlLabel
      key={index}
      control={
        <Checkbox
          key={index}
          defaultValue={false}
          checked={checked}
          classes={{ root: classes.CheckboxRoot }}
          onChange={(e) => onCheckboxChange(e.target.checked)}
        />
      }
      label={comprobante.nombre}
    />
  );
}

const mapStateToProps = (state) => ({
  comprobantesVenta: state.ventas.comprobantesVentas,
  comprobantesGasto: state.gastos.comprobantesGastos
});

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadSucursalById: (sucursalId, success, fail) => dispatch(actions.loadSucursalById(sucursalId, success, fail)),
    onLoadComprobantesVentas: (empresaId) => dispatch(actions.loadComprobantesVentas(empresaId)),
    onLoadComprobantesGastos: (empresaId) => dispatch(actions.loadComprobantesGastos(empresaId)),
    onCreateSucursal: (nuevaSucursal, success) => dispatch(actions.createSucursal(nuevaSucursal, success)),
    onModificarSucural: (updatedSucursalId, updatedSucursal) =>
      dispatch(actions.updateSucursal(updatedSucursalId, updatedSucursal))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SucursalesConfig);

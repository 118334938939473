import * as actionTypes from './actionTypes';
import axios from '../../axios-dashboard';
import idIndicadores from '../../assets/shared/idIndicadores';

//#region Vendedores

export const loadVendedores = (success) => {
  return (dispatch) => {
    dispatch(loadVendedoresStart());
    const authStr = 'Bearer '.concat(sessionStorage.getItem('token'));
    const idEmpresa = parseInt(sessionStorage.getItem('empresaId'), 10);

    axios
      .get(`/vendedores/vendedoresDeLaEmpresa?idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.vendedores}`, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        dispatch(loadVendedoresSuccess(response.data));
        return success(response);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadVendedoresFail(error));
          } else {
            dispatch(loadVendedores());
          }
        } else {
          dispatch(loadVendedoresFail(error));
        }
      });
  };
};

const loadVendedoresStart = () => {
  return {
    type: actionTypes.LOAD_VENDEDORES_START
  };
};

const loadVendedoresSuccess = (vendedores) => {
  return {
    type: actionTypes.LOAD_VENDEDORES_SUCCESS,
    vendedores
  };
};

const loadVendedoresFail = (error) => {
  return {
    type: actionTypes.LOAD_VENDEDORES_FAIL,
    error
  };
};
//#endregion

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BoxInfo__BoxStepInfoContainer__wOTQ2 {\r\n  background: #008ffb24 0% 0% no-repeat padding-box;\r\n  box-shadow: 0px 3px 6px #00000029;\r\n  border: 1px solid #008ffb;\r\n  border-radius: 3px;\r\n  opacity: 1;\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 10px;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.BoxInfo__TextoInfo__4hlz\\+ {\r\n  margin-left: 10px;\r\n  text-align: left;\r\n  font: normal normal normal 16px/21px Roboto;\r\n  letter-spacing: 0px;\r\n  color: #646464;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BoxStepInfoContainer": "BoxInfo__BoxStepInfoContainer__wOTQ2",
	"TextoInfo": "BoxInfo__TextoInfo__4hlz+"
};
export default ___CSS_LOADER_EXPORT___;

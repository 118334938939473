import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Spinner from '../Spinner/Spinner';

const EstadosChequesSelector = (props) => {
  const { estados, hiddenEstados, loading, flexChangeEstados, onLoadEstados, disableLoad, onChangeEstado } = props;

  const [localValue, setLocalValue] = useState('0');
  const [estadosLocal, setEstadosLocal] = useState([]);

  useEffect(() => {
    if (estados.length === 0 && !disableLoad) {
      onLoadEstados();
    }
  }, []);

  useEffect(() => {
    if (hiddenEstados) {
      setEstadosLocal(estados.filter((ar) => !hiddenEstados.find((rm) => rm.id_estado === ar.id_estado)));
      setLocalValue('0');
    } else {
      setEstadosLocal(estados);
    }
  }, [hiddenEstados, flexChangeEstados]);

  return loading ? (
    <Spinner size="small" />
  ) : (
    <FormControl style={{ margin: '0px 10px 0px 10px', width: '140px' }}>
      <InputLabel id="estadoInputLabel">Estado</InputLabel>
      <Select
        labelId="estadoLabel"
        id="estadoSelect"
        onChange={(event) => {
          setLocalValue(event.target.value);
        }}
        value={localValue}
      >
        <MenuItem key={0} value={'0'} onClick={() => onChangeEstado(null)}>
          Estado de valor
        </MenuItem>
        {estadosLocal &&
          estadosLocal.map((estado, index) => (
            <MenuItem key={index} value={estado.id_estado} onClick={() => onChangeEstado(estado)}>
              {estado.nombre}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.configuraciones.loadEstadosCheques.loading,
    estados: state.configuraciones.loadEstadosCheques.estados
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadEstados: () => dispatch(actions.loadEstadosCheques())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EstadosChequesSelector);

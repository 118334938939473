import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  executeCreateSucursal: false,
  executeUpdateSucursal: false,
  loadSucursales: {
    sucursales: [],
    loading: false,
    estaCargado: false,
    error: null
  },
};

//#region execute create sucursal
const executeCreateSucursal = (state, loading) => {
  return updateObject(state, {
    executeCreateSucursal: loading
  });
};
//#endregion

//#region execute update sucursal
const executeUpdateSucursal = (state, loading) => {
  return updateObject(state, {
    executeUpdateSucursal: loading
  });
};
//#endregion

//#region load sucursales
const loadSucursalesStart = (state) => {
  return updateObject(state, {
    loadSucursales: updateObject(state.loadSucursales, {
      sucursales: [],
      estaCargado: false,
      loading: true,
      error: null
    })
  });
};

const loadSucursalesSuccess = (state, sucursales) => {
  return updateObject(state, {
    loadSucursales: updateObject(state.loadSucursales, {
      sucursales,
      estaCargado: true,
      loading: false,
      error: null
    })
  });
};

const loadSucursalesFail = (state, error) => {
  return updateObject(state, {
    loadSucursales: updateObject(state.loadSucursales, {
      sucursales: [],
      estaCargado: true,
      loading: false,
      error
    })
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXECUTE_CREATE_SUCURSAL:
      return executeCreateSucursal(state, action.loading);
    case actionTypes.EXECUTE_UPDATE_SUCURSAL:
      return executeUpdateSucursal(state, action.loading);

    case actionTypes.LOAD_SUCURSALES_START:
      return loadSucursalesStart(state);
    case actionTypes.LOAD_SUCURSALES_SUCCESS:
      return loadSucursalesSuccess(state, action.sucursales);
    case actionTypes.LOAD_SUCURSALES_FAIL:
      return loadSucursalesFail(state, action.error);
    default:
      return state;
  }
};

export default reducer;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login__Login__nap-h {\r\n  width: 400px;\r\n  height: 300px;\r\n  margin: 100px auto;\r\n  text-align: center;\r\n  background-color: white;\r\n  box-shadow: 0px 0px 14px 6px #00000054;\r\n  border-radius: 10px;\r\n}\r\n\r\n.Login__Login__nap-h img {\r\n  /* margin-left: 141px; */\r\n  margin-top: 30px;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Login": "Login__Login__nap-h"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PieDeudasDelMesPorSeleccion__LabelVerPor__0p3Gm {\r\n  font-weight: bold;\r\n}\r\n\r\n.PieDeudasDelMesPorSeleccion__SelectRoot__NWoWE {\r\n  padding-top: 0 !important;\r\n  padding-bottom: 0 !important;\r\n}\r\n  \r\n.PieDeudasDelMesPorSeleccion__TitleCard__k2I7q {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-content: center;\r\n}\r\n  \r\n.PieDeudasDelMesPorSeleccion__TablaContainer__cA5ZS {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 1 1;\r\n  background-color: white;\r\n  align-items: flex-end;\r\n  margin-bottom: 5px;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LabelVerPor": "PieDeudasDelMesPorSeleccion__LabelVerPor__0p3Gm",
	"SelectRoot": "PieDeudasDelMesPorSeleccion__SelectRoot__NWoWE",
	"TitleCard": "PieDeudasDelMesPorSeleccion__TitleCard__k2I7q",
	"TablaContainer": "PieDeudasDelMesPorSeleccion__TablaContainer__cA5ZS"
};
export default ___CSS_LOADER_EXPORT___;

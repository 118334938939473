import React, { Fragment, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { formatNumber, formatTwoIntegerDigits, } from "../../../../assets/shared/formatNumber";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";

import * as actions from "../../../../store/actions";

import PieChart from "../../../Charts/PieChart";
import { storageMonedaSigno } from "../../../../assets/shared/sessionData";

import classes from "./PieDeudasDelMesPorSeleccion.css";
import Spinner from "../../../UI/Spinner/Spinner";
import { Grid, IconButton, TextField, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { compareByDesc } from "../../../../assets/shared/sorting";
import TableDeudasDelMesPorSeleccion from "./TableDeudasDelMesPorSeleccion";
import { dataOptions } from "../../../../assets/constants/dataOptions/OrdenamientoDeudaProveedor";

const PieDeudasDelMesPorSeleccion = (props) => {
  const { ordenamientoSelected, fechaDeudaDelMes, estaOculto, ejecucionDePagina,
    onLoadDeudasDelMesPorOrdenamiento, onChangeOptionSelected, height, spaceBetweenChartTitle,
  } = props; const isDisabledChart = estaOculto;
  const [items, setItems] = useState([]);
  const [limit, setLimit] = useState(20);

  useEffect(() => {
    if (
      !props[dataOptions[ordenamientoSelected].stateKey].estaCargado &&
      ejecucionDePagina
    ) {
      onLoadDeudasDelMesPorOrdenamiento(
        ordenamientoSelected,
        fechaDeudaDelMes.mes,
        fechaDeudaDelMes.anio
      );
    }
  }, [ordenamientoSelected, fechaDeudaDelMes]);

  const handleChangeOptionSelected = (e) => {
    e.preventDefault();
    onChangeOptionSelected(e.target.value);
  };

  const optionSelector = () => {
    const loading = props[dataOptions[ordenamientoSelected].stateKey].loading;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontWeight: 400,
          marginRight: "15px",
        }}
      >
        <div className={classes.LabelVerPor}>
          Ver deudas del{" "}
          {loading
            ? "--/----"
            : `${formatTwoIntegerDigits(fechaDeudaDelMes.mes)}/${fechaDeudaDelMes.anio
            }`}{" "}
          por:{" "}
        </div>
        <Select
          input={<Input classes={{ input: classes.SelectRoot }} />}
          style={{ marginLeft: "5px" }}
          value={ordenamientoSelected}
          onChange={(e) => handleChangeOptionSelected(e)}
        >
          {dataOptions.map((option, index) => {
            return (
              <MenuItem dense key={index} value={index}>
                {option.title}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    );
  };

  const deudasNegativas = () => {
    let deudasNegativo = props[dataOptions[ordenamientoSelected].stateKey].deudasNegativo;

    return (
      !isDisabledChart && deudasNegativo &&
      deudasNegativo.map((deudaNegativo) => ({
        nombre: deudaNegativo.nombre,
        monto: deudaNegativo.monto,
      }))
    );
  };

  const deudasPositivas = useCallback(() => {
    const deudas = props[dataOptions[ordenamientoSelected].stateKey].deudas;
    let deudasPos = deudas ? deudas.filter((vnt) => vnt['monto'] >= 0) : [];
    return deudasPos;
  }, [dataOptions, ordenamientoSelected, props[dataOptions[ordenamientoSelected].stateKey].deudas]);

  useEffect(() => {
    const loading = props[dataOptions[ordenamientoSelected].stateKey].loading;
    const estaCargado = props[dataOptions[ordenamientoSelected].stateKey].estaCargado;

    if (!loading && estaCargado && limit && limit > 0) {
      let deudas = deudasPositivas();
      let ordered = deudas.sort((a, b) => compareByDesc(a, b, 'monto'));
      let filtered = ordered.slice(0, limit);
      let others = ordered.slice(limit);

      if (others && others.length > 0) {
        let otherItem = {
          mes: others[0].mes,
          anio: others[0].anio,
          monto: 0,
        };
        otherItem['nombre'] = "OTROS";
        otherItem['id'] = "0000";
        others.forEach(element => {
          otherItem.monto += element.monto;
        });
        filtered.splice(0, 0, otherItem);
      }
      setItems(filtered);
    }

  }, [dataOptions, ordenamientoSelected, props[dataOptions[ordenamientoSelected].stateKey].loading, props[dataOptions[ordenamientoSelected].stateKey].estaCargado, limit, deudasPositivas]);

  const getSeries = () => {
    let tempItems = limit && limit > 0 ? items : deudasPositivas();
    let series = tempItems ? tempItems.map((deuda) => deuda['monto']) : [];

    return series;
  }

  const getLabels = useCallback(() => {
    let tempItems = limit && limit > 0 ? items : deudasPositivas();
    let labels = tempItems ? tempItems.map((deuda) => deuda['nombre']) : [];

    return labels;
  }, [limit, items, deudasPositivas]);

  const onLimitChange = (value) => {
    let numValue = Number(value);
    setLimit(numValue);
  }

  const getLimit = () => {
    const deudas = props[dataOptions[ordenamientoSelected].stateKey].deudas;

    return (
      <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Grid item xs={6}>
          <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
            Límite
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={limit}
            onChange={(event) => onLimitChange(event.target.value)}
            type="number"
            size='small'
            InputProps={{
              inputProps: {
                max: deudas && deudas.length,
                min: 1,
                style: { padding: '2px', textAlign: 'center' }
              }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          {limit && limit > 0 ? (
            <IconButton size='small' onClick={() => setLimit(0)}>
              <Close fontSize='small' />
            </IconButton>
          ) : <Fragment></Fragment>}
        </Grid>
      </Grid>
    )
  }

  //#region Change View
  const [viewAsPie, setViewAsPie] = useState(true);

  const handleChangeView = () => {
    setViewAsPie(!viewAsPie);
  }

  const dataLabels = {
    enabled: true,
    formatter: function (val, opts) {
      let item = opts.w.config.labels[opts.seriesIndex];

      if (item && item.toUpperCase() === 'OTROS')
        return `OTROS ${formatNumber(val)}%`;
      else
        return `${formatNumber(val)}%`;
    },
  }

  const subTitle = () => {
    return `Total: ${storageMonedaSigno()} ${formatNumber(
      props[dataOptions[ordenamientoSelected].stateKey].total
    )}`;
  }

  const renderPie = () => {
    return (
      <PieChart
        disabledCss
        title={optionSelector()}
        endItems={getLimit()}
        subTitle={subTitle()}
        series={getSeries()}
        labels={getLabels()}
        valoresNegativos={deudasNegativas()}
        loading={props[dataOptions[ordenamientoSelected].stateKey].loading}
        imgSinDatos={
          props[dataOptions[ordenamientoSelected].stateKey].deudas.length === 0
        }
        height={height}
        disabled={isDisabledChart}
        width={"100%"}
        chartType={"pie"}
        spaceBetweenChartTitle={spaceBetweenChartTitle}
        legendHeight={50}
        legendPosition={"bottom"}
        onChangeViewClick={handleChangeView}
        dataLabels={dataLabels}
      />
    );
  }

  const renderTable = () => {
    const title = dataOptions[ordenamientoSelected].title;
    return (
      <TableDeudasDelMesPorSeleccion
        title={optionSelector()}
        subTitle={subTitle()}
        headerTitle={title}
        deudas={props[dataOptions[ordenamientoSelected].stateKey].deudas}
        loading={props[dataOptions[ordenamientoSelected].stateKey].loading}
        onChangeViewClick={handleChangeView}
        height={436}
        disabled={isDisabledChart}
        filename={`Deuda por ${title}`}
      />
    )
  }

  //#endregion

  return props[dataOptions[ordenamientoSelected].stateKey].loading ? (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Spinner />
    </div>
  ) : viewAsPie ? (
    <Fragment>
      {renderPie()}
    </Fragment>
  ) : (
    <Fragment>
      {renderTable()}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    fechaDeudaDelMes: state.compras.fechaDeudasDelMesAnioProveedores,
    ordenamientoSelected: state.compras.ordenamientoDeudaDelMesPorSelectedProveedores,
    deudasPorCategoriaDeCliente: state.compras.deudaDelMesProveedoresPorCategoriaDeCliente,
    deudasPorProveedor: state.compras.deudaDelMesProveedoresPorProveedor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeOptionSelected: (ordenamientoSelected) =>
      dispatch(actions.seleccionOrdenamientoDeudaDelMesProveedoresPor(ordenamientoSelected)),
    onLoadDeudasDelMesPorOrdenamiento: (ordenamiento, mes, anio) =>
      dispatch(actions.loadDeudaDelMesPorOrdenamientoProveedores(ordenamiento, mes, anio)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PieDeudasDelMesPorSeleccion);
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import { useHistory } from 'react-router';
import { storageSeccionNombre } from '../../../assets/shared/sessionData';

export const BackButton = (props) => {
  const history = useHistory(); 
   
  useEffect(() => {
    window.onpopstate = e => {
      sessionStorage.setItem('seccionNombre', storageSeccionNombre());
      props.cambiarPage(history.location.pathname);

      if(history.action === 'PUSH'){
        props.onShowModal();      
      }     
    };
  });

  return (
    <Fragment></Fragment>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowModal: () => dispatch(actions.showBackButtonModal(true)),
    cambiarPage: (page) => dispatch(actions.cambioPaginaRender(page))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BackButton);
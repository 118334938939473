// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PieFacturasPendientes__TitleCard__0axMI {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-content: center;\r\n}\r\n\r\n.PieFacturasPendientes__TablaContainer__Om1M\\+ {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 1 1;\r\n  background-color: white;\r\n  align-items: flex-end;\r\n  margin-bottom: 5px;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TitleCard": "PieFacturasPendientes__TitleCard__0axMI",
	"TablaContainer": "PieFacturasPendientes__TablaContainer__Om1M+"
};
export default ___CSS_LOADER_EXPORT___;

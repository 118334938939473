import React from 'react';
import classes from './BoxInfo.css';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const BoxInfo = (props) => {
  return (
    <div className={classes.BoxStepInfoContainer}>
      <CheckCircleIcon style={{ color: '#646464' }} />
      <div className={classes.TextoInfo}>{props.children}</div>
    </div>
  );
};
export default BoxInfo;

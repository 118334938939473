// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardCliente__CardClienteContainer__JKCNi {\r\n  width: 100%;\r\n  height: 100%;\r\n  box-shadow: 0px 3px 6px #00000029;\r\n  /* margin: 10px 10px auto; */\r\n  background-color: white;\r\n  box-sizing: border-box;\r\n  text-align: left !important;\r\n  padding: 5px 15px 5px 15px;\r\n  border: 1px solid #d9d9d9;\r\n  border-radius: 3px;\r\n}\r\n\r\n.CardCliente__Title__wFUVS {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n}\r\n\r\n.CardCliente__nombreCliente__Kf4-X {\r\n  display: flex;\r\n}\r\n\r\n.CardCliente__Body__pSzz5 {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: space-between;\r\n}\r\n\r\n.CardCliente__columns__jnIwz {\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.CardCliente__separador__0FcoG {\r\n  width: 10px;\r\n}\r\n\r\n.CardCliente__textos__pYder {\r\n  display: flex;\r\n  align-items: center;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n@media (max-width: 750px) {\r\n  .CardCliente__Body__pSzz5 {\r\n    display: inline-block;\r\n    justify-content: space-between;\r\n  }\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CardClienteContainer": "CardCliente__CardClienteContainer__JKCNi",
	"Title": "CardCliente__Title__wFUVS",
	"nombreCliente": "CardCliente__nombreCliente__Kf4-X",
	"Body": "CardCliente__Body__pSzz5",
	"columns": "CardCliente__columns__jnIwz",
	"separador": "CardCliente__separador__0FcoG",
	"textos": "CardCliente__textos__pYder"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect } from "react";
import * as actions from "../../store/actions";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import moment from "moment";
import { connect } from "react-redux";
import { pathNamePages } from "../../assets/shared/pathnames";
import LightTooltip from "../UI/LightTooltip/LightTooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto 10px", 
    display: "flex", 
    alignItems: "center",
    justifyContent: 'flex-start',
  },
  tooltip: {
    textAlign: "left",
    font: "normal normal normal 14px/19px Roboto",
    color: "8D8C8C",
  },
  icon: {
    fontSize: "1.8rem",
    cursor: "pointer",
    marginRight: "10px",
    paddingTop: 0,
    paddingBottom: 0
  },
  iconMobile: {    
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      color: 'white',
    }
  },
}));

const UltimaActualizacion = (props) => {
  const classes = useStyles();
  const {
    onActualizarIndicadoresAnalisisDeudaCliente,    
    onUpdateUltimaActualizacionAnalisisDeudaCliente,
    ultimaActualizacionAnalisisDeudaCliente,
    onActualizarIndicadoresAnalisisDeudaProveedor,
    onUpdateUltimaActualizacionAnalisisDeudaProveedor,
    ultimaActualizacionAnalisisDeudaProveedor,
    ejecucionAnalisisDeudaProveedor,
    clienteSelected,
    ultimaActualizacionCliente,
    onActualizarIndicadoresCliente,
    onUpdateUltimaActualizacionCliente,
    proveedor,
    proveedorSelected,
    ultimaActualizacionProveedor,
    onActualizarIndicadoresProveedor,
    onUpdateUltimaActualizacionProveedor,
  } = props;

  const urlRef = global.window.location.pathname;
  const dateActual = () => {
    return  moment(Date.now()).format("DD/MM/YYYY HH:mm");
  }

  const actualizarFechaHora = (page) => {
    switch (page) {
      case pathNamePages.analisisDeudaCliente:
        return (
          ultimaActualizacionAnalisisDeudaCliente === "" &&
          onUpdateUltimaActualizacionAnalisisDeudaCliente(dateActual())
        );

      case pathNamePages.home:
        return (
          ultimaActualizacionAnalisisDeudaCliente === "" &&
          onUpdateUltimaActualizacionAnalisisDeudaCliente(dateActual())
        );
      
      case pathNamePages.cliente:
        return (ultimaActualizacionCliente === "" && onUpdateUltimaActualizacionCliente(dateActual()));

      case pathNamePages.analisisDeudaProveedor:
        return (
          ultimaActualizacionAnalisisDeudaProveedor === "" &&
          onUpdateUltimaActualizacionAnalisisDeudaProveedor(dateActual())
        );

      case pathNamePages.cliente:
        return (ultimaActualizacionProveedor === "" && onUpdateUltimaActualizacionProveedor(dateActual()));

      default:
        if(urlRef.includes(pathNamePages.cliente))
          return (ultimaActualizacionCliente === "" && onUpdateUltimaActualizacionCliente(dateActual()));
        else if(urlRef.includes(pathNamePages.proveedor))
        return (ultimaActualizacionProveedor === "" && onUpdateUltimaActualizacionProveedor(dateActual()));
        else
          break;
    }
  };

  const infoUltimaActualizacion = () => {
    switch (urlRef) {
      case pathNamePages.analisisDeudaCliente:
        return ultimaActualizacionAnalisisDeudaCliente;

      case pathNamePages.home:
        return ultimaActualizacionAnalisisDeudaCliente;

      case pathNamePages.analisisDeudaProveedor:
        return ultimaActualizacionAnalisisDeudaProveedor;
      
      case pathNamePages.cliente:
        return ultimaActualizacionCliente;

      case pathNamePages.proveedor:
        return ultimaActualizacionProveedor;

      default:
        if(urlRef.includes(pathNamePages.cliente))
          return ultimaActualizacionCliente;
        else if(urlRef.includes(pathNamePages.proveedor))
          return ultimaActualizacionProveedor;
        else
          break;
    }
  };

  const onActualizarIndicadoresClick = (e) => {
    e.preventDefault();
    
    switch (urlRef) {
      case pathNamePages.analisisDeudaCliente:
        return onActualizarIndicadoresAnalisisDeudaClienteSwitch();

      case pathNamePages.home:
        return onActualizarIndicadoresAnalisisDeudaClienteSwitch();

      case pathNamePages.analisisDeudaProveedor:
        return onActualizarIndicadoresAnalisisDeudaProveedorSwitch();

      case pathNamePages.cliente:
        return onActualizarIndicadoresClienteSwitch();

      case pathNamePages.proveedor:
        return onActualizarIndicadoresProveedorSwitch();

      default:
        if(urlRef.includes(pathNamePages.cliente))
          return onActualizarIndicadoresClienteSwitch();
        else if(urlRef.includes(pathNamePages.proveedor))
          return onActualizarIndicadoresProveedorSwitch();
        else
          break;
    }
  };

  const onActualizarIndicadoresAnalisisDeudaClienteSwitch = () => {
    if (props.ejecucionAnalisisDeudaCliente) {
      onUpdateUltimaActualizacionAnalisisDeudaCliente(dateActual());
      onActualizarIndicadoresAnalisisDeudaCliente(indicadoresOcultos);
    }
  };

  const onActualizarIndicadoresAnalisisDeudaProveedorSwitch = () => {
    if (ejecucionAnalisisDeudaProveedor) {
      onUpdateUltimaActualizacionAnalisisDeudaProveedor(dateActual());
      onActualizarIndicadoresAnalisisDeudaProveedor();
    }
  };

  const onActualizarIndicadoresClienteSwitch = () => {
    if (clienteSelected && clienteSelected.idCliente) {
      onUpdateUltimaActualizacionCliente(dateActual());
      onActualizarIndicadoresCliente(clienteSelected.idCliente);
    }
  };

  const onActualizarIndicadoresProveedorSwitch = () => {
    if (proveedorSelected && proveedor.idProveedor) {
      onUpdateUltimaActualizacionProveedor(dateActual());
      onActualizarIndicadoresProveedor(proveedor.idProveedor);
    }
  };

  useEffect(() => {
    actualizarFechaHora(urlRef);
  }, [urlRef]);

  return (
    <div className={classes.root} onClick={(e) => onActualizarIndicadoresClick(e)}>
      <LightTooltip
        arrow
        title={
          <Typography className={classes.tooltip}>
            Última actualización {infoUltimaActualizacion()}
          </Typography>
        }
      >
        <AccessTimeIcon
          className={classes.icon}
          style={{
            color: props.color, 
            fontSize: props.iconSize,
          }}          
        />
      </LightTooltip>
      <div className={classes.iconMobile}>
        <Typography>
          Última actualización:
        </Typography>
        <Typography>
          {infoUltimaActualizacion()}
        </Typography>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ultimaActualizacionAnalisisDeudaCliente: state.ultimasActualizaciones.analisisDeudaCliente,
    ultimaActualizacionAnalisisDeudaProveedor: state.ultimasActualizaciones.analisisDeudaProveedor,
    ultimaActualizacionCliente: state.ultimasActualizaciones.cliente,
    ultimaActualizacionProveedor: state.ultimasActualizaciones.proveedor,
    ejecucionAnalisisDeudaCliente: state.ejecucionPaginas.ejecucionAnalisisDeudaCliente,
    ejecucionAnalisisDeudaProveedor: state.ejecucionPaginas.ejecucionAnalisisDeudaProveedor,
    clienteSelected: state.clientes.clienteById.cliente,
    proveedor: state.proveedores.proveedorById.proveedor,
    proveedorSelected: state.proveedores.proveedorById.proveedorSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //#region Deuda Cliente
    onActualizarIndicadoresAnalisisDeudaCliente: (indicadoresOcultos) => dispatch(actions.actualizarIndicadoresAnalisisDeudaCliente(indicadoresOcultos)),
    onUpdateUltimaActualizacionAnalisisDeudaCliente: (fechaHora) => dispatch(actions.updateUltimaActualizacionAnalisisDeudaCliente(fechaHora)),
    //#endregion

    //#region Deuda Proveedor
    onActualizarIndicadoresAnalisisDeudaProveedor: () => dispatch(actions.actualizarIndicadoresAnalisisDeudaProveedor()),
    onUpdateUltimaActualizacionAnalisisDeudaProveedor: (fechaHora) => dispatch(actions.updateUltimaActualizacionAnalisisDeudaProveedor(fechaHora)),
    //#endregion

    //#region Cliente
    onActualizarIndicadoresCliente: (idCliente) => dispatch(actions.actualizarIndicadoresCliente(idCliente)),
    onUpdateUltimaActualizacionCliente: (fechaHora) => dispatch(actions.updateUltimaActualizacionCliente(fechaHora)),
    //#endregion

    //#region Proveedor
    onActualizarIndicadoresProveedor: (idProveedor) => dispatch(actions.actualizarIndicadoresProveedor(idProveedor)),
    onUpdateUltimaActualizacionProveedor: (fechaHora) => dispatch(actions.updateUltimaActualizacionProveedor(fechaHora)),
    //#endregion
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UltimaActualizacion);

import React, { useRef } from "react";
import { withRouter } from "react-router-dom";

import Chart from "react-apexcharts";

import Spinner from "../UI/Spinner/Spinner";
import FilterListIcon from "@material-ui/icons/FilterList";
import { IconButton, Tooltip } from "@material-ui/core";

import SinResultados from "../../assets/images/SinResultados.png";
import classes from "./Styles/BigChartCard.css";
import locales from "../../assets/shared/locales";
import { storageMonedaSigno } from "../../assets/shared/sessionData";
import { formatNumber } from "../../assets/shared/formatNumber";
import { colorsChart } from "../../assets/shared/colorsCharts";
import { enviarPorMailOption } from "../UI/EnviarChart/EnviarChartOptions";

const BarChart = (props) => {
  const {
    chartStacked,
    seriesRedirectGetter,
    history,
    onPorcionClick,
    colors,
    dataLabelsEnabled,
    dataLabelsFormatter,
    tooltipEnabled,
    tooltipCustom,
    gridShow,
    plotOptionsBarDistributed,
    categories,
    xaxisTickPlacement,
    disabledLegendShow,
    noBoxShadow,
    titleJustifyContent,
    alignTitleItems,
    title,
    onFiltersClick,
    disabled,
    border,
    children,
    loading,
    series,
    chartTextAlign,
    height,
  } = props;

  let chartRef = useRef(null);

  const options = {
    chart: {
      defaultLocale: "es",
      locales: locales,
      type: "bar",
      stacked: chartStacked,
      toolbar: {
        show: true,
        tools: {
          download: true,
          customIcons: [enviarPorMailOption(chartRef, props.title)],
        },
      },
      zoom: {
        enabled: true,
        type: "y",
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: "#90CAF9",
            opacity: 0.4,
          },
          stroke: {
            color: "#0D47A1",
            opacity: 0.4,
            width: 1,
          },
        },
      },
      events: {
        dataPointSelection: function (event, chartContext, config) {
          if (seriesRedirectGetter) {
            const redirect = seriesRedirectGetter
              ? seriesRedirectGetter(config.seriesIndex, config.dataPointIndex)
              : "";
            history.push(redirect);
          }
          if (onPorcionClick) {
            onPorcionClick(config);
          }
        },
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 5,
        left: 3,
        blur: 7,
        opacity: 0.2,
      },
    },
    colors: colors ? colors : colorsChart,
    dataLabels: {
      enabled: dataLabelsEnabled,
      formatter: function (val, opts) {
        return dataLabelsFormatter ? dataLabelsFormatter(val, opts) : val;
      },
    },
    tooltip: {
      enabled: tooltipEnabled,
      custom: tooltipCustom
        ? function ({ series, seriesIndex, dataPointIndex, w }) {
            return tooltipCustom(series, seriesIndex, dataPointIndex, w);
          }
        : undefined,
    },
    grid: { show: gridShow },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: plotOptionsBarDistributed,
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        trim: true,
      },
      tickPlacement: xaxisTickPlacement,
    },
    yaxis: {
      labels: {
        show: true,
        formatter: function (val) {
          return `${storageMonedaSigno()}${formatNumber(val)}`;
        },
      },
    },
    legend: {
      show: !disabledLegendShow,
      position: "right",
      offsetY: 100,
    },
    fill: {
      opacity: 1,
    },
  };

  /* const titleOnClickHandler = (redirectPath) => {
    if (redirectPath) history.push(redirectPath);
  };*/

  return (
    <div
      ref={chartRef}
      style={{
        height: "100%",
        width: "100%",
        boxShadow: noBoxShadow && "none",
      }}
      className={classes.BigChartCard}
    >
      <div
        style={{
          display: "flex",
          justifyContent: titleJustifyContent && titleJustifyContent,
        }}
      >
        <div className={classes.TitleCard}>
          <div
            style={{
              fontWeight: "bold",
              marginBottom: "1px",
              marginTop: "14px",
              width: "100%",
              display: "flex",
              alignItems: alignTitleItems ? alignTitleItems : "center",
              justifyContent: "space-between",
            }}
          >
            {title}
          </div>
          {onFiltersClick && !disabled && (
            <Tooltip title="Ver filtros del indicador">
              <IconButton size="small" onClick={() => onFiltersClick()}>
                <FilterListIcon
                  style={{ color: border && "white" }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {children}
      </div>
      {disabled ? (
        <div>indicador des</div>
      ) : // <img alt="Indicador Deshabilitado" src={IndicadorDeshabilitado} />
      loading ? (
        <Spinner />
      ) : series.length > 0 ? (
        <Chart
          options={options}
          style={{ textAlign: chartTextAlign ? chartTextAlign : "center" }}
          series={series}
          type="bar"
          height={height}
        />
      ) : (
        <img
          alt="Sin Resultados"
          src={SinResultados}
          width="100%" 
          style={{ maxWidth: '100vw', alignSelf: "center"}}
        />
      )}
    </div>
  );
};

export default withRouter(BarChart);

import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { storageUserEmail } from '../../assets/shared/sessionData';
import { urlPortalLogout } from '../../assets/shared/urlApps';

import * as actions from '../../store/actions';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const listItems = [
  {
    id: 'logout',
    title: 'Cerrar sesión'
  }
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
}));

const MenuUsuarioMobile = (props) => {
  const classes = useStyles();
  const { onShowModal } = props;  
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const onLogoutClick = () => {
    sessionStorage.clear();
    return setTimeout(() => urlPortalLogout(), 100);
  };

  const onItemClick = (selectedItem) => {
    if (selectedItem.id === 'logout') {
      return onLogoutClick();
    } else {
      return onShowModal(
        !selectedItem.hiddenTitle && selectedItem.title,
        selectedItem.body,
        selectedItem.fullWidth,
        selectedItem.maxWidth
      );
    }
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem button onClick={handleClick}>
        <ListItemText primary={storageUserEmail()} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {listItems.map((item, key) => (
            <ListItem 
              key={key} 
              button 
              className={classes.nested} 
              onClick={(event) => onItemClick(item)}
              alignItems={'flex-start'}
              divider              
            >
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </List>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowModal: (title, body, fullWidth, maxWidth) => {
      dispatch(actions.showGlobalModal(true, title, body, undefined, false, fullWidth, maxWidth));
    }
  };
};

export default connect(null, mapDispatchToProps)(MenuUsuarioMobile);
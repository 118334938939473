import React, { useEffect } from "react";
import { connect } from "react-redux";
import { dataOptions } from "../../../../assets/constants/dataOptions/OrdenamientoDeudaProveedor";

import * as actions from "../../../../store/actions";
import TablaCubo from "../../../UI/TablaCuboWrapper/TablaCubo";

const TablaDeudaDesglosadaPorMes = (props) => {
  const {
    // dataSelectedId,
    estaCargado,
    ejecucionDePagina,
    onLoadChart,
    deudasDataTable,
    fechaPieDeudas,
    loading,
    totalDeudas,
    onShowFiltersModal,
    onChangeFechaDeudasDelMes,
    ordenamientoSelected,
    onLoadDeudasDelMesPorOrdenamiento,
    onClearDeudasDelMesPorOrdenamiento,
    loadingPie,
  } = props;
  
  useEffect(() => {
    if (!estaCargado && ejecucionDePagina) {
      onLoadChart();
    }
  }, [ejecucionDePagina, estaCargado, onLoadChart]);

  const onDeudasDelMesAnioClick = (mes, anio, index) => {
    if (!loadingPie) {
      onChangeFechaDeudasDelMes(mes, anio);
      return dataOptions.map((value, index) => {
        return index !== ordenamientoSelected
          ? onClearDeudasDelMesPorOrdenamiento(index)
          : onLoadDeudasDelMesPorOrdenamiento(index, mes, anio);
      });
    }
  };

  return (
    <TablaCubo
      title={"Deudas desglosadas por mes"}
      titleMontos={"Deudas"}
      datas={deudasDataTable}
      mes={fechaPieDeudas.mes}
      anio={fechaPieDeudas.anio}
      loading={loading}
      total={totalDeudas}
      disableChangeDate={loadingPie}
      onSelectMesAnioClick={(mes, anio) => onDeudasDelMesAnioClick(mes, anio)}
      onShowFiltersModal={() => onShowFiltersModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    fechaPieDeudas: state.compras.fechaDeudasDelMesAnioProveedores,
    ordenamientoSelected: state.compras.ordenamientoDeudaDelMesPorSelectedProveedores,
    deudasDataTable: state.compras.deudasPorMesProveedores.deudasDataTable,
    loading: state.compras.deudasPorMesProveedores.loading,
    totalDeudas: state.compras.deudasPorMesProveedores.totalDeudas,
    estaCargado: state.compras.deudasPorMesProveedores.estaCargado,

    loadingPie:
      state.compras.deudaDelMesProveedoresPorCategoriaDeCliente.loading ||
      state.compras.deudaDelMesProveedoresPorProveedor.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () =>
      dispatch(
        actions.showListFiltersModal(true, "Deudas desglosadas por mes", [
          "venta",
        ])
      ),
    onLoadChart: (mes, anio) =>
      dispatch(actions.loadDeudaPorMesProveedores(mes, anio)),

    onChangeFechaDeudasDelMes: (mes, anio) =>
      dispatch(actions.seleccionFechaDeudaDelMesProveedoresPor(mes, anio)),

    onLoadDeudasDelMesPorOrdenamiento: (ordenamiento, mes, anio) => {
      dispatch(
        actions.loadDeudaDelMesPorOrdenamientoProveedores(
          ordenamiento,
          mes,
          anio
        )
      );
    },
    onClearDeudasDelMesPorOrdenamiento: (ordenamiento) => {
      dispatch(
        actions.clearDeudaDelMesPorOrdenamientoProveedores(ordenamiento)
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TablaDeudaDesglosadaPorMes);

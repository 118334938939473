// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SucursalesConfig__sucursalesContainer__jcSwz {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 1 1;\r\n}\r\n\r\n.SucursalesConfig__boxAgragarEditarSucursal__stP8Z {\r\n  background: #5151510f 0% 0% no-repeat padding-box;\r\n  border: 1px solid #70707080;\r\n  border-radius: 6px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 100%;\r\n}\r\n\r\n.SucursalesConfig__CheckboxRoot__cMUSN {\r\n  font-size: smaller;\r\n}\r\n\r\n.SucursalesConfig__InputAndControl__UV-iZ {\r\n  display: flex;\r\n  width: 50%;\r\n  padding-left: 20px;\r\n}\r\n\r\n.SucursalesConfig__ListaComprobantesContainer__4emY6 {\r\n  display: flex;\r\n  flex-direction: column;\r\n  max-height: 490px;\r\n  padding-left: 20px;\r\n  width: 100%;\r\n  flex-wrap: wrap;\r\n  overflow-x: auto;\r\n}\r\n\r\n.SucursalesConfig__TabsContainer__-wLmv {\r\n  background: #ffffff 0% 0% no-repeat padding-box;\r\n  border-radius: 3px;\r\n  min-width: 50%;\r\n  justify-content: center;\r\n}\r\n\r\n.SucursalesConfig__TabsScroller__Av2jd {\r\n  display: flex !important;\r\n  justify-content: center;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.SucursalesConfig__TabsIndicator__UbJZM {\r\n  height: 3px !important;\r\n  border-top-right-radius: 5px;\r\n  border-top-left-radius: 5px;\r\n  background-color: #09d4ff !important;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sucursalesContainer": "SucursalesConfig__sucursalesContainer__jcSwz",
	"boxAgragarEditarSucursal": "SucursalesConfig__boxAgragarEditarSucursal__stP8Z",
	"CheckboxRoot": "SucursalesConfig__CheckboxRoot__cMUSN",
	"InputAndControl": "SucursalesConfig__InputAndControl__UV-iZ",
	"ListaComprobantesContainer": "SucursalesConfig__ListaComprobantesContainer__4emY6",
	"TabsContainer": "SucursalesConfig__TabsContainer__-wLmv",
	"TabsScroller": "SucursalesConfig__TabsScroller__Av2jd",
	"TabsIndicator": "SucursalesConfig__TabsIndicator__UbJZM"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { pathNamePages } from '../../assets/shared/pathnames';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import LightTooltip from '../UI/LightTooltip/LightTooltip';
import { Typography } from '@material-ui/core';

const ButtonEjecucionPagina = (props) => {
  const {
    analisisDeudaCliente,
    home,
    analisisDeudaProveedor
  } = pathNamePages;

  const {
    ejecucionAnalisisDeudaCliente,
    paginaRender,
    ejecucionAnalisisDeudaProveedor,
    // vendedorSelected,
    // sucursalSelected
  } = props;

  useEffect(() => {
    ocultarBoton(paginaRender);
  }, [paginaRender]);

  const [botonOculto, setBotonOculto] = useState(false);

  const path = global.window.location.pathname;

  const ejecutarPagina = () => {
    path === home && ejecutarYOcultarBotonLocal('ejecutarAnalisisDeudaCliente', home);
    path === analisisDeudaCliente && ejecutarYOcultarBotonLocal('ejecutarAnalisisDeudaCliente', analisisDeudaCliente);
    path === analisisDeudaProveedor && ejecutarYOcultarBotonLocal('ejecutarAnalisisDeudaProveedor', analisisDeudaProveedor);

  };

  const ejecutarYOcultarBotonLocal = (ejecucionKey, paginaKey) => {
    path === paginaKey && props[ejecucionKey]();
    setBotonOculto(true);
  };

  const ocultarBoton = (pathRender) => {
    (pathRender === home || path === analisisDeudaCliente) && setBotonOculto(ejecucionAnalisisDeudaCliente);
    path === analisisDeudaProveedor &&
      setBotonOculto(ejecucionAnalisisDeudaProveedor);

  };

  return (
    !botonOculto && (
      <LightTooltip
        arrow
        title={
          <Typography style={{ textAlign: 'left', font: 'normal normal normal 14px/19px Roboto', color: '8D8C8C' }}>
            Ejecutar página
          </Typography>
        }
        placement="bottom"
      >
        <PlayCircleFilledWhiteOutlinedIcon
          onClick={() => ejecutarPagina()}
          style={{ color: 'white', fontSize: '1.8rem', cursor: 'pointer', marginRight: '10px' }}
        />
      </LightTooltip>
    )
  );
};

const mapStateToProps = (state) => ({
  ejecucionAnalisisDeudaCliente: state.ejecucionPaginas.ejecucionAnalisisDeudaCliente,
  ejecucionAnalisisDeudaProveedor: state.ejecucionPaginas.ejecucionAnalisisDeudaProveedor,

  

  paginaRender: state.ejecucionPaginas.paginaRender,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ejecutarAnalisisDeudaCliente: () => dispatch(actions.ejecutarAnalisisDeudaCliente()),
    ejecutarAnalisisDeudaProveedor: () => dispatch(actions.ejecutarAnalisisDeudaProveedor()),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonEjecucionPagina);

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardProveedor__CardProveedorContainer__nv4QM {\r\n  width: 100%;\r\n  height: 100%;\r\n  box-shadow: 0px 3px 6px #00000029;\r\n  /* margin: 10px 10px auto; */\r\n  background-color: white;\r\n  box-sizing: border-box;\r\n  text-align: left !important;\r\n  padding: 5px 15px 5px 15px;\r\n  border: 1px solid #d9d9d9;\r\n  border-radius: 3px;\r\n}\r\n\r\n.CardProveedor__Title__P3BQb {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n}\r\n\r\n.CardProveedor__nombreProveedor__5Bz0f {\r\n  display: flex;\r\n}\r\n\r\n.CardProveedor__Body__hXPx- {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: space-between;\r\n}\r\n\r\n.CardProveedor__columns__as5gV {\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.CardProveedor__separador__\\+H-wH {\r\n  width: 10px;\r\n}\r\n\r\n.CardProveedor__textos__eDyZL {\r\n  display: flex;\r\n  align-items: center;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n@media (max-width: 750px) {\r\n  .CardProveedor__Body__hXPx- {\r\n    display: inline-block;\r\n    justify-content: space-between;\r\n  }\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CardProveedorContainer": "CardProveedor__CardProveedorContainer__nv4QM",
	"Title": "CardProveedor__Title__P3BQb",
	"nombreProveedor": "CardProveedor__nombreProveedor__5Bz0f",
	"Body": "CardProveedor__Body__hXPx-",
	"columns": "CardProveedor__columns__as5gV",
	"separador": "CardProveedor__separador__+H-wH",
	"textos": "CardProveedor__textos__eDyZL"
};
export default ___CSS_LOADER_EXPORT___;

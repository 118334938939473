import * as actionTypes from '../actions/actionTypes';

const initialState = {
  ejecucionAnalisisDeudaCliente: false,
  ejecucionAnalisisDeudaProveedor: false,
  paginaRender: "/",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EJECUTAR_ANALISIS_DEUDA_CLIENTE:
      return { ...state, ejecucionAnalisisDeudaCliente: true };

    case actionTypes.EJECUTAR_ANALISIS_DEUDA_PROVEEDOR:
      return { ...state, ejecucionAnalisisDeudaProveedor: true };

    case actionTypes.CAMBIO_PAGINA_RENDER:
      return { ...state, paginaRender: action.page };

    default:
      return state;
  }
};

export default reducer;

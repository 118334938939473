import React from "react";
import ReactExport from "react-data-export";
import IconConvertToExcel from "../../../../assets/images/IconConvertToExcel.png";
import { IconButton, Tooltip } from "@material-ui/core";
import { storageMonedaSigno } from "../../../../assets/shared/sessionData";
import { formatNumber } from "../../../../assets/shared/formatNumber";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const TablaDeudasPorSeleccionToExcel = (props) => {
  const { fecha, deudasPor, deudas } = props;

  const headersStyle = {
    font: { sz: "12", bold: true },
    fill: { fgColor: { rgb: "FFD3D3D3" } },
    alignment: { horizontal: "center" },
  };

  const cellsStyleVerifNegative = (value, isTotal) => {
    return {
      font: { sz: "10", bold: false, color: { rgb: value < 0 && "FFFF0000" } },
      alignment: { horizontal: "right" },
      fill: isTotal && { fgColor: { rgb: "FFD3D3D3" } },
    };
  };

  const formatData = () => {
    var sumMonto = 0;
    var formatData = [];

    deudas.map((deuda) => {
      sumMonto = sumMonto += deuda.monto;
      const inTotal = !deuda.id;
      return formatData.push([
        {
          value: deuda.name,
          style: {
            font: { sz: "10", bold: inTotal },
            fill: inTotal && { fgColor: { rgb: "FFD3D3D3" } },
          },
        },
        {
          value: `${storageMonedaSigno()} ${formatNumber(deuda.deuda)}`,
          style: cellsStyleVerifNegative(deuda.deuda, inTotal),
        },
        {
          value: inTotal ? "-" : `${formatNumber(deuda.porcParticipacion)} %`,
          style: cellsStyleVerifNegative(deuda.porcParticipacion, inTotal),
        },
        {
          value: `${storageMonedaSigno()} ${formatNumber(deuda.creditoMaximo)}`,
          style: cellsStyleVerifNegative(deuda.creditoMaximo, inTotal),
        },
      ]);
    });

    return formatData;
  };

  const multiDataSet = [
    {
      columns: [
        {
          title: deudasPor,
          width: { wch: 60 },
          style: {
            font: { sz: "12", bold: true },
            fill: { fgColor: { rgb: "FFD3D3D3" } },
          },
        },
        {
          title: `Deuda`,
          width: { wpx: 150 },
          style: headersStyle,
        },
        {
          title: `Porc. Participación`,
          width: { wpx: 150 },
          style: headersStyle,
        },
        {
          title: `Crédito Máximo`,
          width: { wpx: 150 },
          style: headersStyle,
        },
      ],
      data: formatData(),
    },
  ];

  return (
    <ExcelFile
      filename={`Deudas por ${deudasPor} del ${fecha}`}
      element={
        <Tooltip title="Descargar en excel">
          <IconButton style={{ marginRight: "10px" }} size="small">
            <img alt="" src={IconConvertToExcel} height={"28px"} />
          </IconButton>
        </Tooltip>
      }
    >
      <ExcelSheet
        dataSet={multiDataSet}
        name={`Deudas por ${deudasPor.toLowerCase()}`}
      />
    </ExcelFile>
  );
};

export default TablaDeudasPorSeleccionToExcel;

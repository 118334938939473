import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Divider } from '@material-ui/core';

const dialog = (props) => {
  const handleClose = () => {
    props.onClose();
  };

  return (
    <Dialog
      fullWidth={props.fullWidth}
      fullScreen={props.fullScreen}
      maxWidth={props.maxWidth ? props.maxWidth : false}
      open={props.show}
      onClose={handleClose}
      style={{
        backdropFilter: 'blur(3px)'
      }}
    >
      {props.title && (
        <DialogTitle
          style={{
            padding: '0px',
            marginTop: '5px',
            paddingLeft: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            backgroundColor: props.backgroundColor
          }}
          disableTypography
        >
          {props.title}
        </DialogTitle>
      )}
      <DialogContent 
        style={{ 
          margin: '0px', 
          padding: '15px',
          backgroundColor: props.backgroundColor
        }}>
          {props.body}
        </DialogContent>
      <Divider />
      {props.actions && <DialogActions>{props.actions}</DialogActions>}
    </Dialog>
  );
};

export default dialog;

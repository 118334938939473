import React from 'react';
import { connect } from 'react-redux';
import * as actions from "../../../../store/actions";
import TablaCheques from './TablaCheques';

const TablaChequesEnCartera = (props) => {
  const { cheques, loading, estaCargado } = props;

  const data = () => {
    let listaCheques = estaCargado ? cheques : [];

    return listaCheques && listaCheques.map((cheque) => {
      return {
        valor: cheque.valor,
        nroCheque: cheque.nroCheque,
        orden: cheque.orden,
        importe: cheque.importe && cheque.importe.toFixed(2),        
        vencimiento: cheque.vencimiento,      
        estado: cheque.estado,      
        banco: cheque.banco,      
        cliente: cheque.clienteId,      
        razonSocial: cheque.razonSocial,      
        tenedor: cheque.tenedor,
      };
    });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <TablaCheques 
        data={data()} 
        loading={loading} 
        maxHeight={props.maxHeight}
        minHeight={props.minHeight}
        onFiltersClick={() => props.onShowFiltersModal()}
        tituloTabla={"Tabla Cheques en Cartera" }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cheques: state.cheques.listaChequesEnCartera.cheques,
    loading: state.cheques.listaChequesEnCartera.loading,
    estaCargado: state.cheques.listaChequesEnCartera.estaCargado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowFiltersModal: () => dispatch(actions.showListFiltersModal(true, 'Cheques en cartera', ['chequesCartera']))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaChequesEnCartera);
export const dataOptions = [
  {
    id: "categoriaDeCliente",
    title: "Categoría de proveedor",
    stateKey: "deudasPorCategoriaDeCliente",
    storeStateKey: "deudaDelMesProveedoresPorCategoriaDeCliente",
  },
  {
    id: "proveedor",
    title: "Proveedor",
    stateKey: "deudasPorProveedor",
    storeStateKey: "deudaDelMesProveedoresPorProveedor",
  },
];

import { IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '../Dialog/Dialog';

import classes from './ModalEstado.css';

export const ModalEstado = (props) => {
  return (
    <Dialog
      title={
        <div classes={classes.TitleDialogContent} style={{ width: '100%' }}>
          <div className={classes.TitleDialogTexto}>
            <Typography variant="h5">{props.title}</Typography>
            <div>
              <IconButton aria-label="close" onClick={() => props.onHiddenModal(false, '')}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </div>
      }
      fullScreen={true}
      show={props.show}
      body={props.content}
      onClose={() => props.onHiddenModal(false, '')}
      backgroundColor="#e5e5e5"
    />
  );
};

const mapStateToProps = (state) => {
  return {
    show: state.modals.estadoModal.show,
    title: state.modals.estadoModal.title,
    content: state.modals.estadoModal.body
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onHiddenModal: () => dispatch(actions.hiddenEstadoModal())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEstado);
import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import * as actions from "../../store/actions";
import moment from "moment";
import Dialog from '../../components/UI/Dialog/Dialog';
import { storageClienteId, storageMonedaId, storageMonedaSigno } from "../../assets/shared/sessionData";
import CardCliente from "../../components/Indicadores/Clientes/CardCliente/CardCliente";
import TextoUltimaCompra from "../../components/Indicadores/Clientes/TextoUltimaCompra/TextoUltimaCompra";
import PiePedidosPendientes from "../../components/Indicadores/Ventas/PiePedidosPendientes/PiePedidosPendientes";
import TextoPedidosPendientes from "../../components/Indicadores/Ventas/TextoPedidosPendientes/TextoPedidosPendientes";
import TextoPedidosPendientesComparativo from "../../components/Indicadores/Ventas/TextoPedidosPendientesComparativo/TextoPedidosPendientesComparativo";
import TextoVentasMensuales from "../../components/Indicadores/Ventas/TextoVentasMensuales/TextoVentasMensuales";
import TextoSaldoCliente from "../../components/Indicadores/Clientes/SaldoCliente/TextoSaldoCliente/TextoSaldoCliente";
import PieFacturasPendientes from "../../components/Indicadores/Ventas/PieFacturasPendientes/PieFacturasPendientes";
import TablaFacturasPendientes from "../../components/Indicadores/Ventas/TablaFacturasPendientes/TablaFacturasPendientes";
import TablaPedidosPendientes from "../../components/Indicadores/Ventas/TablaPedidosPendientes/TablaPedidosPendientes";
import TextoChequesRechazados from "../../components/Indicadores/Cheques/TextoChequesRechazados/TextoChequesRechazados";
import TextoCantidadVentas from "../../components/Indicadores/Ventas/TextoCantidadVentas/TextoCantidadVentas";
import LineEvolucionCompras from "../../components/Indicadores/Clientes/LineEvolucionCompras/LineEvolucionCompras";
import TextoCreditoDisponible from "../../components/Indicadores/Clientes/TextoCreditoDisponible/TextoCreditoDisponible";
import TextoCreditoMaximo from "../../components/Indicadores/Clientes/TextoCreditoMaximo/TextoCreditoMaximo";
import TableComprobantesCtaCte from '../../components/Indicadores/Clientes/TableComprobantesCtaCte/TableComprobantesCtaCte';
import TextoChequesEnCartera from "../../components/Indicadores/Cheques/TextoChequesEnCartera/TextoChequesEnCartera";
import LineChequesEnCartera from "../../components/Indicadores/Cheques/LineCheques/LineChequesEnCartera";
import TablaChequesEnCartera from "../../components/Indicadores/Cheques/TablaCheques/TablaChequesEnCartera";
import TablaChequesRechazados from "../../components/Indicadores/Cheques/TablaCheques/TablaChequesRechazados";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px', 
    }
  },
  containerSaldoCheques: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px',
      paddingLeft: '9px',
      marginTop: '5px', 
    }
  },
  containerCreditoSaldoCheques: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px',
      paddingLeft: '0px',
    }
  },
  containerCuentaCorriente: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px',
      paddingLeft: '0px',
      marginTop: '5px', 
    }
  },
  combinedChartsCard: {
    display: 'inline',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      margin: '5px 0px 0px 0px',
      border: '1px solid #d9d9d9',
      borderRadius: '3px',
      backgroundColor: 'white',
      boxShadow: '0px 3px 6px #00000029',
      paddingRight: '0px'
    }
  },
  gridItem: {   
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    }
  },
  gridItemSaldoCheques: {   
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    },
    [theme.breakpoints.up('sm')]: {
      paddingInline: '5px'
    }
  },
  combinedChartsCardPie: {  
    [theme.breakpoints.down('md')]: {
      marginBottom: '10px',
    },
  },
  combinedChartsCardTabla: {  
    [theme.breakpoints.down('md')]: {
      marginTop: '10px',
    },
  },
  tablaDeuda: {  
    margin: '0px',
    [theme.breakpoints.up('sm')]: {
      paddingRight: '5px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '5px 0px 5px 0px',
    }
  },
  pieDeuda: {
    margin: '0px',
    [theme.breakpoints.down('md')]: {
      marginTop: '5px',
      marginBottom: '10px',
      backgroundColor: 'white',
      border: '1px solid #d9d9d9',
      borderRadius: '3px',
      boxShadow: '0px 3px 6px #00000029',
    }
  },
  tablaSeleccion: {  
    marginTop: '5px',
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    }
  },
  lineEvolucion: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px', 
      paddingLeft: '9px',
      minHeight: '310px',
    },
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    }    
  },
  ordenesDeCompra: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '10px', 
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '5px',
    } 
  },
  cardProveedor: { 
    [theme.breakpoints.up('sm')]: {
      marginBottom: '10px', 
    },
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    }
  },  
  creditoSaldoCheques: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px', 
      marginTop: '5px',
    },
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    }    
  },
}));

const Cliente = (props) => {
  const classes2 = useStyles(); 
  const { onLoadChart } = props;

  const [idCliente] = useState(storageClienteId());  
  const [monedaPresentacion] = useState({ id: storageMonedaId(), signo: storageMonedaSigno() });
  const [dialogState, setDialogState] = useState({ show: false, content: '' });
  const inPhone = window.screen.width <= 750;

  const showContent = () => {
    switch (dialogState.content) {
      case 'EnCartera':
        return <Fragment>
          <LineChequesEnCartera />
          <br/>
          <TablaChequesEnCartera />
        </Fragment>;
      case 'Rechazados':
        return <Fragment>
          <TablaChequesRechazados />
        </Fragment>;
      default:
        break;
    }
  };

  const onShowDialogClick = (content) => {
    setDialogState({ show: true, content: content });
  };

  const handleCloseDialog = () => {
    setDialogState({ show: false, content: '' });
  };

  useEffect(() => {
    onLoadChart(idCliente);   
  }, [idCliente, onLoadChart]);

  const renderFila1 = () => {
    return (
      <Grid item xs={12} container className={classes2.container}>
        <Grid item xs={12} md={3} lg={3} container>
          <Grid item xs={12} className={classes2.cardProveedor}>
            <CardCliente />
          </Grid>
          <Grid item xs={12} className={classes2.gridItem}>
            <TextoVentasMensuales border />
          </Grid>
          <Grid item xs={12} className={classes2.ordenesDeCompra}>
            <TextoPedidosPendientesComparativo border />
          </Grid>
        </Grid>
        <Grid item xs={12} md={9} lg={9} container>
          <Grid item xs={12} className={classes2.lineEvolucion}>
            <LineEvolucionCompras height={295} minHeight={295} />
          </Grid>
          <Grid item xs={12} container className={classes2.containerSaldoCheques}>
            <Grid item xs={12} lg={3} className={classes2.gridItem}>
              <TextoSaldoCliente />
            </Grid>
            <Grid item xs={12} lg={3} className={classes2.gridItemSaldoCheques}>
              <TextoUltimaCompra />
            </Grid>
            <Grid item xs={12} lg={3} className={classes2.gridItemSaldoCheques}>
              <TextoCantidadVentas title={`Cantidad de ventas`} />
            </Grid>
            <Grid item xs={12} lg={3} className={classes2.gridItem}>
              <TextoPedidosPendientes />
            </Grid>
          </Grid>
        </Grid>
      </Grid>   
    );
  }

  const renderFila2 = () => {
    return (
      <Grid item xs={12} container className={classes2.combinedChartsCard} >
        <Grid item xs={12} md={3} lg={3} className={classes2.combinedChartsCardPie}>
          <PiePedidosPendientes noBorder minHeight={'280px'}/>
        </Grid>
        <Grid item xs={12} md={9} lg={9} className={classes2.combinedChartsCardTabla}>
          <TablaPedidosPendientes maxHeight={280} />
        </Grid>
      </Grid>
    )
  }

  const renderFila3 = () => {
    return (
      <Grid item xs={12} container className={classes2.containerCreditoSaldoCheques}>
        <Grid item xs={12} md={3} lg={3} container className={classes2.creditoSaldoCheques}>
          <TextoCreditoMaximo />
        </Grid>
        <Grid item xs={12} md={9} lg={9} container className={classes2.containerSaldoCheques}>
          <Grid item xs={12} md={3} lg={3} className={classes2.gridItem}>
            <TextoSaldoCliente />
          </Grid>
          <Grid item xs={12} md={3} lg={3} className={classes2.gridItemSaldoCheques}>
            <TextoCreditoDisponible border />
          </Grid>
          <Grid item xs={12} md={3} lg={3} className={classes2.gridItemSaldoCheques}>
            <TextoChequesEnCartera onBodyClick={() => onShowDialogClick('EnCartera')} />
          </Grid>
          <Grid item xs={12} md={3} lg={3} className={classes2.gridItem}>
            <TextoChequesRechazados onBodyClick={() => onShowDialogClick('Rechazados')} />            
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderFila4 = () => {
    return (
      <Grid item xs={12} container className={classes2.combinedChartsCard} >
        <Grid item xs={12} md={3} lg={3} className={classes2.combinedChartsCardPie}>
          <PieFacturasPendientes noBorder minHeight={'280px'}/>
        </Grid>
        <Grid item xs={12} md={9} lg={9} className={classes2.combinedChartsCardTabla}>
          <TablaFacturasPendientes maxHeight={280} />
        </Grid>
      </Grid>
    )
  }

  const renderFila5 = () => {
    return (
      <Grid item xs={12} className={classes2.containerCuentaCorriente}>
        <TableComprobantesCtaCte 
          monedaPresentacion={monedaPresentacion}
          maxHeight={450}
        />
      </Grid>
    )
  }

  return (
    <Fragment>
      <Grid container spacing={1}>
        {renderFila1()}
        {renderFila2()}
        {renderFila3()}
        {renderFila4()}
        {renderFila5()}
      </Grid>
      <Dialog
        show={dialogState.show}
        onClose={handleCloseDialog}
        body={<div style={{ minWidth: !inPhone && '500px' }}>{showContent()}</div>}
      />
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    cliente: state.clientes.clienteById.cliente,
    ejecucionClientes: state.ejecucionPaginas.ejecucionClientes,
    estaCargadoPedidos: state.ventas.comprobantesPedidosPendientes.estaCargado,
    estaCargadoFacturas: state.ventas.comprobantesFacturasPendientes.estaCargado,
    loadingPedidos: state.ventas.comprobantesPedidosPendientes.loading,
    loadingFacturas: state.ventas.comprobantesFacturasPendientes.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {    
    onLoadChart: (idCliente) => {
      dispatch(actions.loadClienteById(idCliente, () => {}));
      dispatch(actions.loadVentasMesComparativo(idCliente));
      dispatch(actions.loadPedidosPendientes(idCliente));
      dispatch(actions.loadEvolucionDeGastosMensual(idCliente));
      dispatch(actions.loadPedidosMesComparativo(idCliente));
      dispatch(actions.loadEstadosPedidosPendientes(idCliente));
      dispatch(actions.loadUltimaCompraByCliente(idCliente));      
      dispatch(actions.loadVentasPorVendedor(idCliente));
      dispatch(actions.loadComprobantesPedidosPendientes(idCliente));
      dispatch(actions.loadChequesRechazados(idCliente));
      dispatch(actions.loadChequesEnCartera(idCliente));
      dispatch(actions.loadListadoChequesEnCartera(idCliente));
      dispatch(actions.loadListadoChequesRechazados(idCliente));
      dispatch(actions.loadFacturasPendientes(idCliente));
      dispatch(actions.loadComprobantesFacturasPendientes(idCliente));
      dispatch(actions.loadCuentaCorrienteCliente(idCliente));
      dispatch(actions.loadLineChequesEnCartera(idCliente));

      const dateActual = moment(Date.now()).format("DD/MM/YYYY HH:mm");
      dispatch(actions.updateUltimaActualizacionCliente(dateActual));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cliente);
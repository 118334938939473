import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import Spinner from '../Spinner/Spinner';

const SucursalSelectorButtons = (props) => {
  const { onLoad, loading, sucursales, empresaSelectedId } = props;

  const [sucursalSelectedId, setSucursalSelectedId] = useState(-1);

  useEffect(() => {
    onLoad(empresaSelectedId);
  }, [empresaSelectedId]);

  const onCambiarSucursalClick = (sucursal) => {
    props.onChangeSucursalSelected(sucursal);
    setSucursalSelectedId(sucursal.id);
  };

  return loading ? (
    <Spinner personalSize="35px" />
  ) : (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px', zIndex: 1200 }}>
      {sucursales.map((sucursal, key) => {
        return (
          <button
            disabled={loading}
            key={key}
            style={{
              zIndex: 1300,
              margin: '0px',
              outline: 'none',
              color: sucursal.id === sucursalSelectedId ? '#FFFFFF' : '#CECECE',
              border: '0.5px solid #CECECE',
              textAlign: 'center',
              font: 'normal normal medium 12px/16px Roboto',
              backgroundColor: sucursal.id === sucursalSelectedId ? '#0CC5FF' : '#FFFFFF',
              borderRadius: '3px 0px 0px 3px',
              cursor: sucursal.id !== sucursalSelectedId && !loading && 'pointer'
            }}
            onClick={() => sucursal.id !== sucursalSelectedId && !loading && onCambiarSucursalClick(sucursal)}
          >
            {sucursal.nombre}
          </button>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  sucursales: state.sucursales.loadSucursales.sucursales,
  loading: state.sucursales.loadSucursales.loading
});

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (empresaId) => dispatch(actions.loadSucursales(empresaId, true))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SucursalSelectorButtons);

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import classes from "./NavigationItems.css";

import List from "@material-ui/core/List";

import NavigationItem from "../NavigationItem/NavigationItem";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { pagesInfo } from "../../../assets/shared/pagesInfo";

const NavigationItems = (props) => {
  const [itemSelected, setItemSelected] = useState();
  const { location: { pathname }, } = useHistory();

  useEffect(() => {
    setItemSelected(pathname);
  }, [pathname]);

  const buildListItems = () => {
    return pagesInfo.map(
      (page, index) =>
        page !== "/" && (
          <NavigationItem key={index} link={page.uri} title={page.name} 
            onClickItem={props.onClickItem}>
            {page.imgIcon ? (
              <Typography className={classes.NavigationItemIcon}>
                <img
                  alt=""
                  src={page.imgIcon}
                  style={{ marginLeft: "-4px", color: "blue" }}
                  className={classes.NavigationItemImg}
                  width={30}
                />
              </Typography>
            ) : (
              icon
            )}
          </NavigationItem>
        )
    );
  };

  return (
    <List className={classes.NavigationItems}>
      {props.isAuth && buildListItems()}
    </List>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.token !== null,
  };
};

export default connect(mapStateToProps)(NavigationItems);

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

// const sucursalTodos = { id: 'TODOS-SUCURSAL-GAUSS', nombre: 'Todas', todas: true };

const initialState = {
  vendedores: {
    vendedores: [],
    loading: false,
    error: null,
    estaCargado: false
  },
};

//#region loadVendedores
const loadVendedoresStart = (state) => {
  return updateObject(state, {
    vendedores: updateObject(state.vendedores, {
      loading: true
    })
  });
};

const loadVendedoresSuccess = (state, vendedores) => {
  return updateObject(state, {
    vendedores: updateObject(state.vendedores, {
      loading: false,
      vendedores,
      estaCargado: true,
      error: null
    })
  });
};

const loadVendedoresFail = (state, error) => {
  return updateObject(state, {
    vendedores: updateObject(state.vendedores, {
      loading: false,
      error
    })
  });
};
//#endregion



const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_VENDEDORES_START:
      return loadVendedoresStart(state);
    case actionTypes.LOAD_VENDEDORES_SUCCESS:
      return loadVendedoresSuccess(state, action.vendedores);
    case actionTypes.LOAD_VENDEDORES_FAIL:
      return loadVendedoresFail(state, action.error);

    default:
      return state;
  }
};

export default reducer;

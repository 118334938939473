import * as actionTypes from './actionTypes';
import { enqueueSnackbar } from './snackbar';
import axiosConfig from '../../axios-config';
import moment from 'moment';
import { storageEmpresaId, storageToken } from '../../assets/shared/sessionData';
import idIndicadores from '../../assets/shared/idIndicadores';

export const loadIndicadores = () => {
  return (dispatch) => {
    dispatch(loadIndicadoresStart());
    axiosConfig
      .get(
        `/configuraciones/IndicadoresPorUsuario?indicadorId=${idIndicadores.indicadoresPorUsuario}`
      )
      .then((response) => {
        dispatch(loadIndicadoresSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadIndicadoresFail(error));
      });
  };
};

const loadIndicadoresStart = () => {
  return {
    type: actionTypes.LOAD_CONFIGURACION_INDICADORES_START
  };
};

const loadIndicadoresSuccess = (indicadores) => {
  return {
    type: actionTypes.LOAD_CONFIGURACION_INDICADORES_SUCCESS,
    indicadores
  };
};

const loadIndicadoresFail = (error) => {
  return {
    type: actionTypes.LOAD_CONFIGURACION_INDICADORES_FAIL,
    error
  };
};

export const loadIndicadoresOcultos = (token, success) => {
  return (dispatch) => {
    dispatch(loadIndicadoresOcultosStart());
    axiosConfig
      .get(
        `/configuraciones/IndicadoresOcultosPorUsuario?indicadorId=${
          idIndicadores.indicadoresOcultosPorUsuario
        }`,
      )
      .then((response) => {
        const indicadoresOcultos = {
          ventasDelMes: response.data.includes(idIndicadores.ventasDelMes),
          ventasDelDia: response.data.includes(idIndicadores.ventasDelDia),
          ivaFiscal: response.data.includes(idIndicadores.ivaFiscalDelMes),
          cobranzasDelMes: response.data.includes(idIndicadores.cobranzasDelMes),
          gastosPorCategoria: response.data.includes(idIndicadores.gastosPorCategoria),
          saldoDeudores: response.data.includes(idIndicadores.saldoDeudores),
          saldoAcreedores: response.data.includes(idIndicadores.saldoAcreedores),
          plazoMedioVencACobrar: response.data.includes(idIndicadores.plazoMedioVencACobrar),
          plazoMedioVencAPagar: response.data.includes(idIndicadores.plazoMedioVencAPagar),
          promDiasDeCobranzas: response.data.includes(idIndicadores.promDiasDeCobranzas),
          promDiasDePagos: response.data.includes(idIndicadores.promDiasDePagos),
          chequesEnCartera: response.data.includes(idIndicadores.chequesEnCartera),
          chequesEmitidos: response.data.includes(idIndicadores.chequesEmitidos),
          gastosDelMes: response.data.includes(idIndicadores.gastosDelMes),
          pagosDelMes: response.data.includes(idIndicadores.pagosDelMes),
          ivaFiscalMultiempresa: response.data.includes(idIndicadores.ivaFiscalMultiempresa),
          chequesEnCarteraMultiempresa: response.data.includes(idIndicadores.chequesEnCarteraMultiempresa),
          chequesEmitidosPendientesMultiempresa: response.data.includes(
            idIndicadores.chequesEmitidosPendientesMultiempresa
          ),
          cobranzasMensualesMultiempresa: response.data.includes(idIndicadores.cobranzasMensualesMultiempresa),
          gastosDelMesMultiempresa: response.data.includes(idIndicadores.gastosDelMesMultiempresa),
          pagosDelMesMultiempresa: response.data.includes(idIndicadores.pagosDelMesMultiempresa),
          saldoAcreedoresMultiempresa: response.data.includes(idIndicadores.saldoAcreedoresMultiempresa),
          saldoDeudoresMultiempresa: response.data.includes(idIndicadores.saldoDeudoresMultiempresa),
          ventasDiariasMultiempresa: response.data.includes(idIndicadores.ventasDiariasMultiempresa),
          ventasMensualesMultiempresa: response.data.includes(idIndicadores.ventasMensualesMultiempresa),
          rentabilidadMultiempresa: response.data.includes(idIndicadores.rentabilidadMultiempresa),
          deudaPorCategoriaDeCliente: response.data.includes(idIndicadores.deudaPorCategoriaDeCliente),
          deudaPorCategoriaDeProveedor: response.data.includes(idIndicadores.deudaPorCategoriaDeProveedor)
        };

        dispatch(loadIndicadoresOcultosSuccess(indicadoresOcultos));
        return success();
      })
      .catch((error) => {
        dispatch(loadIndicadoresOcultosFail(error));
      });
  };
};

const loadIndicadoresOcultosStart = () => {
  return {
    type: actionTypes.LOAD_INDICADORES_OCULTOS_START
  };
};

const loadIndicadoresOcultosSuccess = (indicadores) => {
  return {
    type: actionTypes.LOAD_INDICADORES_OCULTOS_SUCCESS,
    indicadores
  };
};

const loadIndicadoresOcultosFail = (error) => {
  return {
    type: actionTypes.LOAD_INDICADORES_OCULTOS_FAIL,
    error
  };
};

export const postIndicadoresOcultos = (indicadores) => {
  return (dispatch) => {
    dispatch(executePostIndicadoresOcultos(true));
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .post(`/configuraciones/IndicadoresPorUsuario`, indicadores, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        window.location.reload();
        dispatch(executePostIndicadoresOcultos(false));
      })
      .catch((error) => {
        dispatch(executePostIndicadoresOcultos(false));
        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      });
  };
};

const executePostIndicadoresOcultos = (loading) => {
  return {
    type: actionTypes.EXECUTE_POST_INDICADORES_OCULTOS,
    loading
  };
};

export const loadCategoriasItemsDeCompras = (empresaId) => {
  return (dispatch) => {
    dispatch(loadCategoriasItemsDeComprasStart());
    axiosConfig
      .get(
        `/configuraciones/CategoriasItemsDeCompras?empresaId=${empresaId}&indicadorId=${
          idIndicadores.categoriaDeItems
        }`
      )
      .then((response) => {
        dispatch(loadCategoriasItemsDeComprasSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadCategoriasItemsDeComprasFail(error));
      });
  };
};

const loadCategoriasItemsDeComprasStart = () => {
  return {
    type: actionTypes.LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_START
  };
};

const loadCategoriasItemsDeComprasSuccess = (categoriasItemsDeCompras) => {
  return {
    type: actionTypes.LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_SUCCESS,
    categoriasItemsDeCompras
  };
};

const loadCategoriasItemsDeComprasFail = (error) => {
  return {
    type: actionTypes.LOAD_CONFIGURACION_CATEGORIAS_ITEMS_COMPRA_FAIL,
    error
  };
};

export const postCategoriasItemsCompra = (empresaId, categItems) => {
  return (dispatch) => {
    dispatch(executePostCategoriaItemsCompra(true));
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .post(`/configuraciones/CategoriasItemsDeCompras?idEmpresa=${empresaId}`, categItems, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        window.location.reload();
        dispatch(executePostCategoriaItemsCompra(false));
      })
      .catch((error) => {
        dispatch(executePostCategoriaItemsCompra(false));
        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      });
  };
};

const executePostCategoriaItemsCompra = (loading) => {
  return {
    type: actionTypes.EXECUTE_POST_CATEGORIAS_ITEMS_COMPRA,
    loading
  };
};

export const loadTiemposRequest = (clienteId = sessionStorage.getItem('clienteId')) => {
  return (dispatch) => {
    dispatch(loadTiemposRequestStart());
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .get(`/configuraciones/CabecerasTiemposIndicadores?clienteId=${clienteId}`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        let indicadores = [];
        let tiempos = [];
        response.data.map((request) => {
          return indicadores.push({
            id: request.id,
            nombre: request.nombre,
            ultimoTiempoService:
              request.tiemposRequest.length === 0 ? 0 : request.tiemposRequest[0].tiempoService.totalSeconds,
            ultimaFecha: request.tiemposRequest.length === 0 ? '-' : request.tiemposRequest[0].fecha
          });
        });
        dispatch(loadTiemposRequestSuccess(indicadores, tiempos));
      })
      .catch((error) => {
        dispatch(loadTiemposRequestFail(error));
      });
  };
};

export const clearTiemposReq = () => {
  return {
    type: actionTypes.CLEAR_TIEMPOS_REQUEST
  };
};

const loadTiemposRequestStart = () => {
  return {
    type: actionTypes.LOAD_TIEMPOS_REQUEST_START
  };
};

const loadTiemposRequestSuccess = (indicadores, tiempos) => {
  return {
    type: actionTypes.LOAD_TIEMPOS_REQUEST_SUCCESS,
    indicadores,
    tiempos
  };
};

const loadTiemposRequestFail = (error) => {
  return {
    type: actionTypes.LOAD_TIEMPOS_REQUEST_FAIL,
    error
  };
};

export const loadDetalleTiemposRequest = (
  indicadorId,
  clienteId = sessionStorage.getItem('clienteId'),
  success,
  fail
) => {
  const authStr = 'Bearer '.concat(storageToken());
  axiosConfig
    .get(
      `/configuraciones/DetalleTiemposIndicador?clienteId=${clienteId}&indicadorId=${indicadorId}`,
      {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      }
    )
    .then((response) => {
      return success(response.data);
    })
    .catch((error) => {
      return fail(error);
    });
};

export const actualizarIndicadores = (page) => {
  return {
    type: actionTypes.ACTUALIZAR_INDICADORES,
    page
  };
};

export const cambiarFechaFija = (fechaFija) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());

    axiosConfig
      .put(
        `/configuraciones/cambiarFechaFija?clienteId=${sessionStorage.getItem('clienteId')}`,
        fechaFija,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        sessionStorage.setItem('fechaFija', moment(response.data).format('MM-DD-YYYY'));
        window.location.reload();
      })
      .catch((error) => {});
  };
};

export const loadCategoriasDeProveedor = (empresaId) => {
  return (dispatch) => {
    dispatch(loadCategoriasDeProveedorStart());
    axiosConfig
      .get(
        `/configuraciones/CategoriasDeProveedores?empresaId=${empresaId}&indicadorId=${
          idIndicadores.categoriasDeProveedor
        }`
      )
      .then((response) => {
        dispatch(loadCategoriasDeProveedorSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadCategoriasDeProveedorFail(error));
      });
  };
};

const loadCategoriasDeProveedorStart = () => {
  return {
    type: actionTypes.LOAD_CATEGORIAS_DE_PROVEEDOR_START
  };
};

const loadCategoriasDeProveedorSuccess = (categorias) => {
  return {
    type: actionTypes.LOAD_CATEGORIAS_DE_PROVEEDOR_SUCCESS,
    categorias
  };
};

const loadCategoriasDeProveedorFail = (error) => {
  return {
    type: actionTypes.LOAD_CATEGORIAS_DE_PROVEEDOR_FAIL,
    error
  };
};

export const postCategoriasDeProveedor = (empresaId, categItems) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .post(`/configuraciones/CategoriasDeProveedores?idEmpresa=${empresaId}`, categItems, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      });
  };
};

export const CambiarAjustaPorInflacion = (ajustaInflacion) => {
  return (dispatch) => {
    dispatch(executeCambiarAjustaPorInflacion(true));
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .put(
        `/configuraciones/AjustaPorInflacion?calculoConInflacion=${ajustaInflacion}`,
        {},
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        dispatch(executeCambiarAjustaPorInflacion(false));
        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      });
  };
};

const executeCambiarAjustaPorInflacion = (loading) => {
  return {
    type: actionTypes.EXECUTE_CAMBIAR_AJUSTA_POR_INFLACION,
    loading
  };
};

export const loadInflacion = () => {
  return (dispatch) => {
    dispatch(loadInflacionStart());
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .get(`/configuraciones/Inflacion?listaSoloUltimoAnio=true`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        dispatch(loadInflacionSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadInflacionFail(error));
      });
  };
};

const loadInflacionStart = () => {
  return {
    type: actionTypes.LOAD_INFLACION_START
  };
};

const loadInflacionSuccess = (inflaciones) => {
  return {
    type: actionTypes.LOAD_INFLACION_SUCCESS,
    inflaciones
  };
};

const loadInflacionFail = (error) => {
  return {
    type: actionTypes.LOAD_INFLACION_FAIL,
    error
  };
};

export const LoadInflacionPorAnio = () => {
  return (dispatch) => {
    dispatch(loadInflacionPorAnioStart());
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .get(`/configuraciones/InflacionPorAnio`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        dispatch(loadInflacionPorAnioSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadInflacionPorAnioFail(error));
      });
  };
};

const loadInflacionPorAnioStart = () => {
  return {
    type: actionTypes.LOAD_INFLACION_POR_ANIO_START
  };
};

const loadInflacionPorAnioSuccess = (inflaciones) => {
  return {
    type: actionTypes.LOAD_INFLACION_POR_ANIO_SUCCESS,
    inflaciones
  };
};

const loadInflacionPorAnioFail = (error) => {
  return {
    type: actionTypes.LOAD_INFLACION_POR_ANIO_FAIL,
    error
  };
};

export const loadNovedades = (mostrarTodasLasNovedades) => {
  return (dispatch) => {
    dispatch(loadNovedadesStart());
    axiosConfig
      .get(
        `/configuraciones/Novedades?aplicacionId=${1}&mostrarTodasLasNovedades=${mostrarTodasLasNovedades}`
      )
      .then((response) => {
        dispatch(loadNovedadesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadNovedadesFail(error));
      });
  };
};

const loadNovedadesStart = () => {
  return {
    type: actionTypes.LOAD_NOVEDADES_START
  };
};

const loadNovedadesSuccess = (novedades) => {
  return {
    type: actionTypes.LOAD_NOVEDADES_SUCCESS,
    novedades
  };
};

const loadNovedadesFail = (error) => {
  return {
    type: actionTypes.LOAD_NOVEDADES_FAIL,
    error
  };
};

export const descartarNovedades = (success, fail) => {
  return (dispatch, getState) => {
    const novedades = getState().configuraciones.loadNovedades.novedades;
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .post(`/configuraciones/DescartarNovedades`, novedades, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        return success && success();
      })
      .catch((error) => {
        return fail && fail();
      });
  };
};

export const loadEstadosCheques = (idEmpresa = storageEmpresaId()) => {
  return (dispatch) => {
    dispatch(loadEstadosChequesStart());
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .get(`/cheques/Estados?idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.estadosCheques}`, {
        headers: { Authorization: authStr, Accept: 'application/json', 'Content-Type': 'application/json' }
      })
      .then((response) => {
        dispatch(loadEstadosChequesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadEstadosChequesFail(error));
      });
  };
};

const loadEstadosChequesStart = () => {
  return {
    type: actionTypes.LOAD_ESTADOS_CHEQUES_START
  };
};

const loadEstadosChequesSuccess = (estados) => {
  return {
    type: actionTypes.LOAD_ESTADOS_CHEQUES_SUCCESS,
    estados
  };
};

const loadEstadosChequesFail = (error) => {
  return {
    type: actionTypes.LOAD_ESTADOS_CHEQUES_FAIL,
    error
  };
};

export const loadChequesValorEstado = ({ propios, idEmpresa = storageEmpresaId(), success, errorFail }) => {
  return (dispatch) => {
    !success && dispatch(loadChequesValorEstadoStart());
    axiosConfig
      .get(
        `/cheques/ValoresYEstados?propios=${propios}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.chequesValorEstado
        }`
      )
      .then((response) => {
        !success && dispatch(loadChequesValorEstadoSuccess(response.data));
        return success && success(response.data);
      })
      .catch((error) => {
        !success && dispatch(loadChequesValorEstadoFail(error));
        return errorFail && errorFail(error);
      });
  };
};

const loadChequesValorEstadoStart = () => {
  return {
    type: actionTypes.LOAD_CHEQUES_VALOR_ESTADO_START
  };
};

const loadChequesValorEstadoSuccess = (chequesValorEstado) => {
  return {
    type: actionTypes.LOAD_CHEQUES_VALOR_ESTADO_SUCCESS,
    chequesValorEstado
  };
};

const loadChequesValorEstadoFail = (error) => {
  return {
    type: actionTypes.LOAD_CHEQUES_VALOR_ESTADO_FAIL,
    error
  };
};

export const postChequesValorEstado = (valoresYEstados, propios, idEmpresa = storageEmpresaId(), success, fail) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(storageToken());
    axiosConfig
      .post(
        `/cheques/ValoresYEstados?propios=${propios}&idEmpresa=${idEmpresa}&indicadorId=${
          idIndicadores.postChequesValorEstado
        }`,
        valoresYEstados,
        {
          headers: { Authorization: authStr }
        }
      )
      .then((response) => {
        return success && success();
      })
      .catch((error) => {
        return fail && fail();
      });
  };
};

export const enviarGraficosPorMail = (data, success) => {
  return (dispatch) => {
    axiosConfig
      .post(`/notificaciones/EnviarGraficosPorMail`, data)
      .then((response) => {
        dispatch(
          enqueueSnackbar({
            message: `Gráficos enviados por mail con éxito!`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
          })
        );

        return success && success();
      })
      .catch((error) => {
        let errorMessage = `Error al enviar los gráficos por mail, intente nuevamente.`;
        if (error.response && error.response.data) {
          errorMessage = error.response.data;
        }

        dispatch(
          enqueueSnackbar({
            message: errorMessage,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
            },
          })
        );
      });
  };
};

export const loadComprobantesPedidos = () => {
  return (dispatch) => {
    dispatch(loadComprobantesPedidosStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const empresaId = sessionStorage.getItem("empresaId");

    axiosConfig
      .get(
        `/ventas/ComprobantesPedidos?empresaId=${empresaId}&indicadorId=${idIndicadores.comprobantesPedidos}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        dispatch(loadComprobantesPedidosSuccess(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500 || error.response.status === 404) {
            dispatch(loadComprobantesPedidosFail(error));
          } else {
            dispatch(loadComprobantesPedidos());
          }
        } else {
          dispatch(loadComprobantesPedidosFail(error));
        }
      });
  };
};

const loadComprobantesPedidosStart = () => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PEDIDOS_START,
  };
};

const loadComprobantesPedidosSuccess = (comprobantesPedidos) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PEDIDOS_SUCCESS,
    comprobantesPedidos,
  };
};

const loadComprobantesPedidosFail = (error) => {
  return {
    type: actionTypes.LOAD_COMPROBANTES_PEDIDOS_FAIL,
    error,
  };
};

export const postComprobantesPedidos = (empresaId, comprobantes) => {
  return (dispatch) => {
    console.log(comprobantes)
    dispatch(executePostComprobantes(true));
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));

    axiosConfig
      .post(
        `/ventas/ComprobantesPedidos?idEmpresa=${empresaId}`,
        comprobantes,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        dispatch(executePostComprobantes(false));

        window.location.reload();
      })
      .catch((error) => {
        dispatch(executePostComprobantes(false));
      });
  };
};

const executePostComprobantes = (loading) => {
  return {
    type: actionTypes.EXECUTE_POST_COMPROBANTES,
    loading,
  };
};
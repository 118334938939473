import React from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import BarDeudasDelMesPorSeleccion from "../../components/Indicadores/Compras/BarDeudasDelMesPorSeleccion/BarDeudasDelMesPorSeleccion";
import LineEvolucionDeudasPorMes from "../../components/Indicadores/Compras/LineEvolucionDeudasPorMes/LineEvolucionDeudasPorMes";
import PieDeudasDelMesPorSeleccion from "../../components/Indicadores/Compras/PieDeudasDelMesPorSeleccion/PieDeudasDelMesPorSeleccion";
import TablaDeudaDesglosadaPorMes from "../../components/Indicadores/Compras/TablaDeudaDesglosadaPorMes/TablaDeudaDesglosadaPorMes";
import TablaDeudasDelMesPorSeleccion from "../../components/Indicadores/Compras/TablaDeudasDelMesPorSeleccion/TablaDeudasDelMesPorSeleccion";
import ConteinerWithEjecutarPagina from "../../components/UI/ConteinerWithEjecutarPagina/ConteinerWithEjecutarPagina";
import { Grid } from "@material-ui/core";
import BoxInfo from "../../components/UI/BoxInfo/BoxInfo";
import { inflacionActivado } from "../../assets/shared/utility";

const useStyles = makeStyles((theme) => ({
  boxInfo: {
    marginTop: '10px ',
  },
  combinedChartsCard: {
    display: 'inline',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      border: '1px solid #d9d9d9',
      borderRadius: '3px',
      backgroundColor: 'white',
      boxShadow: '0px 3px 6px #00000029',
    }
  },
  combinedChartsCardMargin: {
    display: 'inline',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      margin: '5px 0px 0px 0px',
      flexDirection: 'row',
      border: '1px solid #d9d9d9',
      borderRadius: '3px',
      backgroundColor: 'white',
      boxShadow: '0px 3px 6px #00000029',
    }
  },
  gridItem: {
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    }
  },
  tablaDeuda: {
    margin: '0px',
    [theme.breakpoints.up('sm')]: {
      paddingRight: '5px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '5px 0px 5px 0px',
    }
  },
  pieDeuda: {
    margin: '0px',
    [theme.breakpoints.down('md')]: {
      marginTop: '5px',
      marginBottom: '10px',
      backgroundColor: 'white',
      border: '1px solid #d9d9d9',
      borderRadius: '3px',
      boxShadow: '0px 3px 6px #00000029',
    }
  },
  tablaSeleccion: {
    marginTop: '5px',
    [theme.breakpoints.down('md')]: {
      marginBlock: '5px',
    }
  }
}));

const AnalisisDeudaProveedores = (props) => {
  sessionStorage.setItem('seccionNombre', 'Análisis deuda de proveedor');

  const classes = useStyles();
  const { ejecucionAnalisisDeudaProveedor, } = props;

  return (
    <ConteinerWithEjecutarPagina
      showMultiempresa
      isExecuted={!ejecucionAnalisisDeudaProveedor}
    >
      <Grid container>
        <Grid item container>
          <Grid item xs={12} lg={4} className={classes.tablaDeuda}>
            <TablaDeudaDesglosadaPorMes ejecucionDePagina={ejecucionAnalisisDeudaProveedor} />
          </Grid>
          <Grid item xs={12} lg={8} className={classes.gridItem}>
            <LineEvolucionDeudasPorMes />
          </Grid>
        </Grid>
        {inflacionActivado() &&
          <Grid item xs={12} className={classes.boxInfo}>
            <BoxInfo>
              <span>Para los siguientes gráficos, no se toma en cuenta la inflación</span>
            </BoxInfo>
          </Grid>
        }
        <Grid item container className={inflacionActivado() ? classes.combinedChartsCard : classes.combinedChartsCardMargin}>
          <Grid item xs={12} lg={4} className={classes.pieDeuda}>
            <PieDeudasDelMesPorSeleccion
              ejecucionDePagina={ejecucionAnalisisDeudaProveedor}
            />
          </Grid>
          <Grid item xs={12} lg={8} className={classes.gridItem}>
            <BarDeudasDelMesPorSeleccion />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} className={classes.tablaSeleccion}>
          <TablaDeudasDelMesPorSeleccion />
        </Grid>
      </Grid>
    </ConteinerWithEjecutarPagina>
  );
};

const mapStateToProps = (state) => {
  return {
    ejecucionAnalisisDeudaProveedor: state.ejecucionPaginas.ejecucionAnalisisDeudaProveedor,
    ordenamientoSelected: state.compras.ordenamientoDeudaDelMesPorSelected,
    fechaDeudaDelMes: state.compras.fechaDeudasDelMesAnio,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalisisDeudaProveedores);

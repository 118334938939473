import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  comprobantesGastos: {
    comprobantes: [],
    loading: false,
    error: null,
    estaCargado: false,
  },
};

//#region load comprobantes de gastos
const loadComprobantesGastosStart = (state) => {
  return updateObject(state, {
    comprobantesGastos: updateObject(state.comprobantesGastos, {
      loading: true,
      comprobantes: [],
      error: null,
    }),
  });
};

const loadComprobantesGastosSuccess = (state, comprobantes) => {
  return updateObject(state, {
    comprobantesGastos: updateObject(state.comprobantesGastos, {
      loading: false,
      comprobantes,
      estaCargado: true,
    }),
  });
};

const loadComprobantesGastosFail = (state, error) => {
  return updateObject(state, {
    comprobantesGastos: updateObject(state.comprobantesGastos, {
      loading: false,
      error: error,
      estaCargado: true,
    }),
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_COMPROBANTES_GASTOS_START:
      return loadComprobantesGastosStart(state);
    case actionTypes.LOAD_COMPROBANTES_GASTOS_SUCCESS:
      return loadComprobantesGastosSuccess(state, action.comprobantesGastos);
    case actionTypes.LOAD_COMPROBANTES_GASTOS_FAIL:
      return loadComprobantesGastosFail(state, action.error);

    default:
      return state;
  }
};

export default reducer;
